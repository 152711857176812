import { Fragment } from "react";
import { Divider, Grid } from "@mui/material";
// import { createSvgIcon } from "@mui/material/utils";
import Idea from "../../assets/icons/ideaIcon.svg";
import ErrorIcon from "../../assets/icons/errorCircle.svg";
import Lupa from "../../assets/icons/lupa.svg";
import NewLight from "../../assets/icons/noSearchIcon.svg";
import FoundNew from "../../assets/icons/newFound.svg";
export function NoResult({
  titulo,
  subtitulo,
  noDivider,
  error,
  dontSearch,
  newDontSearch,
  newFound
}: any) {
  return (
    <Fragment>
      {noDivider ? null : (
        <Grid item xs={12}>
          <Divider orientation="horizontal" />
        </Grid>
      )}
      <div className="box-resultados">
        <Grid
          pb="22px"
          pt="70px"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {error ? (
            <img src={ErrorIcon} alt="errorIcon" />
          ) : dontSearch ? (
            <img src={Idea} alt="idea" />
          ) : newDontSearch ? (
            <img src={NewLight} alt="idea" />
          ) : newFound ?
            <img src={FoundNew} alt="lupa" />
            : <img src={Lupa} alt="lupa" />}
        </Grid>
        <Grid
          pb="4px"
          sx={{ margin: "auto 0" }}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <label className="TituloResultadoTabla">{titulo}</label>
        </Grid>
        <Grid
          pb="70px"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <label className="subtituloResultadoTabla">{subtitulo}</label>
        </Grid>
      </div>
    </Fragment>
  );
}
