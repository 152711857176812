import { useEffect, useState } from "react";
import { useCurrency } from "src/hooks/useCurrency";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { StyledTableCell } from "src/components/tables/tableStyle";
import { littleTitle, subTable } from "src/components/typography/styles";
import {
  CustomModal,
  CustomModalTitle,
  NoResult,
  NoResults,
} from "src/components";
import { paginatorTheme } from "src/components/paginator/paginatorStyle";
import { SkeletonHistoryUF } from "./loadersUF";
import constants from "src/constants/constants";
import { getCurrentPeriod } from "src/utils/dates";
import moment from "moment";

const {
  COMPONENTS: {
    BUTTONS: { CLOSE },
  },
} = constants;

type HistoryUFProps = {
  onOpen: boolean;
  onClose: () => void;
};

const HistoryUF = ({ onOpen, onClose }: HistoryUFProps) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);

  const { currencyList, count, rows, loadingListCurrency, errorListCurrency } =
    useCurrency();

  const getListCurrency = () => {
    currencyList({
      limit: rowsPerPage,
      offset: (page - 1) * rowsPerPage,
    });
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleClose = () => {
    onClose();
    setPage(1);
    setRowsPerPage(5);
  };

  const findLastDayName = (date: any) => {
    let formatDate = date.slice(0, 4) + "-" + date.slice(4, 6) + "-01";
    let endOfMonth = moment(formatDate).endOf("month").format("dddd DD");
    return endOfMonth.charAt(0).toUpperCase() + endOfMonth.slice(1);
  };

  useEffect(() => {
    onOpen && getListCurrency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen, rowsPerPage, page]);

  return (
    <CustomModal
      maxWidth="sm"
      fullWidth
      open={onOpen}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <CustomModalTitle id="" onClose={handleClose}>
        <Typography sx={littleTitle} textAlign="center" pt="32px">
          Histórico del UF
        </Typography>
      </CustomModalTitle>
      <DialogContent dividers sx={{ overflow: "visible" }}>
        {loadingListCurrency ? (
          <SkeletonHistoryUF rows={5} />
        ) : (
          <>
            {errorListCurrency ? (
              <NoResult
                titulo={"Error"}
                subtitulo={"Error en la carga de datos"}
                error
              />
            ) : (
              <>
                {count === 0 ? (
                  <TableContainer
                    component={Paper}
                    sx={{ boxShadow: "none", borderRadius: "none" }}
                  >
                    <NoResults text={"No hay información para mostrar."} />
                  </TableContainer>
                ) : (
                  <TableContainer
                    className="grayScroll"
                    component={Paper}
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid #D2D6D9",
                      maxHeight: "590px",
                      boxShadow: "0px 2px 5px 0px #C9CFE3BF",
                    }}
                  >
                    <Table
                      sx={{ minWidth: "auto" }}
                      aria-label="simple table"
                      stickyHeader
                    >
                      <TableHead className="color-head-table">
                        <TableRow>
                          <StyledTableCell>Año</StyledTableCell>
                          <StyledTableCell>Día y Mes</StyledTableCell>
                          <StyledTableCell>Valor UF</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {rows.map((row: any, index: number) => {
                        return (
                          <TableBody>
                            <TableRow key={index}>
                              <TableCell>
                                <Typography variant="h5">
                                  {getCurrentPeriod(row.periodName)[2] || ""}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {
                                    getCurrentPeriod(row.periodName)[0]?.split(
                                      " "
                                    )[0]
                                  }
                                </Typography>
                                <Typography sx={subTable}>
                                  {findLastDayName(
                                    getCurrentPeriod(row.periodName)[3]
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {row.currencyValue.toLocaleString("es-cl")}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })}
                    </Table>
                    <ThemeProvider theme={paginatorTheme}>
                      <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={"Mostrar por página"}
                        sx={{ mt: "24px", mb: "24px" }}
                        labelDisplayedRows={(e) => {
                          return page + " de " + Math.ceil(count / rowsPerPage);
                        }}
                      />
                    </ThemeProvider>
                  </TableContainer>
                )}
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="end"
          alignItems="center"
        >          
          <Button onClick={handleClose} color="primary">
            {CLOSE}
          </Button>
        </Grid>
      </DialogActions>
    </CustomModal>
  );
};
export default HistoryUF;
