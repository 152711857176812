import { MenuItem, Select } from "@mui/material";
import { CustomSelectInput, CustomSelectInputSlim, menuItemStyle, menuSelectStyle } from "./Select";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

export const CustomSelect = (props: any) => {
  return (
    <Select
      onChange={props.onChange}
      disabled={props.disabled}
      value={props.value || 0}
      sx={{ width: "100%", textTransform: "capitalize" }}
      input={ props.slim ? 
        <CustomSelectInputSlim
        className={props.borderColor ? "border-color" : ""}
      />
      :
        <CustomSelectInput
          className={props.borderColor ? "border-color" : ""}
        />
      }
      MenuProps={menuSelectStyle}
      name={props.name}
      IconComponent={KeyboardArrowDownRoundedIcon}
      data-testid="select-button"
    >
      <MenuItem
        disabled
        key={0}
        value={0}
        className="display-none"
        sx={props.hideTitle ? { display: "none" } : menuItemStyle}
        data-testid="custom-select-disabled-option"
      >
        {props.titulo}
      </MenuItem>
      {props.data?.map((elem: any, idx: any) => (
        <MenuItem
          key={idx + 1}
          value={idx + 1}
          sx={menuItemStyle}
          data-testid="custom-select-option"
          disabled={elem.disabled}
        >
          {elem.nombre ? elem.nombre : elem}
        </MenuItem>
      ))}
    </Select>
  );
};
