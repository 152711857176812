import { Grid, Typography } from "@mui/material";
import ErrorText from "./errorText";
import "./styles.css";
import Trash from "../../assets/icons/trash.svg";
import ErrorTrash from "../../assets/icons/errorTrash.svg";
import Check from "../../assets/icons/checkFile.svg";
import Cross from "../../assets/icons/crossFile.svg";
import { useEffect, useState } from "react";

export function FileHeader(props) {
  const [renderStatus, setRenderStatus] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (props.uploadInfo !== undefined) {      
      for (const upload of props.uploadInfo) {        
        if (upload.name === props.object.file.name) {
          setRenderStatus(upload.status);
          setErrorMessage(upload.error);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.uploadInfo]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {props.error === false ? (
          renderStatus === 1 ? (
            <>
              <Grid xs={12} className="groupDrag">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid xs={11}>
                    <Typography className="nameFileAdjunto">
                      {props.object.file.name}
                    </Typography>
                  </Grid>
                  <Grid xs={1} textAlign="right" pr="20px">
                    <div
                      alt="icon loading"
                      data-testid="loading-icon"
                      className="spinner"
                    ></div>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : renderStatus === 2 ? (
            <Grid xs={12} className="groupCheck">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid xs={11}>
                  <Typography className="nameFileAdjuntoCheck">
                    {props.object.file.name}
                  </Typography>
                </Grid>
                <Grid xs={1} textAlign="right" pr="20px">
                  <img
                    alt="icon check"
                    src={Check}
                    data-testid="delete-check"
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : renderStatus === 3 ? (
            <>
              <Grid xs={12} className="groupDragError">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid xs={11}>
                    <Typography className="nameFileNoAdjunto">
                      {props.object.file.name}
                    </Typography>
                  </Grid>
                  <Grid xs={1} textAlign="right" pr="20px">
                    <img
                      alt="icon cross"
                      src={Cross}
                      data-testid="cross-icon"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Typography className="tagNoAdjuntado">{errorMessage}</Typography>
            </>
          ) : (
            <Grid xs={12} className="groupDrag">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid xs={11}>
                  <Typography className="nameFileAdjunto">
                    {props.object.file.name}
                  </Typography>
                </Grid>
                <Grid xs={1} textAlign="right" pr="20px">
                  <img
                    className="cursorPointer"
                    alt="icon delete"
                    src={Trash}
                    onClick={() => props.onDelete(props.object)}
                    data-testid="delete-icon"
                  />
                </Grid>
              </Grid>
            </Grid>
          )
        ) : (
          <>
            <Grid xs={12} className="groupDragError">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid xs={11}>
                  <Typography className="nameFileNoAdjunto">
                    {props.object?.file?.name}
                  </Typography>
                </Grid>
                <Grid xs={1} textAlign="right" pr="20px">
                  <img
                    alt="icon delete"
                    className="cursorPointer"
                    src={ErrorTrash}
                    onClick={() => props.onDelete(props.object)}
                    data-testid="delete-icon"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Typography className="tagNoAdjuntado" data-testid="err">
              {ErrorText(props?.object?.errors)}
            </Typography>
          </>
        )}
      </Grid>
    </>
  );
}
