import {
  GET_SURPLUS,
  GET_SURPLUS_CONSOLIDATED,
  SURPLUS_TOTALS,
} from "src/services/graphql/queries";
import { useLazyQuery } from "@apollo/client";

type getSurplusInputType = {
  contributorRut: string | null;
  isActive?: boolean;
  limit: number;
  offset: number;
};

type getConsolidatedInputType = {
  contributorRut: string;
  dateLimit: number;
  limit: number;
  offset: number;
};

export const useSurplus = ({
  collectionPeriod,
}: {
  collectionPeriod: string;
}) => {
  const [
    getSurplus,
    {
      data: {
        period: {
          distribution: {
            surplus: {
              allBy: { count: surplusCount, rows: surplusRows },
            },
          },
        },
      } = {
        period: {
          distribution: {
            surplus: {
              allBy: { count: 0, rows: [] },
            },
          },
        },
      },
      loading: surplusLoading,
      error: surplusError,
    },
  ] = useLazyQuery(GET_SURPLUS, {
    fetchPolicy: "network-only",
  });

  const getSurplusData = (options: getSurplusInputType) => {
    getSurplus({
      variables: {
        input: {
          contributorRut: options.contributorRut || null,
          collectionPeriod: collectionPeriod,
          isActive: options.isActive,
          limit: options.limit,
          offset: options.offset,
        },
      },
    });
  };

  const [
    getSurplusConsolidated,
    {
      data: {
        period: {
          distribution: {
            surplus: {
              allConsolidatedBy: {
                count: consolidatedCount,
                rows: consolidatedRows,
              },
            },
          },
        },
      } = {
        period: {
          distribution: {
            surplus: { allConsolidatedBy: { count: 0, rows: [] } },
          },
        },
      },
      loading: consolidatedLoading,
      error: consolidatedError,
    },
  ] = useLazyQuery(GET_SURPLUS_CONSOLIDATED, {
    fetchPolicy: "network-only",
  });

  const getSuplusConsolidatedData = (options: getConsolidatedInputType) => {
    getSurplusConsolidated({
      variables: {
        input: {          
          contributorRut: options.contributorRut,
          dateLimit: options.dateLimit,
          includeAll: false,
          isActive: true,
          limit: options.limit,
          offset: options.offset,
        },
      },
    });
  };

  const [
    getSurplusTotals,
    {
      data: {
        period: {
          distribution: {
            surplus: { allTotalsBy },
          },
        },
      } = {
        period: {
          distribution: {
            surplus: {
              allTotalsBy: null,
            },
          },
        },
      },
      loading: loadingTotal,
      error: errorTotal,
    },
  ] = useLazyQuery(SURPLUS_TOTALS, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        collectionPeriod,
        isActive: true,
      },
    },
  });

  return {
    getSurplusData,
    surplusCount,
    surplusRows,
    surplusLoading,
    surplusError,
    getSuplusConsolidatedData,
    consolidatedCount,
    consolidatedRows,
    consolidatedLoading,
    consolidatedError,
    getSurplusTotals,
    allTotalsBy,
    loadingTotal,
    errorTotal,
  };
};
