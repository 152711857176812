
import {
    Box,
    Typography,
} from "@mui/material";

import Search from "../../assets/icons/search.svg"
const NoResults = ({ text, subText }: any) => {
    const styleTitle = {
        fontFamily: 'Interstate',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '22px',
        lineHeight: '26px',
        textAlign: 'center',
        letterSpacing: '0.0120588em',
        color: '#203442',
        pt: '27px',
        pb: '8px'
    }
    const styleSub = {
        fontFamily: 'Interstate',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '20px',
        textAlign: 'center',
        letterSpacing: '0.0120588em',
        color: '#364855',
        pb: '48px'
    }
    return (
        <Box pt="51px">
            <Box textAlign="center">
                <img src={Search} alt="search" />
            </Box>
            {
                text ? <Typography sx={styleTitle}>{text}</Typography> : <Typography sx={styleTitle}>Sin resultados</Typography>
            }
            {
                subText ? <Typography sx={styleSub}>{subText}</Typography> : <Typography sx={styleSub}>No se encontraron resultados para esta tabla</Typography>
            }
        </Box>
    );
};

export default NoResults;
