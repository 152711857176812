import constants from "src/constants/constants";

const {
  DEVOLUTION: {
    STATUS: {
      ID: { IN_PROCESS, PAID, REJECTED, UNINITIATED },
    },
  },
  PAGES: {
    DEVOLUTION,
    MODULES: { CREDITS, PAYMENTS, EXCESS, OVERAGE },
  },
  QUERIES: {
    INPUT: {
      PRESIGNED_URL_TYPE: {
        CONTRACTS: CONTRACTS_URL,
        DEVOLUTION: DEVOLUTION_URL,
        PAYMENTS: PAYMENTS_URL,
        EXCESS: EXCESS_URL,
        OVERAGE: OVERAGE_URL,
      },
      LOG_TYPE: {
        FILE_UPLOAD: {
          CONTRACTS: CONTRACTS_ID,
          DEVOLUTION: DEVOLUTION_ID,
          PAYMENTS: PAYMENTS_ID,
          EXCESS: EXCESS_ID,
          OVERAGE: OVERAGE_ID,
        },
      },
    },
  },
} = constants;

export const systemModules = (module: string) => {
  switch (module) {
    case CREDITS:
      return CONTRACTS_ID;
    case PAYMENTS:
      return PAYMENTS_ID;
    case EXCESS:
      return EXCESS_ID;
    case OVERAGE:
      return OVERAGE_ID;
    case DEVOLUTION:
      return DEVOLUTION_ID;
    default:
      return 0;
  }
};

export const presignedUrlType = (module: string) => {
  switch (module) {
    case PAYMENTS:
      return PAYMENTS_URL;
    case EXCESS:
      return EXCESS_URL;
    case OVERAGE:
      return OVERAGE_URL;
    case DEVOLUTION:
      return DEVOLUTION_URL;
    case CREDITS:
      return CONTRACTS_URL;
    default:
      return [];
  }
};

export const devolutionStatus = (selectId: number) => {
  switch (selectId) {
    case 1:
      return IN_PROCESS;
    case 2:
      return UNINITIATED;
    case 3:
      return PAID;
    case 4:
      return REJECTED;
    default:
      return null;
  }
};
