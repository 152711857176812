import { LOGS_BY } from "src/services/graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { useState } from "react";

type LogType = {
  limit?: number;
  offset?: number;
  logTypeId: number;
  fkId?: number;
};

export const useLogs = () => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [
    getData,
    {
      data: {
        log: {
          allBy: { count, rows },
        },
      } = {
        log: { allBy: { count: 0, rows: [] } },
      },
      loading,
      error,
    },
  ] = useLazyQuery(LOGS_BY, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      firstLoad && setFirstLoad(false)
    },
    onError: () => {
      firstLoad && setFirstLoad(false)
    },
  });

  const getLog = (options: LogType) => {
    getData({
      variables: {
        input: {
          limit: options.limit || 1,
          offset: options.offset || 0,
          logTypeId: options.logTypeId,
          fkId: options.fkId || null,
        },
      },
    });
  };

  return { getLog, count, rows, loading, error, firstLoad };
};
