import { styled } from '@mui/material/styles';
import { 
  TableCell, 
  tableCellClasses, 
  //TableRow 
} from '@mui/material';

export const StyledTableCell = styled(TableCell)({
  
  [`&.${tableCellClasses.head}`]: {    
    backgroundColor: '#F8F9F9',
    color: '#364855',
    fontFamily: 'interstate',
    fontStyle: 'normal',
    fontWeight:'400',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.0120588em',
    margin: '0px 4px',
    borderRadius: '0px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '14px',
    backgroundColor: '#FFFFFF',
  },

});


// Comentados porque aún no se utilizan y afectan los test,
// descomentar según uso.

// export const StyledTableRow = styled(TableRow)({
//   backgroundColor: '#FFFFFF',
//   '&:nth-of-type(odd)': {

//     backgroundColor: '#FFFFFF',
//     fontFamily: 'interstate',
//     fontStyle: 'normal',
//     fontWeight: '400',
//     fontSize: '14px',
//     lineHeight: '24px',
//     letterSpacing: ' 0.0120588em',
//     color: '#4C4C4C',
//     borderRadius: '0px',
//     paddingTop: '24px',
//     paddingBottom: '24px',
//     paddingLeft: '24px',
//     paddingRight: '38px'
//   },
//   '&:hover': {
//     backgroundColor: '#E6F6F5'
//   },

//   // hide last border
//   '&:last-child td, &:last-child th': {
//     border: 'transparent',
//   }
// })

// export const StyledTableRowColl = styled(TableRow)({
//   backgroundColor: '#E9EBEC',
//   '&:nth-of-type(odd)': {

//     backgroundColor: '#E9EBEC',
//     fontFamily: 'interstate',
//     fontStyle: 'normal',
//     fontWeight: '400',
//     fontSize: '14px',
//     lineHeight: '24px',
//     letterSpacing: ' 0.0120588em',
//     color: '#4C4C4C',
//     borderRadius: '0px',
//     paddingTop: '24px',
//     paddingBottom: '24px',
//     paddingLeft: '24px',
//     paddingRight: '38px'
//   },
//   '&:hover': {
//     backgroundColor: '#E6F6F5'
//   },

//   // hide last border
//   '&:last-child td, &:last-child th': {
//     border: 'transparent',
//   }
// })

// export const StyledTableCellFixed = styled(TableCell)({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: '#F9FAFB',
//     color: '#67737C',
//     fontFamily: 'interstate',
//     fontStyle: 'normal',
//     fontWeight: '400',
//     fontSize: '14px',
//     lineHeight: '24px',
//     letterSpacing: '0.0120588em',
//     margin: '0px 4px',
//     borderRadius: '0px'

//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: '14px',
//     backgroundColor: '#FFFFFF',
//   },

// })

// export const StyledTableRowFixed = styled(TableRow)({
//   backgroundColor: '#FFFFFF',
//   '&:nth-of-type(odd)': {
//     backgroundColor: '#FFFFFF',
//     fontFamily: 'interstate',
//     fontStyle: 'normal',
//     fontWeight: '400',
//     fontSize: '14px',
//     lineHeight: '24px',
//     letterSpacing: ' 0.0120588em',
//     color: '#4C4C4C',
//     borderRadius: '0px',
//     paddingTop: '24px',
//     paddingBottom: '24px',
//     paddingLeft: '24px',
//     paddingRight: '38px'
//   },
//   '&:hover': {
//     backgroundColor: '#E6F6F5'
//   },

// })

// export const styleHead = {
//   backgroundColor: '#F8F9F9',
//   color: '#364855',
// }

// export const stickyHead = {
//   position: "sticky",
//   backgroundColor: "#F8F9F9",
//   color: '#364855',
//   zIndex: 1,
//   left: 0, 
//   boxShadow: 'inset -5px 0px 5px 0px rgb(201 207 227 / 35%)'
// }
// export const stickyBodyRight = (right: string) => ({
//   position: "sticky",
//   background: "white",
//   right: right
// });
// export const stickyBodyRightF = (right: string) => ({
//   position: "sticky",
//   background: "white",
//   right: right,
//   border: 'none'
// });
// export const stickyBodyRightShadow = (right: string) => ({
//   boxShadow: 'inset 5px 0px 5px 0px rgb(201 207 227 / 35%)',
//   position: "sticky",
//   background: "white",
//   right: right,
  
// });

// export const stickyBodyRightShadowF = (right: string) => ({
//   boxShadow: 'inset 5px 0px 5px 0px rgb(201 207 227 / 35%)',
//   position: "sticky",
//   background: "white",
//   border: 'none',
//   right: right,
  
// });

// export const stickyBodyLeft = (left: string) => ({
//   position: "sticky",
//   background: "white",
//   left: left,
//   boxShadow: 'inset -5px 0px 5px 0px rgb(201 207 227 / 35%)'
// });


// export const stickyBodyRightHead = (right: string) => ({
//   position: "sticky",
//   background: "#F8F9F9",
//   color: '#364855',
//   fontWeight: 400,
//   fontSize: '14px',
//   lineHeight: '24px',
//   letterSpacing: '0.0120588em',
//   right: right,

// });
// export const stickyBodyRightHeadShadow = (right: string) => ({
//   position: "sticky",
//   background: "#F8F9F9",
//   color: '#364855',
//   fontWeight: 400,
//   fontSize: '14px',
//   lineHeight: '24px',
//   letterSpacing: '0.0120588em',
//   right: right,
//   boxShadow: 'inset 5px 0px 5px 0px rgb(201 207 227 / 35%)',

// });

// export const stickyRightHeadShadow = (right: string) => ({
//   position: "sticky",
//   background: "#F8F9F9",
//   color: '#364855',
//   fontWeight: 400,
//   fontSize: '14px',
//   lineHeight: '24px',
//   letterSpacing: '0.0120588em',
//   right: right,
//   boxShadow: 'inset 5px 0px 5px 0px rgb(201 207 227 / 35%)',
// });

// export const stickyRightShadow = (right: string) => ({
//   position: "sticky",
//   background: "white",
//   right: right,
//   boxShadow: 'inset 5px 0px 5px 0px rgb(201 207 227 / 35%)',
// });

// export const stickyBodyLeftHead = (left: string) => ({
//   position: "sticky",
//   background: "#F8F9F9",
//   left: left,
//   boxShadow: 'inset -5px 0px 5px 0px rgb(201 207 227 / 35%)'
// });

