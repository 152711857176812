import { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Typography,
  Button,
  Stack,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";
import {
  blackBold,
  littleText,
  periodStyle,
} from "src/components/typography/styles";
import CloseIcon from "@mui/icons-material/Close";
import { borderBox, filterBox } from "src/components/box/styles";
import { Alerts, Breadcrum, CustomSelect, TabPanel } from "src/components";
import { getCurrentPeriod } from "src/utils/dates";
import { useNavigate } from "react-router-dom";
import { GET_ORIGIN } from "src/services/graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { SkeletonHeaderC } from "./credits/components/skeletonsC";
import TableCollection from "./collectionTable/collectionTable";
import TableCredits from "./creditsTable/TableCredits";
import WarningModalCollection from "./collectionTable/warningModalCollection";
import { hasPermissions } from "src/utils/hasPermissions";
import constants from "src/constants/constants";
import { useLogs } from "src/hooks/useLogs";
import { usePeriod } from "src/hooks/usePeriod";
import { useContract } from "./hooks/useContract";

const {
  QUERIES: {
    INPUT: {
      LOG_TYPE: { CLOSE_COLLECTION },
    },
    FETCH_POLICY: { NETWORK_ONLY },
  },
  COMPONENTS: {
    BUTTONS: { SELECT },
  },
} = constants;

const MenuCollection = () => {
  const [period, setPeriod] = useState<any>(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [periodStatus, setPeriodStatus] = useState<number>(0);
  const [origen, setOrigen] = useState([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [periodListIndex, setPeriodListIndex] = useState<number>(0);
  const [warningOpen, setWarningOpen] = useState<boolean>(false);
  const [valorTab, setValorTab] = useState(0);
  const [periodName, setPeriodName] = useState<any>("");
  let navigate = useNavigate();

  const {
    allPeriodsByProcess,
    periodsByProcess,
    loadingByProcess,
    listPeriodNames,
  } = usePeriod({ processType: 1 });

  const { getContractData, contractCount } = useContract();

  const [getOrigen, { data: dataOrigen }] = useLazyQuery(GET_ORIGIN, {
    fetchPolicy: NETWORK_ONLY as any,
    variables: {
      input: {},
    }
  });

  const { getLog, rows: rowsLogs, loading: loadingLogs } = useLogs();

  const getCloseDate = (fkId: number) => {
    getLog({
      logTypeId: CLOSE_COLLECTION,
      fkId: fkId,
    });
  };

  const handleSelectedPeriod = (option: any) => {
    if (option) {
      setPeriodName(periodsByProcess[option - 1].name);
      setPeriodStatus(periodsByProcess[option - 1].collectionStatusId);
      if (periodsByProcess[option - 1].collectionStatusId === 3) {
        getCloseDate(parseInt(periodsByProcess[option - 1].name));
      }
      setSelectedPeriod(option);
      setPeriodListIndex(periodsByProcess.length - option);
      getContractData({
        collectionPeriod: periodsByProcess[option - 1].name,
        isActive: true,
        limit: 1,
        offset: 0,
      });
    }
  };

  const handleNavigate = (path: string, option: any) => {
    navigate(path, {
      state: {
        period: periodName,
      },
    });
  };

  useEffect(() => {
    allPeriodsByProcess();
    getOrigen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataOrigen?.period?.collection.payment.paymentType.allBy) {
      const names =
        dataOrigen?.period?.collection.payment.paymentType.allBy.map(
          (origin: any) => origin.name
        );
      setOrigen(names);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOrigen]);

  useEffect(() => {
    if (refresh === true) {
      allPeriodsByProcess();
      getCloseDate(parseInt(periodName));
      setRefresh(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    if (selectedPeriod !== null && period !== null) {
      const listLength = periodsByProcess.length;
      setPeriodStatus(
        periodsByProcess[listLength - periodListIndex].collectionStatusId
      );
      setPeriod(listLength - periodListIndex);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodsByProcess]);

  return (
    <>
      <WarningModalCollection
        onOpen={warningOpen}
        handleClose={() => setWarningOpen(false)}
        periodName={periodName}
        setRefresh={setRefresh}
      />
      <Box pl="32px" pr="32px">
        <Typography variant="h1" pt="24px" mb="16px">
          Recaudación
        </Typography>
        <Breadcrum back1="Inicio" link1="/" now="Recaudación" />
        <Grid xs={12}>
          {periodStatus === 3 && !loadingLogs && (
            <Alerts
              mensaje={[
                "Este periodo ha sido cerrado ",
                rowsLogs[0]?.logDate
                  ? "el " +
                    rowsLogs[0]?.logDate.slice(0, 10).split("-").join("/")
                  : "sin fecha registrada",
              ]}
              data={null}
              open={true}
              setOpen={null}
              severidad={"info"}
              noClose
              typeAlert={2}
            />
          )}
        </Grid>
        <Box sx={filterBox} mt="16px" mb="24px">
          {loadingByProcess ? (
            <SkeletonHeaderC />
          ) : (
            <Grid container>
              <Grid xs={12} pb="16px">
                <Typography variant="h2" component="div">
                  Dashboard recaudación
                </Typography>
                <Typography variant="h3" component="div">
                  Selecciona un periodo para ver la información
                  correspondientes.
                </Typography>
              </Grid>
              <Grid xs={12}>
                <Divider />
              </Grid>
              <Grid xs={7} pt="14px" alignSelf={"center"}>
                {selectedPeriod !== null ? (
                  <>
                    <Typography
                      variant="h3"
                      component="div"
                      fontSize="18px"
                      display="inline"
                    >
                      Periodo seleccionado
                    </Typography>
                    <Typography
                      sx={periodStyle}
                      component="div"
                      display="inline"
                      pl="4px"
                    >
                      {getCurrentPeriod(periodName)[0]}
                    </Typography>
                  </>
                ) : (
                  <Typography
                    variant="h3"
                    component="div"
                    fontSize="18px"
                    display="inline"
                  >
                    No se ha seleccionado periodo
                  </Typography>
                )}
              </Grid>
              <Grid xs={5} pt="14px" alignItems="right">
                <Stack direction="row" alignItems="end">
                  <CustomSelect
                    onChange={(e: any) => setPeriod(e.target.value)}
                    value={period}
                    titulo={SELECT}
                    data={listPeriodNames(periodsByProcess)}
                    hideTitle
                  />
                  <Button
                    sx={{ ml: "16px", width: "150px" }}
                    onClick={() => handleSelectedPeriod(period)}
                    data-testid="btn-select"
                  >
                    {SELECT}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          )}
        </Box>
        <Box sx={filterBox} mt="16px" mb="24px">
          <Grid container>
            <Grid xs={12}>
              <Typography variant="h2" component="div">
                Módulos
              </Typography>
            </Grid>
            <Grid xs={4} pt="14px" pr="24px">
              <Grid container sx={borderBox}>
                <Grid item xs={6}>
                  <Typography sx={blackBold}>Acreencias</Typography>
                  <Typography sx={littleText}>subtítulo</Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <Button
                    sx={{ whiteSpace: "pre" }}
                    color="secondary"
                    onClick={() => handleNavigate("acreencias", period)}
                    disabled={!selectedPeriod}
                  >
                    Ir al módulo
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={4} pt="14px" pr="24px">
              <Grid container sx={borderBox}>
                <Grid item xs={6}>
                  <Typography sx={blackBold}>PAC, PAT y Caja</Typography>
                  <Typography sx={littleText}>subtítulo</Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <Button
                    color="secondary"
                    onClick={() => handleNavigate("caja", period)}
                    disabled={!selectedPeriod}
                    sx={{ whiteSpace: "pre" }}
                  >
                    Ir al módulo
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={4} pt="14px">
              <Grid container sx={borderBox}>
                <Grid item xs={6}>
                  <Typography sx={blackBold}>Previred</Typography>
                  <Typography sx={littleText}>subtítulo</Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <Button
                    color="secondary"
                    onClick={() => handleNavigate("previred", period)}
                    disabled={!selectedPeriod}
                  >
                    Ir al módulo
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Tabs
          sx={{ width: "50%" }}
          textColor="primary"
          value={valorTab}
          onChange={(event, value) => setValorTab(value)}
        >
          <Tab label="Pagos" />
          <Tab label="Acreencias" />
        </Tabs>
        <TabPanel value={valorTab} index={0}>
          <TableCollection originOptions={origen} period={periodName} />
        </TabPanel>
        <TabPanel value={valorTab} index={1}>
          <TableCredits />
        </TabPanel>

        <Box sx={filterBox} mt="16px" mb="24px">
          <Grid container>
            <Grid xs={9}>
              <Typography variant="h2" component="div">
                Cerrar periodo
              </Typography>
              <Typography variant="h3" component="div">
                Al terminar de consolidar los datos cierra el periodo para poder
                continuar.
              </Typography>
            </Grid>
            <Grid
              xs={3}
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Button
                startIcon={<CloseIcon />}
                color="secondary"
                disabled={
                  periodStatus !== 1
                    ? true
                    : contractCount === 0 || !hasPermissions().closeProcess
                }
                onClick={() => setWarningOpen(true)}
              >
                Cerrar Periodo
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default MenuCollection;
