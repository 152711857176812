import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import constants from "src/constants/constants";

const {
  COMPONENTS: {
    MENU_ITEMS: { DEVOLUTION, CHANGE_HISTORY },
  },
} = constants;

const MenuDevolution = (props: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-label="devolutionActions" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        className="littleMenu"
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
      >
        <MenuItem
          onClick={() => props.handleDevolution()}
          disabled={props.disabledActions}
        >
          {DEVOLUTION}
        </MenuItem>
        <MenuItem onClick={() => props.handleHistory()}>
          {CHANGE_HISTORY}
        </MenuItem>
      </Menu>
    </>
  );
};

export default MenuDevolution;
