import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import SearchPrevired from "./searchPrevired";
import { ChipsPrevired } from "./chipsPrevired";
import { MenuPrevired } from "./menuPrevired";
import { NoResult } from "src/components/tables/noResult";
import { StyledTableCell } from "src/components/tables/tableStyle";
import { paginatorTheme } from "src/components/paginator/paginatorStyle";
import { boxNoPadding } from "src/components/box/styles";
import { contTitle, subTable } from "src/components/typography/styles";
import download from "src/assets/icons/download.svg";
import { SkeletonTableP } from "./skeletonsPrevired";
import { usePayment } from "src/pages/collection/hooks/usePayment";
import { renderAmount } from "src/utils/numberUtils";

const TablePrevired = (props: any) => {
  const [modalBuscar, setModalBuscar] = useState(false);
  const [filters, setFilters] = useState({
    contributorRut: null,
    payerRut: null,
    paymentDate: null,
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const {
    getPaymentData,
    paymentCount,
    paymentRows,
    paymentLoading,
    paymentError,
  } = usePayment();

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    getPaymentData({
      collectionPeriod: props.period,
      paymentType: "PREVIRED",
      isActive: true,
      limit: rowsPerPage,
      offset: page * rowsPerPage - rowsPerPage,
      ...filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPaymentData({
      collectionPeriod: props.period,
      paymentType: "PREVIRED",
      isActive: true,
      limit: rowsPerPage,
      offset: page * rowsPerPage - rowsPerPage,
      ...filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(1);
    getPaymentData({
      collectionPeriod: props.period,
      paymentType: "PREVIRED",
      isActive: true,
      limit: rowsPerPage,
      offset: 0,
      ...filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (props.refresh === true) {
      setPage(1);
      getPaymentData({
        collectionPeriod: props.period,
        contributorRut: null,
        payerRut: null,
        paymentDate: null,
        paymentType: "PREVIRED",
        isActive: true,
        limit: rowsPerPage,
        offset: 0,
      });
      props.setRefresh(false);
    }
    // eslint-disable-next-line
  }, [props.refresh]);

  return (
    <Box sx={boxNoPadding} mt="16px" mb="24px">
      {paymentLoading ? (
        <SkeletonTableP data-testid="skeleton-table" rows={3} columns={8} />
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                pt="15px"
                pr="24px"
                pb="17px"
              >
                <Typography sx={contTitle} pt="11px" pl="24px">
                  Listado de acreencias
                </Typography>{" "}
                <Stack direction={"row"}>
                  <div>
                    <Button
                      data-testid="ver-filtros"
                      color="inherit"
                      onClick={() => setModalBuscar(true)}
                      disabled={paymentError ? true : false}
                    >
                      Ver filtros
                    </Button>
                  </div>
                  <Button
                    color={"secondary"}
                    startIcon={
                      <img                        
                        src={download}
                        style={{ paddingRight: "12px" }}
                        alt="download"
                      />
                    }
                    disabled
                  >
                    {" "}
                    Exportar
                  </Button>
                </Stack>
              </Stack>
            </Grid>            
            <ChipsPrevired filtros={filters} setFiltros={setFilters} />
          </Grid>

          {paymentError ? (
            <NoResult
              titulo={"No se pudo cargar la tabla"}
              subtitulo={
                "No fue posible mostrar la información debido a un problema de conexión. Intenta recargar la página para solucionar el problema."
              }
              error
            />
          ) : (
            <>
              <SearchPrevired
                onOpen={modalBuscar}
                handleClose={() => setModalBuscar(false)}
                filtros={filters}
                setFiltros={setFilters}
                data-testid="search-modal"
              />
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "none",
                  borderRadius: "none",
                }}
              >
                {paymentCount === 0 ? (
                  filters.contributorRut === "" &&
                  filters.payerRut === "" &&
                  filters.paymentDate === "" ? (
                    <NoResult
                      titulo={"No se han cargado archivos"}
                      subtitulo={"Cargue información para ver el listado"}
                      dontSearch
                    />
                  ) : (
                    <NoResult
                      titulo={"Sin Resultados"}
                      subtitulo={"No se encontraron resultados para esta tabla"}
                    />
                  )
                ) : (
                  <>
                    <Table sx={{ minWidth: 650 }} aria-label="customized table">
                      <TableHead className="color-head-table">
                        <TableRow>
                          <StyledTableCell>
                            RUT y Nombre <br></br> Cliente
                          </StyledTableCell>
                          <StyledTableCell>
                            RUT y Nombre <br></br> Pagador
                          </StyledTableCell>
                          <StyledTableCell>
                            Cotización <br></br> a pagar
                          </StyledTableCell>
                          <StyledTableCell>Cotización 7%</StyledTableCell>
                          <StyledTableCell>
                            Cotización <br></br> adicional
                          </StyledTableCell>
                          <StyledTableCell>
                            R. Imponible <br></br> trabajador
                          </StyledTableCell>
                          <StyledTableCell>
                            Fecha de <br></br>
                            Pago
                          </StyledTableCell>
                          <StyledTableCell>Acciones</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {paymentRows.map((file: any) => {
                        return (
                          <TableBody>
                            <TableRow data-testid="withData">
                              <TableCell>
                                <Typography data-testid="rut" variant="h5">
                                  {file.contributorRut}
                                </Typography>
                                <Typography sx={subTable}>
                                  {file.contributorName}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography data-testid="rut" variant="h5">
                                  {file.payerRut}
                                </Typography>
                                <Typography sx={subTable}>
                                  {file.payerName}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  ${renderAmount(file.amount)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  ${renderAmount(file.taxableSevenPercent)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  ${renderAmount(file.additionalAmount)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  ${renderAmount(file.taxableSalary)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {file.paymentDate
                                    ? file.paymentDate
                                        .slice(0, 10)
                                        .replace(/-/g, "/")
                                    : "Sin info"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <MenuPrevired />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })}
                    </Table>
                    <Grid alignItems="center" pb="15px" pt="24px">
                      <ThemeProvider theme={paginatorTheme}>
                        <TablePagination
                          rowsPerPageOptions={[10, 20, 50]}
                          component="div"
                          count={paymentCount}
                          rowsPerPage={rowsPerPage}
                          page={page - 1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelRowsPerPage={"Mostrar por página"}
                          sx={{ mt: "24px", mb: "24px" }}
                          labelDisplayedRows={(e) => {
                            return (
                              "" +
                              (page) +
                              " de " +
                              Math.ceil(paymentCount / rowsPerPage)
                            );
                          }}
                        />
                      </ThemeProvider>
                    </Grid>
                  </>
                )}
              </TableContainer>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default TablePrevired;
