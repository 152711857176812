import { Fragment } from "react";
import { Grid, Chip, Button } from "@mui/material";
import Cruz from "../../../../assets/icons/cancelIcon.svg";

export function ChipsCash({ filtros, setFiltros }: any) {
  const handleDelete = (filter: number) => {
    if (filter === 1) {
      setFiltros({
        ...filtros,
        contributorRut: null,
      });
    }
    if (filter === 2) {
      setFiltros({
        ...filtros,
        paymentPeriod: null,
      });
    }

    if (filter === 3) {
      setFiltros({
        ...filtros,
        paymentType: null,
      });
    }
    if (filter === 4) {
      setFiltros({
        ...filtros,
        payerRut: null,
      });
    }
  };

  const clearFiltros = () => {
    setFiltros({      
      contributorRut: null,
      paymentPeriod: null,
      paymentType: null,
      payerRut: null,
    });
  };

  return (
    <Fragment>
      {(filtros.contributorRut ||
        filtros.paymentPeriod ||
        filtros.paymentType ||
        filtros.payerRut) && (
        <Fragment>
          <Grid item xs={12} pl="24px" pt="15px">
            <label className="filtroFont">Resultados filtrados por: </label>{" "}
          </Grid>
          <Grid item xs={12} pt="4px" pl="24px">
            {filtros.contributorRut && (
              <Chip
                sx={{ mr: "16px" }}
                data-testid="delete-rut-client-chip"
                label="Rut cliente"
                variant="outlined"
                onDelete={() => handleDelete(1)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-rut-contributor"
                  />
                }
              />
            )}
            {filtros.paymentPeriod && (
              <Chip
                sx={{ mr: "16px" }}
                data-testid="delete-paymentPeriod-chip"
                label="Periodo de remuneración"
                variant="outlined"
                onDelete={() => handleDelete(2)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-payment"
                  />
                }
              />
            )}
            {filtros.paymentType && (
              <Chip
                sx={{ mr: "16px" }}
                label="Origen"
                variant="outlined"
                onDelete={() => handleDelete(3)}
                deleteIcon={
                  <img src={Cruz} alt="cruz" data-testid="icon-delete-origin" />
                }
              />
            )}
            {filtros.payerRut && (
              <Chip
                sx={{ mr: "16px" }}
                data-testid="delete-rut-payer-chip"
                label="Rut pagador"
                variant="outlined"
                onDelete={() => handleDelete(4)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-rut-payer"
                  />
                }
              />
            )}
            <Button
              color="inherit"
              sx={{
                textDecoration: "underline",
                textDecorationThickness: "from-font",
                pl: "12px",
              }}
              onClick={clearFiltros}
            >
              Limpiar filtros
            </Button>
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
}
