import { useState, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Box, styled } from "@mui/material";
import AppBarAlemana from "src/components/header/AppBarAlemana";
import { DrawerLeft } from "src/components/header/drawerLeft";
import { useIsAuthenticated } from "@azure/msal-react";
import { getUserRoles } from "src/utils/msal";
import Files from "src/components/layouts/files";
import { ContextLoaderProvider } from "src/context/ContextLoader";
import MenuCollection from "src/pages/collection/menuCollection";
import Credits from "src/pages/collection/credits/credits";
import Cash from "src/pages/collection/Cash/cash";
import Previred from "src/pages/collection/previred/previred";
import MenuDistribution from "src/pages/distribution/MenuDistribution";
import Excess from "src/pages/distribution/excess/excess";
import RutSearch from "src/pages/rutSearch/rutSearch";
import SettingsMenu from "src/pages/settings/settingsMenu";
import Overage from "src/pages/distribution/overage/overage";
import { hasAccessToRoute } from "src/utils/hasPermissions";
import Welcome from "src/components/pages/welcome";
import PageNotFound from "src/components/pages/pageNotFound";
import Devolution from "src/pages/devolution/devolution";

const drawerWidth = 250;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: "24px 0px",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${drawerWidth}px`,
    width: `calc(100% - ${drawerWidth}px)`,
  }),
  ...(!open && {
    marginLeft: "64px",
    width: `calc(100% - 64px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
}));

const RoutesConfig = () => {
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isAuthenticated = useIsAuthenticated();
  const userRoles = getUserRoles();

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <AppBarAlemana />
        {isAuthenticated && (
          <DrawerLeft
            open={open}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
          />
        )}
        <Box mb="0px" bgcolor="#F2F5F9" mt="42px">
          <Main open={open}>
            <ContextLoaderProvider>
              <Routes>
                {isAuthenticated ? (
                  <>
                    <Route path="/" element={<Welcome isAuthorized={true} />} />
                    <Route path="Recaudacion">
                      <Route index element={<MenuCollection />} />
                      <Route path="acreencias" element={<Credits />} />
                      <Route path="caja" element={<Cash />} />
                      <Route path="previred">
                        <Route index element={<Previred />} />
                        <Route path="files" element={<Files />} />
                      </Route>
                    </Route>
                    <Route path="Distribucion">
                      <Route index element={<MenuDistribution />} />
                      <Route path="excedentes" element={<Excess />} />
                      <Route path="excesos" element={<Overage />} />
                    </Route>
                    <Route path="consultas-rut" element={<RutSearch />} />
                    <Route path="devolucion" element={<Devolution />} />
                    <Route
                      path="Configuracion"
                      element={
                        hasAccessToRoute(userRoles, "/configuracion") ? (
                          <SettingsMenu />
                        ) : (
                          <Welcome isAuthorized={false} />
                        )
                      }
                    />
                    <Route path="*" element={<PageNotFound />} />
                  </>
                ) : (
                  <Route path="*" element={<Welcome isAuthorized={false} />} />
                )}
              </Routes>
            </ContextLoaderProvider>
          </Main>
        </Box>
      </BrowserRouter>
    </Suspense>
  );
};

export default RoutesConfig;
