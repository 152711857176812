import {
  Box,
  // Button,
  Divider,
  Grid,
  Stack,
  //Typography
} from "@mui/material";
import { filterBox, whiteBox } from "../box/styles";
//import { fileLoad, fileProcess, fileSub, fileTitle, fileUp, notFound, period } from "../typography/styles";
// import Consolid from "src/assets/icons/consolid.svg";
import SkeletonBar from "../skeleton/skeletonBar";
const LoadPreviredFiles = () => {
  return (
    <Box sx={filterBox} mt="16px" mb="24px">
      <Grid item xs={12}>
        <div className="container">
          <Grid container>
            <Grid item xs={8} pb="10px">
              <Stack direction={"column"} spacing={"10px"}>
                <SkeletonBar width={180} height={18} />
                <SkeletonBar width={371} height={16} />
                <SkeletonBar width={371} height={16} />
              </Stack>
            </Grid>
            <Grid
              item
              xs={4}
              direction={"row"}
              sx={{ minWidth: "190px !important" }}
              justifyContent="flex-end"
            >
              <Stack direction={"row"} justifyContent="flex-end">
                <Stack
                  direction={"column"}
                  pr="16px"
                  spacing="10px"
                  justifyContent="flex-end"
                >
                  <SkeletonBar width={200} height={14} />
                  <SkeletonBar width={200} height={14} />
                </Stack>
                <SkeletonBar width={180} height={40} />
              </Stack>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Divider component="div" sx={{ mt: "24px" }} />
      <Grid container mt="24px">
        <Grid xs={10}>
          <Stack direction={"row"} spacing={"12px"}>
            <SkeletonBar width="371px" height="16px" />
          </Stack>
        </Grid>
        <Grid xs={2} textAlign="right">
          <SkeletonBar width="auto" height="16px" />
        </Grid>
        <Grid xs={12} pt="19px">
          <Stack direction="row" spacing="24px" justifyContent="center">
            <Box sx={whiteBox}>
              <Grid container>
                <Grid xs={6}>
                  <Stack direction="column" spacing={"8px"}>
                    <SkeletonBar width="118px" height="18px" />
                    <SkeletonBar width="180px" height="18px" />
                    <SkeletonBar width="180px" height="18px" />
                    <SkeletonBar width="140px" height="40px" />
                  </Stack>
                </Grid>
                <Grid xs={6}>
                  <Stack
                    direction="column"
                    alignItems={"flex-end"}
                    spacing={"16px"}
                  >
                    <SkeletonBar width="165px" height="32px" />
                    <SkeletonBar width="95px" height="18px" />
                    <SkeletonBar width="180px" height="18px" />
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box sx={whiteBox}>
              <Grid container>
                <Grid xs={6}>
                  <Stack direction="column" spacing={"8px"}>
                    <SkeletonBar width="118px" height="18px" />
                    <SkeletonBar width="180px" height="18px" />
                    <SkeletonBar width="180px" height="18px" />
                    <SkeletonBar width="140px" height="40px" />
                  </Stack>
                </Grid>
                <Grid xs={6}>
                  <Stack
                    direction="column"
                    alignItems={"flex-end"}
                    spacing={"16px"}
                  >
                    <SkeletonBar width="165px" height="32px" />
                    <SkeletonBar width="95px" height="18px" />
                    <SkeletonBar width="180px" height="18px" />
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Grid>
        <Grid xs={12} pt="24px" justifyContent={"flex-end"} display={"flex"}>
          <SkeletonBar width="140px" height="47px" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoadPreviredFiles;
