import { GET_CONTRACTS } from "src/services/graphql/queries";
import { useLazyQuery } from "@apollo/client";

type getContractInputType = {
  collectionPeriod?: string;
  contributorRut?: string | null;
  paymentPeriod?: string | null;
  workerType?: string | null;
  statusId?: number | null;
  typeId?: number | null;
  addOverdue?: boolean;
  isActive: boolean;
  isConsolidated?: boolean;
  limit: number;
  offset: number;
};

export const useContract = () => {
  const [
    getContract,
    {
      data: {
        period: {
          collection: {
            contributorContract: {
              allBy: { count: contractCount, rows: contractRows },
            },
          },
        },
      } = {
        period: {
          collection: {
            contributorContract: {
              allBy: { count: 0, rows: [] },
            },
          },
        },
      },
      loading: contractLoading,
      error: contractError,
    },
  ] = useLazyQuery(GET_CONTRACTS, {
    fetchPolicy: "network-only",
  });

  const getContractData = (options: getContractInputType) => {
    getContract({
      variables: {
        input: {
          collectionPeriod: options.collectionPeriod || null,
          contributorRut: options.contributorRut || null,
          paymentPeriod: options.paymentPeriod || null,
          workerType: options.workerType || null,
          statusId: options.statusId || null,
          typeId: options.typeId || null,
          addOverdue: options.addOverdue || false,
          isActive: options.isActive,
          isConsolidated: options.isConsolidated || false,
          limit: options.limit,
          offset: options.offset,
        },
      },
    });
  };

  return {
    getContractData,
    contractCount,
    contractRows,
    contractLoading,
    contractError,
  };
};
