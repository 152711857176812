const filterBox = {
  background: " #FFFFFF",
  boxShadow: "0px 2px 5px rgba(201, 207, 227, 0.75)",
  borderRadius: "8px",
  padding: "24px",
};

const nonFilterBox = {
  background: " #E6F6F53D",
  boxShadow: "0px 2px 5px rgba(201, 207, 227, 0.75)",
  borderRadius: "8px",
  padding: "24px",
};

const boxNoPadding = {
  background: " #FFFFFF",
  boxShadow: "0px 2px 5px rgba(201, 207, 227, 0.75)",
  borderRadius: "8px",
};

const boxNoPadding2 = {
  background: " #FFFFFF",
  borderRadius: "8px",
};

const disabledBox = {
  border: "1px solid #D2D6D9",
  borderRadius: "8px",
  padding: "24px",
};

const tables = {
  background: " #FFFFFF",
  boxShadow: "0px 2px 5px rgba(201, 207, 227, 0.75)",
  borderRadius: "8px",
  padding: "24px 0 24px 0",
};
const grayBox = {
  background: " #F2F5F9",
  border: "1px solid #D2D6D9",
  borderRadius: "8px",
  padding: "48px",
  width: "100%",
  textAlign: "center",
};
const whiteBox = {
  border: "1px solid #D2D6D9",
  borderRadius: "8px",
  padding: "16px",
  width: "100%",
};

const tableBox = {
  border: " 1px solid #F2F5F9",
  borderRadius: "8px",
};

const littleCard = {
  border: "1px solid #E9EBEC",
  borderRadius: "8px",
  margin: "8px",
  padding: "16px",
};
const loaderBox = {
  background: "#e2eef4",
  border: "1px solid #005b81",
  borderRadius: "8px",
  float: "right",
  height: "40px",
  position: "sticky",
  right: "30px",
  top: "100px",
  width: "228px",
  zIndex: "999",
};

const loadingFile = {
  position: "absolute",
  width: "109px",
  height: "16px",
  left: "44px",
  top: "12px",
  fontFamily: "Interstate",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
};

const borderBox = {
  border: "1px solid #D2D6D9",
  borderRadius: "8px",
  padding: "26px 16px",
  width: "100%",
};


const grayBoxStyle = {
  border: "none",
  borderRadius: "8px",  
  backgroundColor: "#F8F9F9",
  padding: "16px 16px",
};

const greenBack = {
  backgroundColor: "#E6F6F5",
  border: " 0.5px solid #4DC0B8",
  borderRadius: "8px"
};

const greenBackNB = {
  backgroundColor: "#E6F6F5", 
  borderRadius: "4px"
};


export {
  grayBoxStyle,
  greenBackNB,
  boxNoPadding2,
  boxNoPadding,
  borderBox,
  disabledBox,
  filterBox,
  nonFilterBox,
  tables,
  grayBox,
  whiteBox,
  tableBox,
  littleCard,
  loaderBox,
  loadingFile,
  greenBack
};
