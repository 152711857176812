import { Button } from "@mui/material";
import download from "src/assets/icons/download.svg";
import GreenDownload from "src/assets/icons/greenDownload.svg";
import constants from "src/constants/constants";
import { useLazyQuery } from "@apollo/client";
import { EXPORT_EXCESS } from "src/services/graphql/queries";
import { excessOverageFile } from "src/utils/buildExcel";
import { SNACK_ACTION_TYPE } from "src/reducers/snackInfoReducer";

const { SET_SNACK_ERROR, SET_SNACK_INFO } = SNACK_ACTION_TYPE;
const {
  SNACKBAR_MESSAGE: {
    DISTRIBUTION: {
      OVERAGE: { EXPORT_ERROR },
    },
  },
  COMPONENTS: {
    BUTTONS: { EXPORT },
  },
} = constants;

type ExportOverageType = {  
  period: string | null;
  setSnackInfo: React.Dispatch<React.SetStateAction<any>>;
  disabled: boolean;
  contributorRut: string | null;
  isActive: boolean;
  noData: boolean;
  setDisabledActions: React.Dispatch<React.SetStateAction<boolean>>;
  order: number;
};

const ExportOverage = ({  
  period,
  setSnackInfo,
  disabled,
  contributorRut,
  isActive,
  noData,
  setDisabledActions,
  order,
}: ExportOverageType) => {
  const [getExcessData, { loading, fetchMore }] = useLazyQuery(EXPORT_EXCESS, {
    fetchPolicy: "no-cache",
    onCompleted: (res) => fetchExcessData(res),
    onError: () => {
      setSnackInfo({
        type: SET_SNACK_ERROR,
        payload: EXPORT_ERROR,
      });
      setDisabledActions(false);
    },
  });

  const fetchExcessData = async (result: any) => {
    let temp = result.period.distribution.excess.excessMovement.allBy;
    if (temp) {
      while (temp.rows.length < temp.count) {
        const { data } = await fetchMore({
          variables: {
            input: {
              isActive: isActive,
              collectionPeriod: period,
              contributorRut: contributorRut || null,
              limit: 5000,
              order: order,
              offset: temp.rows.length,
            },
          },
        });
        const mergeExcessRows = [
          ...temp.rows,
          ...data.period.distribution.excess.excessMovement.allBy.rows,
        ];
        temp = { ...temp, rows: mergeExcessRows };
      }
      const excessData = temp.rows;
      exportExcess(excessData);
    }
  };

  const handleExcessDownload = () => {
    setDisabledActions(true);
    getExcessData({
      variables: {
        input: {
          collectionPeriod: period,
          contributorRut: contributorRut || null,
          isActive: isActive,
          limit: 5000,
          order: order,
          offset: 0,
        },
      },
    });
  };

  const exportExcess = async (data: any) => {
    const filename = period ? period : contributorRut!;
    const response = await excessOverageFile(filename, isActive, data);
    if (response) {
      setSnackInfo({
        type: SET_SNACK_INFO,
        payload: {
          open: true,
          message: response.message,
          status: response.status,
        },
      });
    }
    setDisabledActions(false);
  };

  return (
    <>
      {loading ? (
        <Button
          color={"secondary"}
          sx={{
            minWidth: "143.04px",
          }}
        >
          <div className="spinnerButtonGreen"></div>
        </Button>
      ) : (
        <Button
          color={"secondary"}
          startIcon={
            <img
              src={disabled || noData ? download : GreenDownload}
              style={{ paddingRight: "12px" }}
              alt="download"
            />
          }
          disabled={disabled || noData}
          onClick={() => handleExcessDownload()}
        >
          {" "}
          {EXPORT}
        </Button>
      )}
    </>
  );
};

export default ExportOverage;
