import { useEffect, useState } from "react";
import { UPDATE_SURPLUS } from "src/services/graphql/mutations";
import { useMutation } from "@apollo/client";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  blackBold,
  littleTitle,
  modalSubtitle,
  purpleLittle,
} from "src/components/typography/styles";
import {
  CustomModal,
  CustomModalTitle,
} from "src/components/modals/customModal";
import { StyledTableCell } from "src/components/tables/tableStyle";
import CustomInput from "src/components/input/customInput";
import { getCurrentPeriod } from "src/utils/dates";
import constants from "src/constants/constants";
import { SNACK_ACTION_TYPE } from "src/reducers/snackInfoReducer";

const { SET_SNACK_ERROR, SET_SNACK_SUCCESS } = SNACK_ACTION_TYPE;
const {
  COMPONENTS: {
    BUTTONS: { CLOSE, SAVE_CHANGES },
  },
  QUERIES: {
    CODE: { VALIDATION_FAILED },
  },
  SNACKBAR_MESSAGE: {
    EXCESS_MOVEMENT: { UPDATE_ERROR, UPDATE_SUCCESS },
  },
} = constants;

type EditModalProps = {
  onOpen: boolean;
  handleClose: () => void;
  info: {
    id: string;
    surplusGenerated: number;
    readjustment: number;
    interest: number;
    commission: number;
    contributorName: string;
    contributorRut: string;
    paymentPeriod: any;
  };
  setRefresh: (value: boolean) => void;
  setSnackInfo: React.Dispatch<React.SetStateAction<any>>;
  refreshOnError: (value: number) => void;
};

const EditModal = ({
  onOpen,
  handleClose,
  info,
  setRefresh,
  setSnackInfo,
  refreshOnError,
}: EditModalProps) => {
  const [surplusValues, setSurplusValues] = useState({
    surplusGenerated: 0,
    readjustment: 0,
    interest: 0,
    commission: 0,
  });
  const {
    id,
    surplusGenerated,
    readjustment,
    interest,
    commission,
    contributorName,
    contributorRut,
    paymentPeriod,
  } = info || {};
  const [isDisabled, setIsDisabled] = useState(true);

  const [updateExcess, { loading }] = useMutation(UPDATE_SURPLUS, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        id: id,
        surplusGenerated:
          surplusValues.surplusGenerated !== surplusGenerated
            ? surplusValues.surplusGenerated
            : null,
        readjustment:
          surplusValues.readjustment !== readjustment
            ? surplusValues.readjustment
            : null,
        interest:
          surplusValues.interest !== interest ? surplusValues.interest : null,
        commission:
          surplusValues.commission !== commission
            ? surplusValues.commission
            : null,
      },
    },
    onCompleted: () => {
      setSnackInfo({
        type: SET_SNACK_SUCCESS,
        payload: UPDATE_SUCCESS,
      });
      setRefresh(true);
      handleClose();
    },
    onError: (error: any) => {
      let { message, code } = error?.graphQLErrors[0] || {};

      if (code === VALIDATION_FAILED) {
        setSnackInfo({
          type: SET_SNACK_ERROR,
          payload: message.split(".")[0],
        });
        let errorStatus = message.split(":")[1];
        refreshOnError(parseInt(errorStatus));
      } else {
        setSnackInfo({
          type: SET_SNACK_ERROR,
          payload: UPDATE_ERROR,
        });
      }
      handleClose();
    },
  });

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    if (value === "0" || value === "") {
      setSurplusValues({
        ...surplusValues,
        [e.target.name]: 0,
      });
    } else if (value.match(/^\d+$/) && value.length <= 9) {
      setSurplusValues({
        ...surplusValues,
        [e.target.name]: parseInt(value),
      });
    }
  };

  useEffect(() => {
    if (
      surplusValues.surplusGenerated !== surplusGenerated ||
      surplusValues.readjustment !== readjustment ||
      surplusValues.interest !== interest ||
      surplusValues.commission !== commission
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surplusValues]);

  useEffect(() => {
    if (onOpen === true) {
      setSurplusValues({
        surplusGenerated: surplusGenerated,
        readjustment: readjustment,
        interest: interest,
        commission: commission,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen]);

  const currencySign = {
    startAdornment: <InputAdornment position="start">$</InputAdornment>,
  };

  return (
    <>
      <CustomModal
        fullWidth
        maxWidth="md"
        open={onOpen}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <CustomModalTitle id="addCredits" onClose={handleClose}>
          <Typography sx={littleTitle} textAlign="center" pt="32px">
            Editar registro
          </Typography>
          <Typography sx={modalSubtitle} textAlign="center">
            Aquí puedes ver todos los movimientos por periodo de un RUT.
          </Typography>
        </CustomModalTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={6}>
              <Typography sx={purpleLittle} pb="4px">
                Nombre y RUT del cliente
              </Typography>
              <Typography sx={blackBold} pt="4px">
                {contributorName ?? "N/D"} {contributorRut}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={purpleLittle} pb="4px">
                Período remuneración
              </Typography>
              <Typography sx={blackBold} pt="4px">
                {getCurrentPeriod(paymentPeriod)[0]}
              </Typography>
            </Grid>
            <Grid item xs={12} pt="20px">
              <TableContainer
                component={Paper}
                sx={{
                  border: " 0.5px solid #A6AEB3",
                  boxShadow: "none",
                  borderRadius: "8px",
                  borderBottom: "none",
                }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="customized table">
                  <TableHead className="color-head-table">
                    <TableRow>
                      <StyledTableCell
                        sx={{
                          minWidth: "159px",
                          padding: "7px 16px !important",
                        }}
                      >
                        Excedentes
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          minWidth: "159px",
                          padding: "7px 16px !important",
                        }}
                      >
                        Reajuste
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          minWidth: "159px",
                          padding: "7px 16px !important",
                        }}
                      >
                        Intereses
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          minWidth: "159px",
                          padding: "7px 16px !important",
                        }}
                      >
                        Comision
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow data-testid="withData">
                      <TableCell>
                        <CustomInput
                          fullWidth
                          value={surplusValues.surplusGenerated}
                          InputProps={currencySign}
                          name="surplusGenerated"
                          onChange={handleInputChange}
                          inputProps={{
                            "data-testid": "surplusGenerated",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <CustomInput
                          fullWidth
                          value={surplusValues.readjustment}
                          InputProps={currencySign}
                          name="readjustment"
                          onChange={handleInputChange}
                          inputProps={{
                            "data-testid": "readjustment",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <CustomInput
                          fullWidth
                          value={surplusValues.interest}
                          InputProps={currencySign}
                          name="interest"
                          onChange={handleInputChange}
                          inputProps={{
                            "data-testid": "interest",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <CustomInput
                          fullWidth
                          value={surplusValues.commission}
                          InputProps={currencySign}
                          name="commission"
                          onChange={handleInputChange}
                          inputProps={{
                            "data-testid": "commission",
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={"16px"}
          >
            <Button
              onClick={handleClose}
              color="secondary"
              sx={{ width: "150px" }}
            >
              {CLOSE}
            </Button>
            {loading ? (
              <Button color="primary" sx={{ width: "233px" }}>
                <div className="spinnerButton"></div>
              </Button>
            ) : (
              <Button
                sx={{ width: "233px" }}
                disabled={isDisabled}
                onClick={() => updateExcess()}
              >
                {SAVE_CHANGES}
              </Button>
            )}
          </Stack>
        </DialogActions>
      </CustomModal>
    </>
  );
};

export default EditModal;
