import {
  Box,
  // Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
// import { boxNoPadding, filterBox } from "src/components/box/styles";
import SkeletonBar from "src/components/skeleton/skeletonBar";
import { StyledTableCell } from "src/components/tables/tableStyle";

const SkeletonUploadUF = () => (
  <Grid container>
    <Grid item xs={12} pb="16px">
      <Box pb="6px">
        <SkeletonBar width={72} height={14} />
      </Box>
      <SkeletonBar width={"auto"} height={50} />
    </Grid>
    <Grid item xs={12} pb="16%">
      <Box pb="6px">
        <SkeletonBar width={72} height={14} />
      </Box>
      <SkeletonBar width={"auto"} height={50} />
    </Grid>
  </Grid>
);

const SkeletonHistoryUF = (props: any) => (
  <>
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: "8px",
        border: "1px solid #D2D6D9",
        // maxHeight: "290px",
        boxShadow: "0px 2px 5px 0px #C9CFE3BF",
      }}
    >
      {/* <NoResult noDivider dontSearch titulo={"No hay información para mostrar."} subtitulo="No se han encontrados pagos asociados a esta acreencia." /> */}
      <Table sx={{ minWidth: "auto" }} aria-label="simple table">
        <TableHead className="color-head-table">
          <TableRow>
            <StyledTableCell>
              <SkeletonBar width={31} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={64} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={64} height={14} />
            </StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {[...Array(props.rows)].map((x, i) => (
            <TableRow key={i}>
              <TableCell>
                <SkeletonBar width={64} height={14} />
              </TableCell>
              <TableCell>
                <Stack direction={"column"} spacing={"8px"}>
                  <SkeletonBar width={58} height={14} />
                  <SkeletonBar width={68} height={14} />
                </Stack>
              </TableCell>
              <TableCell>
                <SkeletonBar width={83} height={14} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Stack
        direction="row"
        pb="32px"
        pt="32px"
        justifyContent={"center"}
        display={"flex"}
        spacing={"24px"}
        alignItems={"center"}
      >
        <SkeletonBar width={108} height={14} />
        <SkeletonBar width={57} height={32} />
        <SkeletonBar width={88} height={32} />
      </Stack>
    </TableContainer>
  </>
);

export { SkeletonHistoryUF, SkeletonUploadUF };
