import {
  Box,
  // Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { boxNoPadding, filterBox } from "src/components/box/styles";
import SkeletonBar from "src/components/skeleton/skeletonBar";
import { StyledTableCell } from "src/components/tables/tableStyle";
import constants from "src/constants/constants";

const {
  PAGES: { DISTRIBUTION, SEARCH_BY_RUT },
} = constants;

const SkeletonExcess = (props: any) => (
  <>
    {props.header && (
      <Box sx={filterBox} mt="16px" mb="24px">
        <Grid container>
          <Grid xs={7}>
            <Box pb="16px">
              <SkeletonBar width={237} height={18} />
            </Box>
            <Box pb="8px">
              <SkeletonBar width={"auto"} height={16} />
            </Box>
            <SkeletonBar width={572} height={16} />
          </Grid>
          <Grid xs={5} pt="36px">
            <Stack direction="row" textAlign="right" justifyContent="flex-end">
              <Stack
                display={"flex"}
                direction="column"
                alignItems={"flex-end"}
                spacing={"8px"}
                textAlign={"right"}
                pr="16px"
              >
                <SkeletonBar width={154} height={14} />
                <SkeletonBar width={124} height={14} />
              </Stack>
              <SkeletonBar width={180} height={40} />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    )}

    <Box
      sx={
        props.module === SEARCH_BY_RUT
          ? {
              // border: "1px solid #E9EBEC",
              borderRadius: "8px",
              // mt: "16px",
              backgroundColor: "#E6F6F53D",
            }
          : boxNoPadding
      }
      mt={props.module === SEARCH_BY_RUT ? "" : "16px"}
      mb={props.module === SEARCH_BY_RUT ? "" : "24px"}
    >
      {props.module === DISTRIBUTION && (
        <Grid container display={"flex"} alignItems={"center"} p="34px 24px">
          <Grid xs={9}>
            <SkeletonBar width={220} height={18} />
          </Grid>
          <Grid
            xs={3}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Stack direction={"row"}>
              <Box pr="8px">
                <SkeletonBar width={205} height={50} />
              </Box>
              <Box pr="24px">
                <SkeletonBar width={116} height={50} />
              </Box>
              <SkeletonBar width={142} height={50} />
            </Stack>
          </Grid>
        </Grid>
      )}
      {props.module === SEARCH_BY_RUT && (
        <Grid container p="24px" height={128}>
          <Grid
            item
            xs={6}
            borderRight={"1px solid #01A49A"}
            alignSelf={"center"}
            pr="8px"
          >
            <SkeletonBar width={"auto"} height={28} />
          </Grid>
          <Grid item xs={1}>
            {" "}
          </Grid>
          <Grid item xs={5}>
            <Stack direction={"row"} spacing="24px" justifyContent={"flex-end"}>
              <SkeletonBar width={150} height={50} />
              <SkeletonBar width={150} height={50} />
            </Stack>
          </Grid>
        </Grid>
      )}
      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", borderRadius: "none", borderBottom: " 2px" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead className="color-head-table">
            <TableRow>
              {[...Array(props.columns)].map((x, i) => (
                <StyledTableCell key={i}>
                  <SkeletonBar width={80} height={14} />
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(props.rows)].map((x, i) => (
              <TableRow data-testid="withData">
                {[...Array(props.columns)].map((y, o) => (
                  <TableCell>
                    <SkeletonBar width={100} height={14} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Stack
          direction={"row"}
          spacing={"24px"}
          alignItems={"center"}
          pb="32px"
          pt="32px"
          justifyContent={"center"}
          display={"flex"}
        >
          <SkeletonBar width={108} height={14} />
          <SkeletonBar width={68} height={32} />
          <SkeletonBar width={88} height={32} />
        </Stack>
      </TableContainer>
    </Box>
  </>
);

const SkeletonExcessTotals = () => (
  <Box sx={boxNoPadding} mt="16px" mb="24px">
    <Box p="20px 24px" borderTop={"solid 2px #E9EBEC"}>
      <SkeletonBar width={"auto"} height={87} />
    </Box>
  </Box>
);

const SkeletonConfirmProcess = () => (
  <Box sx={filterBox} mt="16px" mb="24px">
    <Grid container justifyContent={"space-between"} alignItems={"center"}>
      <Grid xs={6}>
        <Box pb="6px">
          <SkeletonBar width={164} height={13} />
        </Box>
        <SkeletonBar width={"auto"} height={16} />
      </Grid>
      <Grid xs={6} display={"flex"} justifyContent={"flex-end"}>
        <SkeletonBar width={220} height={18} />
      </Grid>
    </Grid>
  </Box>
);

const SkeletonDetailExcess = () => (
  <>
    <Grid container pb="16px">
      <Grid xs={6} pr="37px">
        <Box pb="8px">
          {" "}
          <SkeletonBar width={146} height={16} />
        </Box>
        <SkeletonBar width={"auto"} height={16} />
      </Grid>
      <Grid xs={6}>
        <Box pb="8px">
          {" "}
          <SkeletonBar width={52} height={14} />
        </Box>
        <SkeletonBar width={124} height={14} />
      </Grid>
      <Grid xs={6} pt="24px">
        <Box pb="8px">
          {" "}
          <SkeletonBar width={52} height={14} />
        </Box>
        <SkeletonBar width={124} height={14} />
      </Grid>
    </Grid>
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        borderRadius: "8px",
        border: "1px solid #E9EBEC",
      }}
    >
      {/* <NoResult noDivider dontSearch titulo={"No hay información para mostrar."} subtitulo="No se han encontrados pagos asociados a esta acreencia." /> */}
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="color-head-table">
          <TableRow>
            <StyledTableCell>
              <SkeletonBar width={80} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={80} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={80} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={80} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={80} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={80} height={14} />
            </StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>
              <SkeletonBar width={97} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={97} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={97} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={97} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={97} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={97} height={14} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Box pb="32px" pt="32px" justifyContent={"center"} display={"flex"}>
        <SkeletonBar width={371} height={16} />
      </Box>
    </TableContainer>
  </>
);

const SkeletonDeletedRecords = () => (
  <>
    <Grid container p="32px">
      <Grid xs={3} pr="37px">
        <SkeletonBar width={218} height={18} />
      </Grid>
      <Grid xs={9} pr="37px">
        <Stack direction={"row"} justifyContent={"end"} spacing={"24px"}>
          <Stack direction={"column"} spacing={"6px"}>
            <SkeletonBar width={48} height={14} />
            <SkeletonBar width={205} height={50} />
          </Stack>
          <Box pt="20px">
            {" "}
            <SkeletonBar width={116} height={50} />
          </Box>
          <Box pt="20px">
            {" "}
            <SkeletonBar width={142} height={50} />
          </Box>
        </Stack>
      </Grid>
    </Grid>
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        borderRadius: "8px",
        border: "1px solid #E9EBEC",
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="color-head-table">
          <TableRow>
            <StyledTableCell>
              <SkeletonBar width={100} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={100} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={100} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={100} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={100} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={100} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={100} height={14} />
            </StyledTableCell>
            <StyledTableCell
              align="center"
              className="headerTableAcciones"
              sx={{ width: "145px !important" }}
            >
              <SkeletonBar width={100} height={14} />
            </StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell className="tableBodyAsignar" align="center">
              <SkeletonBar width={113} height={40} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Stack
        direction={"row"}
        pb="32px"
        pt="32px"
        justifyContent={"center"}
        display={"flex"}
        spacing={"24px"}
        alignItems={"center"}
      >
        <SkeletonBar width={108} height={14} />
        <SkeletonBar width={64} height={32} />
        <SkeletonBar width={80} height={32} />
      </Stack>
    </TableContainer>
  </>
);

const SkeletonHistoryExcess = () => (
  <>
    <TableContainer
      className="grayScroll"
      component={Paper}
      sx={{
        boxShadow: "none",
        borderRadius: "8px",
        border: "0.5px solid #E9EBEC",
        maxHeight: "290px",
      }}
    >
      <Table sx={{ minWidth: "auto" }} aria-label="simple table">
        <TableHead className="color-head-table">
          <TableRow>
            <StyledTableCell>
              <SkeletonBar width={80} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={80} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={80} height={14} />
            </StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>
              <SkeletonBar width={97} height={14} />
            </TableCell>
            <TableCell>
              <Box pb="8px">
                {" "}
                <SkeletonBar width={141} height={14} />
              </Box>
              <SkeletonBar width={159} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={184} height={14} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Stack
        direction={"row"}
        pb="32px"
        pt="32px"
        justifyContent={"center"}
        display={"flex"}
        alignItems={"center"}
      >
        <SkeletonBar width={371} height={16} />
      </Stack>
    </TableContainer>
  </>
);

const SkeletonEditMovement = (props: any) => (
  <>
    <Grid container p="18px 24px">
      <Grid item xs={6} pr="37px">
        <Box pb="8px">
          {" "}
          <SkeletonBar width={146} height={16} />
        </Box>
        <SkeletonBar width={"auto"} height={16} />
      </Grid>
      <Grid item xs={6}>
        <Box pb="8px">
          {" "}
          <SkeletonBar width={52} height={14} />
        </Box>
        <SkeletonBar width={124} height={14} />
      </Grid>
      <Grid item xs={6} pt="24px">
        <Box pb="8px">
          {" "}
          <SkeletonBar width={52} height={14} />
        </Box>
        <SkeletonBar width={124} height={14} />
      </Grid>
      <Grid item xs={12} pt="24px">
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none",
            borderRadius: "8px",
            border: "1px solid #E9EBEC",
          }}
        >
          {/* <NoResult noDivider dontSearch titulo={"No hay información para mostrar."} subtitulo="No se han encontrados pagos asociados a esta acreencia." /> */}
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className="color-head-table">
              <TableRow sx={{ height: 80 }}>
                {[...Array(props.columns)].map((x, i) => (
                  <StyledTableCell key={i}>
                    <SkeletonBar width={80} height={14} />
                  </StyledTableCell>
                ))}
                <StyledTableCell>
                  <SkeletonBar width={36} height={14} />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(props.rows)].map((x, i) => (
                <TableRow data-testid="withData" sx={{ height: 82 }}>
                  {[...Array(props.columns)].map((y, o) => (
                    <TableCell>
                      <SkeletonBar width={97} height={14} />
                    </TableCell>
                  ))}
                  <TableCell>
                    <SkeletonBar width={36} height={14} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* <Box pb="32px" pt="32px" justifyContent={"center"} display={"flex"}>
            <SkeletonBar width={371} height={16} />
          </Box> */}
        </TableContainer>
      </Grid>
    </Grid>
  </>
);
export {
  SkeletonExcess,
  SkeletonDetailExcess,
  SkeletonExcessTotals,
  SkeletonConfirmProcess,
  SkeletonHistoryExcess,
  SkeletonDeletedRecords,
  SkeletonEditMovement,
};
