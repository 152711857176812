import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

const CustomInput = styled(TextField)({
  ' .MuiInputLabel-root': {
    fontFamily: 'Interstate',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: ' 0.0120588em',
    color: ' #BBBBBB',
  },
  "& .MuiOutlinedInput-root.Mui-disabled": {
    '& input': {
      padding: '13px 0px 14px 15px',
      fontFamily: 'Interstate',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: ' 0.0120588em',
      color: ' #FFFFFF',

    },
    "& fieldset": {
      border: '1px solid #BCC2C6',
      boxSizing: 'border-box',
      borderRadius: '8px'
    },
  },
  "& .MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-input":{
      fontFamily: 'Interstate',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: ' 0.0120588em',
      color: ' #364855',
    },
    "&.Mui-focused fieldset": {
      border: '1px solid #5D5FEF',
    },
    "&.Mui-filled fieldset": {
      border: '1px solid #5D5FEF',
    },
    '& input': {
      padding: '13px 0px 14px 15px',
      fontFamily: 'Interstate',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: ' 0.0120588em',
      color: ' #364855',

    },
    "& fieldset": {
      border: '1px solid #BCC2C6',
      boxSizing: 'border-box',
      borderRadius: '8px'
    },
    "& hover": {
      "& fieldset": {
        letterSpacing: '0.0120588em',
        border: '1px solid #364855',
        boxSizing: 'border-box',
        borderRadius: '8px'
      },
      "& input": {
        fontFamily: 'interstate',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: ' 0.0120588em',
        color: '#364855'
      },

    },
    "&:disabled": {
      "& fieldset": {
        height: '56px',
        background: '#E9EBEC',
        borderRadius: '8px'
      },
      "& input": {
        fontFamily: 'interstate',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: ' 0.0120588em',
        color: '#79858E',
        background: '#E9EBEC'

      },
      "& filled": {
        fontFamily: 'interstate',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: ' 0.0120588em',
        color: '#79858E'
      }
    },
    "&: actived":

    {
      borderColor: '1px solid #5D5FEF',
      boxSizing: 'border-box',
      "& fieldset": {
        width: 'auto',
        height: '56px',
        background: '#E9EBEC',
        borderRadius: '8px',
      },
      "& input": {
        fontFamily: 'interstate',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: ' 0.0120588em',
        color: '#79858E',
        background: '#E9EBEC',
        borderColor: '#5143D5'

      },
      "& filled": {
        fontFamily: 'interstate',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: ' 0.0120588em',
        color: '#79858E'
      }
    },


    '& .Mui-error': {
      fontFamily: 'Interstate',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '40px',
      lineHeight: '24px',
      letterSpacing: ' 0.0120588em',
      color: ' #BBBBBB',
    }
    // '&.Mui-filled fieldset': {
    //   borderColor: '#5143D5',
    //   borderWidth: 1.5
    // },
    // '& input:invalid + fieldset': {
    //   borderColor: 'red',
    //   borderWidth: 4,
    // }
  }
}
)

export const CustomInputSlim = styled(TextField)({
  ' .MuiInputLabel-root': {
    fontFamily: 'Interstate',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '24px',
    letterSpacing: ' 0.0120588em',
    color: ' #BBBBBB',
  },
  "& .MuiOutlinedInput-root.Mui-disabled": {
    '& input': {
      padding: '5px 0px 5px 16px',
      fontFamily: 'Interstate',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '13px',
      lineHeight: '24px',
      letterSpacing: ' 0.0120588em',
      color: ' #FFFFFF',

    },
    "& fieldset": {
      border: '1px solid #BCC2C6',
      boxSizing: 'border-box',
      borderRadius: '8px'
    },
  },
  "& .MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-input":{
      fontFamily: 'Interstate',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '13px',
      lineHeight: '24px',
      letterSpacing: ' 0.0120588em',
      color: ' #364855',
    },
    "&.Mui-focused fieldset": {
      border: '1px solid #5D5FEF',
    },
    "&.Mui-filled fieldset": {
      border: '1px solid #5D5FEF',
    },
    '& input': {
      padding: '5px 0px 5px 16px',
      fontFamily: 'Interstate',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '13px',
      lineHeight: '24px',
      letterSpacing: ' 0.0120588em',
      color: ' #364855',

    },
    "& fieldset": {
      border: '1px solid #BCC2C6',
      boxSizing: 'border-box',
      borderRadius: '8px'
    },
    "& hover": {
      "& fieldset": {
        letterSpacing: '0.0120588em',
        border: '1px solid #364855',
        boxSizing: 'border-box',
        borderRadius: '8px'
      },
      "& input": {
        fontFamily: 'interstate',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '13px',
        lineHeight: '24px',
        letterSpacing: ' 0.0120588em',
        color: '#364855'
      },

    },
    "&:disabled": {
      "& fieldset": {
        height: '56px',
        background: '#E9EBEC',
        borderRadius: '8px'
      },
      "& input": {
        fontFamily: 'interstate',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '20px',
        letterSpacing: ' 0.0120588em',
        color: '#79858E',
        background: '#E9EBEC'

      },
      "& filled": {
        fontFamily: 'interstate',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '24px',
        letterSpacing: ' 0.0120588em',
        color: '#79858E'
      }
    },
    "&: actived":

    {
      borderColor: '1px solid #5D5FEF',
      boxSizing: 'border-box',
      "& fieldset": {
        width: 'auto',
        height: '56px',
        background: '#E9EBEC',
        borderRadius: '8px',
      },
      "& input": {
        fontFamily: 'interstate',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '20px',
        letterSpacing: ' 0.0120588em',
        color: '#79858E',
        background: '#E9EBEC',
        borderColor: '#5143D5'

      },
      "& filled": {
        fontFamily: 'interstate',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '24px',
        letterSpacing: ' 0.0120588em',
        color: '#79858E'
      }
    },


    '& .Mui-error': {
      fontFamily: 'Interstate',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '40px',
      lineHeight: '24px',
      letterSpacing: ' 0.0120588em',
      color: ' #BBBBBB',
    }
    // '&.Mui-filled fieldset': {
    //   borderColor: '#5143D5',
    //   borderWidth: 1.5
    // },
    // '& input:invalid + fieldset': {
    //   borderColor: 'red',
    //   borderWidth: 4,
    // }
  }
}
)


export default CustomInput
