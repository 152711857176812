import { GET_EXCESS_DEVOLUTION, GET_SAP } from "src/services/graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { SNACK_ACTION_TYPE } from "src/reducers/snackInfoReducer";
import constants from "src/constants/constants";
import { SAPLoaderFile, excessDevolutionFile } from "src/utils/buildExcel";
import { useState } from "react";

const { SET_SNACK_ERROR, SET_SNACK_SUCCESS, SET_SNACK_WARNING } = SNACK_ACTION_TYPE;
const {
  COMPONENTS: {
    ALERTS: { SUCCESS },
  },
  SNACKBAR_MESSAGE: {
    DEVOLUTION: { EXPORT_ERROR, SAP_EXPORT_ERROR, SAP_EXPORT_NO_DATA_ERROR },
  },
} = constants;

type exportableType = {
  setSnackInfo: React.Dispatch<React.SetStateAction<any>>;
  options: {
    collectionPeriod: string;
    contributorRut: string;
    statusId: number | null;
    typeId: number | null;
    sapType: number;
  };
};

export const useDevolutionExportable = ({
  setSnackInfo,
  options,
}: exportableType) => {
  const { collectionPeriod, contributorRut, statusId, typeId, sapType } =
    options;
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [getDevData, { loading: loadingDevolutionExport, fetchMore }] =
    useLazyQuery(GET_EXCESS_DEVOLUTION, {
      fetchPolicy: "no-cache",
      onCompleted: (res) => fetchDevolutionReportData(res),
      onError: () => {
        setInProgress(false);
        setSnackInfo({
          type: SET_SNACK_ERROR,
          payload: EXPORT_ERROR,
        });
      },
    });

  const fetchDevolutionReportData = async (result: any) => {
    let temp = result.period.distribution.excess.allBy;
    if (temp) {
      while (temp.rows.length < temp.count) {
        const { data } = await fetchMore({
          variables: {
            input: {
              collectionPeriod: collectionPeriod,
              contributorRut: contributorRut || null,
              statusId: statusId,
              typeId: typeId,
              limit: 5000,
              offset: temp.rows.length,
            },
          },
        });
        const mergeSol = [
          ...temp.rows,
          ...data.period.distribution.excess.allBy.rows,
        ];
        temp = {
          ...temp,
          rows: mergeSol,
        };
      }
      const devolutionReport = temp.rows;
      exportDevolution(collectionPeriod, devolutionReport);
    }
  };

  const exportDevolution = async (period: string, data: any) => {
    const response = await excessDevolutionFile(period, data);
    if (response) {
      setSnackInfo({
        type: response.status === SUCCESS ? SET_SNACK_SUCCESS : SET_SNACK_ERROR,
        payload: response.message,
      });
    }
    setInProgress(false);
  };

  const handleClickDevolutionExport = () => {
    setInProgress(true);
    getDevData({
      variables: {
        input: {
          collectionPeriod: collectionPeriod,
          contributorRut: contributorRut || null,
          statusId: statusId,
          typeId: typeId,
          limit: 5000,
          offset: 0,
        },
      },
    });
  };

  const [getSapData, { loading: loadingSAP, fetchMore: fetchMoreSap }] =
    useLazyQuery(GET_SAP, {
      fetchPolicy: "no-cache",
      onCompleted: (res) => {
        const { rows } = res.period.distribution.excess.generateExcessSAPLoader;
        if (rows.length === 0) {
          const typeName = sapType === 22 ? "a demanda" : "mensual";
          setInProgress(false);
          setSnackInfo({
            type: SET_SNACK_WARNING,
            payload: SAP_EXPORT_NO_DATA_ERROR + `${typeName}`,
          });                    
        } else {
          fetchSapData(res);
        }
      },
      onError: () => {
        setInProgress(false);
        setSnackInfo({
          type: SET_SNACK_ERROR,
          payload: SAP_EXPORT_ERROR,
        });
      },
    });

  const fetchSapData = async (result: any) => {
    let temp = result.period.distribution.excess.generateExcessSAPLoader;
    if (temp) {
      while (temp.rows.length / 2 < temp.count) {
        const { data } = await fetchMoreSap({
          variables: {
            input: {
              collectionPeriod: collectionPeriod,
              typeId: sapType,
              limit: 2000,
              offset: temp.rows.length / 2,
            },
          },
        });
        const mergeSol = [
          ...temp.rows,
          ...data.period.distribution.excess.generateExcessSAPLoader.rows,
        ];
        temp = {
          ...temp,
          rows: mergeSol,
        };
      }
      const sapData = temp.rows;
      exportSAP(sapType, sapData);
    }
  };

  const exportSAP = async (type: number, data: any) => {
    const response = await SAPLoaderFile(collectionPeriod, type, data);
    if (response) {
      setSnackInfo({
        type: response.status === SUCCESS ? SET_SNACK_SUCCESS : SET_SNACK_ERROR,
        payload: response.message,
      });
    }
    setInProgress(false);
  };

  const handleClickSapExport = (id: number) => {
    setInProgress(true);
    getSapData({
      variables: {
        input: {
          collectionPeriod: collectionPeriod,
          typeId: id,
          limit: 2000,
          offset: 0,
        },
      },
    });
  };

  return {
    handleClickDevolutionExport,
    loadingDevolutionExport,
    handleClickSapExport,
    loadingSAP,
    inProgress,
  };
};
