import {
  CircularProgress,
  DialogContentText,
  Typography,
} from "@mui/material";
import {
  loader,
  subLoader,
} from "../typography/styles";
import {
  CustomModal,
} from "./customModal";

export const ModalSpinnerLoad = ({ onOpen, onClose, text, subText }: any) => {
  return (
    <CustomModal
      fullWidth
      maxWidth="sm"
      open={onOpen}
      onClose={onClose}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <DialogContentText
        textAlign="center"
        pl="24px"
        pr="24px"
        pt="90px"
        pb="80px"
      >
        <CircularProgress
          disableShrink
          thickness={5}
          size={"39px"}
          sx={{ color: "#00A499", borderRadius: "100px" }}
        />
        {text ? (
          <Typography sx={loader}>{text}</Typography>
        ) : (
          <Typography sx={loader}>Generando archivo...</Typography>
        )}
        {subText ? (
          <Typography sx={subLoader}>{subText}</Typography>
        ) : (
          <Typography sx={subLoader}>
            {" "}
            Este proceso podría tardar unos minutos.
          </Typography>
        )}
      </DialogContentText>
    </CustomModal>
  );
};
