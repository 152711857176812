import { useReducer, useState } from "react";
import { useMutation } from "@apollo/client";
import { CLOSE_COLLECTION_PERIOD } from "src/services/graphql/mutations";
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { littleTitle } from "src/components/typography/styles";
import { AlertSnackbar, CustomModal, CustomModalTitle } from "src/components";
import WarningIcon from "../../../assets/icons/icon_warningOrange.svg";
import constants from "src/constants/constants";
import { getCurrentPeriod } from "src/utils/dates";
import {
  SNACK_ACTION_TYPE,
  SNACKINFO_INITIAL_STATE,
  snackInfoReducer,
} from "src/reducers/snackInfoReducer";

const { CLOSE_SNACK_INFO, SET_SNACK_ERROR, SET_SNACK_SUCCESS } =
  SNACK_ACTION_TYPE;
const {
  COMPONENTS: {
    BUTTONS: { CANCEL },
  },
  SNACKBAR_MESSAGE: {
    COLLECTION: { CLOSE_PERIOD_SUCCESS, CLOSE_PERIOD_ERROR },
  },
} = constants;

const WarningModalCollection = ({
  onOpen,
  handleClose,
  periodName,
  setRefresh,
}: any) => {
  const [checked, setChecked] = useState<boolean>(false);
  const [snackInfoState, snackInfoDispatch] = useReducer(
    snackInfoReducer,
    SNACKINFO_INITIAL_STATE
  );
  const [closePeriod, { loading }] = useMutation(CLOSE_COLLECTION_PERIOD, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        periodName: periodName,
      },
    },
    onCompleted: () => {
      setRefresh(true);
      snackInfoDispatch({
        type: SET_SNACK_SUCCESS,
        payload: CLOSE_PERIOD_SUCCESS,
      });
      handleClose();
    },
    onError: () => {
      snackInfoDispatch({
        type: SET_SNACK_ERROR,
        payload: CLOSE_PERIOD_ERROR,
      });
      handleClose();
    },
  });

  const handleCloseAux = () => {
    handleClose();
    setChecked(false);
  };

  return (
    <>
      <AlertSnackbar
        onClick={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        onClose={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        open={snackInfoState.open}
        status={snackInfoState.status}
        message={snackInfoState.message}
      />
      <CustomModal
        maxWidth="md"
        open={onOpen}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <CustomModalTitle id="addCredits" onClose={handleCloseAux}>
          <Typography sx={littleTitle} textAlign="center" pt="10px">
            Cerrar Periodo
          </Typography>
        </CustomModalTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="column"
            justifyContent="space-around"
            alignItems="center"
          >
            <img
              alt="img"
              src={WarningIcon}
              style={{ marginTop: "27px", marginBottom: "16px" }}
            />
            <Grid item xs={12} textAlign="center" pb="15px">
              <label className="formGrayColor">
                El cerrar{" "}
                <label className="formGrayColorBold"> bloqueará</label>{" "}
                cualquier gestión dentro del periodo{" "}
                <label className="formGrayColorBold">
                  {getCurrentPeriod(periodName)[0]}{" "}
                </label>
                <br></br> ¿estás seguro de continuar con esta acción?
              </label>
            </Grid>
            <Grid
              item
              xs={12}
              textAlign="center"
              justifyContent="center"
              alignItems="center"
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Checkbox
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                />
                <Typography variant="h3">Si, quiero cerrar</Typography>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              onClick={handleCloseAux}
              color="secondary"
              sx={{ padding: "15px 16px" }}
            >
              {CANCEL}
            </Button>
            {loading ? (
              <Button
                color="primary"
                sx={{
                  height: "50px",
                  minWidth: "152.86px",
                  padding: "15px 16px",
                }}
              >
                <div className="spinnerButton"></div>
              </Button>
            ) : (
              <Button
                sx={{ padding: "15px 16px" }}
                onClick={() => closePeriod()}
                disabled={!checked}
              >
                Cerrar periodo
              </Button>
            )}
          </Grid>
        </DialogActions>
      </CustomModal>
    </>
  );
};

export default WarningModalCollection;
