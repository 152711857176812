import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { filterBox, nonFilterBox } from "src/components/box/styles";
import SkeletonBar from "src/components/skeleton/skeletonBar";
import { StyledTableCell } from "src/components/tables/tableStyle";

export const SkeletonDetailOverage = () => (
  <>
    <Box>
      <Grid container pb={"16px"}>
        <Grid xs={6}>
          <Box pb="8px">
            <SkeletonBar width={146} height={16} />{" "}
          </Box>
          <SkeletonBar width={312} height={16} />
        </Grid>
        <Grid xs={6}>
          <Box pb="8px">
            {" "}
            <SkeletonBar width={161} height={14} />
          </Box>
          <SkeletonBar width={111} height={14} />
        </Grid>
      </Grid>
      <Divider />
      <Grid container pt={"16px"} pb={"16px"}>
        <Grid xs={12}>
          <Box pb="8px">
            {" "}
            <SkeletonBar width={146} height={14} />
          </Box>
          <SkeletonBar width={312} height={14} />
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          borderRadius: "8px",
          border: "1px solid #E9EBEC",
        }}
      >
        <Table sx={{ minWidth: "auto" }} aria-label="customized table">
          <TableHead className="color-head-table">
            <TableRow>
              <StyledTableCell>
                <SkeletonBar width={80} height={14} />
              </StyledTableCell>
              <StyledTableCell>
                <SkeletonBar width={80} height={14} />
              </StyledTableCell>
              <StyledTableCell>
                <SkeletonBar width={80} height={14} />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow data-testid="withData">
              <TableCell>
                <SkeletonBar width={100} height={14} />
              </TableCell>
              <TableCell>
                <Stack direction="column">
                  <div style={{ paddingBottom: "6px" }}>
                    <SkeletonBar width={154} height={14} />
                  </div>
                  <SkeletonBar width={154} height={14} />
                </Stack>
              </TableCell>
              <TableCell>
                <SkeletonBar width={184} height={14} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div
          style={{
            paddingTop: "24px",
            paddingBottom: "24px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SkeletonBar width={371} height={16} />
        </div>
      </TableContainer>
    </Box>
  </>
);

export const BtnModalLoad = () => (
  <>
    <Stack
      direction="row"
      justifyContent="end"
      alignItems="center"
      spacing={"16px"}
      data-testid="test-stack"
    >
      <SkeletonBar width={153} height={50} />
      <SkeletonBar width={233} height={50} />
    </Stack>
  </>
);

export const MainLoadOverage = () => (
  <>
    <Box sx={filterBox} mt="16px" mb="24px" data-testid="test-box">
      <Grid container>
        <Grid xs={7}>
          <Box pb="16px">
            <SkeletonBar width={237} height={18} />
          </Box>
          <Box pb="8px">
            <SkeletonBar width={"auto"} height={16} />
          </Box>
        </Grid>
        <Grid xs={5}>
          <Stack direction="row" textAlign="right" justifyContent="flex-end">
            <SkeletonBar width={180} height={50} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
    <Box sx={filterBox} mt="16px" mb="24px">
      <Grid container>
        <Grid xs={7}>
          <Box pb="16px">
            <SkeletonBar width={237} height={18} />
          </Box>
          <Box pb="8px">
            <SkeletonBar width={"auto"} height={16} />
          </Box>
          <SkeletonBar width={572} height={16} />
        </Grid>
        <Grid xs={5} pt="36px">
          <Stack direction="row" textAlign="right" justifyContent="flex-end">
            <Stack
              direction={"column"}
              spacing={"8px"}
              pr="16px"
              pt="6px"
              alignItems={"end"}
            >
              <SkeletonBar width={154} height={14} />
              <SkeletonBar width={124} height={14} />
            </Stack>
            <SkeletonBar width={180} height={50} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
    <Stack direction={"row"} spacing={"40px"}>
      <SkeletonBar width={153} height={14} />
      <SkeletonBar width={153} height={14} />
      <SkeletonBar width={153} height={14} />
    </Stack>
  </>
);

export const LoadOverageTableDef = () => (
  <>
    <Box sx={filterBox} mt="28px">
      <SkeletonBar width={30} height={13} />
      <Stack
        direction="row"
        pr={"8px"}
        justifyContent={"space-between"}
        display={"flex"}
        pt="9px"
      >
        <Stack direction={"row"} spacing={"16px"} display={"flex"}>
          <SkeletonBar width={205} height={50} />
          <SkeletonBar width={116} height={50} />
        </Stack>
        <Stack
          direction={"row"}
          spacing={"16px"}
          justifyContent={"flex-end"}
          display={"flex"}
        >
          <SkeletonBar width={142} height={50} />
          <SkeletonBar width={142} height={50} />
        </Stack>
      </Stack>
    </Box>
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        border: "1px solid #E9EBEC",
        marginBottom: "24px  ",
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="customized table">
        <TableHead className="color-head-table">
          <TableRow>
            <StyledTableCell sx={{ minWidth: "134.75px" }}>
              <SkeletonBar width={"auto"} height={14} />
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: "134.75px" }}>
              <SkeletonBar width={"auto"} height={14} />
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: "134.75px" }}>
              <SkeletonBar width={"auto"} height={14} />
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: "134.75px" }}>
              <SkeletonBar width={"auto"} height={14} />
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: "134.75px" }}>
              <SkeletonBar width={"auto"} height={14} />
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: "134.75px" }}>
              <SkeletonBar width={"auto"} height={14} />
            </StyledTableCell>
            <StyledTableCell
              sx={{ minWidth: "134.75px" }}
              className="headerTableEspecial"
            >
              <SkeletonBar width={"auto"} height={14} />
            </StyledTableCell>
            <StyledTableCell
              sx={{ minWidth: "134.75px" }}
              className="headerTableAcciones"
            >
              <SkeletonBar width={"auto"} height={14} />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow data-testid="withData">
            <TableCell>
              <Box pb={"8px"}>
                <SkeletonBar width={"auto"} height={14} />
              </Box>
              <SkeletonBar width={"auto"} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell className="tableBodyEspecial">
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell className="tableBodyAsignar">
              <SkeletonBar width={64} height={50} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Stack
        direction={"row"}
        spacing={"24px"}
        justifyContent={"center"}
        p="24px"
        alignItems={"center"}
      >
        <SkeletonBar width={108} height={14} />
        <SkeletonBar width={68} height={32} />
        <SkeletonBar width={88} height={32} />
      </Stack>
    </TableContainer>
  </>
);
export const LoadOverageTableHistory = () => (
  <>
    <Box sx={filterBox} mt="28px">
      <Box pb="8px">
        <SkeletonBar width={164} height={13} />
      </Box>
      <SkeletonBar width={572} height={13} />
    </Box>
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        border: "1px solid #E9EBEC",
        marginBottom: "24px  ",
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="customized table">
        <TableHead className="color-head-table">
          <TableRow>
            <StyledTableCell sx={{ minWidth: "134.75px" }}>
              <SkeletonBar width={100} height={14} />
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: "134.75px" }}>
              <SkeletonBar width={"auto"} height={14} />
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: "134.75px" }}>
              <SkeletonBar width={"auto"} height={14} />
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: "134.75px" }}>
              <SkeletonBar width={100} height={14} />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow data-testid="withData">
            <TableCell>
              <SkeletonBar width={128} height={14} />
            </TableCell>
            <TableCell>
              <Box pb={"8px"}>
                <SkeletonBar width={121} height={14} />
              </Box>
              <SkeletonBar width={149} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={168} height={40} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Stack
        direction={"row"}
        spacing={"24px"}
        justifyContent={"center"}
        p="24px"
        alignItems={"center"}
      >
        <SkeletonBar width={108} height={14} />
        <SkeletonBar width={68} height={32} />
        <SkeletonBar width={88} height={32} />
      </Stack>
    </TableContainer>
  </>
);
export const LoadOverageFooter = () => (
  <>
    <Box sx={filterBox} p="24px" data-testid="test-footer">
      <SkeletonBar width={"auto"} height={87} />
    </Box>
  </>
);

export const LoadOverageTableDelete = () => (
  <>
    <Box sx={filterBox} mt="28px" alignItems={"center"}>
      <Stack
        direction="row"
        pr={"8px"}
        justifyContent={"space-between"}
        display={"flex"}
        alignItems={"center"}
        pt="9px"
      >
        <SkeletonBar width={406} height={16} />
        <Stack
          direction={"row"}
          spacing={"16px"}
          justifyContent={"flex-end"}
          display={"flex"}
        >
          <Box>
            <Box pb="8px">
              <SkeletonBar width={30} height={13} />
            </Box>
            <SkeletonBar width={298} height={50} />
          </Box>
          <Box pt="21px">
            <SkeletonBar width={116} height={50} />
          </Box>
          <Box pt="21px">
            <SkeletonBar width={142} height={50} />
          </Box>
        </Stack>
      </Stack>
    </Box>
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        border: "1px solid #E9EBEC",
        marginBottom: "24px  ",
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="customized table">
        <TableHead className="color-head-table">
          <TableRow>
            <StyledTableCell sx={{ minWidth: "134.75px" }}>
              <SkeletonBar width={"auto"} height={14} />
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: "134.75px" }}>
              <SkeletonBar width={"auto"} height={14} />
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: "134.75px" }}>
              <SkeletonBar width={"auto"} height={14} />
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: "134.75px" }}>
              <SkeletonBar width={"auto"} height={14} />
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: "134.75px" }}>
              <SkeletonBar width={"auto"} height={14} />
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: "134.75px" }}>
              <SkeletonBar width={"auto"} height={14} />
            </StyledTableCell>
            <StyledTableCell
              sx={{ minWidth: "134.75px" }}
              className="headerTableEspecial"
            >
              <SkeletonBar width={"auto"} height={14} />
            </StyledTableCell>
            <StyledTableCell
              sx={{ minWidth: "134.75px" }}
              className="headerTableAcciones"
            >
              <SkeletonBar width={"auto"} height={14} />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow data-testid="withData">
            <TableCell>
              <Box pb={"8px"}>
                <SkeletonBar width={"auto"} height={14} />
              </Box>
              <SkeletonBar width={"auto"} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell className="tableBodyEspecial">
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell className="tableBodyAsignar">
              <SkeletonBar width={64} height={50} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Stack
        direction={"row"}
        spacing={"24px"}
        justifyContent={"center"}
        p="24px"
        alignItems={"center"}
      >
        <SkeletonBar width={108} height={14} />
        <SkeletonBar width={68} height={32} />
        <SkeletonBar width={88} height={32} />
      </Stack>
    </TableContainer>
  </>
);
export const SkeletonTableOverage = (props: any) => (
  <>
    <Box sx={props.filter ? filterBox : nonFilterBox} mt="16px" alignItems={"center"}>
      <Stack
        direction="row"
        pr={"8px"}
        justifyContent={"space-between"}
        display={"flex"}
        alignItems={"center"}
        pt="9px"
      >
        <SkeletonBar width={406} height={16} />
        <Stack
          direction={"row"}
          spacing={"16px"}
          justifyContent={"flex-end"}
          display={"flex"}
        >
          {props.filter && (
            <Box>
              <Box pb="8px">
                <SkeletonBar width={30} height={13} />
              </Box>
              <SkeletonBar width={298} height={50} />
            </Box>
          )}
          <Box pt="21px">
            <SkeletonBar width={160} height={50} />
          </Box>
          <Box pt="21px">
            <SkeletonBar width={142} height={50} />
          </Box>
        </Stack>
      </Stack>
    </Box>
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        border: "1px solid #E9EBEC",
        marginBottom: "24px  ",
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="customized table">
        <TableHead className="color-head-table">
          <TableRow>
            {[...Array(props.columns)].map((x, i) => (
              <StyledTableCell key={i} sx={{ minWidth: "134.75px" }}>
                <SkeletonBar width={100} height={14} />
              </StyledTableCell>
            ))}
            <StyledTableCell
              sx={{ minWidth: "134.75px" }}
              className="headerTableAcciones"
              align="center"
            >
              <SkeletonBar width={100} height={14} />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(props.rows)].map((x, i) => (
            <TableRow data-testid="withData">
              <TableCell>
                <Box pb={"8px"}>
                  <SkeletonBar width={100} height={14} />
                </Box>
                <SkeletonBar width={100} height={14} />
              </TableCell>
              {[...Array(props.columns - 1)].map((y, o) => (
                <TableCell>
                  <SkeletonBar width={100} height={14} />
                </TableCell>
              ))}
              <TableCell className="tableBodyAsignar">
                <SkeletonBar width={64} height={14} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Stack
        direction={"row"}
        spacing={"24px"}
        justifyContent={"center"}
        p="24px"
        alignItems={"center"}
      >
        <SkeletonBar width={108} height={14} />
        <SkeletonBar width={68} height={32} />
        <SkeletonBar width={88} height={32} />
      </Stack>
    </TableContainer>
  </>
);
/* <SkeletonBar width={"auto"} height={87} /> */
