import { Box, Typography, Button, Grid } from "@mui/material";
import { cardsPLType } from "src/interfaces";
import { grayBox, whiteBox } from "src/components/box/styles";
import { useNavigate } from "react-router-dom";
import {
  fileLoad,
  fileProcess,
  fileSub,
  fileTitle,
  fileUp,
  graySub,
  grayTitle,
  notFound,
} from "src/components/typography/styles";

type CardsFilesType = {
  cardType: string;
  fileData: cardsPLType;
  period: string;
  showButton?: boolean;
};

const CardsFiles: React.FC<CardsFilesType> = ({
  cardType,
  fileData,
  period,
  showButton = true,
}) => {
  const navigate = useNavigate();

  const handleVerArchivosClick = () => {
    navigate("files", { state: { cardType, period } });
  };

  const renderNoFiles = () => (
    <Box sx={grayBox}>
      <>
        <Typography sx={grayTitle}>Aún no hay archivos cargados</Typography>
        <Typography sx={graySub}>Archivo {cardType}</Typography>
      </>
    </Box>
  );

  const renderFiles = () => (
    <Box sx={whiteBox}>
      <Grid container justifyContent="space-between">
        <Grid xs={8}>
          <Typography sx={fileTitle}>Archivos {cardType}</Typography>
        </Grid>
        <Grid
          alignContent="flex-end"
          alignItems="flex-end"
          alignSelf="flex-end"
          textAlign="center"
          xs={4}
          pt="8px"
          pb="4px"
          sx={{ backgroundColor: "#EEECFB", borderRadius: "4px" }}
        >
          <Typography sx={fileLoad}>
            Pago:{" "}
            {fileData?.amount.toLocaleString("es-CL", {
              style: "currency",
              currency: "CLP",
            })}{" "}
          </Typography>
        </Grid>
        <Grid xs={9}>
          <Typography sx={fileUp}>
            {fileData?.filesCount} Archivos cargados
          </Typography>
        </Grid>
        <Grid xs={9}>
          <Typography sx={fileSub} pt="8px">
            Última carga: {fileData?.uploadDate}
          </Typography>
        </Grid>
        {cardType === "PL" && (
          <Grid xs={3} textAlign="right">
            <Typography sx={fileProcess}>
              {fileData?.registerCount} registros
            </Typography>
          </Grid>
        )}
        <Grid xs={9} pt="8px">
          <Button
            data-testid="downloadTXT"
            color="secondary"
            size="small"
            onClick={handleVerArchivosClick}
          >
            Ver archivos
          </Button>
        </Grid>
        <Grid xs={3} textAlign="right" pt="4px">
          <Typography sx={notFound}>0 no encontrados</Typography>
        </Grid>
      </Grid>
    </Box>
  );

  const renderFilesNoButton = () => (
    <Box sx={whiteBox}>
      <Grid container justifyContent="space-between">
        <Grid xs={7}>
          <Typography sx={fileTitle}>Archivos {cardType}</Typography>
        </Grid>
        <Grid xs={4} textAlign="right">
          <Typography sx={fileProcess}>
            {fileData?.registerCount} registros
          </Typography>
        </Grid>
        <Grid xs={7}>
          <Typography sx={fileUp}>
            {fileData?.filesCount} Archivos cargados
          </Typography>
        </Grid>
        <Grid xs={4} textAlign="right" pt="4px">
          <Typography sx={notFound}>0 no encontrados</Typography>
        </Grid>
        <Grid xs={9}>
          <Typography sx={fileSub} pt="8px">
            {" "}
            Última carga: {fileData?.uploadDate}
          </Typography>
        </Grid>
        <Grid
          alignContent="flex-end"
          alignItems="flex-end"
          alignSelf="flex-end"
          textAlign="center"
          xs={4}
          pt="8px"
          pb="4px"
          sx={{ backgroundColor: "#EEECFB", borderRadius: "4px" }}
        >
          <Typography sx={fileLoad}>
            Pago:{" "}
            {fileData?.amount.toLocaleString("es-CL", {
              style: "currency",
              currency: "CLP",
            })}{" "}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );

  if (!showButton) {
    return (
      <>{fileData?.registerCount ? renderFilesNoButton() : renderNoFiles()}</>
    );
  }

  return <>{fileData?.registerCount ? renderFiles() : renderNoFiles()}</>;
};

export default CardsFiles;
