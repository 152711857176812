const ErrorText = (errors) => {
  let errores = [];
  errors &&
    errors.forEach((error) => {
      if (error.code === "file-invalid-type") {
        errores.push(1);
      }
      if (error.code === "file-too-large") {
        errores.push(2);
      }
      if (error.code === "too-many-files") {
        errores.push(3);
      }
      if (error.code === "file-exists") {
        errores.push(4);
      }
      if (error.code === "file-type") {
        errores.push(5);
      }
      if (error.code === "file-period") {
        errores.push(6);
      }
      if (error.code === "file-space") {
        errores.push(7);
      }
    });
  if (errores.indexOf(3) !== -1) {    
    return "Error: Máximo de archivos superado.";
  }
  if (errores.indexOf(1) !== -1) {
    return "Error: Formato de archivo inválido.";
  }
  if (errores.indexOf(2) !== -1) {    
    return "Error: El archivo pesa más de 4MB.";
  }
  if (errores.indexOf(4) !== -1) {
    return "Error: El archivo ya existe.";
  }
  if (errores.indexOf(5) !== -1) {
    return "Error: El archivo no corresponde al tipo seleccionado.";
  }
  if (errores.indexOf(6) !== -1) {
    return "Error: El archivo no corresponde al periodo seleccionado.";
  }
  if (errores.indexOf(7) !== -1) {
    return "Error: El nombre de archivo no puede contener espacios.";
  }
  return "";
};

export default ErrorText;
