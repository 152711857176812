import {
  PROCESS_DEVOLUTION,
  UPDATE_EXCESS_CLASSIFICATION_DEV,
} from "src/services/graphql/mutations";
import { GET_EXCESS_DEVOLUTION } from "src/services/graphql/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { SNACK_ACTION_TYPE } from "src/reducers/snackInfoReducer";
import constants from "src/constants/constants";

const { SET_SNACK_ERROR, SET_SNACK_SUCCESS, SET_SNACK_WARNING } = SNACK_ACTION_TYPE;
const {
  DEVOLUTION: {
    DEVOLUTION_TYPE: {
      ID: { MONTHLY: MONTHLY_ID, ON_DEMAND: ON_DEMAND_ID },
    },
  },
  QUERIES: {
    FETCH_POLICY: { NETWORK_ONLY },
  },
} = constants;

type devolutionType = {
  handleClose: () => void;
  handleCloseWarning: () => void;
  setSnackInfo: React.Dispatch<React.SetStateAction<any>>;
  setRefresh: (value: boolean) => void;
  devolutionInfo: {
    collectionPeriod: string;
    typeId: number;
  };
  classifyInfo: {
    rowTypeId: number;
    excessId: number;
  };
};

type getDevolutionInput = {
  collectionPeriod: string;
  contributorRut: string;
  statusId: number | null;
  typeId: number | null;
  limit: number;
  offset: number;
};

export const useDevolution = ({
  setSnackInfo,
  setRefresh,
  handleClose,
  handleCloseWarning,
  devolutionInfo,
  classifyInfo,
}: devolutionType) => {
  const { collectionPeriod, typeId } = devolutionInfo;
  const { rowTypeId, excessId } = classifyInfo;

  const [
    getDevolution,
    {
      data: {
        period: {
          distribution: {
            excess: {
              allBy: { count: countDevolution, rows: rowsDevolution },
            },
          },
        },
      } = {
        period: {
          distribution: {
            excess: { allBy: { count: 0, rows: [] } },
          },
        },
      },
      loading: loadingDevolution,
      error: errorDevolution,
    },
  ] = useLazyQuery(GET_EXCESS_DEVOLUTION, {
    fetchPolicy: NETWORK_ONLY as any,
  });

  const getDevolutionData = (options: getDevolutionInput) =>
    getDevolution({
      variables: {
        input: {
          collectionPeriod: options.collectionPeriod,
          contributorRut: options.contributorRut || null,
          statusId: options.statusId,
          typeId: options.typeId,
          limit: options.limit,
          offset: options.offset,
        },
      },
    });

  const [processDevolution, { loading: loadingProcess, error: errorProcess }] =
    useMutation(PROCESS_DEVOLUTION, {
      variables: {
        input: {
          collectionPeriod: collectionPeriod,
          typeId: typeId,
        },
      },
      onCompleted: (res) => {
        const typeName = typeId === ON_DEMAND_ID ? "a demanda" : "mensual";
        const { totalRowsUpdated } = res.period.distribution.excess.processDevolution.data;
        if (totalRowsUpdated === 0) {
          setSnackInfo({
            type: SET_SNACK_WARNING,
            payload: `No se encontraron registros de tipo devolución ${typeName} para procesar.`,
          });          
        } else {          
          setSnackInfo({
            type: SET_SNACK_SUCCESS,
            payload: `Los registros del tipo devolución ${typeName} se han procesado correctamente`,
          });
          setRefresh(true);
        }
        handleCloseWarning();
      },
      onError: (error: any) => {
        const typeName = typeId === ON_DEMAND_ID ? "a demanda" : "mensual";
        setSnackInfo({
          type: SET_SNACK_ERROR,
          payload: `Ocurrió un error al procesar los registros dev. ${typeName}`,
        });
        //debug
        console.log(error);
      },
    });

  const [
    classifyDevolution,
    { loading: loadingClassify, error: errorClassify },
  ] = useMutation(UPDATE_EXCESS_CLASSIFICATION_DEV, {
    variables: {
      input: {
        id: excessId,
        typeId: rowTypeId === 1 ? MONTHLY_ID : ON_DEMAND_ID,
      },
    },
    onCompleted: () => {
      setSnackInfo({
        type: SET_SNACK_SUCCESS,
        payload: "El tipo de devolución se ha guardado correctamente.",
      });
      setRefresh(true);
      handleClose();
    },
    onError: () => {
      setSnackInfo({
        type: SET_SNACK_ERROR,
        payload: "Ocurrió un error al guardar el tipo de devolución.",
      });
    },
  });

  return {
    getDevolutionData,
    rowsDevolution,
    countDevolution,
    loadingDevolution,
    errorDevolution,
    processDevolution,
    loadingProcess,
    errorProcess,
    classifyDevolution,
    loadingClassify,
    errorClassify,
  };
};
