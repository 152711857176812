import { useEffect, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableCell } from "src/components/tables/tableStyle";
import {
  blackBold,
  errorText,
  littleDetail,
  littleTitle,
  modalSubtitle,
  purpleLittle,
  titleTextBox,
} from "src/components/typography/styles";
import {
  ConfirmModal,
  CustomInputSlim,
  CustomModal,
  CustomModalTitle,
  NoResult,
} from "src/components";
import { capitalCase } from "src/utils/stringUtils";
import GreenRefresh from "src/assets/icons/refreshGreen.svg";
import GrayRefresh from "src/assets/icons/arrowRepeatGray.svg";
import GreenTrash from "src/assets/icons/trashGreen.svg";
import GrayTrash from "src/assets/icons/trashGray.svg";
import { getCurrentPeriod } from "src/utils/dates";
import { BULK_UPDATE_SURPLUS_MOVEMENT } from "src/services/graphql/mutations";
import { useMutation } from "@apollo/client";
import constants from "src/constants/constants";
import { DeleteModal } from "src/pages/collection/credits/components/deleteModal";
import { SNACK_ACTION_TYPE } from "src/reducers/snackInfoReducer";

const { SET_SNACK_ERROR, SET_SNACK_SUCCESS } = SNACK_ACTION_TYPE;
const {
  COMPONENTS: {
    BUTTONS: { CLOSE, SAVE_CHANGES },
  },
  EXCESS: {
    MOVEMENT_TYPE: { CREDIT, CHARGE, REVERSE },
  },
  QUERIES: {
    CODE: { VALIDATION_FAILED },
    FETCH_POLICY: { NETWORK_ONLY },
  },
  SNACKBAR_MESSAGE: {
    EXCESS_MOVEMENT: { UPDATE_ERROR, UPDATE_SUCCESS },
  },
} = constants;

type MovementInfo = {
  id: number;
  movementDate: string;
  movementType: string;
  amountType: string;
  amount: number;
  reversable: boolean;
};

type EditMovementProps = {
  onOpen: boolean;
  handleCloseModal: () => void;
  info: {
    contributorRut: string;
    contributorName: string;
    collectionPeriod: string;
    accountableBalance: number;
    availableBalance: number;
    excessDetail: [MovementInfo];
  };
  setRefresh: (value: boolean) => void;
  setSnackInfo: React.Dispatch<React.SetStateAction<any>>;
  refreshOnError: () => void;
};

const EditMovement = ({
  onOpen,
  handleCloseModal,
  info,
  setRefresh,
  setSnackInfo,
  refreshOnError,
}: EditMovementProps) => {
  const {
    contributorRut,
    contributorName,
    collectionPeriod,
    availableBalance,
    excessDetail,
  } = info || {};
  const [movementData, setMovementData] = useState<any>(excessDetail);
  const [movementInfo, setMovementInfo] = useState([
    { id: 0, amount: 0, amountType: "" },
  ]);
  const [showDelete, setShowDelete] = useState(false);
  const [showReverseConfirm, setShowReverseConfirm] = useState(false);
  const [rowInfo, setRowInfo] = useState<MovementInfo>({
    id: 0,
    movementDate: "",
    movementType: "",
    amountType: "",
    amount: 0,
    reversable: false,
  });

  const creditDetail =
    excessDetail &&
    excessDetail.filter((item: any) => item.amountType === CREDIT);

  const chargeDetail =
    excessDetail &&
    excessDetail.filter((item: any) => item.amountType === CHARGE);

  const [updateMovement, { loading }] = useMutation(
    BULK_UPDATE_SURPLUS_MOVEMENT,
    {
      fetchPolicy: NETWORK_ONLY as any,
      onCompleted: () => {
        setSnackInfo({
          type: SET_SNACK_SUCCESS,
          payload: UPDATE_SUCCESS,
        });
        setRefresh(true);
        handleClose();
      },
      onError: (error: any) => {
        let { message, code } = error?.graphQLErrors[0] || {};
        if (VALIDATION_FAILED === code) {
          setSnackInfo({
            type: SET_SNACK_ERROR,
            payload: message.split(".")[0],
          });
          refreshOnError();
        } else {
          setSnackInfo({
            type: SET_SNACK_ERROR,
            payload: UPDATE_ERROR,
          });
        }
        handleClose();
      },
    }
  );

  const handleRowAmountChange = (e: any, id: number, index: number) => {
    const newMovementInfo = [...movementInfo];

    let value = e.target.value;
    const regex = /^\d+$/;
    value = value.replace(/^0+/, "");

    if (regex.test(value)) {
      newMovementInfo[index] = {
        id: id,
        amount: parseInt(value),
        amountType: movementData[index].amountType,
      };
      const updatedValues = movementData.map((item: any) => {
        return item.id === id ? { ...item, amount: parseInt(value) } : item;
      });
      setMovementData(updatedValues);
    } else if (value === "") {
      newMovementInfo[index] = {
        id: id,
        amount: 0,
        amountType: movementData[index].amountType,
      };
      const updatedValues = movementData.map((item: any) => {
        return item.id === id ? { ...item, amount: 0 } : item;
      });
      setMovementData(updatedValues);
    }
    setMovementInfo(newMovementInfo);
  };

  const updatedMovementInfo = () => {
    let updatedMovement = movementInfo.filter(
      (item) => item !== undefined && item.id !== 0
    );
    return updatedMovement;
  };

  const checkValidAmount = () => {
    let updatedAmount = updatedMovementInfo().map((item: any) => {
      const { amount } = item;
      return amount;
    });
    let validAmount = updatedAmount.every((item: any) => item > 0);
    return validAmount;
  };

  const handleSaveChanges = () => {
    let updatedMovement = updatedMovementInfo().map((item: any) => {
      const { amountType, ...rest } = item;
      return rest;
    });

    updateMovement({
      variables: {
        input: {
          movementsData: updatedMovement,
        },
      },
    });
  };

  const handleDeleteRow = (row: any) => {
    setShowDelete(true);
    setRowInfo(row);
  };

  const handleReverseRow = (row: any) => {
    setShowReverseConfirm(true);
    setRowInfo(row);
  };

  const handleClose = () => {
    setMovementData(excessDetail);
    setMovementInfo([{ id: 0, amount: 0, amountType: "" }]);
    handleCloseModal();
  };

  const validateTotalAmount = () => {
    let totalCredit = movementInfo
      .filter((item: any) => item?.amountType === CREDIT)
      .reduce((acc: any, item: any) => acc + item.amount, 0);

    let totalCharge = movementInfo
      .filter((item: any) => item?.amountType === CHARGE)
      .reduce((acc: any, item: any) => acc + item.amount, 0);

    let initialCredit = 0;
    movementInfo.forEach((item: any) => {
      if (item !== undefined) {
        let mov =
          creditDetail && creditDetail.find((i: any) => i.id === item.id);
        if (mov) {
          initialCredit += mov.amount;
        }
      }
    });

    let initialCharge = 0;
    movementInfo.forEach((item: any) => {
      if (item !== undefined) {
        let mov =
          chargeDetail && chargeDetail.find((i: any) => i.id === item.id);
        if (mov) {
          initialCharge += mov.amount;
        }
      }
    });

    let deltaCredit = totalCredit - initialCredit;
    let deltaCharge = totalCharge - initialCharge;

    let validAmount =
      availableBalance + deltaCredit - deltaCharge < 0 ? false : true;
    return validAmount;
  };

  useEffect(() => {
    onOpen && setMovementData(excessDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen]);

  useEffect(() => {
    setMovementData(excessDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excessDetail]);

  return (
    <>
      <DeleteModal
        onOpen={showDelete}
        onClose={() => setShowDelete(false)}
        setRefresh={setRefresh}
        setSnackInfoParent={setSnackInfo}
        info={rowInfo}
        type={4}
        refreshOnError={refreshOnError}
        handleEditClose={handleClose}
      />
      <ConfirmModal
        onOpen={showReverseConfirm}
        onClose={() => setShowReverseConfirm(false)}
        setRefresh={setRefresh}
        setSnackInfoParent={setSnackInfo}
        info={rowInfo}
        refreshOnError={refreshOnError}
        handleEditClose={handleClose}
      />
      <CustomModal
        maxWidth="md"
        fullWidth
        open={onOpen}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <CustomModalTitle id="" onClose={handleCloseModal}>
          <Typography sx={littleTitle} textAlign="center" pt="32px">
            Editar detalle de movimientos
          </Typography>
          <Typography sx={modalSubtitle} textAlign="center">
            Aquí puedes ver o editar todos los movimientos por periodo de un
            RUT.
          </Typography>
        </CustomModalTitle>
        <DialogContent dividers sx={{ p: "0 !important" }}>
          {/* <SkeletonEditMovement /> */}
          <Grid container p={"18px 24px"}>
            <Grid item xs={4}>
              <Typography sx={purpleLittle} pb="4px">
                Nombre y RUT cliente
              </Typography>
              <Typography sx={blackBold} pt="4px">
                {contributorName} {contributorRut}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={purpleLittle} pb="4px">
                Período recaudación
              </Typography>
              <Typography sx={blackBold} pt="4px">
                {getCurrentPeriod(collectionPeriod)[0]}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={purpleLittle} pb="4px">
                Saldo disponible
              </Typography>
              <Typography sx={blackBold} pt="4px">
                ${availableBalance.toLocaleString("es-CL")}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container p={"18px 24px"}>
            <Grid item xs={9}>
              <Typography sx={titleTextBox} pb="4px">
                Movimientos
              </Typography>
              <Typography sx={littleDetail} pt="4px">
                Aquí puedes editar el monto del detalle de movimientos.
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {!validateTotalAmount() && (
                <Typography sx={errorText}>
                  Montos editados exceden saldo disponible
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} pt="24px">
              <TableContainer
                className="grayScroll"
                component={Paper}
                sx={{
                  boxShadow: "none",
                  borderRadius: "8px",
                  border: "0.5px solid #D2D6D9",
                  maxHeight: "290px",
                }}
              >
                {movementData?.length > 0 ? (
                  <Table
                    sx={{ minWidth: "auto" }}
                    aria-label="simple table"
                    stickyHeader
                  >
                    <TableHead className="color-head-table">
                      <TableRow>
                        <StyledTableCell>Fecha movimiento</StyledTableCell>
                        <StyledTableCell>
                          Tipo de <br></br> movimiento
                        </StyledTableCell>
                        <StyledTableCell>Detalle</StyledTableCell>
                        <StyledTableCell sx={{ width: "132px !important" }}>
                          Monto
                        </StyledTableCell>
                        <StyledTableCell>Reversar</StyledTableCell>
                        <StyledTableCell>Eliminar</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {movementData?.map((row: any, index: number) => {
                      return (
                        <TableBody>
                          <TableRow key={index}>
                            <TableCell>
                              <Typography variant="h5">
                                {row.movementDate}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h5">
                                {capitalCase(row.amountType)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h5">
                                {capitalCase(row.movementType)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <CustomInputSlim
                                onChange={(e) =>
                                  handleRowAmountChange(e, row.id, index)
                                }
                                error={movementData[index]?.amount === 0}
                                value={movementData[index]?.amount}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                                inputProps={{
                                  maxLength: 10,
                                  min: 0,
                                  "data-testid": "input-amount",
                                }}
                                disabled={
                                  row.movementType === REVERSE ||
                                  (row.amountType === CHARGE && !row.reversable)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <Button
                                color="inherit"
                                onClick={() => handleReverseRow(row)}
                                disabled={
                                  row.amountType === CREDIT || !row.reversable
                                }
                              >
                                <img
                                  src={
                                    row.amountType === CREDIT ||
                                    row.movementType === REVERSE ||
                                    !row.reversable
                                      ? GrayRefresh
                                      : GreenRefresh
                                  }
                                  alt=""
                                />
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Button
                                color="inherit"
                                onClick={() => handleDeleteRow(row)}
                                disabled={
                                  row.movementType === REVERSE ||
                                  (row.amountType === CHARGE && !row.reversable)
                                }
                              >
                                <img
                                  src={
                                    row.movementType === REVERSE ||
                                    (row.amountType === CHARGE &&
                                      !row.reversable)
                                      ? GrayTrash
                                      : GreenTrash
                                  }
                                  alt=""
                                />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })}
                  </Table>
                ) : (
                  <NoResult
                    titulo={"No se encontraron resultados"}
                    subtitulo={
                      "No se encontraron movimientos asociados a este registro de excedentes."
                    }
                  />
                )}
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
          >
            <Button
              onClick={handleClose}
              color="secondary"
              sx={{ width: "150px", mr: "16px" }}
            >
              {CLOSE}
            </Button>
            {loading ? (
              <Button
                color="primary"
                sx={{ height: "50px", minWidth: "230px" }}
              >
                <div className="spinnerButton"></div>
              </Button>
            ) : (
              <Button
                onClick={handleSaveChanges}
                color="primary"
                sx={{ width: "230px" }}
                disabled={
                  excessDetail === movementData ||
                  !validateTotalAmount() ||
                  !checkValidAmount()
                }
              >
                {SAVE_CHANGES}
              </Button>
            )}
          </Grid>
        </DialogActions>
      </CustomModal>
    </>
  );
};
export default EditMovement;
