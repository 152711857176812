import { Fragment } from "react";
import { Grid, Chip, Button } from "@mui/material";
import Cruz from "../../../assets/icons/cancelIcon.svg";
import { Filters, CollectionFilterProps } from "./collectionTable";

export function ChipsCollection({
  filtros,
  setFiltros,
}: CollectionFilterProps) {
  const removeChip = (filter: number) => {
    const filterMap: { [key: number]: keyof Filters } = {
      1: "contributorRut",
      2: "payerRut",
      3: "statusId",
      4: "workerType",
      5: "paymentType",
    };

    const filterKey = filterMap[filter];

    if (filterKey) {
      setFiltros({
        ...filtros,
        [filterMap[filter]]: null,
      });
    }
  };

  const clearFiltros = () => {
    setFiltros({
      ...filtros,
      contributorRut: null,
      payerRut: null,
      statusId: null,
      workerType: null,
      paymentType: null,
    });
  };

  const { contributorRut, payerRut, statusId, workerType, paymentType } =
    filtros;

  return (
    <Fragment>
      {(contributorRut ||
        payerRut ||
        statusId ||
        workerType ||
        paymentType) && (
        <Fragment>
          <Grid item xs={12} pl="24px" pt="15px">
            <label className="filtroFont">Resultados filtrados por: </label>{" "}
          </Grid>
          <Grid item xs={12} pt="4px" pl="24px">
            {contributorRut && (
              <Chip
                sx={{ mr: "16px" }}
                data-testid="chip-CRut"
                label="Rut cliente"
                variant="outlined"
                onDelete={() => removeChip(1)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-rut-contributor"
                  />
                }
              />
            )}
            {payerRut && (
              <Chip
                sx={{ mr: "16px" }}
                data-testid="chip-PRut"
                label="Rut pagador"
                variant="outlined"
                onDelete={() => removeChip(2)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-rut-payer"
                  />
                }
              />
            )}
            {statusId && (
              <Chip
                sx={{ mr: "16px" }}
                data-testid="chip-statusId"
                label="Clasificación"
                variant="outlined"
                onDelete={() => removeChip(3)}
                deleteIcon={
                  <img src={Cruz} alt="cruz" data-testid="icon-delete-status" />
                }
              />
            )}
            {workerType && (
              <Chip
                sx={{ mr: "16px" }}
                data-testid="chip-workerType"
                label="Tipo Trabajador"
                variant="outlined"
                onDelete={() => removeChip(4)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-worker-type"
                  />
                }
              />
            )}
            {paymentType && (
              <Chip
                sx={{ mr: "16px" }}
                data-testid="chip-paymentType"
                label="Origen"
                variant="outlined"
                onDelete={() => removeChip(5)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-payment-type"
                  />
                }
              />
            )}
            <Button
              color="inherit"
              sx={{
                textDecoration: "underline",
                textDecorationThickness: "from-font",
                pl: "12px",
              }}
              onClick={clearFiltros}
            >
              Limpiar filtros
            </Button>
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
}
