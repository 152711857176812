import { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { StyledTableCell } from "src/components/tables/tableStyle";
import { paginatorTheme } from "src/components/paginator/paginatorStyle";
import { subTable } from "src/components/typography/styles";
import NoResults from "src/components/tables/noResults";
import { SkeletonTableC } from "src/pages/collection/credits/components/skeletonsC";
import { useLogs } from "src/hooks/useLogs";

type ChangeLogTableProps = {
  id: string;
  logTypeId: number;
};

const ChangeLogTable = ({ id, logTypeId }: ChangeLogTableProps) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);

  const { getLog, count, rows, loading } = useLogs();

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    getLog({
      limit: rowsPerPage,
      offset: 0,
      logTypeId: logTypeId,
      fkId: parseInt(id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getLog({
      limit: rowsPerPage,
      offset: page * rowsPerPage - rowsPerPage,
      logTypeId: logTypeId,
      fkId: parseInt(id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  return (
    <>
      {loading ? (
        <SkeletonTableC grid={false} table={"thin"} />
      ) : (
        <>
          {count === 0 ? (
            <TableContainer
              component={Paper}
              sx={{ boxShadow: "none", borderRadius: "none" }}
            >
              <NoResults text={"No hay información para mostrar."} />
            </TableContainer>
          ) : (
            <>
              <TableContainer
                component={Paper}
                sx={{ boxShadow: "none", borderRadius: "none" }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead className="color-head-table">
                    <TableRow>
                      <StyledTableCell sx={{ minWidth: 150 }}>
                        {" "}
                        Usuario{" "}
                      </StyledTableCell>
                      <StyledTableCell sx={{ minWidth: 150 }}>
                        Fecha y hora
                      </StyledTableCell>
                      <StyledTableCell>Descripción </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {rows.map((file: any) => {
                    return (
                      <TableBody>
                        <TableRow key={file.id}>
                          <TableCell>
                            <Typography variant="h5">
                              {file.userName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h5">
                              {file.logDate.slice(0, 10).split("-").join("/")}
                            </Typography>
                            <Typography sx={subTable}>
                              {file.logDate.slice(11, 16) + "hrs"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h5">
                              {file.description}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })}
                </Table>
              </TableContainer>
              <Grid alignItems="center">
                <ThemeProvider theme={paginatorTheme}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={"Mostrar por página"}
                    sx={{ mt: "24px", mb: "24px" }}
                    labelDisplayedRows={(e) => {
                      return (
                        "" + page + " de " + Math.ceil(count / rowsPerPage)
                      );
                    }}
                  />
                </ThemeProvider>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ChangeLogTable;
