import React, { useState, useReducer, useRef, useEffect } from "react";
import { Button } from "@mui/material";
import Upload from "../../assets/icons/uploadWhite.svg";
import { UPLOAD_CONFIRMED_INVENTORY } from "src/services/graphql/mutations";
import { useLazyQuery, useMutation } from "@apollo/client";
import { fileToBase64 } from "src/utils/files";
import { readFile } from "src/utils/readExcel";
import { ModalSpinnerLoad } from "../modals/ModalSpinnerLoad";
import { GET_PRESIGNED_URL } from "src/services/graphql/queries";
import { putS3fetch } from "src/utils/s3";
import {
  SNACK_ACTION_TYPE,
  SNACKINFO_INITIAL_STATE,
  snackInfoReducer,
} from "src/reducers/snackInfoReducer";
import constants from "src/constants/constants";
import AlertSnackbar from "../snackbar/AlertSnackbar";
import { presignedUrlType } from "src/utils/selectors";
import { buildJsonFromFile } from "src/utils/buildJson";

const {
  COMPONENTS: {
    BUTTONS: { UPLOAD_FILE, UPLOAD_PAYROLL },
  },
  FILE: {
    MIMETYPE: { TXT, EXCEL, EXCEL_OLD },
    FORMAT: {
      TXT: TXT_FORMAT,
      EXCEL: EXCEL_FORMAT,
      EXCEL_OLD: EXCEL_OLD_FORMAT,
    },
  },
  PAGES: {
    DEVOLUTION,
    MODULES: { EXCESS, OVERAGE, CREDITS },
  },
  QUERIES: {
    FETCH_POLICY: { NETWORK_ONLY },
  },
  SNACKBAR_MESSAGE: {
    DEVOLUTION: { UPLOAD_ERROR: DEVOLUTION_UPLOAD_ERROR },
    DISTRIBUTION: {
      EXCESS_UPLOAD_ERROR: {
        DEFAULT: EXCESS_UPLOAD_ERROR_DEFAULT,
        MOVEMENT_DETAIL_ERROR: EXCESS_UPLOAD_ERROR_MOVEMENT_DETAIL_ERROR,
      },
      OVERAGE: {
        UPLOAD_ERROR: { DEFAULT: OVERAGE_UPLOAD_ERROR_DEFAULT },
      },
    },
    CREDITS_UPLOAD_ERROR,
    UPLOAD_ERROR_DEFAULT,
    UPLOAD_VALIDATION_ERROR,
  },
} = constants;

const { CLOSE_SNACK_INFO, SET_SNACK_ERROR } = SNACK_ACTION_TYPE;

const UploadButton = (props: any) => {
  const [file, setFile] = useState<File | null>(null);
  const hiddenFileInput = useRef() as React.MutableRefObject<HTMLInputElement>;
  const maxAllowedSize = props.maxSize * 1024 * 1024;
  const fileTypes = props.accept === TXT_FORMAT ? [TXT] : [EXCEL, EXCEL_OLD];
  const [snackInfoState, snackInfoDispatch] = useReducer(
    snackInfoReducer,
    SNACKINFO_INITIAL_STATE
  );

  const [uploadConfirmedFile, { loading }] = useMutation(
    UPLOAD_CONFIRMED_INVENTORY,
    {
      onCompleted: () => {
        props.setUploadState([false, true]);
        props.setShowSnackbar(true);
        props.setRefresh(true);
        props.setOpenLoad(false);
      },
      onError: () => {
        props.setUploadState([true, false]);
        props.setShowSnackbar(true);
        props.setOpenLoad(false);
      },
    }
  );

  const [getPresignedURL] = useLazyQuery(GET_PRESIGNED_URL, {
    fetchPolicy: NETWORK_ONLY as any,
    variables: {
      input: {
        types: presignedUrlType(props.module),
      },
    },
    onError: () => {
      handleDefaultError(props.module);
    },
  });

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let errorArray = [];
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > maxAllowedSize) {
        errorArray.push("El archivo es demasiado grande");
      }
      if (!fileTypes.includes(e.target.files[0].type)) {
        errorArray.push("El archivo no es del tipo correcto");
      }
      if (errorArray.length === 0) {
        setFile(e.target.files[0]);
      } else {
        handleCloseOnError(UPLOAD_VALIDATION_ERROR);
      }
    }
    e.target.value = "";
  };

  const handleUpload = async () => {
    try {
      if (file) {
        const fileBase64 = await fileToBase64(file);
        uploadConfirmedFile({
          variables: {
            input: {
              base64: fileBase64,
              inventoryId: props.inventoryId,
              inventoryInstitutionId: props.inventoryInstitutionId,
              mimeType: file.type,
              name: file.name,
              size: file.size,
            },
          },
        });
      }
    } catch (error) {
      return error;
    }
  };

  const handleCloseOnError = (message: string) => {
    props.setOpenLoad(false);
    snackInfoDispatch({
      type: SET_SNACK_ERROR,
      payload: message,
    });
    setFile(null);
  };

  const handlePresignedUpload = async (module: string) => {
    if (file) {
      props.setUploadInProgress(true);
      const { data, error: readError } = await readFile(file, module);
      try {
        if (data !== null) {
          const {
            data: { primaryJson, secondaryJson },
            error: errorJson,
          } = buildJsonFromFile(data, module, props.period, props.mapData);
          if (errorJson.length === 0) {
            let resultPrimaryJson: any;
            let resultSecondaryJson: any;
            let resultFile: any;
            try {
              await getPresignedURL()
                .then(async (res) => {
                  resultPrimaryJson = await putS3fetch(
                    res.data.utils.getPresignedURL[0].url,
                    primaryJson,
                    1
                  );
                  if (module !== DEVOLUTION) {
                    resultSecondaryJson = await putS3fetch(
                      res.data.utils.getPresignedURL[1].url,
                      secondaryJson,
                      1
                    );
                  } else {
                    resultSecondaryJson = 200;
                  }
                  resultFile = await putS3fetch(
                    res.data.utils.getPresignedURL[
                      module !== DEVOLUTION ? 2 : 1
                    ].url,
                    file,
                    0
                  );
                })
                .finally(() => {
                  if (
                    (resultPrimaryJson && resultSecondaryJson && resultFile) ===
                    200
                  ) {
                    props.onUpload();
                  } else {
                    handleDefaultError(module);
                  }
                });
            } catch (error) {
              handleDefaultError(module);
            }
          } else {
            if (module === EXCESS) {
              handleCloseOnError(EXCESS_UPLOAD_ERROR_MOVEMENT_DETAIL_ERROR);
              //debug suggested as there is no error detail message for this case
              console.log("Detalle error movimientos: ", errorJson);
            } else {
              handleDefaultError(module);
            }
          }
        } else {
          readError
            ? handleCloseOnError(readError)
            : handleDefaultError(module);
        }
      } catch (error) {
        readError ? handleCloseOnError(readError) : handleDefaultError(module);
      }
    }
  };

  const handleDefaultError = (module: string) => {
    switch (module) {
      case CREDITS:
        return handleCloseOnError(CREDITS_UPLOAD_ERROR);
      case EXCESS:
        return handleCloseOnError(EXCESS_UPLOAD_ERROR_DEFAULT);
      case OVERAGE:
        return handleCloseOnError(OVERAGE_UPLOAD_ERROR_DEFAULT);
      case DEVOLUTION:
        return handleCloseOnError(DEVOLUTION_UPLOAD_ERROR);
      default:
        return handleCloseOnError(UPLOAD_ERROR_DEFAULT);
    }
  };

  useEffect(() => {
    if (file) {
      if (props.accept === TXT_FORMAT) {
        props.setOpenLoad(true);
        handleUpload();
      }
      if (props.accept.includes(EXCEL_FORMAT || EXCEL_OLD_FORMAT)) {
        props.setOpenLoad(true);
        handlePresignedUpload(props.module);
      }
    }
    // eslint-disable-next-line
  }, [file]);

  return (
    <>
      <AlertSnackbar
        onClick={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        onClose={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        open={snackInfoState.open}
        status={snackInfoState.status}
        message={snackInfoState.message}
      />
      {(loading || props.uploadInProgress) && (
        <ModalSpinnerLoad
          onOpen={props.openLoad}
          text="Cargando archivo"
          subText=" "
        />
      )}
      <>
        <Button
          color="primary"
          startIcon={<img src={Upload} alt="" />}
          onClick={handleClick}
          disabled={props.disabled}
        >
          {props.module === DEVOLUTION ? UPLOAD_PAYROLL : UPLOAD_FILE}
        </Button>
        <input
          type="file"
          ref={hiddenFileInput}
          accept={props.accept}
          onChange={handleFileChange}
          style={{ display: "none" }}
          data-testid="fileInput"
        />
      </>
    </>
  );
};

export default UploadButton;
