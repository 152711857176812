import { useEffect, useReducer, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import CheckIcon from "src/assets/icons/checkIcon.svg";
import CheckGray from "src/assets/icons/checkGray.svg";
import {
  littleDetail,
  warningGray,
  warningPurple,
} from "src/components/typography/styles";
import { filterBox } from "src/components/box/styles";
import {
  Alerts,
  AlertSnackbar,
  Breadcrum,
  CustomSelect,
  TabPanel,
  UploadButton,
  WarningModalCustom,
} from "src/components";
import SkeletonBar from "src/components/skeleton/skeletonBar";
import {
  SNACK_ACTION_TYPE,
  SNACKINFO_INITIAL_STATE,
  snackInfoReducer,
} from "src/reducers/snackInfoReducer";
import { SkeletonHeaderC } from "../collection/credits/components/skeletonsC";
import { usePeriod } from "src/hooks/usePeriod";
import TableDevolution from "./components/TableDevolution";
import { getCurrentPeriod } from "src/utils/dates";
import { RecordTableCash } from "../collection/Cash";
import constants from "src/constants/constants";
import { useStatus } from "src/hooks/useStatus";
import { useMutation } from "@apollo/client";
import {
  CLOSE_DEVOLUTION_PROCESS,
  INSERT_FILE_DEVOLUTION,
  INSERT_JSON_DEVOLUTION,
} from "src/services/graphql/mutations";
import { useLogs } from "src/hooks/useLogs";
import { systemModules } from "src/utils/selectors";
import { hasPermissions } from "src/utils/hasPermissions";

const {
  CLOSE_SNACK_INFO,
  SET_SNACK_ERROR,
  SET_SNACK_SUCCESS,
  SET_SNACK_WARNING,
} = SNACK_ACTION_TYPE;
const {
  FILE: {
    FORMAT: { EXCEL, EXCEL_OLD },
  },
  PAGES: { DEVOLUTION },
  QUERIES: {
    CODE: { FOREIGN_KEY_ERROR, UNIQUE_KEY_ERROR },
  },
  SNACKBAR_MESSAGE: {
    DEVOLUTION: {
      CONFIRM_PERIOD_ERROR,
      CONFIRM_PERIOD_SUCCESS,
      UPLOAD_ERROR,
      UPLOAD_FOREIGN_KEY_ERROR,
      UPLOAD_UNIQUE_KEY_ERROR,
      UPLOAD_SUCCESS,
    },
    UPLOAD_WARNING,
  },
} = constants;

const Devolution = () => {
  const [snackInfoState, snackInfoDispatch] = useReducer(
    snackInfoReducer,
    SNACKINFO_INITIAL_STATE
  );
  const [filterPeriod, setFilterPeriod] = useState<any>({
    value: 0,
    name: "",
    status: 0,
  });
  const [selectedPeriod, setSelectedPeriod] = useState<string>("");
  const [refresh, setRefresh] = useState<boolean>(false);
  const [disabledActions, setDisabledActions] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number>(0);
  const [openWarning, setOpenWarning] = useState(false);
  const [openLoad, setOpenLoad] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [readOnly, setReadOnly] = useState(false);

  const {
    allPeriodsByProcess,
    periodsByProcess,
    loadingByProcess,
    listPeriodNames,
    getLastConfirmedPeriod,
    confirmedPeriod,
    loadingConfirmedPeriod,
  } = usePeriod({ processType: 3 });

  const { statusByType, allStatusByType, loadingByType } = useStatus({
    specificType: 4,
  });

  const { getLog, rows: rowsLogs, loading: loadingLogs } = useLogs();
  const getLatestUploadedFile = (period: string) => {
    getLog({
      logTypeId: systemModules(DEVOLUTION),
      fkId: parseInt(period),
    });
  };

  const [insertDevolutionJson] = useMutation(INSERT_JSON_DEVOLUTION, {
    variables: {
      input: {
        collectionPeriod: selectedPeriod,
      },
    },
    onError: (error: any) => {
      const errorType = error?.graphQLErrors[0]?.message || "Error";
      snackInfoDispatch({
        type: SET_SNACK_ERROR,
        payload:
          errorType === FOREIGN_KEY_ERROR
            ? UPLOAD_FOREIGN_KEY_ERROR
            : errorType === UNIQUE_KEY_ERROR
            ? UPLOAD_UNIQUE_KEY_ERROR
            : UPLOAD_ERROR,
      });
      handleCloseLoading();
    },
  });

  const [insertDevolutionFile] = useMutation(INSERT_FILE_DEVOLUTION, {
    onError: () => {
      snackInfoDispatch({
        type: SET_SNACK_WARNING,
        payload: UPLOAD_WARNING,
      });
      handleCloseLoading();
    },
  });

  const handleInsertDB = async () => {
    if (selectedPeriod) {
      await insertDevolutionJson().then(async (res) => {
        if (res?.data?.period !== null && res?.data?.period !== undefined) {
          await insertDevolutionFile({
            variables: {
              input: {
                logId:
                  res.data.period.distribution.devolution.devolutionMovement
                    .bulkCreate.data.logId,
              },
            },
          }).finally(() => {
            setRefresh(true);
            snackInfoDispatch({
              type: SET_SNACK_SUCCESS,
              payload: UPLOAD_SUCCESS,
            });
          });
        }
      });
      handleCloseLoading();
    }
  };

  const [confirmDevolution, { loading: loadingConfirm }] = useMutation(
    CLOSE_DEVOLUTION_PROCESS,
    {
      variables: {
        input: {
          periodName: selectedPeriod,
        },
      },
      onCompleted: () => {
        snackInfoDispatch({
          type: SET_SNACK_SUCCESS,
          payload: CONFIRM_PERIOD_SUCCESS,
        });
        allPeriodsByProcess();
        setOpenWarning(false);
      },
      onError: () => {
        snackInfoDispatch({
          type: SET_SNACK_ERROR,
          payload: CONFIRM_PERIOD_ERROR,
        });
        setOpenWarning(false);
      },
    }
  );

  const handleCloseLoading = () => {
    setOpenLoad(false);
    setUploadInProgress(false);
  };

  const handleSelectedPeriod = (period: any) => {    
    setSelectedPeriod(period.name);
    getLatestUploadedFile(period.name);
    setReadOnly(period.status === 3);
    setTabValue(0);
  };

  const WarningModalContent = () => {
    return (
      <>
        <br></br>
        <Typography sx={warningGray}>
          Estás confirmando el periodo de <br></br>
          devolución
        </Typography>{" "}
        <Typography sx={warningPurple}>
          {getCurrentPeriod(selectedPeriod as any)[0]}
        </Typography>
        <br></br>{" "}
        <Typography sx={warningGray}>
          ¿deseas continuar con esta acción?
        </Typography>
      </>
    );
  };

  useEffect(() => {
    allPeriodsByProcess();
    getLastConfirmedPeriod();
    statusByType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refresh) {
      getLatestUploadedFile(selectedPeriod);
      getLastConfirmedPeriod();
      setTabValue(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    if (periodsByProcess.length > 0) {
      setFilterPeriod({
        value: 1,
        name: periodsByProcess[0].name,
        status: periodsByProcess[0].devolutionStatusId,
      });
      handleSelectedPeriod(periodsByProcess[0]);
      setReadOnly(periodsByProcess[0].devolutionStatusId === 3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodsByProcess]);

  return (
    <>
      <AlertSnackbar
        onClick={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        onClose={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        open={snackInfoState.open}
        status={snackInfoState.status}
        message={snackInfoState.message}
      />
      <WarningModalCustom
        secondaryButton="Cancelar"
        primaryButton="Confirmar"
        contentText={<WarningModalContent />}
        title="Confirmar periodo"
        headWarning
        onOpen={openWarning}
        loading={loadingConfirm}
        onClose={() => setOpenWarning(false)}
        onConfirm={confirmDevolution}
        loadingWidth={"120px"}
      />
      <Box pl="32px" pr="32px">
        <Typography variant="h1" pt="24px" mb="16px">
          Devolución
        </Typography>
        <Breadcrum back1="Inicio" link1="/" now="Devolución" />
        {loadingConfirmedPeriod ? (
          <SkeletonBar width={"auto"} height={52} />
        ) : (
          confirmedPeriod !== null && (
            <>
              <Alerts
                mensaje={`Último periodo confirmado 
                ${getCurrentPeriod(confirmedPeriod.name)[0]}.`}
                open={true}
                data={null}
                setOpen={null}
                severidad={"info"}
                noClose
                typeAlert={2}
              />
            </>
          )
        )}
        <Box sx={filterBox} mt="16px" mb="24px">
          {loadingByProcess ? (
            <SkeletonHeaderC />
          ) : (
            <Grid container>
              <Grid xs={7} pb="16px">
                <Typography variant="h2" component="div">
                  Selección de periodo
                </Typography>
                <Typography variant="h3" component="div">
                  Selecciona un periodo para ver la información correspondiente.
                </Typography>
              </Grid>
              <Grid xs={5} pt="14px" alignItems="right">
                <Stack direction="row" alignItems="end">
                  <CustomSelect
                    onChange={(e: any) => {
                      setFilterPeriod({
                        value: e.target.value,
                        name: periodsByProcess[e.target.value - 1].name,
                        status:
                          periodsByProcess[e.target.value - 1]
                            .devolutionStatusId,
                      });
                    }}
                    value={filterPeriod.value}
                    titulo={
                      periodsByProcess.length === 0
                        ? "No hay periodos confirmados"
                        : "Seleccione"
                    }
                    disabled={periodsByProcess.length === 0}
                    data={listPeriodNames(periodsByProcess)}
                    hideTitle
                  />
                  <Button
                    sx={{ ml: "16px", width: "150px" }}
                    onClick={() => handleSelectedPeriod(filterPeriod)}
                    data-testid="btn-select"
                    disabled={periodsByProcess.length === 0}
                  >
                    Seleccionar
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          )}
          <Divider sx={{ mt: "16px", mb: "16px" }} />
          {loadingLogs || loadingByProcess ? (
            <SkeletonHeaderC />
          ) : (
            <Grid container>
              <Grid xs={7} pb="16px">
                <Typography variant="h2" component="div">
                  Carga información de devoluciones:
                </Typography>
                <br />
                <Typography variant="h3" component="div">
                  Aquí puedes cargar los archivos del periodo actual
                </Typography>
                <Typography variant="h3" component="div">
                  {rowsLogs.length > 0
                    ? "Última carga realizada el " +
                      rowsLogs[0]?.logDate.slice(0, 10).split("-").join("/") +
                      " a las " +
                      rowsLogs[0]?.logDate.slice(11, 16) +
                      " hrs con un total de " +
                      rowsLogs[0]?.description +
                      " registros."
                    : "No se han realizado cargas."}
                </Typography>
              </Grid>
              <Grid xs={5} pt="50px">
                <Stack
                  direction="row"
                  alignItems="end"
                  textAlign="right"
                  justifyContent="flex-end"
                >
                  <Typography sx={littleDetail} component="div" pr="16px">
                    Formato aceptado: xlsx
                    <br />
                    Peso máximo de 2MB
                  </Typography>
                  <UploadButton
                    disabled={
                      readOnly ||
                      loadingConfirmedPeriod ||
                      loadingByProcess ||
                      loadingByType ||
                      !confirmedPeriod?.isPayrollUploadable
                    }
                    accept={[EXCEL, EXCEL_OLD]}
                    maxSize={2}
                    onUpload={() => handleInsertDB()}
                    openLoad={openLoad}
                    setOpenLoad={setOpenLoad}
                    uploadInProgress={uploadInProgress}
                    setUploadInProgress={setUploadInProgress}
                    period={selectedPeriod}
                    module={DEVOLUTION}
                    mapData={allStatusByType}
                  />
                </Stack>
              </Grid>
            </Grid>
          )}
        </Box>
        <Tabs
          sx={{ width: "100%" }}
          textColor="primary"
          value={tabValue}
          onChange={(event, value) => setTabValue(value)}
        >
          <Tab label="Devolución" />
          <Tab label="Historial de Carga" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <TableDevolution
            refresh={refresh}
            setRefresh={setRefresh}
            setSnackInfo={snackInfoDispatch}
            collectionPeriod={selectedPeriod}
            readOnly={readOnly}
            loadingList={loadingByProcess}
            disabledActions={disabledActions}
            setDisabledActions={setDisabledActions}
          />
          <Box sx={filterBox} mt="16px" mb="24px">
            <Grid container>
              <Grid xs={9}>
                <Typography variant="h2" component="div">
                  Confirmar periodo de devolución
                </Typography>
                <Typography variant="h3" component="div">
                  Para validar la información de un periodo ha de realizarse la
                  confirmación del mismo. Esta acción deshabilitará las opciones
                  de edición para este periodo.
                </Typography>
              </Grid>
              <Grid
                xs={3}
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Button
                  startIcon={
                    <img
                      src={
                        readOnly ||
                        disabledActions ||
                        !hasPermissions().closeProcess
                          ? CheckGray
                          : CheckIcon
                      }
                      alt=""
                    />
                  }
                  color="secondary"
                  onClick={() => setOpenWarning(true)}
                  disabled={
                    readOnly ||
                    disabledActions ||
                    !hasPermissions().closeProcess
                  }
                >
                  {readOnly ? "Confirmado" : "Confirmar"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <RecordTableCash
            refresh={refresh}
            setRefresh={setRefresh}
            period={selectedPeriod}
            module={DEVOLUTION}
            tabValue={tabValue}
          />
        </TabPanel>
      </Box>
    </>
  );
};

export default Devolution;
