import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_EXCESS } from "src/services/graphql/queries";
import {
  Box,
  Typography,
  Grid,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableBody,
  ThemeProvider,
  Stack,
  IconButton,
} from "@mui/material";
import { boxNoPadding, tableBox } from "src/components/box/styles";
import { paginatorTheme } from "src/components/paginator/paginatorStyle";
import { StyledTableCell } from "src/components/tables/tableStyle";
import CustomInput from "src/components/input/customInput";
import littleClose from "src/assets/icons/littleClose.svg";
import {
  contTitle,
  errorText,
  littleTitle,
  subTable,
  titleLight,
} from "src/components/typography/styles";
import { NoResult } from "src/components/tables/noResult";
import { SkeletonTableOverage } from "./loaderOverage";
import { handleRunChangeUtils } from "src/utils/validationUtils";
import constants from "src/constants/constants";
import MenuOverage from "./menuOverage";
import ChangeLogModal from "src/components/modals/changeLogModal";
import ExportOverage from "./exportOverage";
import DetailExcess from "../../excess/components/detailExcess";
import EditOverageNew from "./EditOverageNew";
import { ConfirmModalNew } from "src/components";
import { getCurrentPeriod } from "src/utils/dates";
import useOverage from "../hook/useOverage";
import { renderAmount } from "src/utils/numberUtils";

const {
  EXCESS: {
    MOVEMENT_TYPE: { EXCESS },
  },
  QUERIES: {
    INPUT: {
      LOG_TYPE: {
        UPDATE: { OVERAGE },
      },
    },
    FETCH_POLICY: { NETWORK_ONLY },
  },
  COMPONENTS: {
    BUTTONS: { DELETE, SEARCH, LOG_HISTORY },
  },
} = constants;

type tableOverageProps = {
  period: string | null;
  isActive: boolean;
  confirmed: boolean;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackInfo: React.Dispatch<any>;
  canUpdate: boolean;
  disabledActions: boolean;
  setDisabledActions: React.Dispatch<React.SetStateAction<boolean>>;
  refreshOnError?: (status: number) => void;
};

const TableOverage = ({
  period,
  isActive,
  confirmed,
  refresh,
  setRefresh,
  setSnackInfo,
  canUpdate,
  disabledActions,
  setDisabledActions,
  refreshOnError,
}: tableOverageProps) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [showDelete, setShowDelete] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [rowInfo, setRowInfo] = useState<any>({
    id: "",
    contributorRut: "",
    contributorName: "",
    collectionPeriod: "",
    paymentPeriod: "",
    availableBalance: 0,
    statusId: 0,
    statusName: "",
    typeId: 0,
    excessDetail: [],
  });
  const [selectedRowId, setSelectedRowId] = useState<number>(0);
  const [excessDetailInfo, setExcessDetailInfo] = useState({
    movementId: "",
    movementAmount: 0,
    updateable: false,
  });
  const [formattedRun, setFormattedRun] = useState("");
  const [runValue, setRunValue] = useState("");
  const [rut, setRut] = useState<any>({
    run: "",
    formattedRun: "",
    error: "",
    isValid: false,
  });
  const [rutFilter, setRutFilter] = useState(false);

  const [
    getExcess,
    {
      data: {
        period: {
          distribution: {
            excess: {
              allBy: { count, rows },
            },
          },
        },
      } = {
        period: { distribution: { excess: { allBy: { count: 0, rows: [] } } } },
      },
      loading,
      error,
    },
  ] = useLazyQuery(GET_EXCESS, {
    fetchPolicy: NETWORK_ONLY as any,
    variables: {
      input: {
        collectionPeriod: period,
        contributorRut: null,
        isActive: isActive,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
      },
    },
    onCompleted: () => {
      if (firstLoad) {
        setFirstLoad(false);
      }
      if (selectedRowId !== 0) {
        const selectedRow = rows.find((row: any) => row.id === selectedRowId);
        if (selectedRow) {
          setRowInfo(selectedRow);
        } else {
          setSelectedRowId(0);
        }
      }
    },
    onError: () => {
      if (firstLoad) {
        setFirstLoad(false);
      }
    },
  });

  const { deleteOverage, loadingDelete } = useOverage({
    id: selectedRowId === 0 ? 0 : rowInfo.id,
    setSnackInfo: setSnackInfo,
    setRefresh: setRefresh,
    handleClose: () => setShowDelete(false),
    refreshOnError: refreshOnError,
  });

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleRunChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let checkRun = handleRunChangeUtils(value);
    if (checkRun.runValue !== "" || value.length === 0) {
      setRunValue(checkRun.runValue);
      setFormattedRun(checkRun.formattedRun);
      setRut({
        run: checkRun.formattedRun ? checkRun.runValue : null,
        formattedRun: checkRun.formattedRun,
        error: checkRun.error,
        isValid: checkRun.isValid,
      });
    }
  };

  const clearRunFilter = () => {
    setRunValue("");
    setFormattedRun("");
    setRut({
      run: "",
      formattedRun: "",
      error: "",
      isValid: false,
    });
    setRutFilter(false);
  };

  const checkValidRut = (data: any) => {
    if (data.error === "" && data.run !== "" && data.isValid) return true;
    return false;
  };

  const handleFilter = () => {
    setRutFilter(true);
    setPage(1);
    getExcess({
      variables: {
        input: {
          collectionPeriod: period,
          contributorRut: rut.formattedRun.replace(/\./g, ""),
          isActive: isActive,
          limit: rowsPerPage,
          offset: 0,
        },
      },
    });
  };

  const checkExcessEmptyState = () => {
    if (count === 0 && rut.run === "") {
      return true;
    } else {
      return false;
    }
  };

  const handleDetails = (elem: any, type: number) => {
    setRowInfo({
      id: elem.id,
      paymentPeriod: elem.paymentPeriod,
      contributorRut: elem.contributorRut,
      contributorName: elem.contributorName,
      collectionPeriod: elem.collectionPeriod,
      availableBalance: elem.availableBalance,
      statusId: elem.statusId,
      statusName: elem.statusName,
      typeId: elem.typeId,
      excessDetail: elem.excessDetail,
    });
    setSelectedRowId(elem.id);
    if (type === 1) setShowDetail(true);
    if (type === 2) setShowHistory(true);
    if (type === 3) setShowEdit(true);
    if (type === 4) setShowDelete(true);
  };

  useEffect(() => {
    if (period) {
      getExcess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (period) {
      getExcess({
        variables: {
          input: {
            collectionPeriod: period,
            contributorRut: rut.formattedRun.replace(/\./g, "") || null,
            isActive: isActive,
            limit: rowsPerPage,
            offset: (page - 1) * rowsPerPage,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (refresh === true) {
      setPage(1);
      getExcess({
        variables: {
          input: {
            contributorRut: rut.formattedRun.replace(/\./g, "") || null,
            collectionPeriod: period,
            isActive: isActive,
            limit: rowsPerPage,
            offset: 0,
          },
        },
      });
      setRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    if (runValue === "" && period) {
      setPage(1);
      getExcess({
        variables: {
          input: {
            contributorRut: null,
            collectionPeriod: period,
            isActive: isActive,
            limit: rowsPerPage,
            offset: 0,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runValue]);

  const getExcessDetailIdAndAmount = (excessDetail: any) => {
    const detailExcessMovement = excessDetail.filter(
      (detail: any) => detail.movementType === EXCESS
    );
    if (detailExcessMovement.length === 1) {
      setExcessDetailInfo({
        movementId: detailExcessMovement[0].id,
        movementAmount: detailExcessMovement[0].amount,
        updateable: true,
      });
    } else {
      setExcessDetailInfo({
        movementId: "",
        movementAmount: 0,
        updateable: false,
      });
    }
  };

  useEffect(() => {
    if (rowInfo?.excessDetail !== null && rowInfo?.excessDetail.length > 0) {
      getExcessDetailIdAndAmount(rowInfo.excessDetail);
    }
  }, [rowInfo]);

  const confirmContent = () => {
    return (
      <div style={{ textAlign: "center", paddingTop: "19px" }}>
        <label className="firstTextModal">
          Al eliminar este registro no podrá recuperar <br></br> los datos
          eliminados correspondientes a
        </label>{" "}
        <br></br>
        <label className="blueModalText">
          {getCurrentPeriod(rowInfo?.paymentPeriod)[0]} - RUT{" "}
          {rowInfo?.contributorRut}
        </label>
        <br></br>
        <label className="firstTextModal">
          {" "}
          ¿Estás seguro de continuar con esta acción?
        </label>
      </div>
    );
  };

  return (
    <>
      <DetailExcess
        onOpen={showDetail}
        handleCloseModal={() => setShowDetail(false)}
        info={rowInfo}
        type={2}
      />
      <EditOverageNew
        onOpen={showEdit}
        handleCloseModal={() => setShowEdit(false)}
        info={rowInfo}
        setRefresh={setRefresh}
        setSnackInfo={setSnackInfo}
        canUpdate={canUpdate}
        refreshOnError={refreshOnError}
      />
      <ConfirmModalNew
        content={confirmContent()}
        label={DELETE}
        loading={loadingDelete}
        onClose={() => setShowDelete(false)}
        onOpen={showDelete}
        title={"Eliminar registro"}
        toDo={deleteOverage}
      />
      <ChangeLogModal
        onOpen={showHistory}
        onClose={() => setShowHistory(false)}
        id={rowInfo?.id}
        logTypeId={OVERAGE}
      />
      {loading || firstLoad ? (
        <SkeletonTableOverage rows={3} columns={4} filter={true} />
      ) : (
        <Box sx={boxNoPadding} mt="16px" mb="24px">
          <Box sx={{ borderRadius: "8px", p: "24px " }}>
            <Grid container alignItems={"center"}>
              {isActive ? (
                <Grid item xs={6} pr={"8px"}>
                  <Typography
                    sx={littleTitle}
                    component="div"
                    display={"inline"}
                  >
                    Excesos
                  </Typography>
                  <Typography
                    sx={titleLight}
                    component="div"
                    display={"inline"}
                  >
                    {" "}
                    - Saldo carga
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <Typography sx={contTitle}>
                    Listado de registros eliminados
                  </Typography>{" "}
                </Grid>
              )}
              <Grid item xs={6}>
                <Stack
                  direction="row"
                  spacing="16px"
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <Box>
                    <Typography variant="h3" pb="6px">
                      RUT
                    </Typography>
                    <CustomInput
                      id="outlined-basic"
                      variant="outlined"
                      fullWidth
                      sx={{
                        borderRadius: "16px",
                        backgroundColor: "#FFF",
                      }}
                      placeholder="Ingresar"
                      type="text"
                      disabled={error ? true : checkExcessEmptyState()}
                      value={formattedRun || runValue}
                      onChange={handleRunChange}
                      inputProps={{
                        "data-testid": "input-run",
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={clearRunFilter}
                            data-testid="cleanButton"
                            disabled={disabledActions}
                          >
                            <img src={littleClose} alt="limpiar rut" />
                          </IconButton>
                        ),
                      }}
                    />
                    {rut.error && (
                      <Typography sx={errorText}>RUT inválido.</Typography>
                    )}
                  </Box>
                  <Box pt="30px">
                    <Button
                      color="secondary"
                      disabled={
                        error ? true : !checkValidRut(rut) || disabledActions
                      }
                      onClick={handleFilter}
                    >
                      {SEARCH}
                    </Button>
                  </Box>
                  <Box pt="30px">
                    <ExportOverage
                      period={period}
                      setSnackInfo={setSnackInfo}
                      disabled={error ? true : false || checkExcessEmptyState()}
                      contributorRut={rut.formattedRun.replace(/\./g, "")}
                      isActive={isActive}
                      order={1}
                      noData={count === 0}
                      setDisabledActions={setDisabledActions}
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box sx={tableBox}>
            <Paper className="paper-tables-2" sx={{ boxShadow: "none " }}>
              {error ? (
                <NoResult
                  titulo={"No se pudo cargar la tabla"}
                  subtitulo={
                    "No fue posible mostrar la información debido a un problema de conexión. Intenta recargar la página para solucionar el problema."
                  }
                  error
                />
              ) : (
                <>
                  {count === 0 ? (
                    rutFilter ? (
                      <NoResult
                        titulo={"No se encontró información asociada al RUT"}
                        subtitulo={"Inténtelo nuevamente usando otro criterio"}
                        newFound
                      />
                    ) : (
                      <NoResult
                        titulo={"No hay información para mostrar."}
                        subtitulo={
                          isActive
                            ? "Cargue información para visualizar excesos"
                            : "No se han realizado acciones de eliminación para visualizar registros."
                        }
                        newDontSearch
                      />
                    )
                  ) : (
                    <>
                      <TableContainer
                        className="tContainer-padding-0"
                        component={Paper}
                        sx={{
                          borderRadius: 0,
                          boxShadow: "none ",
                          borderTop: "solid 2px #E9EBEC",
                        }}
                      >
                        <Table
                          sx={{ minWidth: 650 }}
                          aria-label="customized table"
                        >
                          <TableHead className="color-head-table">
                            <TableRow>
                              <StyledTableCell>
                                RUT y Nombre <br></br> cliente
                              </StyledTableCell>
                              <StyledTableCell>Abono</StyledTableCell>
                              <StyledTableCell>Cargo</StyledTableCell>
                              <StyledTableCell>Saldo</StyledTableCell>
                              <StyledTableCell
                                width={"50px"}
                                className="headerTableAcciones"
                                align="center"
                              >
                                Acciones
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          {rows.map((row: any, index: number) => {
                            return (
                              <TableBody>
                                <TableRow key={index}>
                                  <TableCell>
                                    <Typography variant="h5">
                                      {" "}
                                      {row.contributorRut}
                                    </Typography>
                                    <Typography sx={subTable}>
                                      {row.contributorName ?? "N/D"}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="h5">
                                      ${renderAmount(row.creditAmount)}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="h5">
                                      ${renderAmount(row.chargeAmount)}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="h5">
                                      ${renderAmount(row.availableBalance)}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    className="tableBodyAsignar"
                                    align="center"
                                  >
                                    {isActive ? (
                                      <MenuOverage
                                        info={row}
                                        handleDetails={() =>
                                          handleDetails(row, 1)
                                        }
                                        handleHistory={() =>
                                          handleDetails(row, 2)
                                        }
                                        handleEdit={() => handleDetails(row, 3)}
                                        handleDelete={() =>
                                          handleDetails(row, 4)
                                        }
                                        disabled={
                                          !excessDetailInfo.updateable ||
                                          confirmed
                                        }
                                        canUpdate={canUpdate}
                                        disabledActions={confirmed}
                                      />
                                    ) : (
                                      <Button
                                        color="secondary"
                                        onClick={() => handleDetails(row, 2)}
                                      >
                                        {LOG_HISTORY}
                                      </Button>
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            );
                          })}
                        </Table>
                      </TableContainer>
                      <ThemeProvider theme={paginatorTheme}>
                        <TablePagination
                          rowsPerPageOptions={[10, 20, 50]}
                          component="div"
                          count={count}
                          rowsPerPage={rowsPerPage}
                          page={page - 1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelRowsPerPage={"Mostrar por página"}
                          sx={{ mt: "24px", mb: "24px" }}
                          labelDisplayedRows={(e) => {
                            return (
                              "" +
                              (page || 0) +
                              " de " +
                              Math.ceil(count / rowsPerPage)
                            );
                          }}
                          SelectProps={{
                            disabled: disabledActions,
                          }}
                          backIconButtonProps={
                            disabledActions
                              ? {
                                  disabled: disabledActions,
                                }
                              : undefined
                          }
                          nextIconButtonProps={
                            disabledActions
                              ? {
                                  disabled: disabledActions,
                                }
                              : undefined
                          }
                        />
                      </ThemeProvider>
                    </>
                  )}
                </>
              )}
            </Paper>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TableOverage;
