import { useEffect, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { StyledTableCell } from "src/components/tables/tableStyle";
import { paginatorTheme } from "src/components/paginator/paginatorStyle";
import {
  blackBold,
  littleTitle,
  modalSubtitle,
  purpleLittle,
  subTable,
} from "src/components/typography/styles";
import {
  CustomModal,
  CustomModalTitle,
} from "src/components/modals/customModal";
import { getCurrentPeriod } from "src/utils/dates";
import { NoResult } from "src/components/tables/noResult";
import { SkeletonTableModal } from "../previred/components/skeletonsPrevired";
import { usePayment } from "src/pages/collection/hooks/usePayment";
import { renderAmount } from "src/utils/numberUtils";

type AssociatedPaymentsProps = {
  onOpen: boolean;
  handleCloseModal: () => void;
  info: {
    contributorName: string;
    contributorRut: string;
    contractedAmount: number;
    paymentPeriod: string;
  };
};

const AssociatedPayments = ({
  onOpen,
  handleCloseModal,
  info,
}: AssociatedPaymentsProps) => {
  const { contributorName, contributorRut, contractedAmount, paymentPeriod } =
    info;
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);

  const {
    getPaymentData,
    paymentCount,
    paymentRows,
    paymentLoading,
    paymentError,
  } = usePayment();

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (onOpen) {
      getPaymentData({
        contributorRut: contributorRut,
        paymentPeriod: paymentPeriod,
        includePrevired: true,
        isActive: true,
        limit: rowsPerPage,
        offset: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen]);

  useEffect(() => {
    if (onOpen) {
      getPaymentData({
        contributorRut: contributorRut,
        paymentPeriod: paymentPeriod,
        includePrevired: true,
        isActive: true,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  return (
    <CustomModal
      maxWidth="md"
      fullWidth
      open={onOpen}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <CustomModalTitle id="" onClose={handleCloseModal}>
        <Typography sx={littleTitle} textAlign="center" pt="32px">
          Pagos asociados
        </Typography>
        <Typography sx={modalSubtitle} textAlign="center">
          A continuación se muestra una lista de todos los pagos asociados.
        </Typography>
      </CustomModalTitle>
      <DialogContent dividers sx={{ overflow: "visible" }}>
        <Grid container pb="24px">
          <Grid xs={6} pr="24px">
            <Typography sx={purpleLittle} component="div" pb="4px">
              Nombre y RUT cliente
            </Typography>
            <Typography sx={blackBold} component="div">
              {contributorName} {contributorRut}
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography sx={purpleLittle} component="div" pb="4px">
              Pactado pesos
            </Typography>
            <Typography sx={blackBold} component="div">
              ${renderAmount(contractedAmount)}
            </Typography>
          </Grid>
          <Grid xs={6} pt="24px">
            <Typography sx={purpleLittle} component="div" pb="4px">
              Periodo remuneración
            </Typography>
            <Typography sx={blackBold} component="div">
              {getCurrentPeriod(paymentPeriod)[0]}
            </Typography>
          </Grid>
        </Grid>
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none",
            borderRadius: "8px",
            border: "1px solid #E9EBEC",
          }}
        >
          {paymentLoading ? (
            <SkeletonTableModal />
          ) : (
            <>
              {paymentError ? (
                <NoResult
                  titulo={"No se pudo cargar la tabla"}
                  subtitulo={
                    "No fue posible mostrar la información debido a un problema de conexión."
                  }
                  error
                />
              ) : (
                <>
                  {paymentCount === 0 ? (
                    <NoResult
                      noDivider
                      dontSearch
                      titulo={"No hay información para mostrar."}
                      subtitulo="No se han encontrado pagos asociados a esta acreencia."
                    />
                  ) : (
                    <>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead className="color-head-table">
                          <TableRow>
                            <StyledTableCell>
                              RUT y Nombre pagador
                            </StyledTableCell>
                            <StyledTableCell>
                              Periodo recaudación
                            </StyledTableCell>
                            <StyledTableCell>Origen</StyledTableCell>
                            <StyledTableCell>Total pagado</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        {paymentRows.map((item: any) => {
                          return (
                            <TableBody>
                              <TableRow key={item.id}>
                                <TableCell>
                                  <Typography data-testid="rut" variant="h5">
                                    {item.payerRut}
                                  </Typography>
                                  <Typography sx={subTable}>
                                    {item.payerName}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="h5">
                                    {getCurrentPeriod(item.collectionPeriod)[0]}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="h5">
                                    {item.paymentType}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="h5">
                                    ${renderAmount(item.amount)}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          );
                        })}
                      </Table>
                      <Grid alignItems="center">
                        <ThemeProvider theme={paginatorTheme}>
                          <TablePagination
                            rowsPerPageOptions={[5, 10]}
                            component="div"
                            count={paymentCount}
                            rowsPerPage={rowsPerPage}
                            page={page - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={"Mostrar por página"}
                            sx={{ mt: "24px", mb: "24px" }}
                            labelDisplayedRows={(e) => {
                              return (
                                "" +
                                page +
                                " de " +
                                Math.ceil(paymentCount / rowsPerPage)
                              );
                            }}
                          />
                        </ThemeProvider>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            onClick={handleCloseModal}
            color="secondary"
            sx={{ width: "150px" }}
          >
            Cerrar
          </Button>
        </Grid>
      </DialogActions>
    </CustomModal>
  );
};
export default AssociatedPayments;
