import { Fragment } from "react";
import { Grid, Chip, Button } from "@mui/material";
import Cruz from "src/assets/icons/cancelIcon.svg";

export function ChipsPrevired({ filtros, setFiltros }: any) {
  const handleDelete = (filter: number) => {
    if (filter === 1) {
      setFiltros({
        ...filtros,
        contributorRut: null,
      });
    }
    if (filter === 2) {
      setFiltros({
        ...filtros,
        payerRut: null,
      });
    }
    if (filter === 3) {
      setFiltros({
        ...filtros,
        paymentDate: null,
      });
    }
  };

  const clearFiltros = () => {
    setFiltros({
      ...filtros,
      contributorRut: null,
      payerRut: null,
      paymentDate: null,
    });
  };

  return (
    <Fragment>
      {(filtros.contributorRut || filtros.payerRut || filtros.paymentDate) && (
        <Fragment>
          <Grid item xs={12} pl="24px" pt="15px">
            <label className="filtroFont">Resultados filtrados por: </label>{" "}
          </Grid>

          <Grid item xs={12} pt="4px" pl="24px">
            {filtros.contributorRut && (
              <Chip
                sx={{ mr: "16px" }}
                data-testid="delete-contributorRut-chip"
                label="RUT Cliente"
                variant="outlined"
                onDelete={() => handleDelete(1)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-contributorRut"
                  />
                }
              />
            )}

            {filtros.payerRut && (
              <Chip
                sx={{ mr: "16px" }}
                data-testid="delete-payerRut-chip"
                label="RUT Pagador"
                variant="outlined"
                onDelete={() => handleDelete(2)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-payerRut"
                  />
                }
              />
            )}

            {filtros.paymentDate && (
              <Chip
                sx={{ mr: "16px" }}
                data-testid="delete-paymentPeriod-chip"
                label="Fecha de pago"
                variant="outlined"
                onDelete={() => handleDelete(3)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-paymentDate"
                  />
                }
              />
            )}
            <Button
              color="inherit"
              sx={{
                textDecoration: "underline",
                textDecorationThickness: "from-font",
                pl: "12px",
              }}
              onClick={clearFiltros}
            >
              Limpiar filtros
            </Button>
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
}
