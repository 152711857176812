import {
  CURRENCY_INFO,
  CURRENT_CURRENCY,
  LIST_CURRENCY,
} from "src/services/graphql/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_UF, UPDATE_UF } from "src/services/graphql/mutations";

type CurrencyInputType = {
  currencyType: number;
  periodName: string;
};

type AddCurrencyType = {
  periodName: string;
  currencyType: number;
  currencyValue: number;
  onCompleted: () => void;
  onError: () => void;
};

type EditCurrencyType = {
  id: number;
  currencyValue: number;
  onCompleted: () => void;
  onError: () => void;
};

export const useCurrency = () => {
  const [
    getCurrencyData,
    {
      data: {
        period: {
          currency: { oneBy },
        },
      } = {
        period: {
          currency: {
            oneBy: {
              currencyValue: 0,
            },
          },
        },
      },
      loading,
      error,
    },
  ] = useLazyQuery(CURRENCY_INFO, {
    fetchPolicy: "network-only",
  });

  const [
    listCurrencyData,
    {
      data: {
        period: {
          currency: {
            allBy: { count, rows },
          },
        },
      } = {
        period: {
          currency: {
            allBy: {
              count: 0,
              rows: [],
            },
          },
        },
      },
      loading: loadingListCurrency,
      error: errorListCurrency,
    },
  ] = useLazyQuery(LIST_CURRENCY, {
    fetchPolicy: "network-only",
  });

  const [
    getCurrentCurrency,
    {
      data: {
        period: {
          currency: { current },
        },
      } = {
        period: {
          currency: {
            current: { currencyId: null, currencyValue: 0, periodName: "" },
          },
        },
      },
      loading: loadingCurrent,
    },
  ] = useLazyQuery(CURRENT_CURRENCY, {
    fetchPolicy: "network-only",
  });

  const currencyInfo = (options: CurrencyInputType) => {
    getCurrencyData({
      variables: {
        input: {
          currencyType: options.currencyType,
          periodName: options.periodName,
        },
      },
    });
  };

  const currencyList = (options: { limit: number; offset: number }) => {
    listCurrencyData({
      variables: {
        input: {
          limit: options.limit,
          offset: options.offset,
        },
      },
    });
  };

  const [createCurrency, { loading: loadingAddCurrency }] =
    useMutation(CREATE_UF);

  const addCurrency = (options: AddCurrencyType) => {
    createCurrency({
      variables: {
        input: {
          periodName: options.periodName,
          currencyType: options.currencyType,
          currencyValue: options.currencyValue,
        },
      },
      onCompleted: options.onCompleted,
      onError: options.onError,
    });
  };

  const [updateCurrency, { loading: loadingEditCurrency }] =
    useMutation(UPDATE_UF);

  const editCurrency = (options: EditCurrencyType) => {
    updateCurrency({
      variables: {
        input: {
          id: options.id,
          currencyValue: options.currencyValue,
        },
      },
      onCompleted: options.onCompleted,
      onError: options.onError,
    });
  };

  return {
    currencyInfo,
    oneBy,
    loading,
    error,
    currencyList,
    count,
    rows,
    loadingListCurrency,
    errorListCurrency,
    getCurrentCurrency,
    current,
    loadingCurrent,
    addCurrency,
    loadingAddCurrency,
    editCurrency,
    loadingEditCurrency,
  };
};
