import {
  //Box,
  // Button,
  // Divider,
  // Grid,
  Paper,
  // Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  // TablePagination,
  TableRow,
  // ThemeProvider,
  // Typography
} from "@mui/material";
// import {
//     filterBox,
//     tableBox,
//     whiteBox
// } from "../box/styles";
import SkeletonBar from "../skeleton/skeletonBar";
import { StyledTableCell } from "../tables/tableStyle";

const LoadViewFiles = ({ rows }: any) => {
  const generateRows = () => {
    let rowArr = [];
    for (let i = 0; i < rows; i++) {
      rowArr.push(
        <TableRow data-testid="TableRow">
          <TableCell>
            <SkeletonBar width="172px" height="14px" />
          </TableCell>
          <TableCell>
            <div style={{ paddingBottom: "6px" }}>
              <SkeletonBar width="160px" height="14px" />
            </div>
            <SkeletonBar width="120px" height="14px" />
          </TableCell>
          <TableCell>
            <SkeletonBar width="160px" height="14px" />
          </TableCell>
          <TableCell>
            <SkeletonBar width="168px" height="40px" />
          </TableCell>
        </TableRow>
      );
    }
    return rowArr;
  };

  return (
    <TableContainer
      className="tContainer-padding-0"
      component={Paper}
      sx={{
        borderRadius: 0,
        boxShadow: "none ",
        borderTop: "solid 2px #E9EBEC",
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="customized table">
        <TableHead className="color-head-table" data-testid="TableHead">
          <TableRow>
            <StyledTableCell>
              {" "}
              <SkeletonBar width="125px" height="14px" />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width="143px" height="14px" />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width="40px" height="14px" />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width="60px" height="14px" />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>{generateRows()}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default LoadViewFiles;
