import { useReducer, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { littleTitle, modalSubtitle } from "src/components/typography/styles";
import InfoGray from "src/assets/icons/infoGray.svg";
import { InfoText } from "src/components";
import { readPaymentsExcel } from "src/utils/readExcel";
import { buildJsonPayers, buildJsonPayments } from "src/utils/files";
import { GET_PRESIGNED_URL } from "src/services/graphql/queries";
import { putS3fetch } from "src/utils/s3";
import {
  INSERT_JSON_PAYMENT,
  INSERT_FILE_PAYMENT,
} from "src/services/graphql/mutations";
import constants from "src/constants/constants";
import { getCurrentPeriod } from "src/utils/dates";
import {
  SNACK_ACTION_TYPE,
  SNACKINFO_INITIAL_STATE,
  snackInfoReducer,
} from "src/reducers/snackInfoReducer";

const {
  COMPONENTS: {
    BUTTONS: { CANCEL },
  },
  ERROR_MESSAGE: { DEFAULT, PAYMENTS_UPLOAD_FOREIGN_KEY },
  FILE: {
    MIMETYPE: { EXCEL },
  },
  QUERIES: {
    INPUT: {
      PRESIGNED_URL_TYPE: { PAYMENTS },
    },
  },
  SNACKBAR_MESSAGE: { UPLOAD_VALIDATION_ERROR },
} = constants;

const { RESET_SNACK_INFO, SET_SNACK_ERROR, SET_SNACK_SUCCESS } =
  SNACK_ACTION_TYPE;

const ModalFileCash = ({ onOpen, onClose, period, setRefresh }: any) => {
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [uploadFinished, setUploadFinished] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [snackInfoState, snackInfoDispatch] = useReducer(
    snackInfoReducer,
    SNACKINFO_INITIAL_STATE
  );
  const [file, setFile] = useState<File | null>(null);
  const hiddenFileInput = useRef() as React.MutableRefObject<HTMLInputElement>;
  const maxAllowedSize = 4 * 1024 * 1024;
  const fileTypes = [EXCEL];

  const [getPresignedURL] = useLazyQuery(GET_PRESIGNED_URL, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        types: PAYMENTS,
      },
    },
  });

  const [insertPaymentsJson] = useMutation(INSERT_JSON_PAYMENT, {
    fetchPolicy: "network-only",
    onError: (error) => {
      let errorType = error?.graphQLErrors[0]?.message.split(":")[0];
      snackInfoDispatch({
        type: SET_SNACK_ERROR,
        payload:
          errorType === "SequelizeForeignKeyConstraintError"
            ? PAYMENTS_UPLOAD_FOREIGN_KEY
            : error?.graphQLErrors[0]?.message || DEFAULT,
      });
      setUploadError(true);
      setUploadInProgress(false);
    },
  });
  
  const [insertPaymentsXlsx] = useMutation(INSERT_FILE_PAYMENT, {
    fetchPolicy: "network-only",
  });

  const handleClose = () => {
    onClose();
    snackInfoDispatch({
      type: RESET_SNACK_INFO,
    });
    setFile(null);
    setUploadError(false);
    setUploadFinished(false);
  };

  const handleCloseModal = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason !== "backdropClick") {
      handleClose();
    }
  };

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let errorArray = [];
    if (uploadError) setUploadError(false);
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > maxAllowedSize) {
        errorArray.push("El archivo es demasiado grande");
      }
      if (!fileTypes.includes(e.target.files[0].type)) {
        errorArray.push("Formato incorrecto");
      }
      if (errorArray.length === 0) {
        setFile(e.target.files[0]);
        snackInfoDispatch({
          type: RESET_SNACK_INFO,
        });
      } else {
        snackInfoDispatch({
          type: SET_SNACK_ERROR,
          payload: UPLOAD_VALIDATION_ERROR,
        });
        setUploadError(true);
      }
    }
    e.target.value = "";
  };

  const handlePaymentUpload = async () => {
    if (file) {
      setUploadInProgress(true);
      try {
        const response = await readPaymentsExcel(file);
        if (response.data !== null) {
          let resultJsonPayers: any;
          let resultJsonPayments: any;
          let resultFile: any;
          const jsonPayers = await buildJsonPayers(response.data);
          const jsonPayments = buildJsonPayments(response.data, period);
          try {
            await getPresignedURL()
              .then(async (res) => {
                if (res.error === undefined) {
                  resultJsonPayers = await putS3fetch(
                    res.data.utils.getPresignedURL[0].url,
                    jsonPayers,
                    1
                  );
                  resultJsonPayments = await putS3fetch(
                    res.data.utils.getPresignedURL[1].url,
                    jsonPayments.data,
                    1
                  );
                  resultFile = await putS3fetch(
                    res.data.utils.getPresignedURL[2].url,
                    file,
                    0
                  );
                } else {
                  setUploadError(true);
                }
              })
              .finally(() => {
                if (
                  (resultJsonPayers && resultJsonPayments && resultFile) === 200
                ) {
                  handlePaymentsInsertDB();
                } else {
                  snackInfoDispatch({
                    type: SET_SNACK_ERROR,
                    payload: DEFAULT,
                  });
                  setUploadInProgress(false);
                }
              });
          } catch (error) {
            snackInfoDispatch({
              type: SET_SNACK_ERROR,
              payload: DEFAULT,
            });
            setUploadError(true);
            setUploadInProgress(false);
          }
        } else {
          snackInfoDispatch({
            type: SET_SNACK_ERROR,
            payload: response.error,
          });
          setUploadInProgress(false);
          setUploadError(true);
        }
      } catch (error) {
        snackInfoDispatch({
          type: SET_SNACK_ERROR,
          payload: DEFAULT,
        });
        setUploadError(true);
        setUploadInProgress(false);
      }
    }
  };

  const handlePaymentsInsertDB = async () => {
    if (period) {
      await insertPaymentsJson({
        variables: {
          input: {
            collectionPeriod: period,
          },
        },
      }).then(async (res) => {
        if (
          res?.data?.period?.collection?.payment !== null &&
          res?.data?.period?.collection?.payment !== undefined
        ) {
          try {
            await insertPaymentsXlsx({
              variables: {
                input: {
                  logId: res.data.period.collection.payment.bulkCreate.data.logId,
                },
              },
            }).finally(() => {
              snackInfoDispatch({
                type: SET_SNACK_SUCCESS,
                payload: "Carga exitosa.",
              });
            });
          } catch (error) {
            /* an error on the second mutation is not a problem
            because the first mutation was successful. It should result in
            a warning style message but it doesn't exist yet */
            snackInfoDispatch({
              type: SET_SNACK_SUCCESS,
              // type: SET_SNACK_WARNING,
              payload: "Carga exitosa sin respaldo en Gestor Documental.",
            });
          }
          setRefresh(true);
          setUploadInProgress(false);
          setUploadFinished(true);
        }
      });
    }
  };

  const TextDetail = () => {
    return <>{snackInfoState.message}</>;
  };
  const ErrorText = () => {
    return <>{snackInfoState.message}</>;
  };

  return (
    <Dialog
      maxWidth="md"
      open={onOpen}
      onClose={handleCloseModal}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <DialogTitle>
        <Typography sx={littleTitle} textAlign="center" pt="32px">
          Carga de archivo recaudación PAC, PAT y Caja
        </Typography>
        <Typography sx={modalSubtitle} textAlign="center">
          Periodo de recaudación {getCurrentPeriod(period)[0]}.
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <label className="textDetailModal">
              En esta sección, podrás cargar la información de recaudación PAC,
              PAT y Caja. Selecciona el archivo que deseas cargar y presiona el
              botón{" "}
              <label className="textDetailModalBold"> Subir archivo </label>para
              comenzar el proceso.
            </label>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="secondary"
              sx={{ padding: " 15px 32px !important" }}
              onClick={handleClick}
              disabled={uploadFinished || uploadInProgress}
            >
              Buscar archivo
              <input
                type="file"
                ref={hiddenFileInput}
                accept={EXCEL}
                onChange={handleFileChange}
                hidden
                data-testid="input-file"
              />
            </Button>
            {file ? (
              <label className="fileSelect">{file.name}</label>
            ) : (
              <label className="fileNotSelect">Sin archivo seleccionado</label>
            )}
          </Grid>
          <Grid item xs={12} pt="16px" pb="8px">
            <img src={InfoGray} alt="informacion" />{" "}
            <label className="fileInfo">
              Puedes cargar un archivo en formato .xlsx con un peso máximo de
              4MB.
            </label>
          </Grid>
          {snackInfoState.open && snackInfoState.status === "success" && (
            <Grid item xs={12} pt="16px" pb="8px">
              <InfoText
                success
                title="Resultado de carga"
                text={<TextDetail />}
              />
            </Grid>
          )}
          {snackInfoState.open && snackInfoState.status === "error" && (
            <Grid item xs={12} pt="16px" pb="8px">
              <InfoText error boldText={<ErrorText />} />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", padding: "16px" }}>
        <Button
          color="secondary"
          sx={{ height: "50px", width: "150px" }}
          onClick={handleClose}
        >
          {CANCEL}
        </Button>
        {uploadInProgress ? (
          <Button
            color="secondary"
            size="small"
            sx={{ height: "50px", width: "150px" }}
          >
            <div className="spinnerButtonGreen"></div>
          </Button>
        ) : (
          <>
            {uploadFinished ? (
              <Button
                color="primary"
                sx={{ width: "150px" }}
                onClick={handleClose}
              >
                Listo
              </Button>
            ) : (
              <Button
                color="primary"
                sx={{ width: "150px" }}
                onClick={handlePaymentUpload}
                disabled={file === null || uploadError}
              >
                Subir archivo
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ModalFileCash;
