import { Fragment } from "react";
import { Grid, Chip, Button } from "@mui/material";
import Cruz from "../../../assets/icons/cancelIcon.svg";

export function ChipsCredits({ filtros, setFiltros }: any) {
  const handleDelete = (filter: number) => {
    if (filter === 1) {
      setFiltros({
        ...filtros,
        contributorRut: null,
      });
    }
    if (filter === 2) {
      setFiltros({
        ...filtros,
        paymentPeriod: null,
      });
    }
    if (filter === 3) {
      setFiltros({
        ...filtros,
        statusId: null,
      });
    }
    if (filter === 4) {
      setFiltros({
        ...filtros,
        workerType: null,
      });
    }
    if (filter === 5) {
      setFiltros({
        ...filtros,
        typeId: null,
      });
    }
  };

  const clearFiltros = () => {
    setFiltros({
      ...filtros,
      contributorRut: null,
      paymentPeriod: null,
      statusId: null,
      workerType: null,
      typeId: null,
    });
  };

  return (
    <Fragment>
      {(filtros.contributorRut ||
        filtros.paymentPeriod ||
        filtros.statusId ||
        filtros.workerType ||
        filtros.typeId) && (
        <Fragment>
          <Grid item xs={12} pl="24px" pt="15px">
            <label className="filtroFont">Resultados filtrados por: </label>{" "}
          </Grid>

          <Grid item xs={12} pt="4px" pl="24px">
            {filtros.contributorRut && (
              <Chip
                sx={{ mr: "16px" }}
                data-testid="delete-contributorRut-chip"
                label="Rut cliente"
                variant="outlined"
                onDelete={() => handleDelete(1)}
                deleteIcon={
                  <img src={Cruz} alt="cruz" data-testid="icon-delete-contributorRut" />
                }
              />
            )}

            {filtros.paymentPeriod && (
              <Chip
                sx={{ mr: "16px" }}
                data-testid="delete-paymentPeriod-chip"
                label="Periodo remuneración"
                variant="outlined"
                onDelete={() => handleDelete(2)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-paymentPeriod"
                  />
                }
              />
            )}
            {filtros.statusId && (
              <Chip
                sx={{ mr: "16px" }}
                data-testid="delete-statusId-chip"
                label="Tipo Acreencia"
                variant="outlined"
                onDelete={() => handleDelete(3)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-statusId"
                  />
                }
              />
            )}
            {filtros.workerType && (
              <Chip
                sx={{ mr: "16px" }}
                data-testid="delete-workerType-chip"
                label="Tipo Trabajador"
                variant="outlined"
                onDelete={() => handleDelete(4)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-workerType"
                  />
                }
              />
            )}
            {filtros.typeId && (
              <Chip
                sx={{ mr: "16px" }}
                data-testid="delete-typeId-chip"
                label="Estado pago"
                variant="outlined"
                onDelete={() => handleDelete(5)}
                deleteIcon={
                  <img
                    src={Cruz}
                    alt="cruz"
                    data-testid="icon-delete-typeId"
                  />
                }
              />
            )}
            <Button
              color="inherit"
              sx={{
                textDecoration: "underline",
                textDecorationThickness: "from-font",
                pl: "12px",
              }}
              onClick={clearFiltros}
            >
              Limpiar filtros
            </Button>
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
}
