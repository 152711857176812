import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { GET_FILES } from "src/services/graphql/queries";
import { useLazyQuery } from "@apollo/client";
import {
  Box,
  Typography,
  Grid,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableBody,
  ThemeProvider,
} from "@mui/material";
import {  
  period,
} from "../typography/styles";
import { filterBox, tableBox } from "src/components/box/styles";
import { CustomSelect } from "../Select/CustomSelect";
import FileDetail, { title, total, regis } from "./fileDetail";
import { StyledTableCell } from "../tables/tableStyle";
import { paginatorTheme } from "../paginator/paginatorStyle";
import Breadcrum from "../breadcrum/Breadcrum";
import DownloadButton from "../buttons/DownloadButton";
import SkeletonBar from "../skeleton/skeletonBar";
import LoadViewFiles from "../loaderPages/loadViewFiles";
import LoaderMoreView from "../loaderPages/loaderMoreView";
import NoResults from "../tables/noResults";
import { getCurrentPeriod } from "src/utils/dates";

const Files = () => {
  const [showType, setShowType] = useState("list");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const [getCard, setGetCard] = useState(
    location.state?.cardType === "PL" ? 1 : 2
  );
  const periodDate = getCurrentPeriod(location.state.period);
  const typeFile = getCard === 1 ? "PL" : "AA";

  const [getData, { data, loading }] = useLazyQuery(GET_FILES, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        type: getCard,
        targetMonth: parseInt(periodDate[1]),
        targetYear: parseInt(periodDate[2]),
        offset: page * rowsPerPage - rowsPerPage,
        limit: rowsPerPage,
      },
    },
  });

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [getCard, rowsPerPage, page]);
  
  return (
    <>
      <Box pl="32px" pr="32px">
        <Typography variant="h1" pt="27px" mb="24px">
          {data?.period?.collection?.previred?.totals?.count || 0} Archivos{" "}
          {getCard === 1 ? "PL" : "AA"} cargados
        </Typography>
        <Breadcrum
          back1="Inicio"
          link1="/"
          now={
            (data?.period?.collection?.previred?.totals?.count || 0) +
            " Archivos " +
            typeFile +
            " cargados"
          }
          stateInfo={{ period: periodDate[3] }}
        />
        <Box sx={filterBox} mt="24px">
          <Grid container>
            <Grid xs={2}>
              <CustomSelect
                onChange={(e: any) => {
                  setGetCard(e.target.value);
                  setPage(1);
                }}
                value={getCard}
                titulo={"Seleccionar"}
                name="fileType"
                data={["Archivos PL", "Archivos AA"]}
              />
            </Grid>
            <Grid xs={8} mt="8px">
              {showType === "list" ? (
                <Button
                  color="inherit"
                  onClick={() => setShowType("showMore")}
                  data-testid="btn-view-cards"
                >
                  <svg
                    width="15"
                    height="12"
                    viewBox="0 0 15 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1.00012H12"
                      stroke="#01736C"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M1 6.00012H14"
                      stroke="#01736C"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M1 11.0001H9"
                      stroke="#01736C"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </Button>
              ) : showType === "showMore" ? (
                <Button
                  color="inherit"
                  onClick={() => setShowType("list")}
                  data-testid="btn-view-list"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="6" height="6" fill="#01736C" />
                    <rect x="8" width="6" height="6" fill="#01736C" />
                    <rect y="8" width="6" height="6" fill="#01736C" />
                    <rect x="8" y="8" width="6" height="6" fill="#01736C" />
                  </svg>
                </Button>
              ) : (
                ""
              )}
            </Grid>

            {loading ? (
              <Grid xs={2} display="flex" justifyContent="end" mt="12px">
                <SkeletonBar width="180px" height="18px" />
              </Grid>
            ) : (
              <Grid xs={2} textAlign="right" mt="12px" whiteSpace="pre">
                <Typography
                  variant="h3"
                  component="div"
                  fontSize="18px"
                  display="inline"
                >
                  Periodo:
                </Typography>
                <Typography
                  sx={period}
                  component="div"
                  display="inline"
                  pl="4px"
                >
                  {periodDate[0]}
                </Typography>
              </Grid>
            )}

            {loading ? (
              <Grid xs={12} mt="18px">
                {showType === "list" ? (
                  <LoadViewFiles rows={3} />
                ) : (
                  <LoaderMoreView columns={2} rows={2} />
                )}
              </Grid>
            ) : (
              <>
                {data?.period?.collection?.previred?.totals?.count === 0 ? (
                  <Grid xs={12} mt="18px">
                    <NoResults />
                  </Grid>
                ) : (
                  <>
                    <Grid xs={12} mt="18px">
                      {showType === "list" ? (
                        <Box sx={tableBox}>
                          <Paper
                            className="paper-tables-2"
                            sx={{ boxShadow: "none " }}
                          >
                            <>
                              <TableContainer
                                className="tContainer-padding-0"
                                component={Paper}
                                sx={{
                                  borderRadius: 0,
                                  boxShadow: "none ",
                                  borderTop: "solid 2px #E9EBEC",
                                }}
                              >
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="customized table"
                                >
                                  <TableHead
                                    className="color-head-table"
                                    data-testid="TableHead"
                                  >
                                    <TableRow>
                                      <StyledTableCell>
                                        Nombre de archivo
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        Cantidad de registros
                                      </StyledTableCell>
                                      <StyledTableCell>Pagos</StyledTableCell>
                                      <StyledTableCell>
                                        Acciones
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  {data?.period?.collection?.previred?.totals?.rows.map(
                                    (file: any) => {
                                      return (
                                        <TableBody>
                                          <TableRow
                                            data-testid="TableRow"
                                            key={file.id}
                                          >
                                            <TableCell>
                                              <Typography
                                                data-testid="fileName"
                                                variant="h5"
                                                sx={title}
                                              >
                                                {file.fileName.replace(
                                                  ".txt",
                                                  ""
                                                )}
                                              </Typography>
                                            </TableCell>
                                            <TableCell>
                                              <Typography
                                                data-testid="registerCount"
                                                variant="h5"
                                                sx={regis}
                                              >
                                                {file.registerCount} registros
                                              </Typography>
                                              <Typography variant="subtitle2">
                                                0 no encontrados
                                              </Typography>
                                            </TableCell>
                                            <TableCell>
                                              <Typography
                                                variant="h5"
                                                sx={total}
                                              >
                                                {file.amount.toLocaleString(
                                                  "es-CL",
                                                  {
                                                    style: "currency",
                                                    currency: "CLP",
                                                  }
                                                )}
                                              </Typography>
                                            </TableCell>
                                            <TableCell>
                                              <DownloadButton
                                                disabled={
                                                  file?.documentId === null
                                                    ? true
                                                    : false
                                                }
                                                display="list"
                                                documentId={file.documentId}
                                                fileType={"txt"}
                                                txt={true}
                                              />
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      );
                                    }
                                  )}
                                </Table>
                              </TableContainer>
                              <ThemeProvider theme={paginatorTheme}>
                                <TablePagination
                                  rowsPerPageOptions={[10, 20, 50]}
                                  component="div"
                                  count={data?.period?.collection?.previred?.totals?.count || 0}
                                  rowsPerPage={rowsPerPage}
                                  page={page - 1}
                                  onPageChange={handleChangePage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                                  labelRowsPerPage={"Mostrar por página"}
                                  sx={{ mt: "24px", mb: "24px" }}
                                  labelDisplayedRows={(e) => {
                                    return (
                                      "" +
                                      (page || 0) +
                                      " de " +
                                      Math.ceil(
                                        (data?.period?.collection?.previred?.totals?.count || 0) /
                                          (rowsPerPage || 0)
                                      )
                                    );
                                  }}
                                />
                              </ThemeProvider>
                            </>
                          </Paper>
                        </Box>
                      ) : showType === "showMore" ? (
                        <Paper
                          className="paper-tables-2"
                          sx={{ boxShadow: "none " }}
                        >
                          <Grid container>
                            {data?.period?.collection?.previred?.totals?.rows.map((item: any) => {
                              return (
                                <Grid xs={12} sm={6} mt="18px" key={item.id}>
                                  <FileDetail
                                    name={item.fileName}
                                    registers={item.registerCount}
                                    //snoRegisters={item.noRegisters}
                                    payment={item.amount}
                                    documentId={item.documentId}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                          <ThemeProvider theme={paginatorTheme}>
                            <TablePagination
                              rowsPerPageOptions={[10, 20, 50]}
                              component="div"
                              count={data?.period?.collection?.previred?.totals?.count || 0}
                              rowsPerPage={rowsPerPage}
                              page={page - 1}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              labelRowsPerPage={"Mostrar por página"}
                              sx={{ mt: "24px", mb: "24px" }}
                              labelDisplayedRows={(e) => {
                                return (
                                  "" +
                                  (page || 0) +
                                  " de " +
                                  Math.ceil(
                                    (data?.period?.collection?.previred?.totals?.count || 0) /
                                      (rowsPerPage || 0)
                                  )
                                );
                              }}
                            />
                          </ThemeProvider>
                        </Paper>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Files;
