import {
  Box,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { boxNoPadding } from "src/components/box/styles";
import SkeletonBar from "src/components/skeleton/skeletonBar";
import { StyledTableCell } from "src/components/tables/tableStyle";

export const SkeletonsP = () => (
  <Grid container p="24px" data-testid="loaderHistorialPS">
    <Grid item xs={12}>
      <Grid container p="24px">
        <Grid item xs={12}>
          <SkeletonBar width={218} height={18} />
        </Grid>
        <Grid item xs={12} pt="8px">
          <SkeletonBar width={500} height={16} />
        </Grid>
        <Grid item xs={12} pt="24px">
          <SkeletonBar width={170} height={14} />
        </Grid>
      </Grid>
      <Grid item xs={12} p="24px">
        <Grid
          container
          border="1px solid #D2D6D9"
          borderRadius="8px"
          padding="16px"
          style={{
            maxHeight: "450px",
            paddingRight: "8px",
          }}
        >
          <Grid container sx={{ marginBottom: "20px" }}>
            <Grid
              item
              xs={1}
              textAlign="right"
              pr="16px"
              style={{ flexBasis: "5%", maxWidth: "5%" }}
            >
              <Skeleton
                animation="wave"
                sx={{ bgcolor: "#F2F5F9" }}
                variant="circular"
                width={16}
                height={16}
              />
            </Grid>
            <Grid
              item
              xs={11}
              textAlign="left"
              style={{ flexBasis: "95%", maxWidth: "95%" }}
            >
              <Stack direction={"column"} spacing={"4px"}>
                <SkeletonBar width={100} height={14} />
                <SkeletonBar width={200} height={14} />
                <Stack direction={"row"} spacing={"16px"}>
                  <SkeletonBar width={200} height={14} />
                  <SkeletonBar width={90} height={14} />
                  <SkeletonBar width={150} height={14} />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: "20px" }}>
            <Grid
              item
              xs={1}
              textAlign="right"
              pr="16px"
              style={{ flexBasis: "5%", maxWidth: "5%" }}
            >
              <Skeleton
                animation="wave"
                sx={{ bgcolor: "#F2F5F9" }}
                variant="circular"
                width={16}
                height={16}
              />
            </Grid>
            <Grid
              item
              xs={11}
              textAlign="left"
              style={{ flexBasis: "95%", maxWidth: "95%" }}
            >
              <Stack direction={"column"} spacing={"4px"}>
                <SkeletonBar width={100} height={14} />
                <SkeletonBar width={200} height={14} />
                <Stack direction={"row"} spacing={"16px"}>
                  <SkeletonBar width={200} height={14} />
                  <SkeletonBar width={90} height={14} />
                  <SkeletonBar width={150} height={14} />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: "20px" }}>
            <Grid
              item
              xs={1}
              textAlign="right"
              pr="16px"
              style={{ flexBasis: "5%", maxWidth: "5%" }}
            >
              <Skeleton
                animation="wave"
                sx={{ bgcolor: "#F2F5F9" }}
                variant="circular"
                width={16}
                height={16}
              />
            </Grid>
            <Grid
              item
              xs={11}
              textAlign="left"
              style={{ flexBasis: "95%", maxWidth: "95%" }}
            >
              <Stack direction={"column"} spacing={"4px"}>
                <SkeletonBar width={100} height={14} />
                <SkeletonBar width={200} height={14} />
                <Stack direction={"row"} spacing={"16px"}>
                  <SkeletonBar width={200} height={14} />
                  <SkeletonBar width={90} height={14} />
                  <SkeletonBar width={150} height={14} />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export const SkeletonHeaderP = () => (
  <Grid item xs={12}>
    <div className="container">
      <Grid container>
        <Grid item xs={8} pb="10px">
          <Stack direction={"column"} spacing={"10px"}>
            <SkeletonBar width={180} height={18} />
            <SkeletonBar width={371} height={16} />
          </Stack>
        </Grid>
        <Grid
          item
          xs={4}
          pt="24px"
          direction={"row"}
          sx={{ minWidth: "190px !important" }}
          justifyContent="flex-end"
        >
          <Stack direction={"row"} justifyContent="flex-end">
            <Stack
              direction={"column"}
              pr="16px"
              spacing="10px"
              justifyContent="flex-end"
            >
              <SkeletonBar width={200} height={14} />
              <SkeletonBar width={200} height={14} />
            </Stack>
            <SkeletonBar width={180} height={40} />
          </Stack>
        </Grid>
      </Grid>
    </div>
  </Grid>
);

export const SkeletonTableP = (props: any) => (
  <Box sx={boxNoPadding} mt="16px" mb="24px">
    <Grid container>
      <Grid item xs={12}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          pt="15px"
          pr="24px"
        >
          <div style={{ paddingLeft: "24px", paddingTop: "16px" }}>
            <SkeletonBar width={218} height={18} />
          </div>

          <Stack direction={"row"}>
            <div style={{ paddingRight: "16px", paddingTop: "12px" }}>
              <SkeletonBar width={120} height={16} />
            </div>
            <SkeletonBar width={140} height={40} />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none", borderRadius: "none", paddingTop: "17px" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="customized table">
        <TableHead className="color-head-table">
          <TableRow>
            {[...Array(props.columns)].map((x, i) => (
              <StyledTableCell key={i}>
                <SkeletonBar width={80} height={14} />
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(props.rows)].map((x, i) => (
            <TableRow data-testid="withData" key={i}>
              {[...Array(props.columns)].map((y, o) => (
                <TableCell key={o}>
                  <SkeletonBar width={100} height={14} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
);

export const SkeletonTableModal = () => (
  <Box>
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none", borderRadius: "none" }}
    >
      <Table sx={{ minWidth: "auto" }} aria-label="customized table">
        <TableHead className="color-head-table">
          <TableRow>
            <StyledTableCell>
              <SkeletonBar width={80} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={80} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={80} height={14} />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow data-testid="withData">
            <TableCell>
              <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell>
              <Stack direction="column">
                <div style={{ paddingBottom: "6px" }}>
                  <SkeletonBar width={154} height={14} />
                </div>
                <SkeletonBar width={154} height={14} />
              </Stack>
            </TableCell>
            <TableCell>
              <SkeletonBar width={184} height={14} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div
        style={{
          paddingTop: "24px",
          paddingBottom: "24px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SkeletonBar width={371} height={16} />
      </div>
    </TableContainer>
  </Box>
);
