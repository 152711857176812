import { Fragment, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import constants from "src/constants/constants";

const {
  COMPONENTS: {
    MENU_ITEMS: { DETAIL, EDIT_MOVEMENTS },
  },
} = constants;

export const MenuExcessRut = (props: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton aria-label="more" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        className="littleMenu"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => props.handleEdit()}
          className="littleMenu"
          disabled={props.info.excessDetail === null || !props.canUpdate}
        >
          {props.label ?? EDIT_MOVEMENTS}
        </MenuItem>
        <MenuItem onClick={() => props.handleDetails()} className="littleMenu">
          {DETAIL}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
