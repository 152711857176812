import { useEffect, useReducer, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import {
  bigGreenFont,
  blackBold,
  contTitle,
  grayFontBox,
  rutGreen,
  slimText,
  subSlimText,
  sublimLH,
  sublimLHBold,
} from "src/components/typography/styles";
import {
  borderBox,
  filterBox,
  grayBoxStyle,
  greenBackNB,
} from "src/components/box/styles";
import {
  Alerts,
  AlertSnackbar,
  Breadcrum,
  CustomSelect,
  RutInput,
} from "src/components";
import ImgRut from "../../assets/icons/rutImg.svg";
import PerfilImg from "../../assets/icons/userGreen.svg";
import { GET_PERIOD_BY } from "src/services/graphql/queries";
import { useLazyQuery } from "@apollo/client";
import constants from "src/constants/constants";
import { getCurrentPeriod, getNextMonthYear } from "src/utils/dates";
import SkeletonBar from "src/components/skeleton/skeletonBar";
import {
  SNACK_ACTION_TYPE,
  SNACKINFO_INITIAL_STATE,
  snackInfoReducer,
} from "src/reducers/snackInfoReducer";
import ByRutOverage from "./components/ByRutOverage";
import ByRutSurplus from "./components/ByRutSurplus";
import useRutSearch from "./hook/useRutSearch";

const { CLOSE_SNACK_INFO } = SNACK_ACTION_TYPE;
const {
  COMPONENTS: {
    BUTTONS: { SEARCH },
  },
} = constants;

const RutSearch = () => {
  const [searchRut, setSearchRut] = useState("");
  const [newSearch, setNewSearch] = useState(false);
  const [loadingRutSearch, setLoadingRutSearch] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [sectionValue, setSectionValue] = useState(1);
  const [snackInfoState, snackInfoDispatch] = useReducer(
    snackInfoReducer,
    SNACKINFO_INITIAL_STATE
  );
  const [rutInfo, setRutInfo] = useState({
    contributorRut: "",
    contributorName: "",
    accountableBalance: 0,
    availableBalance: 0,
    excess: 0,
    collectionPeriod: "",
    paymentPeriod: "",
    excessDetail: [],
  });
  const [formattedRun, setFormattedRun] = useState("");
  const [validRut, setValidRut] = useState(false);

  const [
    getPeriodByStatusId,
    {
      data: { period: { oneBy } } = {
        period: {
          oneBy: null,
        },
      },
      loading,
    },
  ] = useLazyQuery(GET_PERIOD_BY, {
    fetchPolicy: constants.QUERIES.FETCH_POLICY.NETWORK_ONLY as any,
    variables: {
      input: {
        distributionStatusId: 3,
        isCurrent: true,
      },
    },
  });

  const {
    getContextData,
    contributorName,
    surplusAvailableBalance,
    surplusAccountableBalance,
    excessAvailableBalance,
    loadingContext,
  } = useRutSearch({
    setSnackInfo: snackInfoDispatch,
    handleClose: () => {},
    setRefresh: () => {},
  });

  const handleSearch = (rut: string) => {
    const cleanRut = rut.replace(/\./g, "");
    if (cleanRut !== rutInfo.contributorRut) {
      setSearchRut(cleanRut);
      setNewSearch(true);
      resetRutInfo();
      getContextData(cleanRut);
    }
  };

  const resetRutInfo = () => {
    setRutInfo({
      contributorRut: "",
      contributorName: "",
      accountableBalance: 0,
      availableBalance: 0,
      excess: 0,
      collectionPeriod: "",
      paymentPeriod: "",
      excessDetail: [],
    });
  };

  const refreshOnError = () => {
    getPeriodByStatusId();
    setRefresh(true);
  };

  useEffect(() => {
    getPeriodByStatusId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchRut === "") {
      resetRutInfo();
      setValidRut(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRut]);

  useEffect(() => {
    if (refresh === true) {
      getContextData(searchRut.replace(/\./g, ""));
      setRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <>
      <AlertSnackbar
        onClick={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        onClose={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        open={snackInfoState.open}
        status={snackInfoState.status}
        message={snackInfoState.message}
      />
      <Box pl="32px" pr="32px">
        <Typography variant="h1" pt="24px" mb="16px">
          Consultas por RUT
        </Typography>
        <Breadcrum back1="Inicio" link1="/" now="Consultas por RUT" />
        {loading ? (
          <SkeletonBar width={"auto"} height={52} />
        ) : (
          oneBy !== null && (
            <>
              <Alerts
                mensaje={`Último periodo confirmado 
                  ${getCurrentPeriod(oneBy.name)[0]}.`}
                open={true}
                data={null}
                setOpen={null}
                severidad={"info"}
                noClose
                typeAlert={2}
              />
              {oneBy.isConfirmable === false && (
                <Alerts
                  mensaje={`Confirmación en proceso para el periodo ${getNextMonthYear(
                    oneBy.name
                  )}.`}
                  open={true}
                  data={null}
                  setOpen={null}
                  severidad={"warning"}
                  noClose
                  typeAlert={2}
                />
              )}
            </>
          )
        )}
        {/* <SkeletonRutHeader /> */}
        <Box sx={filterBox} mt="16px" mb="24px">
          <Grid container alignItems={"center"}>
            <Grid xs={2} pt="14px" alignSelf={"center"}>
              <img src={ImgRut} alt="" />
            </Grid>
            <Grid xs={10} pb="16px" pl="24px">
              <Typography sx={rutGreen} component="div">
                Consulta por RUT
              </Typography>
              <Typography variant="h3" component="div">
                Aquí podrás buscar información de recaudación y distribución.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={filterBox} mt="16px" mb="24px">
          <Grid container>
            <Grid xs={12}>
              <Typography sx={slimText} component="div">
                Buscador de clientes
              </Typography>
              <Typography sx={subSlimText} pt="4px">
                Ingresa un RUT para consultar en la plataforma.
              </Typography>
            </Grid>
            <Grid xs={4} pt="14px">
              <RutInput
                formattedRun={formattedRun}
                setFormattedRun={setFormattedRun}
                setValidRut={setValidRut}
                setSearchRut={setSearchRut}
                setRutFilter={() => {}}
                disabled={false}
              />
            </Grid>
            <Grid xs={2} pt="14px" pl="14px">
              <Button
                fullWidth
                color="secondary"
                onClick={() => handleSearch(formattedRun)}
                disabled={
                  !validRut ||
                  formattedRun === "" ||
                  loadingRutSearch ||
                  loadingContext
                }
              >
                {loadingRutSearch || loadingContext ? (
                  <div className="spinnerButtonGreen"></div>
                ) : (
                  SEARCH
                )}
              </Button>
            </Grid>
            <Grid xs={6} mt="14px" pl="16px">
              <Box
                border={"1px solid #E2E3E5"}
                borderRadius={"8px"}
                p="9px 16px"
                display={"flex"}
                alignItems={"center"}
              >
                <img src={PerfilImg} alt="" />
                <Typography sx={sublimLH} display={"inline"} pl="8px" pr="4px">
                  {" "}
                  Cliente:{" "}
                </Typography>
                <Typography sx={sublimLHBold} display={"inline"}>
                  {contributorName !== "" &&
                    searchRut !== "" &&
                    contributorName}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={12} pt="14px" sx={borderBox} mt="24px">
              <Grid container>
                <Grid item xs={4} pr="16px">
                  <Box sx={greenBackNB} p="8px 16px">
                    <Typography sx={grayFontBox} display={"inline"}>
                      Excedentes:
                    </Typography>
                    <Typography sx={blackBold} display={"inline"}>
                      {" "}
                      {surplusAvailableBalance !== 0 && searchRut !== ""
                        ? "$" + surplusAvailableBalance.toLocaleString("es-cl")
                        : "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} pr="16px">
                  <Box sx={greenBackNB} p="8px 16px">
                    <Typography sx={grayFontBox} display={"inline"}>
                      Excesos:{" "}
                    </Typography>
                    <Typography sx={blackBold} display={"inline"}>
                      {excessAvailableBalance !== 0 && searchRut !== ""
                        ? "$" + excessAvailableBalance.toLocaleString("es-cl")
                        : "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={greenBackNB} p="8px 16px">
                    <Typography sx={grayFontBox} display={"inline"}>
                      Pagos:{" "}
                    </Typography>
                    <Typography sx={blackBold} display={"inline"}>
                      {" "}
                      {"-"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} sx={grayBoxStyle} mt="24px">
              <Grid container>
                <Grid xs={4} pr="24px">
                  <Typography variant="h3" pb="6px">
                    Sección
                  </Typography>
                  <CustomSelect
                    onChange={(e: any, index: any) => {
                      setSectionValue(parseInt(index.props.value));
                    }}
                    titulo={"Excedentes"}
                    hideTitle={true}
                    value={sectionValue}
                    data={[
                      { id: 1, nombre: "Excedentes" },
                      { id: 2, nombre: "Excesos" },
                      { id: 3, nombre: "Pagos", disabled: true },
                    ]}
                  />
                </Grid>
                {
                  {
                    1: (
                      <>
                        <Grid
                          item
                          xs={4}
                          borderRight={"1px solid #01A49A"}
                          alignSelf={"center"}
                          mt="30px"
                        >
                          <Typography sx={contTitle} display={"inline"}>
                            Saldo disponible:{" "}
                          </Typography>
                          <Typography
                            sx={bigGreenFont}
                            display={"inline"}
                            pr="20px"
                          >
                            {surplusAvailableBalance !== 0 && searchRut !== ""
                              ? "$" +
                                surplusAvailableBalance.toLocaleString("es-cl")
                              : "-"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          pl="24px"
                          xs={4}
                          alignSelf={"center"}
                          mt="30px"
                        >
                          <Typography sx={contTitle} display={"inline"}>
                            Saldo contable:{" "}
                          </Typography>
                          <Typography
                            sx={bigGreenFont}
                            display={"inline"}
                            pr="20px"
                          >
                            {surplusAccountableBalance !== 0 && searchRut !== ""
                              ? "$" +
                                surplusAccountableBalance.toLocaleString(
                                  "es-cl"
                                )
                              : "-"}
                          </Typography>
                        </Grid>
                      </>
                    ),
                    2: (
                      <Grid item xs={8} alignSelf={"center"} mt="30px">
                        <Typography sx={contTitle} display={"inline"}>
                          Saldo disponible:{" "}
                        </Typography>
                        <Typography
                          sx={bigGreenFont}
                          display={"inline"}
                          pr="20px"
                        >
                          {excessAvailableBalance !== 0 && searchRut !== ""
                            ? "$" +
                              excessAvailableBalance.toLocaleString("es-cl")
                            : "-"}
                        </Typography>
                      </Grid>
                    ),
                  }[sectionValue]
                }
              </Grid>
            </Grid>
            <Grid xs={12} pt="14px" mt="24px">
              {
                {
                  1: (
                    <ByRutSurplus
                      rutSearch={searchRut}
                      rutInfo={rutInfo}
                      setRutInfo={setRutInfo}
                      newSearch={newSearch}
                      setNewSearch={setNewSearch}
                      refreshOnError={refreshOnError}
                      setSnackInfo={snackInfoDispatch}
                      refresh={refresh}
                      setRefresh={setRefresh}
                      // isUpdateEnabled={oneBy?.confirmableExcess === true}
                      isUpdateEnabled={oneBy?.isConfirmable === true}
                      setLoadingRutSearch={setLoadingRutSearch}
                    />
                  ),
                  2: (
                    <ByRutOverage
                      rutSearch={searchRut}
                      setSnackInfo={snackInfoDispatch}
                      contributorName={contributorName}
                      excessAvailableBalance={excessAvailableBalance}
                      setLoadingRutSearch={setLoadingRutSearch}
                      refresh={refresh}
                      setRefresh={setRefresh}
                    />
                  ),
                  3: (
                    <Typography variant="h3" pb="6px">
                      Pagos
                    </Typography>
                  ),
                }[sectionValue]
              }
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default RutSearch;
