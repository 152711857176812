import { useState, useEffect } from "react";
import {
  CustomInput,
  CustomSelect,
  CustomModal,
  CustomModalTitle,
} from "src/components";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  detailText,
  littleTitle,
  modalSubtitle,
} from "src/components/typography/styles";
import { getCurrentPeriod } from "src/utils/dates";
import { validateRun } from "src/utils/validationUtils";
import { formatRut } from "src/utils/stringUtils";
import { findOption } from "src/utils/arrayUtils";

const SearchCash = ({
  onOpen,
  handleClose,
  filtros,
  setFiltros,
  options,
  origen,
}: any) => {
  const [disableButton, setDisableButton] = useState(true);
  const [runError, setRunError] = useState("");
  const [runValue, setRunValue] = useState(filtros?.contributorRut || "");
  const [formattedRun, setFormattedRun] = useState(
    formatRut(filtros?.contributorRut) || ""
  );
  const [period, setPeriod] = useState<string>(filtros?.paymentPeriod || null);
  const [aux, setAux] = useState<string>(filtros?.paymentType || null);
  const [runValuePayer, setRunValuePayer] = useState(filtros?.payerRut || "");
  const [formattedRunPayer, setFormattedRunPayer] = useState(
    formatRut(filtros?.payerRut) || ""
  );
  const [runErrorPayer, setRunErrorPayer] = useState("");
  const origenAux =
    origen?.filter((element: string) => element !== "PREVIRED") || null;
  const [option, setOption] = useState(findOption(options, period));
  const [optionOrigen, setOptionOrigen] = useState(findOption(origen, aux));

  const handleRunChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let formatted = value.replace(/[^0-9kK-]/g, "").toUpperCase();
    const formatString = formatted?.match(/-/g) || null;

    if (formatted.charAt(0) === "0") {
      formatted = formatted.substring(1);
    }

    if (formatted.length <= 11) {
      setRunValue(formatted.replace("-", ""));
      if (formatted.trim() === "") {
        setRunError("");
        setFormattedRun("");
      } else {
        const isValid = validateRun(formatted);
        if (isValid) {
          setRunError("");
          if (formatted.length >= 8) {
            // setDisableButton(false);
          }
          const formattedWithSeparators = formatRut(formatted);
          setFormattedRun(formattedWithSeparators);
        } else {
          if (formatted.length >= 8) {
            setRunError("RUN inválido.");
            // setDisableButton(true);
          }
          setFormattedRun(formatted);
        }
      }
      if (formatString !== null && formatString.length > 1) {
        setRunError("RUN inválido.");
        // setDisableButton(true);
      }
    }
  };

  const handleRunChangePayer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let formatted = value.replace(/[^0-9kK-]/g, "").toUpperCase();
    const formatString = formatted?.match(/-/g) || null;

    if (formatted.charAt(0) === "0") {
      formatted = formatted.substring(1);
    }

    if (formatted.length <= 11) {
      setRunValuePayer(formatted.replace("-", ""));
      if (formatted.trim() === "") {
        setRunErrorPayer("");
        // setDisableButton(true);
        setFormattedRunPayer("");
      } else {
        const isValid = validateRun(formatted);
        if (isValid) {
          setRunErrorPayer("");
          if (formatted.length >= 8) {
            // setDisableButton(false);
          }
          const formattedWithSeparators = formatRut(formatted);
          setFormattedRunPayer(formattedWithSeparators);
        } else {
          if (formatted.length >= 8) {
            setRunErrorPayer("RUN inválido.");
            // setDisableButton(true);
          }
          setFormattedRunPayer(formatted);
        }
      }
      if (formatString !== null && formatString.length > 1) {
        setRunErrorPayer("RUN inválido.");
        // setDisableButton(true);
      }
    }
  };

  const handleFilter = () => {
    setFiltros({
      ...filtros,
      payerRut:
        runValuePayer !== "" ? formattedRunPayer.replace(/\./g, "") : null,
      paymentType: aux,
      contributorRut: runValue !== "" ? formattedRun.replace(/\./g, "") : null,
      paymentPeriod: period,
    });
    handleClose();
  };

  const getOptionsPeriod = (dataOptions: any) => {
    let optionsArray: any[] = [];
    if (dataOptions) {
      dataOptions.forEach((element: any) =>
        optionsArray.push(getCurrentPeriod(element)[0])
      );
    }
    optionsArray.push("Sin selección");
    return optionsArray;
  };

  const getOrigen = (dataOptions: any) => {
    let optionsArray: any[] = [];
    if (dataOptions) {
      dataOptions.forEach((element: any) => optionsArray.push(element));
    }
    optionsArray.push("Sin selección");
    return optionsArray;
  };

  const handleValidation = (selectedOption: number) => {
    if (
      selectedOption &&
      runError === "" &&
      runErrorPayer === "" &&
      (formattedRun.length >= 8 || formattedRun.length === 0) &&
      (formattedRunPayer.length >= 8 || formattedRunPayer.length === 0)
    ) {
      // setDisableButton(false);
    }
  };

  useEffect(() => {
    const runClienteValid: boolean =
      runValue.length >= 8 && runValue.length !== 0;
    const runPayerValid: boolean =
      runValuePayer.length >= 8 && runValuePayer.length !== 0;

    if (runError !== "" || runErrorPayer !== "") {
      setDisableButton(true);
    } else if (runClienteValid || runPayerValid || option || optionOrigen) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runValue, option, runValuePayer, optionOrigen]);

  return (
    <CustomModal
      maxWidth="md"
      open={onOpen}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <CustomModalTitle id="addCredits" onClose={handleClose}>
        <Typography sx={littleTitle} textAlign="center" pt="32px">
          Buscar recaudación PAC, PAT y Caja
        </Typography>
        <Typography sx={modalSubtitle} textAlign="center">
          Seleccione los criterios de búsqueda para encontrar registros de PAC,
          PAT o Caja específicos.
        </Typography>
      </CustomModalTitle>
      <DialogContent dividers>
        <Grid
          container
          direction="column"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid sx={{ margin: "0", width: "100%" }} container spacing={2}>
            <Grid
              item
              xs={6}
              p="0 !important"
              pr="24px !important"
              pb="24px !important"
            >
              <Typography sx={detailText} pb="6px">
                RUT Cliente
              </Typography>
              <Stack direction="row">
                <CustomInput
                  placeholder="Ingresar RUN"
                  fullWidth
                  value={formattedRun || runValue}
                  onChange={handleRunChange}
                  inputProps={{
                    "data-testid": "input-run-contributor",
                  }}
                />
              </Stack>
              {runError && (
                <Typography
                  sx={{ color: "red", fontSize: 8 }}
                  variant="caption"
                >
                  {runError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} p="0 !important" pb="24px !important">
              <Typography sx={detailText} pb="6px">
                RUT Pagador
              </Typography>
              <Stack direction="row">
                <CustomInput
                  placeholder="Ingresar RUN"
                  fullWidth
                  value={formattedRunPayer || runValuePayer}
                  onChange={handleRunChangePayer}
                  inputProps={{
                    "data-testid": "input-run-payer",
                  }}
                />
              </Stack>
              {runErrorPayer && (
                <Typography
                  sx={{ color: "red", fontSize: 8 }}
                  variant="caption"
                >
                  {runErrorPayer}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} p="0 !important" pr="24px !important">
              <Typography sx={detailText} pb="6px">
                Periodo de remuneración
              </Typography>
              <CustomSelect
                onChange={(e: any) => {
                  setOption(e.target.value);
                  setPeriod(options[e.target.value - 1]);
                  handleValidation(e.target.value);
                }}
                value={option}
                titulo={"Seleccionar"}
                hideTitle={true}
                data={getOptionsPeriod(options)}
              />
            </Grid>
            <Grid item xs={6} p="0 !important">
              <Typography sx={detailText} pb="6px">
                Origen
              </Typography>
              <CustomSelect
                onChange={(e: any) => {
                  setOptionOrigen(e.target.value);
                  setAux(origenAux[e.target.value - 1]);
                  handleValidation(e.target.value);
                }}
                value={optionOrigen}
                titulo={"Seleccionar"}
                hideTitle={true}
                data={getOrigen(origenAux)}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            onClick={handleClose}
            color="secondary"
            sx={{ width: "150px" }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleFilter}
            sx={{ width: "150px" }}
            disabled={disableButton}
          >
            Filtrar
          </Button>
        </Grid>
      </DialogActions>
    </CustomModal>
  );
};

export default SearchCash;
