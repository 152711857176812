import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import { capitalCaseAll } from "./stringUtils";
import { getPreviousPeriod, lastDayOfMonth } from "./dates";
import constants from "src/constants/constants";

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(
        reader.result?.toString().replace("data:", "").replace(/^.+,/, "") || ""
      );
    reader.onerror = (error) => reject(error);
  });
};

export const base64ToFile = (base64: string, filename: string) => {
  const buff = Buffer.from(base64, "base64").toString("utf-8");
  const blob = new Blob([buff], { type: "text/plain" });
  saveAs(blob, filename);
};

// //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
// export const base64ToExcelFile = (base64: string, filename: string) => {
//   const buff = Buffer.from(base64, "base64").toString("utf-8");
//   const blob = new Blob([buff], { type: "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," });
//   saveAs(blob, filename);

// };

export const base64ToExcelFile = (base64: string, filename: string) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {
    type: constants.FILE.MIMETYPE.EXCEL,
  });
  saveAs(blob, filename);
};

const buildInventoryPipes = (data: any) => {
  let pipe = "|";
  let pipes = "||||||||";
  let newLine = "\n";
  let inventoryPipes = data.map((item: any) => {
    let itemInv = {
      rut: item.rut.split("-")[0],
      dv: item.rut.split("-")[1],
      month: item.period.slice(4, 6) + item.period.slice(0, 4),
    };
    return `${itemInv.rut}${pipe}${itemInv.dv}${pipe}${pipes}${itemInv.month}${pipes}${newLine}`;
  });
  return inventoryPipes.join("");
};

const buildConfirmedInventory = (data: any, code: number) => {
  let pipe = "|";
  let pipe2 = "||";
  let pipe8 = "||||||||";
  let newLine = "\n";
  let invConfirmedArr: any = [];
  data.forEach((item: any) => {
    if (item.kinetycId === code) {
      let itemInv = {
        rut: item.rut.split("-")[0] + pipe,
        dv: item.rut.split("-")[1] + pipe,
        month: item.period.slice(4, 6) + item.period.slice(0, 4) + pipe,
        esencialRut: item.rutEsencial
          ? item.rutEsencial.split("-")[0] + pipe
          : pipe,
        esencialDv: item.rutEsencial
          ? item.rutEsencial.split("-")[1] + pipe
          : pipe,
        codeEsencial: item.codeEsencial ? item.codeEsencial + pipe : pipe,
        folioFun: item.folioFUN ? item.folioFUN + pipe : pipe,
      };
      let itemRow = `${itemInv.rut}${itemInv.dv}${pipe8}${itemInv.month}${pipe2}${itemInv.esencialRut}${itemInv.esencialDv}${itemInv.codeEsencial}${itemInv.folioFun}${pipe}${newLine}`;
      invConfirmedArr.push(itemRow);
    } else {
      let itemInv = {
        rut: item.rut.split("-")[0],
        dv: item.rut.split("-")[1],
        month: item.period.slice(4, 6) + item.period.slice(0, 4),
      };
      let itemRow = `${itemInv.rut}${pipe}${itemInv.dv}${pipe}${pipe8}${itemInv.month}${pipe8}${newLine}`;
      invConfirmedArr.push(itemRow);
    }
  });
  return invConfirmedArr.join("");
};

const buildHandoverInventory = (data: any, code: number) => {
  let pipe = "|";
  let pipes = "||||||||";
  let zero = "0";
  let newLine = "\n";
  let invHandoverArr: any = [];
  let invArr: any = [];

  data.forEach((item: any) => {
    if (item.kinetycId === code) {
      let itemInv = {
        rut: item.rut.split("-")[0] + pipe,
        dv: item.rut.split("-")[1] + pipe,
        numberForm: item.numberForm ? item.numberForm + pipe : pipe,
        employerRut: item.rutCompany
          ? item.rutCompany.split("-")[0] + pipe
          : pipe,
        employerDv: item.rutCompany
          ? item.rutCompany.split("-")[1] + pipe
          : pipe,
        employerName: item.businessName ? item.businessName + pipe : pipe,
        taxableIncome: item.taxableIncome ? item.taxableIncome + pipe : pipe,
        mandatoryLegalContribution: item.mandatoryLegalContribution
          ? item.mandatoryLegalContribution + pipe
          : pipe,
        voluntaryContribution: item.voluntaryContribution
          ? item.voluntaryContribution + pipe
          : zero + pipe,
        contributionToPay: item.contributionToPay
          ? item.contributionToPay + pipe
          : pipe,
        month: item.period.slice(4, 6) + item.period.slice(0, 4) + pipe,
        insuranceRut: item.institutionRut
          ? item.institutionRut.split("-")[0] + pipe
          : pipe,
        insuranceDv: item.institutionRut
          ? item.institutionRut.split("-")[1] + pipe
          : pipe,
        esencialRut: item.rutEsencial
          ? item.rutEsencial.split("-")[0] + pipe
          : pipe,
        esencialDv: item.rutEsencial
          ? item.rutEsencial.split("-")[1] + pipe
          : pipe,
        codeEsencial: item.codeEsencial ? item.codeEsencial + pipe : pipe,
        folioFun: item.folioFUN ? item.folioFUN + pipe : pipe,
        kinetycId: item.kinetycId ? item.kinetycId + pipe : pipe,
      };
      let itemRow = `${itemInv.rut}${itemInv.dv}${itemInv.numberForm}${itemInv.employerRut}${itemInv.employerDv}${itemInv.employerName}${itemInv.taxableIncome}${itemInv.mandatoryLegalContribution}${itemInv.voluntaryContribution}${itemInv.contributionToPay}${itemInv.month}${itemInv.insuranceRut}${itemInv.insuranceDv}${itemInv.esencialRut}${itemInv.esencialDv}${itemInv.codeEsencial}${itemInv.folioFun}${itemInv.kinetycId}${zero}${newLine}`;
      invHandoverArr.push(itemRow);
    } else {
      let itemInv = {
        rut: item.rut.split("-")[0] + pipe,
        dv: item.rut.split("-")[1] + pipe,
        month: item.period.slice(4, 6) + item.period.slice(0, 4),
      };
      let itemRow = `${itemInv.rut}${pipe}${itemInv.dv}${pipe}${pipes}${itemInv.month}${pipes}${newLine}`;
      invArr.push(itemRow);
    }
  });
  return invArr.concat(invHandoverArr).join("");
};

export const generateInventoryTXT = async (period: string, data: any) => {
  const inventoryPipes = buildInventoryPipes(data);
  const blob = new Blob([inventoryPipes], { type: "text/plain" });
  saveAs(blob, `Archivo de Consulta de CME Esencial_${period}.txt`);
};

export const generateConfirmedInventoryTXT = async (
  period: string,
  data: any,
  code: number
) => {
  const confirmedInventory = buildConfirmedInventory(data, code);
  const blob = new Blob([confirmedInventory], { type: "text/plain" });
  saveAs(blob, `Archivo_de_Confirmacion_de_CME_${code}_${period}_Esencial.txt`);
};

export const generateHandoverInventory = async (
  period: string,
  data: any,
  code: number
) => {
  const handoverInventory = buildHandoverInventory(data, code);
  const blob = new Blob([handoverInventory], { type: "text/plain" });
  saveAs(blob, `Archivo_de_Traspaso_CME_${code}_108_${period}.txt`);
};

export const checkData = (data: any) => {
  if (typeof data === "string") {
    return data !== null && data.trim() !== "";
  } else {
    return data !== null;
  }
};

// const formatStringDate = (date: string) => {
//   const dateArray = date.split("-");
//   return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
// };

export const buildJsonContributors = (data: any) => {
  let jsonContributors = data.map((item: any) => {
    return {
      rut: item.rut.trim(),
      name: capitalCaseAll(item.name.trim()),
    };
  });
  let uniqueContributors = jsonContributors.filter(
    (item: any, index: number, self: any) =>
      index === self.findIndex((t: any) => t.rut === item.rut && t.rut !== null)
  );
  return uniqueContributors;
};

export const buildJsonContracts = (data: any, period: string) => {
  let jsonContract = data.map((item: any) => {
    return {
      rut: item.rut.trim(),
      sell_period: item.sellPeriod.toString(),
      payment_period: item.paymentPeriod.toString(),
      collection_period: period,
      payment_uf: item.paymentUF,
      worker_type: item.workerType,
      plan_type: item.planType,
    };
  });
  return jsonContract;
};

/**
 *
 * @param data data read from excel file on payments module
 * @returns json array of unique payers
 */
export const buildJsonPayers = (data: any) => {
  let jsonPayers = data.map((item: any) => {
    return {
      rut: item.payer_rut.trim(),
      name: capitalCaseAll(item.payer_name.trim()),
    };
  });
  let uniquePayers = jsonPayers.filter(
    (item: any, index: number, self: any) =>
      index === self.findIndex((t: any) => t.rut === item.rut && t.rut !== null)
  );
  return uniquePayers;
};

/**
 *
 * @param data data read from excel file on payments module
 * @param period selected period on payments module
 * @returns json array of payments and sum of payments
 */
export const buildJsonPayments = (data: any, period: string) => {  
  let jsonPayments = data.map((item: any) => {    
    return {
      contributor_rut: item.contributor_rut.trim(),
      payer_rut: item.payer_rut.trim(),
      payment_period: item.payment_period.toString(),
      collection_period: period,
      worker_type: item.worker_type,
      amount: item.amount,
      payment_type: item.payment_type,
      payment_date: item.payment_date,
      taxable_seven_percent: item.taxable_seven_percent,
      additional_amount: item.additional_amount,
      taxable_salary: item.taxable_salary,
    };
  });
  return {
    data: jsonPayments,    
  };
};

export const buildJsonExcess = (data: any, period: string) => {
  let jsonExcess: any = [];
  let jsonError: any = [];

  let contributors = data.map((item: any) => {
    return {
      contributor_rut: item.contributor_rut.trim(),
      payment_period: getPreviousPeriod(period as any)[3],
      collection_period: period,
      distribution_type: "SURPLUS",
    };
  });

  let uniqueContributors = contributors.filter(
    (item: any, index: number, self: any) =>
      index ===
      self.findIndex((t: any) => t.contributor_rut === item.contributor_rut)
  );

  uniqueContributors.forEach((item: any) => {
    let contributorMovements = data.filter(
      (t: any) => t.contributor_rut === item.contributor_rut
    );

    let contributorDetail = item;
    contributorMovements.forEach((t: any) => {
      if (item.contributor_rut === t.contributor_rut) {
        switch (t.movement_type) {
          case "EXCEDENTE":
            if (!contributorDetail.hasOwnProperty("surplus_generated")) {
              contributorDetail = {
                ...contributorDetail,
                surplus_generated: t.amount,
              };
            } else {
              jsonError.push({
                contributor_rut: item.contributor_rut,
                error: "El registro contiene más de un excedente generado.",
              });
            }
            break;
          case "REAJUSTE":
            if (!contributorDetail.hasOwnProperty("readjustment")) {
              contributorDetail = {
                ...contributorDetail,
                readjustment: t.amount,
              };
            } else {
              jsonError.push({
                contributor_rut: item.contributor_rut,
                error: "El registro contiene más de un reajuste.",
              });
            }
            break;
          case "INTERES":
            if (!contributorDetail.hasOwnProperty("interest")) {
              contributorDetail = {
                ...contributorDetail,
                interest: t.amount,
              };
            } else {
              jsonError.push({
                contributor_rut: item.contributor_rut,
                error: "El registro contiene más de un interés.",
              });
            }
            break;
          case "COMISION":
            if (!contributorDetail.hasOwnProperty("commission")) {
              contributorDetail = {
                ...contributorDetail,
                commission: t.amount,
              };
            } else {
              jsonError.push({
                contributor_rut: item.contributor_rut,
                error: "El registro contiene más de una comisión.",
              });
            }
            break;
          default:
            jsonError.push({
              contributor_rut: item.contributor_rut,
              error: "El registro contiene movimientos no válidos.",
            });
        }
      }
    });
    jsonExcess.push(contributorDetail);
  });

  let jsonExcessMovements = data.map((item: any) => {
    return {
      contributor_rut: item.contributor_rut.trim(),
      payment_period: getPreviousPeriod(period as any)[3],
      collection_period: period,
      amount_type: item.amount_type,
      movement_type: item.movement_type,
      amount: item.amount,
      // movement_date: lastDayOfMonth(getPreviousPeriod(period as any)[3]).toISOString().split("T")[0]
      movement_date: lastDayOfMonth(period).toISOString().split("T")[0],
      distribution_type: "SURPLUS",
    };
  });

  return {
    data: {
      excess: jsonExcess,
      excessMovements: jsonExcessMovements,
    },
    error: jsonError,
  };
};

export const buildJsonOverage = (data: any, period: string) => {
  let jsonError: any = [];
  const DISTRIBUTION_TYPE = "EXCESS";
  const AMOUNT_TYPE = "ABONO";
  const MOVEMENT_TYPE = "EXCESO";

  let jsonOverage = data.map((item: any) => {
    return {
      contributor_rut: item.contributor_rut.trim(),      
      payment_period: getPreviousPeriod(period as any)[3],
      collection_period: period,
      distribution_type: DISTRIBUTION_TYPE,
    };
  });

  let uniqueJsonOverage = jsonOverage.filter(
    (item: any, index: number, self: any) =>
      index ===
      self.findIndex(
        (t: any) =>
          t.contributor_rut === item.contributor_rut &&
          t.contributor_rut !== null
      )
  );

  // if (uniqueContributors.lenght !== jsonOverage.lenght) {
  //   jsonError.push({
  //     error: "El archivo contiene más de un registro de excesos por rut.",
  //   });
  // }

  let jsonOverageMovements = data.map((item: any) => {
    return {
      contributor_rut: item.contributor_rut.trim(),
      payment_period: item.payment_period,      
      collection_period: period,
      amount_type: AMOUNT_TYPE,
      movement_type: MOVEMENT_TYPE,
      amount: item.amount,      
      movement_date: lastDayOfMonth(period).toISOString().split("T")[0],
      distribution_type: DISTRIBUTION_TYPE,
    };
  });

  return {
    data: {
      overage: uniqueJsonOverage,
      overageMovements: jsonOverageMovements,
    },
    error: jsonError,
  };
};
