import { useEffect } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { littleTitle, modalSubtitle } from "src/components/typography/styles";
import {
  CustomModal,
  CustomModalTitle,
} from "src/components/modals/customModal";
import CustomInput from "src/components/input/customInput";
import { DatePicker } from "src/components/datePicker/datePicker";
import constants from "src/constants/constants";
import { getCurrentPeriod } from "src/utils/dates";

const {
  COMPONENTS: {
    BUTTONS: { CANCEL, SAVE },
  },
} = constants;

const UploadUF = ({ onOpen, onClose, ufInfo, setUfInfo, onConfirm }: any) => {
  const period = getCurrentPeriod(ufInfo?.period)[0]?.split(" ")[0] ?? "";
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  const handleClose = () => {
    onClose();
    setUfInfo({
      ...ufInfo,
      newValue: 0,
      displayValue: 0,
    });
  };

  const lastDayOfMonth = (date: any) => {
    let formatDate = date.slice(4, 6) + "-01-" + date.slice(0, 4);
    const newDate = new Date(formatDate);
    newDate.setMonth(newDate.getMonth() + 1);
    newDate.setDate(0);
    return newDate;
  };

  const formatNewDate = (date: Date) => {
    let fDate = date.toLocaleString("es-cl", { dateStyle: "medium" });
    return fDate.replace(/-/g, "/");
  };

  const handleInputChange = (e: any) => {
    let value = e.target.value;
    const regex = /^(\d+)(,\d{0,2})?$/;
    value = value.replace(/^0+/, "");
    if (regex.test(value)) {
      setUfInfo({
        ...ufInfo,
        newValue: parseFloat(value.toString().replace(",", ".")),
        displayValue: value,
      });
    } else if (value === "") {
      setUfInfo({
        ...ufInfo,
        newValue: 0,
        displayValue: 0,
      });
    }
  };

  useEffect(() => {
    onOpen &&
      setUfInfo({
        ...ufInfo,
        newValue: ufInfo.newValue ? ufInfo.newValue : ufInfo.value,
        displayValue: ufInfo.displayValue
          ? ufInfo.displayValue
          : ufInfo.value.toString().replace(".", ","),
        date: formatNewDate(lastDayOfMonth(ufInfo.period)),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen]);

  return (
    <>
      <CustomModal
        fullWidth
        maxWidth="sm"
        open={onOpen}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <CustomModalTitle id="addCredits" onClose={handleClose}>
          <Typography sx={littleTitle} textAlign="center" pt="32px">
            Actualizar UF
          </Typography>
          <Typography sx={modalSubtitle} textAlign="center">
            Ingresa el último valor de la UF de{" "}
            {/* {getCurrentPeriod(ufInfo.period)[0].split(" ")[0]}. */}
            {period}
          </Typography>
        </CustomModalTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12} pb="16px">
              <Typography variant="h3" pb="6px">
                Fecha del
              </Typography>
              <DatePicker value={lastDayOfMonth(ufInfo.period)} disabled />
            </Grid>
            <Grid item xs={12} pb="16%">
              <Typography variant="h3" pb="6px">
                Valor UF
              </Typography>
              <CustomInput
                id="outlined-basic"
                variant="outlined"
                placeholder="ej. 35.000"
                fullWidth
                value={ufInfo.displayValue}
                sx={{ borderRadius: "16px" }}
                onChange={handleInputChange}
                inputProps={{
                  maxLength: 15,
                  "data-testid": "currencyInput",
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button onClick={handleClose} color="secondary">
            {CANCEL}
          </Button>
          <Button
            onClick={handleConfirm}
            disabled={
              ufInfo.value === parseFloat(ufInfo.newValue) ? true : false
            }
          >
            {SAVE}
          </Button>
        </DialogActions>
      </CustomModal>
    </>
  );
};

export default UploadUF;
