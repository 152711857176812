import { useState } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  //   TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import CustomInput, { CustomInputSlim } from "../input/customInput";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import constants from "src/constants/constants";

export const DatePicker = ({
  value,
  onChange,
  minDate,
  maxDate,
  disabled,
  id,
  testid,
  label,
  error,
  noClear,
  slim,
}: any) => {
  const [openIcon, setOpenIcon] = useState(false);
  return (
    <FormControl
      sx={{ width: "100%!important", margin: "0" }}
      error={error ? true : undefined}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <DesktopDatePicker
          components={{
            OpenPickerIcon: CalendarMonthOutlinedIcon,
          }}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          label={label}
          onChange={(f: any) => {
            onChange(f);
          }}
          disableOpenPicker={openIcon}
          InputProps={{
            sx: {
              "& .MuiSvgIcon-root": { color: "#909AA1" },
              fontSize: "16px",
            },
          }}
          renderInput={(params: any) => (
            <Grid>
              {slim ? (
                <CustomInputSlim
                  fullWidth
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: constants.COMPONENTS.PLACEHOLDER.CALENDAR_DATE,
                    "data-testid": testid,
                  }}
                  error={error ? true : undefined}
                  sx={{ margin: "0!important" }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              ) : (
                <CustomInput
                  fullWidth
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: constants.COMPONENTS.PLACEHOLDER.CALENDAR_DATE,
                  }}
                  error={error ? true : undefined}
                  sx={{ margin: "0!important" }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              )}
              {value && !noClear ? (
                <>
                  {setOpenIcon(true)}
                  <IconButton
                    style={{
                      position: "absolute",
                      bottom: slim ? ".01rem" : "",
                      top: slim ? "" : ".5rem",
                      margin: "auto",
                      right: ".5rem",
                    }}
                    sx={{ opacity: ".5" }}
                    disabled={disabled}
                    onClick={() => onChange(null)}
                  >
                    <ClearIcon sx={{ width: "20px", height: "20px" }} />
                  </IconButton>
                </>
              ) : (
                <>{setOpenIcon(false)}</>
              )}
            </Grid>
          )}
        />
      </LocalizationProvider>
      <FormHelperText>{error || ""}</FormHelperText>
    </FormControl>
  );
};
