import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { DownloadButton, NoResult } from "src/components";
import { StyledTableCell } from "src/components/tables/tableStyle";
import { paginatorTheme } from "src/components/paginator/paginatorStyle";
import { boxNoPadding } from "src/components/box/styles";
import { contTitle } from "src/components/typography/styles";
import { SkeletonTableCash } from "./skeletonsCash";
import constants from "src/constants/constants";
import { systemModules } from "src/utils/selectors";
import { useLogs } from "src/hooks/useLogs";

const {
  PAGES: {
    DEVOLUTION,
    MODULES: { EXCESS, OVERAGE },
  },
} = constants;

export default function RecordTableCash(props: any) {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const { getLog, count, rows, loading, error, firstLoad } = useLogs();

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (props.tabValue === 1) {
      getLog({
        limit: rowsPerPage,
        offset: page * rowsPerPage - rowsPerPage,
        logTypeId: systemModules(props.module),
        fkId: parseInt(props.period),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.refresh === true) {
      getLog({
        limit: rowsPerPage,
        offset: 0,
        logTypeId: systemModules(props.module),
        fkId: parseInt(props.period),
      });
      props.setRefresh(false);
    }
    // eslint-disable-next-line
  }, [props.refresh]);

  useEffect(() => {
    getLog({
      limit: rowsPerPage,
      offset: page * rowsPerPage - rowsPerPage,
      logTypeId: systemModules(props.module),
      fkId: parseInt(props.period),
    });
    // eslint-disable-next-line
  }, [page, rowsPerPage]);

  return (
    <Box sx={boxNoPadding} mt="16px" mb="24px">
      {firstLoad || loading ? (
        <SkeletonTableCash
          grid={true}
          rows={3}
          columns={4}
          table={"full"}
          data-testid="skeleton-table"
          module={props.module || null}
        />
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={contTitle} pt="24px" pl="24px">
                Historial de Carga
              </Typography>{" "}
              <Typography variant="h3" pb="26px" pl="24px">
                Aquí podrás ver los cambios que se han realizado en este módulo.
              </Typography>{" "}
            </Grid>
          </Grid>
          {error ? (
            <NoResult
              titulo={"Error"}
              subtitulo={"Error en la carga de datos"}
              error
            />
          ) : (
            <>
              {count === 0 ? (
                <NoResult
                  titulo={"No hay información para mostrar."}
                  subtitulo={"Cargue información para visualizar historial"}
                  dontSearch
                />
              ) : (
                <TableContainer
                  component={Paper}
                  sx={{ boxShadow: "none", borderRadius: "none" }}
                >
                  <Table sx={{ minWidth: 650 }} aria-label="customized table">
                    <TableHead className="color-head-table">
                      <TableRow>
                        <StyledTableCell>Usuario</StyledTableCell>
                        <StyledTableCell>Fecha y hora de carga</StyledTableCell>
                        <StyledTableCell>Registros de la carga</StyledTableCell>
                        {props.module !== EXCESS &&
                          props.module !== OVERAGE &&
                          props.module !== DEVOLUTION && (
                            <StyledTableCell>Total de pagos</StyledTableCell>
                          )}
                        <StyledTableCell sx={{ width: "200px" }}>
                          Acciones
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {rows.map((row: any) => {
                      return (
                        <TableBody>
                          <TableRow data-testid="withData">
                            <TableCell>
                              <Typography variant="h5">
                                {row.userName}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h5">
                                {row.logDate.slice(0, 10).split("-").join("/") +
                                  " " +
                                  row.logDate.slice(11, 16) +
                                  "hrs"}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h5">
                                {row.description.split("|")[0]}
                                {Number(row.description.split("|")[0]) === 1
                                  ? " registro"
                                  : " registros"}
                              </Typography>
                            </TableCell>
                            {props.module !== EXCESS &&
                              props.module !== OVERAGE &&
                              props.module !== DEVOLUTION && (
                                <TableCell>
                                  <Typography variant="h5">
                                    {row.description.split("|")[1] ===
                                    undefined ? (
                                      "Sin info"
                                    ) : (
                                      <>${row.description.split("|")[1]}</>
                                    )}
                                  </Typography>
                                </TableCell>
                              )}
                            <TableCell>
                              <DownloadButton
                                disabled={
                                  row?.descriptionAnnex === null ? true : false
                                }
                                display="list"
                                documentId={row?.descriptionAnnex}
                                fileType={"xlsx"}
                                data-testid="downloadButton"
                                txt={false}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })}
                  </Table>
                  <Grid alignItems="center" pb="15px" pt="24px">
                    <ThemeProvider theme={paginatorTheme}>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, 50]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={"Mostrar por página"}
                        sx={{ mt: "24px", mb: "24px" }}
                        labelDisplayedRows={(e) => {
                          return (
                            "" + page + " de " + Math.ceil(count / rowsPerPage)
                          );
                        }}
                      />
                    </ThemeProvider>
                  </Grid>
                </TableContainer>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
}
