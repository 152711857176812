import { getUserRoles } from "./msal";

const ADMIN = "ROL_ADMIN";
// const USER = "ROL_USER";

const findPermission = (list: string[], role: string) => {
  return list?.indexOf(role) !== -1 ? true : false;
};

const canUpdate = (list: string[]) => {
  return findPermission(list, ADMIN);
};

const canUploadFile = (list: string[]) => {
  return findPermission(list, ADMIN);
};

const canCloseProcess = (list: string[]) => {
  return findPermission(list, ADMIN);
};

export const hasAccessToRoute = (list: string[], route: string) => {
  if (route === "/configuracion") {
    return findPermission(list, ADMIN);
  }
  return true;
};

export const hasPermissions = () => {
  const userRoles = getUserRoles();
  return {
    update: canUpdate(userRoles),
    uploadFile: canUploadFile(userRoles),
    closeProcess: canCloseProcess(userRoles),
  };
};
