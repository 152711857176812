import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableCell } from "src/components/tables/tableStyle";
import {
  blackBold,
  littleTitle,
  modalSubtitle,
  purpleLittle,
} from "src/components/typography/styles";
import {
  CustomModal,
  CustomModalTitle,
} from "src/components/modals/customModal";
import { NoResult } from "src/components/tables/noResult";
import { getCurrentPeriod } from "src/utils/dates";
import { capitalCaseAll } from "src/utils/stringUtils";
import constants from "src/constants/constants";
import { renderAmount } from "src/utils/numberUtils";

export type ExcessInfo = {
  id: string;
  collectionPeriod: string;
  commission: number;
  contributorName: string;
  contributorRut: string;
  surplusGenerated: number;
  interest: number;
  paymentPeriod: string;
  readjustment: number;
  excessDetail: any;
};
type OverageInfo = {
  id: string;
  availableBalance: number;
  collectionPeriod: string;
  contributorName: string;
  contributorRut: string;
  paymentPeriod: string;
  statusId: number;
  statusName: string;
  typeId: number;
  excessDetail: any;
};

type DetailExcessProps = {
  onOpen: boolean;
  handleCloseModal: () => void;
  info: ExcessInfo | OverageInfo;
  type: number;
};

const DetailExcess = ({
  onOpen,
  handleCloseModal,
  info,
  type,
}: DetailExcessProps) => {
  const {
    collectionPeriod,
    contributorRut,
    contributorName,
    paymentPeriod,
    excessDetail,
  } = info || {};
  return (
    <CustomModal
      maxWidth="md"
      fullWidth
      open={onOpen}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <CustomModalTitle id="" onClose={handleCloseModal}>
        <Typography sx={littleTitle} textAlign="center" pt="32px">
          Ver detalle de movimientos
        </Typography>
        <Typography sx={modalSubtitle} textAlign="center">
          Aquí puedes ver los movimientos por periodo de un RUT
        </Typography>
      </CustomModalTitle>
      <DialogContent dividers sx={{ overflow: "visible" }}>
        <Grid container>
          <Grid xs={6}>
            <Typography sx={purpleLittle} pb="4px">
              Nombre y RUT cliente
            </Typography>
            <Typography sx={blackBold} pt="4px">
              {contributorName ?? "N/D"} {contributorRut}
            </Typography>
          </Grid>
          {type === 1 && (
            <>
              <Grid xs={6}>
                <Typography sx={purpleLittle} pb="4px">
                  Período remuneración
                </Typography>
                <Typography sx={blackBold} pt="4px">
                  {getCurrentPeriod(paymentPeriod)[0]}
                </Typography>
              </Grid>
              <Grid xs={6} pt="24px" pb="16px">
                <Typography sx={purpleLittle} pb="4px">
                  Período recaudación
                </Typography>
                <Typography sx={blackBold} pt="4px">
                  {getCurrentPeriod(collectionPeriod)[0]}
                </Typography>
              </Grid>
            </>
          )}
          {type === 2 && (
            <Grid xs={6} pb="16px">
              <Typography sx={purpleLittle} pb="4px">
                Período recaudación
              </Typography>
              <Typography sx={blackBold} pt="4px">
                {getCurrentPeriod(collectionPeriod)[0]}
              </Typography>
            </Grid>
          )}
        </Grid>
        <TableContainer
          className="grayScroll"
          component={Paper}
          sx={{
            boxShadow: "none",
            borderRadius: "8px",
            border: "0.5px solid #4DC0B8",
            maxHeight: "290px",
          }}
        >
          {!excessDetail ? (
            <NoResult
              titulo={"No se encontraron resultados"}
              subtitulo={
                "No se encontraron movimientos asociados a este registro de excedentes."
              }
            />
          ) : (
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead className="color-head-table">
                <TableRow>
                  {type === 2 && (
                    <StyledTableCell>
                      Periodo <br></br> remuneración
                    </StyledTableCell>
                  )}
                  <StyledTableCell>
                    Fecha de <br></br> movimiento
                  </StyledTableCell>
                  <StyledTableCell>
                    Tipo de <br></br> movimiento
                  </StyledTableCell>
                  <StyledTableCell>
                    Detalle de <br></br> movimiento
                  </StyledTableCell>
                  <StyledTableCell>Monto</StyledTableCell>
                </TableRow>
              </TableHead>
              {excessDetail?.map((item: any, index: number) => {
                return (
                  <TableBody>
                    <TableRow key={index}>
                      {type === 2 && (
                        <TableCell>
                          <Typography variant="h5">
                            {getCurrentPeriod(item.paymentPeriod)[0]}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell>
                        <Typography variant="h5">
                          {item.movementDate}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5">
                          {capitalCaseAll(item.amountType)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5">
                          {capitalCaseAll(item.movementType)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5">
                          ${renderAmount(item.amount)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                );
              })}
            </Table>
          )}
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="end"
          alignItems="center"
        >
          <Button
            onClick={handleCloseModal}
            color="secondary"
            sx={{ width: "150px" }}
          >
            {constants.COMPONENTS.BUTTONS.CLOSE}
          </Button>
        </Grid>
      </DialogActions>
    </CustomModal>
  );
};
export default DetailExcess;
