const constants = {
  REACT_APP_AZURE_ALLOWED_ROLES: ["ROL_ADMIN", "ROL_USER"],
  COMPONENTS: {
    ALERTS: {
      ERROR: "error",
      INFO: "info",
      SUCCESS: "success",
      WARNING: "warning",
    },
    BUTTONS: {
      CANCEL: "Cancelar",
      CLOSE: "Cerrar",
      CONFIRM: "Confirmar",
      DELETE: "Eliminar",
      DOWNLOAD: "Descargar",
      EDIT: "Editar",
      EXPORT: "Exportar",
      GENERATE_REPORT: "Reporte maestro",
      HYSTORY: "Ver histórico",
      FILTERS: "Ver filtros",
      LOG_HISTORY: "Ver historial",
      MOVEMENT: "Movimiento",
      SAVE: "Guardar",
      SAVE_CHANGES: "Guardar cambios",
      SEARCH: "Buscar",
      SELECT: "Seleccionar",
      UPLOAD_FILE: "Cargar archivo",
      UPLOAD_PAYROLL: "Cargar Nómina",
      UPDATE: "Actualizar",
    },
    MENU_ITEMS: {
      ADD_MOVEMENT: "Nuevo movimiento",
      CHANGE_HISTORY: "Historial de cambios",
      DELETE: "Eliminar",
      DELETE_MOVEMENTS: "Eliminar movimientos",
      DELETE_RECORD: "Eliminar registro",
      DETAIL: "Ver detalle",
      DEVOLUTION: "Devolución",
      EDIT: "Editar",
      EDIT_EXCESS_AMOUNT: "Editar monto excesos",
      EDIT_MOVEMENTS: "Editar movimientos",
      EDIT_RECORD: "Editar registro",
      MARK_RETURN_ON_DEMAND: "Marcar dev. a demanda",
      UNMARK_RETURN_ON_DEMAND: "Desmarcar dev. a demanda",
    },
    NO_RESULT: {
      ERROR: "No se pudo cargar la tabla",
      ERROR_SUBTITLE:
        "No fue posible mostrar la información debido a un problema de conexión. Intenta recargar la página para solucionar el problema.",
      NEW_SEARCH: "Realiza una consulta",
      NEW_SEARCH_SUBTITLE:
        "Selecciona la sección e ingresa el RUT a consultar para visualizar.",
      NO_INFO: "No hay información para mostrar.",
      NO_INFO_DEVOLUTION:
        "No se encontraron devoluciones disponibles para gestionar",
      NO_RESULT: "Sin Resultados",
      NO_RESULT_RUT: "No se encontró información asociada al RUT",
      NO_RESULT_RUT_SUBTITLE: "Inténtalo nuevamente usando otro criterio.",
    },
    PLACEHOLDER: {
      CALENDAR_DATE: "DD / MM / AAAA",
    },
    SELECTS: {
      DATE_RANGE: {
        TWELVE_MONTHS: "Últimos 12 meses",
        TWENTY_FOUR_MONTHS: "Últimos 24 meses",
      },
    },
  },
  DATE: {
    UPDATE_FORMAT: "YYYY-MM-DD",
  },
  EXCESS: {
    MOVEMENT_TYPE: {
      CREDIT: "ABONO",
      CHARGE: "CARGO",
      EXCESS: "EXCESO",
      REVERSE: "REVERSA",
      BALANCE_TRANSFER: "TRASPASO DE SALDOS",
      AMOUNT_ADJUSTMENT: "AJUSTE DE SALDOS",
      RETURN_MONTHLY: "DEVOLUCION MENSUAL",
      RETURN_ON_DEMAND: "DEVOLUCION A DEMANDA",
      REJECTED_RETURN: "RECHAZO DEVOLUCION",
    },
  },
  DEVOLUTION: {
    DEVOLUTION_TYPE: {
      MONTHLY: "Devolución mensual",      
      ON_DEMAND: "Devolución a demanda",
      NOT_APPLICABLE: "No aplica",
      ID: {
        MONTHLY: 20,
        NOT_APPLICABLE: 18,
        ON_DEMAND: 22,
      },
    },
    STATUS: {
      UNINITIATED: "No Iniciado",
      IN_PROCESS: "En proceso",
      PAID: "Pagada",
      REJECTED: "Rechazada",
      ID: {
        UNINITIATED: 5,
        IN_PROCESS: 2,
        PAID: 17,
        REJECTED: 19,
      },
    },
  },
  PAGES: {
    COLLECTION: "collection",
    DEVOLUTION: "devolution",
    DISTRIBUTION: "distribution",
    SEARCH_BY_RUT: "rutSearch",
    MODULES: {
      CREDITS: "credits",
      EXCESS: "excess",
      OVERAGE: "overage",
      PAYMENTS: "payments",
    },
    DISPLAY_NAME: {
      CREDITS: "Acreencias",
      CASH: "PAC, PAT y Caja",
      EXCESS: "Excedentes",
      OVERAGE: "Excesos",
      PAYMENTS: "Recaudación",
    },
  },
  SNACKBAR_MESSAGE: {
    COLLECTION: {
      CLOSE_PERIOD_ERROR:
        "Ocurrió un error al cerrar el periodo, inténtalo nuevamente.",
      CLOSE_PERIOD_SUCCESS: "El periodo ha sido cerrado correctamente.",
      CREDITS_EXPORT_ERROR:
        "Ocurrió un error al exportar las acreencias, inténtalo nuevamente.",
      CREDITS_EXPORT_SUCCESS:
        "El listado de acreencias se exportó correctamente",
      PAYMENTS_EXPORT_ERROR:
        "Ocurrió un error al exportar la recaudación, inténtalo nuevamente.",
      PAYMENTS_EXPORT_SUCCESS:
        "El listado de recaudación se exportó correctamente.",
      PAYMENTS_UPDATE_ERROR:
        "Ocurrió un error al editar el registro de recaudación, inténtalo nuevamente.",
      PAYMENTS_UPDATE_SUCCESS:
        "El registro de recaudación ha sido guardado correctamente.",
      PAYMENTS_BULK_UPDATE_ERROR:
        "Ocurrió un error al editar la clasificación, inténtalo nuevamente.",
      PAYMENTS_BULK_UPDATE_SUCCESS:
        "La edición de la clasificación fue realizada con éxito.",
    },
    DEVOLUTION: {
      CONFIRM_PERIOD_ERROR:
        "Ocurrió un error al confirmar el periodo de devolución, inténtalo nuevamente.",
      CONFIRM_PERIOD_SUCCESS: "Periodo de devolución confirmado exitosamente.",
      EXPORT_ERROR:
        "Ocurrió un error al exportar las devoluciones, inténtalo nuevamente.",
      EXPORT_SUCCESS: "El listado de devoluciones se exportó correctamente.",
      SAP_MONTHLY_EXPORT_SUCCESS:
        "El cargador SAP devolución mensual se generó correctamente.",
      SAP_ON_DEMAND_EXPORT_SUCCESS:
        "El cargador SAP devolución a demanda se generó correctamente.",
      SAP_EXPORT_ERROR:
        "Ocurrió un error al generar el cargador SAP, inténtalo nuevamente.",
      SAP_EXPORT_NO_DATA_ERROR:
        "No hay registros en proceso para generar cargador dev. ",
      SAP_PROCESS_ERROR: "Ocurrió un error al procesar los registros dev. ",
      UPLOAD_SUCCESS:
        "El archivo nómina de devoluciones ha sido cargado correctamente.",
      UPLOAD_ERROR:
        "Ocurrió un error al cargar el archivo nómina de devoluciones, inténtalo nuevamente.",
      UPLOAD_FOREIGN_KEY_ERROR:
        "La nómina contiene información de RUT que no corresponden a este proceso de devolución.",
      UPLOAD_UNIQUE_KEY_ERROR:
        "La nómina contiene RUT de devoluciones ya cargadas en el sistema.",
    },
    DISTRIBUTION: {
      CONFIRM_PERIOD_ERROR: "Ocurrió un error al confirmar el periodo.",
      CONFIRM_PERIOD_SUCCESS:
        "Se ha confirmado el periodo, la información será procesada para publicación.",
      CONFIRM_EXCESS_ERROR:
        "Ocurrió un error al confirmar excedentes, inténtalo nuevamente.",
      CONFIRM_EXCESS_SUCCESS:
        "La carga de excedentes ha sido confirmada con éxito.",
      EXCESS_DELETE_ERROR:
        "Ocurrió un error al eliminar el registro, inténtalo nuevamente.",
      EXCESS_DELETE_SUCCESS: "El registro ha sido eliminado correctamente.",
      EXCESS_EXPORT_ERROR:
        "Ocurrió un error al exportar los excedentes, inténtalo nuevamente.",
      EXCESS_REPORT_EXPORT_ERROR:
        "Ocurrió un error al exportar el reporte maestro, inténtalo nuevamente.",
      EXCESS_EXPORT_SUCCESS:
        "El listado de excedentes se exportó correctamente.",
      EXCESS_REPORT_EXPORT_SUCCESS:
        "El reporte maestro de excedentes se exportó correctamente.",
      EXCESS_UPDATE_ERROR:
        "Ocurrió un error al editar el registro de excedentes, inténtalo nuevamente.",
      EXCESS_UPDATE_SUCCESS:
        "El registro de excedentes ha sido editado correctamente.",
      EXCESS_UPLOAD_ERROR: {
        DEFAULT:
          "Ocurrió un error al cargar el archivo de excedentes, inténtalo nuevamente.",
        FOREIGN_KEY_CONSTRAINT:
          "El archivo contiene RUT de afiliados no registrados en el sistema.",
        UNIQUE_CONSTRAINT:
          "El archivo contiene excedentes ya cargados en el sistema.",
        MOVEMENT_DETAIL_ERROR:
          "El archivo contiene registros con detalle de movimientos no permitidos.",
      },
      EXCESS_UPLOAD_SUCCESS:
        "El archivo de excedentes ha sido cargado correctamente.",
      EXCESS_UPLOAD_WARNING: "No fue posible guardar en el gestor documental.",
      OVERAGE: {
        CONFIRM_ERROR:
          "Ocurrió un error al confirmar excesos, inténtalo nuevamente.",
        CONFIRM_SUCCESS: "La carga de excesos ha sido confirmada con éxito.",
        DELETE_ERROR:
          "Ocurrió un error al eliminar el registro de excesos, inténtalo nuevamente.",
        DELETE_SUCCESS:
          "El registro de excesos ha sido eliminado correctamente.",
        EXPORT_ERROR:
          "Ocurrió un error al exportar los excesos, inténtalo nuevamente.",
        EXPORT_SUCCESS: "El listado de excesos se exportó correctamente.",
        SAP_MONTHLY_EXPORT_SUCCESS:
          "El cargador SAP devolución mensual se generó correctamente.",
        SAP_ON_DEMAND_EXPORT_SUCCESS:
          "El cargador SAP devolución a demanda se generó correctamente.",
        SAP_EXPORT_ERROR:
          "Ocurrió un error al generar el cargador SAP, inténtalo nuevamente.",
        SAP_EXPORT_NO_DATA_ERROR:
          "No hay registros en proceso para generar cargador dev. ",
        SAP_PROCESS_ERROR: "Ocurrió un error al procesar los registros dev. ",
        UPDATE_ERROR:
          "Ocurrió un error al editar el registro de excesos, inténtalo nuevamente.",
        UPDATE_SUCCESS: "El registro de excesos ha sido editado correctamente.",
        UPLOAD_ERROR: {
          DEFAULT:
            "Ocurrió un error al cargar el archivo de excesos, inténtalo nuevamente.",
          FOREIGN_KEY_CONSTRAINT:
            "El archivo contiene RUT de afiliados no registrados en el sistema.",
          UNIQUE_CONSTRAINT:
            "El archivo contiene excesos ya cargados en el sistema.",
        },
        UPLOAD_SUCCESS: "El archivo de excesos ha sido cargado correctamente.",
        UPLOAD_WARNING: "No fue posible guardar en el gestor documental.",
      },
      PERIOD_STATUS: {
        CONFIRMABLE:
          "La confirmación de un periodo deshabilita las opciones de edición, la información sólo quedará disponible a nivel de consulta.",
        CONFIRMED:
          "El periodo ha sido confirmado, la información será procesada esta noche para su publicación.",
        ERROR: "Ha ocurrido un error al confirmar periodo, contacte a soporte.",
        IN_PROGRESS:
          "Confirmación en proceso, se han bloqueado las opciones de edición para este periodo.",
      },
    },
    EXCESS_MOVEMENT: {
      CREATE_ERROR:
        "Ocurrió un error al agregar el movimiento, inténtalo nuevamente.",
      CREATE_SUCCESS: "El nuevo movimiento ha sido agregado correctamente.",
      DELETE_ERROR:
        "Ocurrió un error al eliminar el movimiento, inténtalo nuevamente.",
      DELETE_SUCCESS: "El movimiento ha sido eliminado correctamente.",
      REVERSE_ERROR: "Ocurrió un error al reversar el movimiento.",
      REVERSE_SUCCESS: "El movimiento ha sido reversado correctamente.",
      UPDATE_ERROR:
        "Ocurrió un error al editar movimiento(s), inténtalo nuevamente.",
      UPDATE_SUCCESS: "La edición se ha realizado correctamente.",
    },
    EXCESS: {
      UPDATE_CLASSIFICATION_ERROR:
        "Ocurrió un error al actualizar la clasificación del registro, inténtalo nuevamente.",
    },
    SETTINGS: {
      CREATE_CURRENCY_ERROR:
        "Ocurrió un error al crear la UF, inténtalo nuevamente.",
      CREATE_CURRENCY_SUCCESS: "UF creada correctamente.",
      UPDATE_CURRENCY_ERROR:
        "Ocurrió un error al actualizar la UF, inténtalo nuevamente.",
      UPDATE_CURRENCY_SUCCESS: "UF actualizada con éxito.",
    },
    DEFAULT: "",
    _TRY_AGAIN: ", inténtalo nuevamente.",
    SUCCESS_ETL: "Generando descarga, por favor espere...",
    ERROR_ETL: "Ha ocurrido un error, inténtalo nuevamente.",
    CREDITS_DELETE_ERROR:
      "Ha ocurrido un error al intentar eliminar el registro de acreencias.",
    CREDITS_DELETE_SUCCESS:
      "El registro de acreencias ha sido eliminado correctamente.",
    CREDITS_EXPORT_ERROR:
      "Ocurrió un error al exportar los registros de acreencias",
    CREDITS_EXPORT_SUCCESS:
      "El listado de registro de acreencias se exportó correctamente",
    CREDITS_UPDATE_ERROR:
      "Ha ocurrido un error al editar el registro de acreencia.",
    CREDITS_UPDATE_SUCCESS:
      "El registro de acreencia ha sido editado correctamente.",
    CREDITS_UPLOAD_ERROR:
      "No fue posible cargar el archivo de acreencias, inténtalo nuevamente",
    CREDITS_UPLOAD_SUCCESS:
      "El archivo de acreencias ha sido cargado correctamente.",
    CREDITS_UPLOAD_WARNING: "No fue posible guardar en el gestor documental.",
    PAYMENTS_DELETE_ERROR:
      "Ha ocurrido un error al intentar eliminar el registro de recaudación PAC, PAT y Caja.",
    PAYMENTS_DELETE_SUCCESS:
      "El registro de recaudación PAC, PAT y Caja ha sido eliminado correctamente.",
    PAYMENTS_EXPORT_ERROR:
      "Ocurrió un error al exportar los registros de pagos",
    PAYMENTS_EXPORT_SUCCESS:
      "El listado de registro de pagos se exportó correctamente",
    PAYMENTS_UPDATE_ERROR:
      "Ha ocurrido un error al intentar editar el registro de recaudación PAC, PAT y Caja.",
    PAYMENTS_UPDATE_SUCCESS:
      "El registro de recaudación PAC, PAT y Caja ha sido editado correctamente.",
    INVENTORY_ERROR:
      "No fue posible generar el inventario, inténtalo nuevamente.",
    INVENTORY_SUCCESS: "Se generó correctamente el inventario.",
    INVENTORY_SEND_SUCCESS: "Se confirmó correctamente el envío del inventario",
    INVENTORY_PAYMENT_SUCCESS: "Se confirmó correctamente el pago del traspaso",
    INVENTORY_SEND_ERROR: "Ha ocurrido un error, inténtalo nuevamente",
    INVENTORY_TERMINATE_SUCCESS: "Se terminó correctamente el proceso",
    INVENTORY_TERMINATE_ERROR:
      "No fue posible terminar el proceso, inténtalo nuevamente",
    PREVIRED_CONSOLIDATED_ERROR: "No fue posible generar el consolidado",
    PREVIRED_CONSOLIDATED_SUCCESS: "Se generó correctamente el consolidado",
    DOWNLOAD_ERROR: "Ha ocurrido un error en la descarga",
    DOWNLOAD_SUCCESS: "Descarga exitosa",
    JENKINS_TEST_PIPE: "Hola mundo!!!",
    ERROR_DEFAULT: "Ha ocurrido un error",
    DEFAULT_SUCCESS: "Operación exitosa",
    UPLOAD_SUCCESS: "Se cargó correctamente el inventario confirmado",
    UPLOAD_ERROR: "No fue posible cargar el inventario, inténtalo nuevamente",
    UPLOAD_ERROR_DEFAULT:
      "Ocurrió un error al cargar el archivo, inténtalo nuevamente.",
    UPLOAD_VALIDATION_ERROR: "El archivo no cumple el formato requerido",
    UPLOAD_WARNING: "No fue posible guardar en el gestor documental.",
  },
  INVENTORY_STATUS: {
    BACKUP_IMAGE_UNAVAILABLE: "Imagen de respaldo no disponible.",
    BACKUP_IMAGE_ERROR:
      "No fue posible cargar la imagen de respaldo, inténtalo nuevamente.",
  },
  GLOBAL_LOADER: {
    CHECKING_STATUS: "Procesando...",
  },
  ERROR_MESSAGE: {
    DEFAULT: "Ha ocurrido un error, inténtalo nuevamente.",
    PAYMENTS_UPLOAD_FOREIGN_KEY:
      "El archivo contiene registros de clientes no identificados o el periodo asociado no existe en el sistema.",
  },
  READ_FILE_MESSAGE: {
    ERROR: {
      COLUMNS_FORMAT: " Columnas no cumplen el formato requerido.",
      INCOMPLETE_DATA: " Información incompleta.",
      INVALID_DATA: " Datos no válidos.",
      INVALID_STRUCTURE: " La estructura del archivo no es correcta.",
      DEFAULT: "Ocurrió un error al leer el archivo.",
      DUPLICATED_CONTRIBUTOR: " RUT de afiliado duplicado.",      
    },
  },
  RUN_VALIDATION_ERROR: "No se pudo validar RUN. Verifique la información",
  QUERIES: {
    CODE: {
      VALIDATION_FAILED: "VALIDATION_FAILED",
      FOREIGN_KEY_ERROR: "SequelizeForeignKeyConstraintError",
      UNIQUE_KEY_ERROR: "SequelizeUniqueConstraintError",
    },
    INPUT: {
      PRESIGNED_URL_TYPE: {
        CONTRACTS: [1, 2, 3],
        DEVOLUTION: [13, 14],
        EXCESS: [7, 8, 9],
        OVERAGE: [10, 11, 12],
        PAYMENTS: [4, 5, 6],
      },
      LOG_TYPE: {
        FILE_UPLOAD: {
          CONTRACTS: 18,
          DEVOLUTION: 38,
          EXCESS: 30,
          PAYMENTS: 21,
          OVERAGE: 35,
        },
        UPDATE: {
          CREDITS: 19,
          CURRENCY: 29,
          EXCESS: 27,
          EXCESS_MOVEMENT: 31,
          OVERAGE: 36,
          PAYMENTS: 22,
        },
        CLOSE_COLLECTION: 23,
        CLOSE_DISTRIBUTION: 26
      },
    },
    FETCH_POLICY: {
      NETWORK_ONLY: "network-only",
    },
  },
  FILE: {
    COLUMNS: {
      PAYMENT_PERIOD: "Periodo remuneracion",
      CONTRIBUTOR_RUT: "RUT afiliado",
      CONTRIBUTOR_NAME: "Nombre afiliado",
      PAYER_RUT: "Rut pagador",
      PAYER_NAME: "Nombre pagador",
      WORKER_TYPE: "Tipo trabajador",
      AMOUNT: "Monto",
      AMOUNT_TYPE: "Tipo movimiento",
      MOVEMENT_TYPE: "Detalle movimiento",
    },
    MIMETYPE: {
      EXCEL:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      EXCEL_OLD: "application/vnd.ms-excel",
      PDF: "application/pdf",
      TXT: "text/plain",
    },
    FORMAT: {
      EXCEL: ".xlsx",
      EXCEL_OLD: ".xls",
      PDF: ".pdf",
      TXT: ".txt",
    },
    VALIDATION: {
      EXCEL_COLUMNS: {
        OUT_OF_BOUNDS: "Out of bounds. Excel supports columns from 1 to 16384",
      },
      DEFAULT_ROW_LIMIT: 10000,
      PAYROLL_ROW_LIMIT: 3000,
    },
  },
  STATUS: {
    ENTERED: "Ingresado",
    IN_PROGRESS: "En proceso",
    PAID: "Pagada",
  },
  PAYMENT_TYPE: {
    PAT: "PAT",
    PAC: "PAC",
    CAJA: "CAJA",
    EXCESS: "EXCESO",
  },
};

export default constants;
