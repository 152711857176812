/* istanbul ignore file */
import { Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import { useDropzone } from "react-dropzone";
import IconSubir from "../../assets/icons/cloud.svg";
import { useState, useEffect } from "react";
import { FileHeader } from "./fileHeader";
import "./styles.css";
import { dragUpload } from "../typography/styles";
import { Box } from "@mui/system";

export function DragAndDrop({
  files,
  maxFiles,
  setFiles,
  period,
  fileType,
  count,
  failed,
  setSelectDisabled,
  setUploadDisabled,
  dropDisabled,
  uploadInfo,
  uploadState,
}) {
  const [errorDocs, setErrorDocs] = useState(null);
  const [hover, setHover] = useState(false);

  const onDrop = (accFiles, rejFiles) => {
    var temp = files;
    var tempError = errorDocs;

    accFiles.forEach((file) => {
      if (!temp) {
        temp = [];
        temp.push({ file });
      } else if (temp.length < maxFiles) {
        temp.push({ file });
      } else if (temp.length >= maxFiles) {
        let errors = [];
        let errorsB = {
          code: "too-many-files",
          message: "Limite de archivos superado",
        };
        errors.push(errorsB);
        if (!tempError) {
          tempError = [];
          tempError.push({ errors, file });
        } else {
          tempError.push({ errors, file });
        }
      }
    });
    setFiles(temp);
    rejFiles.forEach((rechazado) => {
      if (!tempError) {
        tempError = [];
        tempError.push({ ...rechazado });
      } else {
        tempError.push({ ...rechazado });
      }
    });
    setErrorDocs(tempError);
    setHover(false);
  };

  function Delete(file) {
    const listArchivos = files.filter((fw) => fw !== file);
    if (listArchivos.length === 0) {
      setFiles(null);
    } else {
      setFiles(listArchivos);
    }
  }

  function Drop(item) {
    const listErrores = errorDocs.filter((fw) => fw !== item);
    setErrorDocs(listErrores);
  }

  function uniqueNameValidator(file) {
    if (files) {
      const fileNames = files.map((file) => file.file.name);
      if (fileNames.includes(file.name)) {
        return {
          code: "file-exists",
          message: "El archivo ya existe",
        };
      }
    }
    return null;
  }

  function validateFileType(file) {
    let fileName = file?.name?.substring(0, 2).toLowerCase();
    if (fileType === 1 && fileName === "pl") {
      return null;
    }
    if (fileType === 2 && fileName === "aa") {
      return null;
    }
    return {
      code: "file-type",
      message: "El archivo no corresponde al tipo de carga",
    };
  }

  function validateFilePeriod(file) {
    let currentPeriod = period[2] + period[1];
    let filePeriod = file?.name?.substring(8, 14);
    if (filePeriod === currentPeriod) {
      return null;
    } else {
      return {
        code: "file-period",
        message: "Periodo incorrecto",
      };
    }
  }

  function noEmptySpaceValidator(file) {
    if (file.name.includes(" ")) {
      return {
        code: "file-space",
        message: "El nombre del archivo no puede contener espacios",
      };
    }
    return null;
  }

  const {
    getRootProps,
    // getInputProps
  } = useDropzone({
    onDrop,
    // maxFiles: maxFiles,
    disabled: dropDisabled,
    accept: {
      "text/plain": [".txt"],
    },
    onDragEnter: () => {
      setHover(true);
    },
    onDragLeave: () => {
      setHover(false);
    },
    // maxSize: 5000 * 1024, // 5MB
    maxSize: 4000 * 1024, // 4MB
    validator: (file) => {
      const error = uniqueNameValidator(file);
      if (error) {
        return error;
      }
      const error2 = validateFileType(file);
      if (error2) {
        return error2;
      }
      const error3 = validateFilePeriod(file);
      if (error3) {
        return error3;
      }
      const error4 = noEmptySpaceValidator(file);
      if (error4) {
        return error4;
      }
      return null;
    },
  });

  useEffect(() => {
    if (files?.length > 0 || errorDocs?.length > 0) {
      setSelectDisabled(true);
    } else {
      setSelectDisabled(false);
    }
    if (errorDocs?.length > 0) {
      setUploadDisabled(true);
    } else {
      setUploadDisabled(false);
    }
    // eslint-disable-next-line
  }, [onDrop]);

  return (
    <Fragment>
      {!uploadState && (
        <Grid item p="0">
          <div style={{ paddingTop: '22px', paddingBottom: '16px' }}
            {...getRootProps({
              className:
                (maxFiles && files?.length === maxFiles) || dropDisabled
                  ? "DragDropBackgroundDisabled"
                  : hover
                    ? "DragDropBackgroundOnDrag"
                    : "DragDropBackground",
            })}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              p="0"
            >
              <Grid xs={12}>
                <img src={IconSubir} alt="img iconSubir" />
              </Grid>
              <Grid xs={12}>
                <label
                  className={
                    (maxFiles && files?.length === maxFiles) || dropDisabled
                      ? "seleccionaLabelDisabled"
                      : "seleccionaLabel"
                  }
                >
                  Arrastra y suelta los archivos o{" "}
                </label>
                <label
                  className={
                    (maxFiles && files?.length === maxFiles) || dropDisabled
                      ? "arrastraLabelDisabled"
                      : "arrastraLabel"
                  }
                >
                  búscalos en tu ordenador
                </label>
              </Grid>
              <Grid xs={12}>
                <label className="drop">
                  Formatos compatibles: TXT. Peso máximo de 4MB
                </label>
              </Grid>
            </Grid>
          </div>
        </Grid>
      )
      }
      {
        (files?.length > 0 || errorDocs?.length > 0) && (
          <>
            <Grid
              pt="22px"
              xs={12}
              textAlign="left"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
              }}
            >
              <Typography sx={dragUpload} pb="8px">
                {uploadState ? (
                  <>
                    Cargando {count + failed}/{files?.length || 0}
                  </>
                ) : (
                  <>
                    Por cargar{" "}
                    {(files?.length || 0) + (errorDocs?.length || 0) || 0}/
                    {files?.length || 0}
                  </>
                )}
              </Typography>
              <Typography variant="h3" pb="8px">
                Archivos fallidos: {failed}
              </Typography>
            </Grid>
            <Box
              className="boxFiles"
              overflow="scroll"
              maxHeight={uploadState ? "320px" : "160px"}
            >
              {files?.map((fileWrapper, index) => (
                <Grid item key={index}>
                  <FileHeader
                    onDelete={Delete}
                    object={fileWrapper}
                    error={false}
                    fileType={fileType}
                    period={period}
                    uploadInfo={uploadInfo}
                  />
                </Grid>
              ))}
              {errorDocs?.map((fileWrapper, index) => (
                <Grid item key={index}>
                  <FileHeader onDelete={Drop} object={fileWrapper} error={true} />
                </Grid>
              ))}
            </Box>
          </>
        )
      }
    </Fragment >
  );
}
