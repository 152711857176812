import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import icon_warningOrange from "src/assets/icons/icon_warningOrange.svg";
import {
  CustomModal,
  CustomModalTitle,
} from "src/components/modals/customModal";
import { modalTitle } from "src/components/typography/styles";
import { REVERSE_SURPLUS_MOVEMENT } from "src/services/graphql/mutations";
import { useMutation } from "@apollo/client";
import constants from "src/constants/constants";
import { capitalCase } from "src/utils/stringUtils";
import { SNACK_ACTION_TYPE } from "src/reducers/snackInfoReducer";

const { SET_SNACK_ERROR, SET_SNACK_SUCCESS } = SNACK_ACTION_TYPE;
const {
  COMPONENTS: {
    BUTTONS: { CONFIRM, CANCEL },
  },
  QUERIES: {
    CODE: { VALIDATION_FAILED },
    FETCH_POLICY: { NETWORK_ONLY },
  },
  SNACKBAR_MESSAGE: {
    EXCESS_MOVEMENT: { REVERSE_ERROR, REVERSE_SUCCESS },
  },
} = constants;

export const ConfirmModal = ({
  onOpen,
  onClose,
  // setRefresh,
  setSnackInfoParent,
  info,
  refreshOnError,
  handleEditClose,
}: any) => {
  const [reverseMovement, { loading }] = useMutation(REVERSE_SURPLUS_MOVEMENT, {
    fetchPolicy: NETWORK_ONLY as any,
    onCompleted: () => {
      setSnackInfoParent({
        type: SET_SNACK_SUCCESS,
        payload: REVERSE_SUCCESS,
      });
      //refresh page to update last confirmed period (not necessarily an error)
      refreshOnError();
      onClose();
    },
    onError: (error: any) => {
      let { message, code } = error?.graphQLErrors[0] || {};
      if (VALIDATION_FAILED === code) {
        setSnackInfoParent({
          type: SET_SNACK_ERROR,
          payload: message,
        });
        refreshOnError();
        handleEditClose();
      } else {
        setSnackInfoParent({
          type: SET_SNACK_ERROR,
          payload: REVERSE_ERROR,
        });
      }
      onClose();
    },
  });

  const handleConfirm = () => {
    reverseMovement({
      variables: {
        input: {
          reverseId: info.id,
        },
      },
    });
  };

  const confirmTitle = () => {
    return <Typography sx={modalTitle}>Reversar movimiento</Typography>;
  };

  const confirmContent = () => {
    return (
      <div style={{ textAlign: "center", paddingTop: "19px" }}>
        <label className="firstTextModal">
          Estás reversando el movimiento:
        </label>{" "}
        <br></br>
        <label className="blueModalText">
          {capitalCase(info.amountType)}, {capitalCase(info.movementType)},
          monto de ${info.amount}
        </label>
        <br></br>
        <label className="boldModalText">
          {" "}
          ¿Estás seguro de continuar con esta acción?
        </label>
      </div>
    );
  };

  return (
    <>
      <CustomModal
        fullWidth
        maxWidth="sm"
        open={onOpen}
        onClose={onClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <CustomModalTitle id="warning" onClose={onClose}>
          {confirmTitle()}
        </CustomModalTitle>
        {
          <DialogContent
            dividers
            sx={{
              textAlign: "center",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <img
              alt="img"
              src={icon_warningOrange}
              style={{ marginTop: "3px" }}
            />
            {confirmContent()}
          </DialogContent>
        }

        <DialogActions sx={{ textAlign: "center" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {loading ? (
              <Button
                color="primary"
                sx={{ height: "50px", width: "119.72px" }}
              >
                <div className="spinnerButton"></div>
              </Button>
            ) : (
              <Button color="primary" onClick={() => handleConfirm()}>
                {CONFIRM}
              </Button>
            )}
            <Button
              color="secondary"
              onClick={onClose}
              sx={{ marginLeft: "24px" }}
            >
              {CANCEL}
            </Button>
          </Grid>
        </DialogActions>
      </CustomModal>
    </>
  );
};
