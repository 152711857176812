import { useEffect, useReducer, useState } from "react";
import { UPDATE_PAYMENT } from "src/services/graphql/mutations";
import { useMutation } from "@apollo/client";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { DatePicker } from "src/components/datePicker/datePicker";
import {
  blackBold,
  detailText,
  errorText,
  littleTitle,
  modalSubtitle,
  purpleLittle,
} from "src/components/typography/styles";
import {
  AlertSnackbar,
  CustomInput,
  CustomModal,
  CustomModalTitle,
  CustomSelect,
} from "src/components";
import {
  convertUTCDateToLocalDate,
  createPeriodList,
  getCurrentPeriod,
} from "src/utils/dates";
import {
  SNACK_ACTION_TYPE,
  SNACKINFO_INITIAL_STATE,
  snackInfoReducer,
} from "src/reducers/snackInfoReducer";
import constants from "src/constants/constants";
import moment from "moment";
import { findOption } from "src/utils/arrayUtils";

const { CLOSE_SNACK_INFO, SET_SNACK_ERROR, SET_SNACK_SUCCESS } =
  SNACK_ACTION_TYPE;
const {
  COMPONENTS: {
    BUTTONS: { EDIT, CANCEL },
  },
  DATE: { UPDATE_FORMAT },
  SNACKBAR_MESSAGE: { PAYMENTS_UPDATE_ERROR, PAYMENTS_UPDATE_SUCCESS },
} = constants;

type EditPaymentProps = {
  onOpen: boolean;
  handleClose: () => void;
  info: {
    id: number | string;
    contributorName: string;
    contributorRut: string;
    payerName: string;
    payerRut: string;
    amount: number;
    paymentDate: string;
    paymentPeriod: string;
    paymentType: string;
  };
  originOptions: any;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditPayment = ({
  onOpen,
  handleClose,
  info,
  setRefresh,
  originOptions,
}: EditPaymentProps) => {
  const {
    id,
    contributorName,
    contributorRut,
    payerName,
    payerRut,
    amount,
    paymentDate,
    paymentPeriod,
    paymentType,
  } = info;
  const periodList = createPeriodList(true);
  const [inputPaymentAmount, setInputPaymentAmount] = useState<any>(
    amount || 0
  );
  const [originId, setOriginId] = useState<number>(0);
  const [paymentDateAux, setPaymentDateAux] = useState<any>(null);
  const [selectPeriod, setSelectPeriod] = useState<any>({
    value: findOption(periodList, paymentPeriod),
    name: paymentPeriod,
  });
  const [snackInfoState, snackInfoDispatch] = useReducer(
    snackInfoReducer,
    SNACKINFO_INITIAL_STATE
  );
  const [initialValues, setInitialValues] = useState<any>({
    paymentAmount: amount,
    paymentDate: paymentDate,
    paymentType: paymentType,
    paymentPeriod: paymentPeriod,
  });
  const [inputErrors, setInputErrors] = useState<any>({
    paymentAmount: "",
    paymentDate: "",
  });

  const [updatePayment, { loading }] = useMutation(UPDATE_PAYMENT, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      setRefresh(true);
      snackInfoDispatch({
        type: SET_SNACK_SUCCESS,
        payload: PAYMENTS_UPDATE_SUCCESS,
      });
      handleClose();
    },
    onError: () => {
      snackInfoDispatch({
        type: SET_SNACK_ERROR,
        payload: PAYMENTS_UPDATE_ERROR,
      });
      handleClose();
    },
  });

  const findOriginString = (id: number) => {
    let originType = "";
    if (originOptions) {
      originOptions.forEach((element: any, index: number) => {
        if (index + 1 === id) {
          originType = element;
        }
      });
    }
    return originType;
  };

  const findOriginId = (paymentType: any) => {
    if (originOptions) {
      originOptions.forEach((element: any, index: number) => {
        if (element === paymentType) {
          setOriginId(index + 1);
        }
      });
    }
  };

  const handleAmountChange = (value: any) => {
    const regex = /^\d{1,9}?$/;
    value = value.replace(/^0+/, "");
    if (regex.test(value)) {
      setInputPaymentAmount(value);
      if (inputErrors.paymentAmount) {
        setInputErrors({
          ...inputErrors,
          paymentAmount: "",
        });
      }
    }
    if (value === "") {
      setInputPaymentAmount(0);
      setInputErrors({
        ...inputErrors,
        paymentAmount: "Monto de pago debe ser mayor a 0.",
      });
    }
  };

  const handleOriginId = (value: any) => {
    // eslint-disable-next-line eqeqeq
    if (value != originId) {
      setOriginId(value);
    }
  };

  const handleDateChange = (date: any) => {
    if (date) {
      setPaymentDateAux(convertUTCDateToLocalDate(date));
      if (inputErrors.paymentDate) {
        setInputErrors({
          ...inputErrors,
          paymentDate: "",
        });
      }
    } else {
      setPaymentDateAux(null);
      setInputErrors({
        ...inputErrors,
        paymentDate: "Fecha de pago es requerida.",
      });
    }
  };

  const handleSave = () => {
    let parsedPayment = parseInt(inputPaymentAmount);
    updatePayment({
      variables: {
        input: {
          id: id as number,
          amount:
            parsedPayment !== initialValues?.paymentAmount
              ? parsedPayment
              : null,
          paymentDate: moment(paymentDateAux).format(UPDATE_FORMAT),
          paymentType: findOriginString(originId),
          paymentPeriod: selectPeriod.name,
        },
      },
    });
  };

  const fillPeriodOptions = (data: Array<string>) => {
    return data.map((element: string) => {
      return {
        nombre: getCurrentPeriod(element)[0],
      };
    });
  };

  const checkPaymentInput = () => {
    if (inputErrors.paymentAmount === "") {
      if (
        parseInt(inputPaymentAmount) !== initialValues?.paymentAmount &&
        inputPaymentAmount !== 0
      ) {
        return true;
      }
    }
    return false;
  };

  const checkOriginInput = () => {
    if (findOriginString(originId) !== initialValues?.paymentType) {
      return true;
    }
    return false;
  };

  const checkDateInput = () => {
    if (inputErrors.paymentDate === "") {
      if (
        moment(paymentDateAux).format("DD/MM/YYYY") !==
        paymentDate?.slice(0, 10)
      ) {
        return true;
      }
    }
    return false;
  };

  const checkPeriod = () => {
    return selectPeriod.name !== paymentPeriod;
  };

  const checkValidInputs = () => {
    let validPaymentAmount = inputPaymentAmount !== 0;
    let validDate = paymentDateAux !== null;
    return Boolean(validPaymentAmount && validDate);
  };

  const buttonDisabled = () => {
    return !(
      (checkDateInput() ||
        checkOriginInput() ||
        checkPaymentInput() ||
        checkPeriod()) &&
      checkValidInputs()
    );
  };

  useEffect(() => {
    if (onOpen === true) {
      if (amount) {
        setInputPaymentAmount(amount);
      }
      if (paymentType) {
        findOriginId(paymentType);
      }
      if (paymentDate) {
        let aux = paymentDate.slice(0, 10).split("/");
        setPaymentDateAux(
          convertUTCDateToLocalDate(aux[1] + "-" + aux[0] + "-" + aux[2])
        );
      }
      if (paymentPeriod) {
        setSelectPeriod({
          value: findOption(periodList, paymentPeriod),
          name: paymentPeriod,
        });
      }
      setInitialValues({
        paymentAmount: amount,
        paymentDate: paymentDate,
        paymentType: paymentType,
        paymentPeriod: paymentPeriod,
      });
      setInputErrors({
        paymentAmount: "",
        paymentDate: "",
      });
    } else {
      setInitialValues({
        paymentAmount: null,
        paymentDate: null,
        paymentType: null,
        paymentPeriod: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen]);

  return (
    <>
      <AlertSnackbar
        onClick={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        onClose={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        open={snackInfoState.open}
        status={snackInfoState.status}
        message={snackInfoState.message}
      />
      <CustomModal
        maxWidth="md"
        open={onOpen}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <CustomModalTitle id="addCredits" onClose={handleClose}>
          <Typography sx={littleTitle} textAlign="center" pt="32px">
            Edición para recaudación PAC, PAT y Caja
          </Typography>
          <Typography sx={modalSubtitle} textAlign="center">
            Modifica los campos necesarios para actualizar la información.
          </Typography>
        </CustomModalTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="column"
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid sx={{ margin: "0", width: "100%" }} container spacing={2}>
              <Grid item xs={6} pr="0 !important">
                <Typography sx={purpleLittle} pb="4px">
                  Nombre y RUT del cliente
                </Typography>
                <Typography sx={blackBold} pt="4px">
                  {contributorName + " " + contributorRut}
                </Typography>
              </Grid>
              <Grid item xs={6} pr="0 !important">
                <Typography sx={purpleLittle} pb="4px">
                  Nombre y RUT pagador
                </Typography>
                <Typography sx={blackBold} pt="4px">
                  {payerName + " " + payerRut}
                </Typography>
              </Grid>
              <Grid item xs={6} pr="0 !important">
                <Typography sx={detailText} pb="6px">
                  Origen de pago
                </Typography>
                <CustomSelect
                  value={originId}
                  hideTitle={true}
                  onChange={(e: any) => handleOriginId(e.target.value)}
                  data={originOptions}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography sx={detailText} pb="6px">
                  Monto de pago
                </Typography>
                <CustomInput
                  fullWidth
                  value={inputPaymentAmount}
                  onChange={(e) => handleAmountChange(e.target.value)}
                  inputProps={{
                    "data-testid": "input-pago",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                {inputErrors.paymentAmount && (
                  <Typography sx={errorText}>
                    {inputErrors.paymentAmount}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} pr="0 !important">
                <Typography sx={detailText} pb="6px">
                  Período de remuneración
                </Typography>
                <CustomSelect
                  value={selectPeriod.value}
                  hideTitle={true}
                  onChange={(e: any) => {
                    setSelectPeriod({
                      value: e.target.value,
                      name: periodList[e.target.value - 1],
                    });
                  }}
                  data={fillPeriodOptions(periodList)}
                />
              </Grid>
              <Grid item xs={6} pb="0 !important" pr="0 !important">
                <Typography sx={detailText} pb="6px">
                  Fecha de pago
                </Typography>
                <DatePicker
                  value={paymentDateAux}
                  maxDate={new Date()}
                  onChange={(date: any) => {
                    handleDateChange(date);
                  }}
                  label={""}
                />
                {inputErrors.paymentDate && (
                  <Typography sx={errorText}>
                    {inputErrors.paymentDate}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              onClick={handleClose}
              color="secondary"
              sx={{ width: "150px" }}
            >
              {CANCEL}
            </Button>
            {loading ? (
              <Button
                color="primary"
                sx={{ height: "50px", minWidth: "150px" }}
              >
                <div className="spinnerButton"></div>
              </Button>
            ) : (
              <Button
                onClick={handleSave}
                sx={{ width: "150px" }}
                disabled={buttonDisabled()}
              >
                {EDIT}
              </Button>
            )}
          </Grid>
        </DialogActions>
      </CustomModal>
    </>
  );
};

export default EditPayment;
