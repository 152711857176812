import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import ChangeLogTable from "../tables/changeLogTable";
import { littleTitle, modalSubtitle } from "src/components/typography/styles";

type ChangeLogModalProps = {
  onOpen: boolean;
  onClose: () => void;
  id: string;
  logTypeId: number;
};

const ChangeLogModal = ({
  onOpen,
  onClose,
  id,
  logTypeId,
}: ChangeLogModalProps) => {
  return (
    <Dialog
      maxWidth="md"
      open={onOpen}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <DialogTitle>
        <Typography sx={littleTitle} textAlign="center" pt="32px">
          Historial de cambios
        </Typography>
        <Typography sx={modalSubtitle} textAlign="center">
          A continuación se muestra una lista de todas las modificaciones
          realizadas en este registro.
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          sx={{
            margin: "0",
            width: "100%",
            border: "1px solid #E9EBEC",
            borderRadius: "8px",
          }}
          container
          spacing={2}
        >
          <Grid xs={12}>
            <ChangeLogTable id={id} logTypeId={logTypeId} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", padding: "16px" }}>
        <Button color="secondary" sx={{ width: "150px" }} onClick={onClose}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeLogModal;
