import { GET_EXCESS_MOVEMENT_TYPES } from "src/services/graphql/queries";
import { useLazyQuery } from "@apollo/client";

export const useMovement = () => {
  const [
    getMovementTypes,
    {
      data: {
        period: {
          distribution: {
            movementType: { allBy: movementTypes },
          },
        },
      } = { period: { distribution: { movementType: { allBy: [] } } } },
      loading: loadingMovementTypes,
    },
  ] = useLazyQuery(GET_EXCESS_MOVEMENT_TYPES, {
    fetchPolicy: "network-only",
  });

  const movementTypesBy = (specificType: number) => {
    getMovementTypes({
      variables: {
        input: {
          specificType,
        },
      },
    });
  };

  return {
    movementTypesBy,
    movementTypes,
    loadingMovementTypes,
  };
};
