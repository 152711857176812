import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableCell } from "src/components/tables/tableStyle";
import {
  blackBold,
  errorTextSmall,
  littleDetail,
  littleTitle,
  modalSubtitle,
  purpleLittle,
  titleTextBox,
} from "src/components/typography/styles";
import {
  CustomModal,
  CustomModalTitle,
} from "src/components/modals/customModal";
import { CustomInputSlim } from "src/components/input/customInput";
import { DatePicker } from "src/components/datePicker/datePicker";
import { CustomSelect } from "src/components/Select/CustomSelect";
import { useEffect, useState } from "react";
import constants from "src/constants/constants";
import {
  convertUTCDateToLocalDate,
  getCurrentPeriod,
  lastDayOfMonth,
} from "src/utils/dates";
import { CREATE_SURPLUS_MOVEMENT } from "src/services/graphql/mutations";
import { useMutation } from "@apollo/client";
import { capitalCase } from "src/utils/stringUtils";
import moment from "moment";
import { SNACK_ACTION_TYPE } from "src/reducers/snackInfoReducer";
import { useMovement } from "../hook/useMovement";

const { SET_SNACK_ERROR, SET_SNACK_SUCCESS } = SNACK_ACTION_TYPE;
const {
  COMPONENTS: {
    BUTTONS: { CANCEL, SAVE },
  },
  DATE: { UPDATE_FORMAT },
  EXCESS: {
    MOVEMENT_TYPE: { CREDIT, REVERSE },
  },
  QUERIES: {
    CODE: { VALIDATION_FAILED },
    FETCH_POLICY: { NETWORK_ONLY },
  },
  SNACKBAR_MESSAGE: {
    EXCESS_MOVEMENT: { CREATE_ERROR, CREATE_SUCCESS },
  },
} = constants;

type AddMovementProps = {
  onOpen: boolean;
  handleCloseModal: () => void;
  info: {
    contributorRut: string;
    contributorName: string;
    collectionPeriod: string;
    paymentPeriod: string;
    availableBalance: number;
  };
  distributionType: number;
  setRefresh: (value: boolean) => void;
  setSnackInfo: React.Dispatch<React.SetStateAction<any>>;
  refreshOnError: () => void;
};

const AddMovement = ({
  onOpen,
  handleCloseModal,
  info,
  distributionType,
  // setRefresh,
  setSnackInfo,
  refreshOnError,
}: AddMovementProps) => {
  const {
    contributorRut,
    contributorName,
    collectionPeriod,
    paymentPeriod,
    availableBalance,
  } = info || {};
  const [date, setDate] = useState<any>(null);
  const [amountType, setAmountType] = useState(0);
  const [movementType, setMovementType] = useState(0);
  const [amount, setAmount] = useState<number>(0);
  const [movementTypeOptions, setMovementTypeOptions] = useState<any>([]);
  const [contextInfo, setContextInfo] = useState({
    type: "",
    period: "",
  });

  const { movementTypesBy, movementTypes, loadingMovementTypes } =
    useMovement();

  const findMovementTypeName = (value: number) => {
    let name = "";
    if (amountType !== 0 && movementTypeOptions[amountType - 1].length > 0) {
      movementTypeOptions[amountType - 1].forEach(
        (item: any, index: number) => {
          if (index === value - 1) {
            name = item.name;
          }
        }
      );
      return name;
    }
  };

  const [addSurplusMovement, { loading }] = useMutation(
    CREATE_SURPLUS_MOVEMENT,
    {
      fetchPolicy: NETWORK_ONLY as any,
      variables: {
        input: {
          contributorRut: contributorRut,
          movementType: findMovementTypeName(movementType),
          movementDate: moment(convertUTCDateToLocalDate(date)).format(
            UPDATE_FORMAT
          ),
          amount: amount,          
        },
      },
      onCompleted: () => {
        // setRefresh(true);
        //refresh full page to update last confirmed period (not necessarily an error)
        refreshOnError();
        setSnackInfo({
          type: SET_SNACK_SUCCESS,
          payload: CREATE_SUCCESS,
        });
        handleClose();
      },
      onError: (error: any) => {
        let { message, code } = error?.graphQLErrors[0] || {};
        if (VALIDATION_FAILED === code) {
          setSnackInfo({
            type: SET_SNACK_ERROR,
            payload: message.split(".")[0],
          });
          refreshOnError();
        } else {
          setSnackInfo({
            type: SET_SNACK_ERROR,
            payload: CREATE_ERROR,
          });
        }
        handleClose();
      },
    }
  );

  const generateListByType = (data: any[]) => {
    const [listAbono, listCargo] = data.reduce<[any[], any[]]>(
      ([abono, cargo], item) =>
        item.amountType === CREDIT
          ? [[...abono, item], cargo]
          : [abono, [...cargo, item]],
      [[], []]
    );
    const filterListCargo = listCargo.filter((item) => item.name !== REVERSE);
    setMovementTypeOptions([listAbono, filterListCargo]);
  };

  const handleClose = () => {
    setDate(null);
    setAmountType(0);
    setMovementType(0);
    setAmount(0);
    handleCloseModal();
  };

  const handleInputChange = (e: any) => {
    let value = e.target.value;
    const regex = /^\d+$/;
    value = value.replace(/^0+/, "");
    if (regex.test(value)) {
      setAmount(parseInt(value));
    } else if (value === "") {
      setAmount(0);
    }
  };

  const isSaveDisabled = () => {
    return (
      amountType === 0 ||
      movementType === 0 ||
      amount === 0 ||
      date === null ||
      (distributionType === 1 &&
        amountType === 2 &&
        amount > info.availableBalance) ||
      (distributionType === 2 &&
        amountType === 1 &&
        amount > info.availableBalance)
    );
  };

  useEffect(() => {
    onOpen && distributionType === 1 && movementTypesBy(2);
    onOpen &&
      distributionType === 2 &&
      setMovementTypeOptions([
        [
          {
            name: "USO EN COTIZACION",
            amountType: "CARGO",
          },
        ],
      ]);
    setContextInfo({
      type: distributionType === 1 ? "recaudación" : "remuneración",
      period: distributionType === 1 ? collectionPeriod : paymentPeriod,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen]);

  useEffect(() => {
    if (movementTypes.length > 0) generateListByType(movementTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movementTypes]);

  return (
    <CustomModal
      maxWidth="md"
      fullWidth
      open={onOpen}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <CustomModalTitle id="" onClose={handleClose}>
        <Typography sx={littleTitle} textAlign="center" pt="32px">
          Nuevo movimiento
        </Typography>
        <Typography sx={modalSubtitle} textAlign="center">
          Aquí puedes agregar un movimiento por periodo para un RUT.
        </Typography>
      </CustomModalTitle>
      <DialogContent dividers sx={{ p: "0 !important" }}>
        <Grid container p={"18px 24px"}>
          <Grid item xs={4}>
            <Typography sx={purpleLittle} pb="4px">
              Nombre y RUT cliente
            </Typography>
            <Typography sx={blackBold} pt="4px">
              {contributorName} {contributorRut}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={purpleLittle} pb="4px">
              Período {contextInfo.type}
            </Typography>
            <Typography sx={blackBold} pt="4px">
              {getCurrentPeriod(contextInfo.period as any)[0]}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={purpleLittle} pb="4px">
              Saldo disponible
            </Typography>
            <Typography sx={blackBold} pt="4px">
              ${availableBalance.toLocaleString("es-CL")}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container p={"18px 24px"}>
          <Grid item xs={9}>
            <Typography sx={titleTextBox} pb="4px">
              Movimientos
            </Typography>
            <Typography sx={littleDetail} pt="4px">
              Aquí puedes agregar la información del movimiento.
            </Typography>
          </Grid>
          <Grid item xs={12} pt="24px">
            <TableContainer
              className="grayScroll"
              component={Paper}
              sx={{
                boxShadow: "none",
                borderRadius: "8px",
                border: "0.5px solid #D2D6D9",
                maxHeight: "310px !important",
              }}
            >
              <Table
                sx={{ minWidth: "auto" }}
                aria-label="simple table"
                stickyHeader
              >
                <TableHead className="color-head-table">
                  <TableRow>
                    <StyledTableCell sx={{ width: "187px !important" }}>
                      Fecha movimiento
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "184px !important" }}>
                      Tipo de movimiento
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "260px !important" }}>
                      Detalle
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "240px !important" }}>
                      Monto
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell>
                      <DatePicker
                        slim
                        noClear
                        value={date}
                        onChange={(date: any) => {
                          setDate(date);
                        }}
                        minDate={distributionType === 1 ? null : new Date()}
                        maxDate={
                          distributionType === 1
                            ? new Date()
                            : lastDayOfMonth(collectionPeriod)
                        }
                        label={""}
                        testid="input-date"
                      />
                    </TableCell>
                    <TableCell>
                      <CustomSelect
                        slim
                        onChange={(e: any) => {
                          setAmountType(e.target.value);
                          setMovementType(0);
                        }}
                        hideTitle={true}
                        value={amountType}
                        titulo={"Seleccione"}
                        name="amountType"
                        disabled={loadingMovementTypes ? true : false}
                        data={
                          distributionType === 1
                            ? ["Abono", "Cargo"]
                            : ["Cargo"]
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <CustomSelect
                        slim
                        onChange={(e: any) => {
                          setMovementType(e.target.value);
                        }}
                        hideTitle={true}
                        value={movementType}
                        titulo={"Seleccione"}
                        name="movementType"
                        disabled={amountType === 0 ? true : false}
                        data={movementTypeOptions[amountType - 1]?.map(
                          (item: any) => capitalCase(item.name)
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomInputSlim
                        onChange={handleInputChange}
                        error={
                          amountType === 2 && amount > info.availableBalance
                        }
                        value={amount}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        inputProps={{
                          maxLength: 10,
                          min: 0,
                          "data-testid": "input-amount",
                        }}
                      />
                      {amountType === 2 && amount > info.availableBalance && (
                        <Typography sx={errorTextSmall}>
                          Monto excede saldo disponible
                        </Typography>
                      )}
                      {distributionType === 2 &&
                        amountType === 1 &&
                        amount > info.availableBalance && (
                          <Typography sx={errorTextSmall}>
                            Monto excede saldo disponible
                          </Typography>
                        )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="end"
          alignItems="center"
        >
          <Button
            onClick={handleClose}
            color="secondary"
            sx={{ width: "150px", mr: "16px" }}
          >
            {CANCEL}
          </Button>
          {loading ? (
            <Button color="primary" sx={{ height: "50px", minWidth: "150px" }}>
              <div className="spinnerButton"></div>
            </Button>
          ) : (
            <Button
              onClick={() => {
                addSurplusMovement();
              }}
              color="primary"
              sx={{ width: "150px" }}
              disabled={isSaveDisabled()}
            >
              {SAVE}
            </Button>
          )}
        </Grid>
      </DialogActions>
    </CustomModal>
  );
};
export default AddMovement;
