import { useEffect, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import {
  detailText,
  errorText,
  littleTitle,
  modalSubtitle,
} from "src/components/typography/styles";
import CustomInput from "src/components/input/customInput";
import { CustomSelect } from "src/components/Select/CustomSelect";
import {
  CustomModal,
  CustomModalTitle,
} from "src/components/modals/customModal";
import { formatRut } from "src/utils/stringUtils";
import { handleRunChangeUtils, validateRun } from "src/utils/validationUtils";
import { getCurrentPeriod } from "src/utils/dates";
import { findOption } from "src/utils/arrayUtils";

const workerOptions = [
  {
    id: "1",
    name: "Dependiente",
    smallName: "D",
  },
  {
    id: "2",
    name: "Independiente",
    smallName: "I",
  },
  {
    id: "3",
    name: "Pensionado",
    smallName: "P",
  },
  {
    id: "4",
    name: "Voluntario",
    smallName: "V",
  },
];

const paymentOptions = [
  {
    id: "1",
    name: "Al día",
    value: "14",
  },
  {
    id: "2",
    name: "Atrasada",
    value: "15",
  },
];

const statusOptions = [
  {
    id: "1",
    name: "Pendiente",
    value: "16",
  },
  {
    id: "2",
    name: "Pagada",
    value: "17",
  },
];

const SearchCredits = ({
  onOpen,
  handleClose,
  filters,
  setFilters,
  options,
}: any) => {
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [rut, setRut] = useState({
    run: filters?.contributorRut,
    formattedRun: formatRut(filters?.contributorRut),
    error: "",
    isValid:
      filters?.contributorRut !== null
        ? validateRun(filters?.contributorRut)
        : false,
  });
  const [runValue, setRunValue] = useState(filters?.contributorRut || "");
  const [formattedRun, setFormattedRun] = useState(
    formatRut(filters?.contributorRut) || ""
  );

  const findOptionId = (id: string, data: any) => {
    let optionId = null;
    if (data) {
      data.forEach((element: any) => {
        // eslint-disable-next-line eqeqeq
        if (id == element.value) {
          optionId = element.id;
        }
      });
    }
    return optionId;
  };

  const [statusId, setStatusId] = useState<any>(
    findOptionId(filters?.statusId, statusOptions) || null
  );
  const [paymentStatus, setPaymentStatus] = useState<any>(
    findOptionId(filters?.typeId, paymentOptions) || null
  );
  const [period, setPeriod] = useState<string>(filters?.paymentPeriod || null);
  const [option, setOption] = useState(findOption(options, period));

  const getOptionsPeriod = (dataOptions: any) => {
    let optionsArray: any[] = [];
    if (dataOptions) {
      dataOptions.forEach((element: any) => {
        optionsArray.push(getCurrentPeriod(element)[0]);
      });
    }
    optionsArray.push("Sin selección");
    return optionsArray;
  };

  const handleCloseAux = () => {
    handleClose();
  };

  const findId = (id: string, optionsArr: any) => {
    let optionId = null;
    if (optionsArr) {
      optionsArr.forEach((element: any, index: number) => {
        // eslint-disable-next-line eqeqeq
        if (id == element.id) {
          optionId = index + 1;
        }
      });
    }
    return optionId ? parseInt(optionId) : null;
  };

  const handlePaymentChange = (id: string) => {
    let paymentStatusIdAux = findId(id, paymentOptions);
    // eslint-disable-next-line eqeqeq
    if (paymentStatusIdAux == null) {
      setPaymentStatus(null);
    }
    // eslint-disable-next-line eqeqeq
    else if (id != paymentStatus) {
      setPaymentStatus(id);
    }
  };

  const handleStatusChange = (id: string) => {
    let statusIdAux = findId(id, statusOptions);
    // eslint-disable-next-line eqeqeq
    if (statusIdAux == null) {
      setStatusId(null);
    }
    // eslint-disable-next-line eqeqeq
    else if (id != statusId) {
      setStatusId(id);
    }
  };

  const findWorkerSmallNameId = (str: string) => {
    let id = "";
    if (workerOptions) {
      workerOptions.forEach((item: any) => {
        if (item.smallName === str) {
          id = item.id;
        }
      });
    }
    return id;
  };

  const findFiltersId = (str: string, data: any) => {
    let id = "";
    if (data) {
      data.forEach((item: any) => {
        // eslint-disable-next-line eqeqeq
        if (item.id == str) {
          id = item.value;
        }
      });
    }
    return id ? parseInt(id) : null;
  };

  const getWorkerOptions = () => {
    let optionsArray: any[] = [];
    if (workerOptions) {
      workerOptions.forEach((element: any) => optionsArray.push(element));
    }
    optionsArray.push({ smallName: "", name: "Sin selección" });
    return optionsArray;
  };

  const getOptions = (data: any) => {
    let optionsArray: any[] = [];
    if (data) {
      data.forEach((element: any) => optionsArray.push(element));
    }
    optionsArray.push({ name: "Sin selección" });
    return optionsArray;
  };

  const [workerTypeId, setWorkerTypeId] = useState(
    findWorkerSmallNameId(filters?.workerType) || null
  );

  const handleWorkerTypeId = (id: string) => {
    let workerTypeIdAux = findId(id, workerOptions);
    // eslint-disable-next-line eqeqeq
    if (workerTypeIdAux == null) {
      setWorkerTypeId(null);
    }
    // eslint-disable-next-line eqeqeq
    else if (id != workerTypeId) {
      setWorkerTypeId(id);
    }
  };

  const handleRunChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    let checkRun = handleRunChangeUtils(value);
    if (checkRun.runValue !== "" || value.length === 0) {
      setRunValue(checkRun.runValue);
      setFormattedRun(checkRun.formattedRun);
      setRut({
        run: checkRun.formattedRun ? checkRun.runValue : null,
        formattedRun: checkRun.formattedRun,
        error: checkRun.error,
        isValid: checkRun.isValid,
      });
    }
  };

  const findWorkerTypeSmallName = (id: any) => {
    let smallName = "";
    if (workerOptions) {
      workerOptions.forEach((item: any) => {
        if (item.id.toString() === id.toString()) {
          smallName = item.smallName;
        }
      });
    }
    return smallName;
  };

  const checkValidRut = (data: any) => {
    if (data.error === "") {
      if (data.run !== "" && data.isValid) {
        return true;
      }
      if (data.run === "" || data.run === null) {
        return true;
      }
      if (data.run === filters.contributorRut) {
        return true;
      }
    }
    return false;
  };

  const checkAnyInputUpdated = () => {
    let updatedRut = filters?.contributorRut !== rut.run;
    let updatedPeriod = filters?.paymentPeriod !== period;
    let updatedStatusId =
      filters?.statusId !== findFiltersId(statusId, statusOptions);
    let updatedWType =
      findWorkerSmallNameId(filters?.workerType) !== (workerTypeId || "");
    let updatedTypeId =
      filters?.typeId !== findFiltersId(paymentStatus, paymentOptions);

    let inputUpdated = Boolean(
      updatedRut ||
        updatedPeriod ||
        updatedStatusId ||
        updatedWType ||
        updatedTypeId
    );
    return inputUpdated;
  };

  const checkAllInputsEmpty = () => {
    let updatedRut = rut.run === "" || rut.run === null;
    let updatedPeriod = !period;
    let updatedStatusId = !statusId;
    let updatedTypeId = !paymentStatus;
    let emptyWType = !workerTypeId;
    let allInputsEmpty = Boolean(
      updatedRut &&
        updatedPeriod &&
        updatedStatusId &&
        updatedTypeId &&
        emptyWType
    );
    return allInputsEmpty;
  };

  const handleValidFilters = () => {
    setDisableButton(
      !(checkValidRut(rut) && checkAnyInputUpdated() && !checkAllInputsEmpty())
    );
  };

  const handleFilter = () => {
    setFilters({
      ...filters,
      contributorRut:
        rut.run !== null ? rut.formattedRun.replace(/\./g, "") : null,
      paymentPeriod: period,
      statusId: statusId ? findFiltersId(statusId, statusOptions) : null,
      typeId: paymentStatus
        ? findFiltersId(paymentStatus, paymentOptions)
        : null,
      workerType: workerTypeId ? findWorkerTypeSmallName(workerTypeId) : null,
    });
    handleClose();
  };

  useEffect(() => {
    if (filters !== undefined) handleValidFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rut, period, statusId, paymentStatus, workerTypeId, onOpen]);

  return (
    <CustomModal
      maxWidth="md"
      fullWidth
      open={onOpen}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <CustomModalTitle id="addCredits" onClose={handleCloseAux}>
        <Typography sx={littleTitle} textAlign="center" pt="32px">
          Buscar acreencias
        </Typography>
        <Typography sx={modalSubtitle} textAlign="center">
          Seleccione los criterios de búsqueda para encontrar registros de
          acreencias específicos.
        </Typography>
      </CustomModalTitle>
      <DialogContent dividers>
        <Grid
          container
          direction="column"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid sx={{ margin: "0", width: "100%" }} container spacing={2}>
            <Grid
              item
              xs={6}
              p="0 !important"
              pr="24px !important"
              pb="24px !important"
            >
              <Typography sx={detailText} pb="6px">
                RUT Cliente
              </Typography>
              <CustomInput
                placeholder="Ingresar RUN"
                fullWidth
                value={formattedRun || runValue}
                onChange={handleRunChange}
                inputProps={{
                  "data-testid": "input-cRun",
                }}
              />
              {rut.error && <Typography sx={errorText}>{rut.error}</Typography>}
            </Grid>
            <Grid item xs={6} p="0 !important">
              <Typography sx={detailText} pb="6px">
                Periodo de remuneración
              </Typography>
              <CustomSelect
                onChange={(e: any) => {
                  setOption(e.target.value);
                  setPeriod(options[e.target.value - 1]);
                }}
                value={option}
                hideTitle={true}
                titulo={"Seleccione"}
                data={getOptionsPeriod(options)}
              />
            </Grid>
            <Grid
              item
              xs={6}
              p="0 !important"
              pr="24px !important"
              pb="24px !important"
            >
              <Typography sx={detailText} pb="6px">
                Estado de pago
              </Typography>
              <CustomSelect
                titulo={"Seleccione"}
                hideTitle={true}
                value={statusId}
                onChange={(e: any) => handleStatusChange(e.target.value)}
                data={getOptions(statusOptions).map((elem: any) => elem.name)}
              />
            </Grid>
            <Grid item xs={6} p="0 !important" pb="24px !important">
              <Typography sx={detailText} pb="6px">
                Tipo de trabajador
              </Typography>
              <CustomSelect
                titulo={"Seleccione"}
                hideTitle={true}
                value={workerTypeId}
                onChange={(e: any) => handleWorkerTypeId(e.target.value)}
                data={getWorkerOptions().map(
                  (elem: any) => elem.smallName + " - " + elem.name
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} p="0 !important">
          <Typography sx={detailText} pb="6px">
            Tipo Acreencia
          </Typography>
          <CustomSelect
            titulo={"Seleccione"}
            hideTitle={true}
            value={paymentStatus}
            onChange={(e: any) => handlePaymentChange(e.target.value)}
            data={getOptions(paymentOptions).map((elem: any) => elem.name)}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            onClick={handleCloseAux}
            color="secondary"
            sx={{ width: "150px" }}
          >
            Cancelar
          </Button>
          <Button
            sx={{ width: "150px" }}
            onClick={handleFilter}
            disabled={disableButton}
          >
            Filtrar
          </Button>
        </Grid>
      </DialogActions>
    </CustomModal>
  );
};

export default SearchCredits;
