import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  CustomInput,
  CustomModal,
  CustomModalTitle,
  CustomSelect,
  DatePicker,
} from "src/components";
import {
  blackBold,
  detailText,
  littleTitle,
  modalSubtitle,
  purpleLittle,
} from "src/components/typography/styles";
import constants from "src/constants/constants";
import { convertUTCDateToLocalDate, getCurrentPeriod } from "src/utils/dates";
import useRutSearch from "../hook/useRutSearch";
import { useEffect, useState } from "react";
import { capitalCase } from "src/utils/stringUtils";
import { ExcessMovementInfo } from "../rutSearchTypes";
import moment from "moment";
import { useMovement } from "../hook/useMovement";

const {
  COMPONENTS: {
    BUTTONS: { CANCEL, SAVE },
  },
  DATE: { UPDATE_FORMAT },
  EXCESS: {
    MOVEMENT_TYPE: { BALANCE_TRANSFER, EXCESS },
  },
} = constants;

type AddMovementNewProps = {
  onOpen: boolean;
  handleCloseModal: () => void;
  info: {
    availableBalance: number;
    contributorName: string;
    contributorRut: string;
  };
  setRefresh: (value: boolean) => void;
  setSnackInfo: React.Dispatch<React.SetStateAction<any>>;
};

const AddMovementNew = ({
  onOpen,
  handleCloseModal,
  info,
  setRefresh,
  setSnackInfo,
}: AddMovementNewProps) => {
  const { contributorRut, contributorName, availableBalance } = info;
  const [selectedContract, setSelectedContract] = useState<any>(0);
  const [movementDate, setMovementDate] = useState<any>("");
  const today = new Date();
  const [movementType, setMovementType] = useState<number>(0);
  const [amountType, setAmountType] = useState<number>(1);
  const [amount, setAmount] = useState<number>(0);

  const handleClose = () => {
    handleCloseModal();
    setSelectedContract(0);
    setMovementType(0);
    setMovementDate("");
    setAmount(0);
  };

  const {
    getPendingContractsData,
    allPendingBy,
    loadingPendingContracts,
    AddExcessMovement,
    loadingCreateExcessMovement,
  } = useRutSearch({ setSnackInfo, handleClose, setRefresh });

  const { movementTypesBy, movementTypes, loadingMovementTypes } =
    useMovement();

  const contractsList = (data: any) => {
    if (allPendingBy.length === 0) {
      return [{ id: 0, nombre: "Sin acreencias pendientes" }];
    } else {
      return data.map((contract: any) => {
        return {
          id: contract.id,
          nombre: `${
            getCurrentPeriod(contract.collectionPeriod)[0]
          } - Monto $${contract.dueAmount.toLocaleString("es-CL")}`,
        };
      });
    }
  };

  const filteredExcessMovementTypes = movementTypes.filter(
    (type: any) => type.name !== EXCESS && type.name !== BALANCE_TRANSFER
  );

  const handleAmountChange = (e: any) => {
    const regex = /^\d+$/;
    const value = e.target.value.replace(/^0+/, "");
    if (regex.test(value)) {
      setAmount(parseInt(value));
    } else if (value === "") {
      setAmount(0);
    }
  };

  const handleSave = () => {
    const newMovement: ExcessMovementInfo = {
      movementDate: moment(convertUTCDateToLocalDate(movementDate)).format(
        UPDATE_FORMAT
      ),
      movementType: filteredExcessMovementTypes[movementType - 1]?.name,
      contributorRut: contributorRut,
      contractId: allPendingBy[selectedContract - 1]?.id,
      amount: amount,
    };
    AddExcessMovement(newMovement);
  };

  const isSaveDisabled = () => {
    return (
      amount === 0 ||
      amount > availableBalance ||
      amount > allPendingBy[selectedContract - 1]?.dueAmount ||
      selectedContract === 0 ||
      movementType === 0 ||
      movementDate === ""
    );
  };

  useEffect(() => {
    if (onOpen === true) {
      getPendingContractsData(contributorRut);
      movementTypesBy(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen]);

  useEffect(() => {
    movementTypes.length > 0 && setMovementType(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movementTypes]);

  return (
    <CustomModal
      maxWidth="md"
      fullWidth
      open={onOpen}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <CustomModalTitle id="" onClose={handleCloseModal}>
        <Typography sx={littleTitle} textAlign="center" pt="32px">
          Nuevo movimiento
        </Typography>
        <Typography sx={modalSubtitle} textAlign="center">
          Aquí puedes agregar un movimiento por periodo para un RUT.
        </Typography>
      </CustomModalTitle>
      <DialogContent dividers sx={{ p: "0 !important" }}>
        <Grid container p={"18px 24px"}>
          <Grid item xs={6}>
            <Typography sx={purpleLittle} pb="4px">
              Nombre y RUT cliente
            </Typography>
            <Typography sx={blackBold} pt="4px">
              {contributorName} {contributorRut}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={purpleLittle} pb="4px">
              Saldo disponible
            </Typography>
            <Typography sx={blackBold} pt="4px">
              ${availableBalance.toLocaleString("es-CL")}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          direction="column"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid sx={{ margin: "0", width: "100%" }} container spacing={2}>
            <Grid item xs={6} p="0 !important" px="24px !important">
              <Typography sx={detailText} pb="6px">
                Fecha de movimiento
              </Typography>
              <DatePicker
                noClear
                value={movementDate}
                onChange={(date: any) => setMovementDate(date)}
                minDate={new Date(today.getFullYear(), today.getMonth(), 1)}
                maxDate={today}
                testid="input-date"
              />
            </Grid>
            <Grid item xs={6} p="0 !important" pr="24px !important">
              <Typography sx={detailText} pb="6px">
                Tipo de movimiento
              </Typography>
              <CustomSelect
                onChange={(e: any) => setAmountType(e.target.value)}
                value={amountType}
                titulo={"Seleccione"}
                hideTitle
                data={[{ id: 1, nombre: "Cargo" }]}
              />
            </Grid>
            <Grid item xs={6} p="0 !important" px="24px !important">
              <Typography sx={detailText} pb="6px">
                Detalle
              </Typography>
              <CustomSelect
                onChange={(e: any) => setMovementType(e.target.value)}
                value={movementType}
                titulo={"Seleccione"}
                hideTitle
                data={filteredExcessMovementTypes.map((type: any) => {
                  return {
                    id: type.id,
                    nombre: capitalCase(type.name),
                  };
                })}
                disabled={loadingMovementTypes}
              />
            </Grid>
            <Grid item xs={6} p="0 !important" pr="24px !important">
              <Typography sx={detailText} pb="6px">
                Acreencias a pagar
              </Typography>
              <CustomSelect
                onChange={(e: any) => setSelectedContract(e.target.value)}
                value={selectedContract}
                titulo={
                  allPendingBy.length === 0
                    ? "Sin acreencias pendientes"
                    : "Seleccione"
                }
                data={contractsList(allPendingBy)}
                disabled={loadingPendingContracts || allPendingBy.length === 0}
                hideTitle
              />
            </Grid>
            <Grid
              item
              xs={6}
              p="0 !important"
              px="24px !important"
              pb="24px !important"
            >
              <Typography sx={detailText} pb="6px">
                Monto
              </Typography>
              <CustomInput
                onChange={handleAmountChange}
                error={amount > availableBalance}
                value={amount}
                id="outlined-basic"
                variant="outlined"
                fullWidth
                sx={{ borderRadius: "16px" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 10,
                  min: 0,
                  "data-testid": "input-amount",
                }}
                {...(amount > availableBalance && {
                  helperText:
                    "El monto no puede ser mayor al saldo disponible.",
                  error: true,
                })}
                {...(amount > allPendingBy[selectedContract - 1]?.dueAmount && {
                  helperText:
                    "El monto sobrepasa la deuda de la acreencia a pagar.",
                  error: true,
                })}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="end"
          alignItems="center"
        >
          <Button
            onClick={handleClose}
            color="secondary"
            sx={{ width: "150px", mr: "16px" }}
          >
            {CANCEL}
          </Button>
          {loadingCreateExcessMovement ? (
            <Button color="primary" sx={{ height: "50px", minWidth: "150px" }}>
              <div className="spinnerButton"></div>
            </Button>
          ) : (
            <Button
              onClick={handleSave}
              color="primary"
              sx={{ width: "150px" }}
              disabled={isSaveDisabled()}
            >
              {SAVE}
            </Button>
          )}
        </Grid>
      </DialogActions>
    </CustomModal>
  );
};

export default AddMovementNew;
