import { Box } from "@mui/material";
import GreenCheck from "src/assets/icons/greenCheck.svg";
import RedCancel from "src/assets/icons/cancelRed.svg";

type InfoTextType = {
  title?: string;
  text?: React.JSX.Element
  success?: boolean;
  error?: boolean;
  boldText?: React.JSX.Element;
};

export const InfoText = ({ title, text, success, error, boldText }: InfoTextType) => {
  const className = () => {
    if (success) {
      return "boxInfo";
    }
    return error ? "errorBox" : ""
  }  

  return (    
    <Box className={className()}>
      <div>
        {success ? (
          <img src={GreenCheck} alt="" />
        ) : (
          <img src={RedCancel} alt="" />
        )}
      </div>
      <div style={{ paddingLeft: "10px" }}>
        <label className="textBoldBox">{boldText}</label>
        <label className="titleInfoBox"> {title}</label>
        <br></br>
        <label className="subTextInfoBox "> {text}</label>
      </div>
    </Box>
  );
};
