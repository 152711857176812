import {
  Box,
  Typography,
  Grid,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableBody,
  ThemeProvider,
  // Stack,
  // IconButton,
  Divider,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import {
  Alerts,
  ChangeLogModal,
  CustomSelect,
  RutInput,
  WarningModalCustom,
} from "src/components";
import { boxNoPadding, tableBox } from "src/components/box/styles";
import { paginatorTheme } from "src/components/paginator/paginatorStyle";
import { StyledTableCell } from "src/components/tables/tableStyle";
import { NoResult } from "src/components/tables/noResult";
import { useEffect, useState } from "react";
import { useDevolution } from "../hook/useDevolution";
import { subTable, warningGray } from "src/components/typography/styles";
import { SkeletonTableDevolution } from "./loaderDevolution";
import MenuDevolution from "./MenuDevolution";
import constants from "src/constants/constants";
import ModalUpdateDevolution from "./ModalUpdateDevolution";
// import SearchOverage from "src/pages/distribution/overage/components/searchOverage";
import { devolutionStatus } from "src/utils/selectors";
import MenuExportDevolution from "./MenuExportDevolution";
import { useDevolutionExportable } from "../hook/useDevolutionExportable";

const {
  DEVOLUTION: {
    DEVOLUTION_TYPE: {
      MONTHLY,
      NOT_APPLICABLE,
      ID: {
        MONTHLY: MONTHLY_ID,
        NOT_APPLICABLE: NOT_APPLICABLE_ID,
        ON_DEMAND: ON_DEMAND_ID,
      },
    },
    STATUS: {
      IN_PROCESS,
      PAID,
      REJECTED,
      UNINITIATED,
      ID: { UNINITIATED: UNINITIATED_ID },
    },
  },
  QUERIES: {
    INPUT: {
      LOG_TYPE: {
        UPDATE: { OVERAGE },
      },
    },
  },
  COMPONENTS: {
    BUTTONS: { SEARCH },
    NO_RESULT: {
      ERROR,
      ERROR_SUBTITLE,
      NO_INFO,
      NO_INFO_DEVOLUTION,
      NO_RESULT_RUT,
      NO_RESULT_RUT_SUBTITLE,
    },
  },
} = constants;

const TableDevolution = (props: any) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [formattedRun, setFormattedRun] = useState("");
  const [validRut, setValidRut] = useState(false);
  const [searchRut, setSearchRut] = useState<any>("");
  const [rutFilter, setRutFilter] = useState(false);
  const [sapType, setSapType] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [showHistory, setShowHistory] = useState(false);
  const [showDevolution, setShowDevolution] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [processInfo, setProcessInfo] = useState<any>({
    title: "",
    typeId: 0,
    typeName: "",
  });
  const [rowInfo, setRowInfo] = useState<any>({
    id: "",
    contributorRut: "",
    contributorName: "",
    collectionPeriod: "",
    availableBalance: 0,
    statusName: "",
    typeName: "",
    excessDetail: [],
  });
  const [filters, setFilters] = useState<any>({
    statusId: null,
    typeId: null,
  });
  const [rowSelectedType, setRowSelectedType] = useState<number>(0);
  const {
    getDevolutionData,
    rowsDevolution,
    countDevolution,
    loadingDevolution,
    errorDevolution,
    processDevolution,
    loadingProcess,
    classifyDevolution,
    loadingClassify,
  } = useDevolution({
    setSnackInfo: props.setSnackInfo,
    setRefresh: props.setRefresh,
    handleClose: () => setShowDevolution(false),
    handleCloseWarning: () => setOpenWarning(false),
    devolutionInfo: {
      collectionPeriod: props.collectionPeriod,
      typeId: processInfo.typeId,
    },
    classifyInfo: {
      excessId: rowInfo.id,
      rowTypeId: rowSelectedType,
    },
  });
  const {
    handleClickDevolutionExport,
    handleClickSapExport,
    loadingDevolutionExport,
    loadingSAP,
    inProgress,
  } = useDevolutionExportable({
    setSnackInfo: props.setSnackInfo,
    options: {
      collectionPeriod: props.collectionPeriod,
      contributorRut: searchRut || null,
      statusId: devolutionStatus(filters.statusId),
      typeId: null, //typeId
      sapType: sapType,
    },
  });

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleClickProcess = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleWarningOption = (option: number) => {
    setOpenWarning(true);
    option === 1
      ? setProcessInfo({
          title: "Procesar devolución a demanda",
          typeId: ON_DEMAND_ID,
          typeName: "a demanda",
        })
      : setProcessInfo({
          title: "Procesar devolución mensual",
          typeId: MONTHLY_ID,
          typeName: "mensual",
        });
    setAnchorEl(null);
  };

  const handleDetails = (elem: any, type: number) => {
    setRowInfo({
      id: elem.id,
      contributorRut: elem.contributorRut,
      contributorName: elem.contributorName,
      collectionPeriod: elem.collectionPeriod,
      availableBalance: elem.availableBalance,
      statusName: elem.statusName,
      typeName:
        elem.typeName !== NOT_APPLICABLE
          ? elem.typeName === MONTHLY
            ? MONTHLY_ID
            : ON_DEMAND_ID
          : 0,
      excessDetail: elem.excessDetail,
    });
    setRowSelectedType(
      elem.typeName !== NOT_APPLICABLE ? (elem.typeName === MONTHLY ? 1 : 2) : 0
    );
    if (type === 1) setShowDevolution(true);
    if (type === 2) setShowHistory(true);
  };

  const handleSearch = (rut: string) => {
    const rutInput = rut.replace(/\./g, "");
    setSearchRut(rutInput);
    setRutFilter(rutInput !== "");
    getDevolutionData({
      collectionPeriod: props.collectionPeriod,
      contributorRut: rutInput,
      statusId: devolutionStatus(filters.statusId),
      typeId: null,
      limit: rowsPerPage,
      offset: 0,
    });
    setPage(1);
  };

  const resetAllFilters = () => {
    setRutFilter(false);
    setFilters({ statusId: null, typeId: null });
    setSearchRut("");
  };

  useEffect(() => {
    if (!rutFilter && searchRut === "") {
      setPage(1);
      getDevolutionData({
        collectionPeriod: props.collectionPeriod,
        contributorRut: "",
        statusId: devolutionStatus(filters.statusId),
        typeId: null,
        limit: rowsPerPage,
        offset: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRut]);

  useEffect(() => {
    if (props.collectionPeriod) {
      setPage(1);
      getDevolutionData({
        collectionPeriod: props.collectionPeriod,
        contributorRut: "",
        statusId: null,
        typeId: null,
        limit: rowsPerPage,
        offset: 0,
      });
      resetAllFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.collectionPeriod]);

  useEffect(() => {
    props.collectionPeriod &&
      getDevolutionData({
        collectionPeriod: props.collectionPeriod,
        contributorRut: searchRut || null,
        statusId: devolutionStatus(filters.statusId),
        typeId: null,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (props.refresh) {
      getDevolutionData({
        collectionPeriod: props.collectionPeriod,
        contributorRut: searchRut || null,
        statusId: devolutionStatus(filters.statusId),
        typeId: null,
        limit: rowsPerPage,
        offset: 0,
      });
      setPage(1);
      props.setRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  useEffect(() => {
    filters.statusId === 5 && setFilters({ ...filters, statusId: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    !rutFilter &&
      filters.statusId === null &&
      props.setDisabledActions(countDevolution === 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDevolution]);

  const WarningModalContent = () => {
    const content =
      processInfo.typeId === MONTHLY_ID ? (
        <Typography sx={warningGray}>
          Al aceptar esta opción, los registros marcados como devolución mensual{" "}
          <br></br>quedarán en estado "En proceso" y solo se gestionarán esos{" "}
          <br></br>
          registros con la rendición de nómina
        </Typography>
      ) : (
        <Typography sx={warningGray}>
          Al aceptar esta opción, los registros marcados como devolución a{" "}
          <br></br>demanda quedarán en estado "En proceso" y solo se gestionarán{" "}
          <br></br>
          esos registros con la rendición de nómina
        </Typography>
      );
    return (
      <>
        <br></br>
        {content}
        <Grid container alignContent={"center"} pr="18px">
          <Alerts
            mensaje={
              "Se procesará un máximo de 10.000 registros de devolución."
            }
            open={true}
            data={null}
            setOpen={null}
            severidad={"warning"}
            noClose
            typeAlert={2}
          />
        </Grid>
      </>
    );
  };

  const TagsDevolution = ({ status }: any) => {
    switch (true) {
      case status === UNINITIATED:
        return (
          <label className="tagEstadoCasos tagGGreen">{UNINITIATED}</label>
        );
      case status === PAID:
        return <label className="tagEstadoCasos tagGreen">{PAID}</label>;
      case status === IN_PROCESS:
        return <label className="tagEstadoCasos tagPurple">{IN_PROCESS}</label>;
      case status === REJECTED:
        return <label className="tagEstadoCasos tagRed">{REJECTED}</label>;
      default:
        return <label className="tagEstadoCasos tagRed">{status}</label>;
    }
  };

  return (
    <>
      <WarningModalCustom
        secondaryButton="Cancelar"
        primaryButton="Aceptar"
        contentText={<WarningModalContent />}
        title={processInfo.title}
        headWarning
        onOpen={openWarning}
        loading={loadingProcess}
        onClose={() => setOpenWarning(false)}
        onConfirm={processDevolution}
        loadingWidth={"104.3px"}
      />
      <ModalUpdateDevolution
        onOpen={showDevolution}
        info={rowInfo}
        loading={loadingClassify}
        handleClose={() => setShowDevolution(false)}
        toDo={classifyDevolution}
        selectedType={rowSelectedType}
        setSelectedType={setRowSelectedType}
      />
      {/* <SearchOverage
        onOpen={searchModal}
        handleClose={() => setSearchModal(false)}
        filters={filters}
        setFilters={setFilters}
        disabled={false}
        data-testid="search-modal"
      /> */}
      <ChangeLogModal
        onOpen={showHistory}
        onClose={() => setShowHistory(false)}
        id={rowInfo?.id}
        logTypeId={OVERAGE}
      />
      {loadingDevolution || props.loadingList ? (
        <SkeletonTableDevolution rows={3} columns={4} />
      ) : (
        <Box sx={boxNoPadding} mt="16px" mb="24px">
          <Box sx={{ borderRadius: "8px", p: "24px ", pt: "12px" }}>
            <Grid container justifyContent={"space-between"}>
              <Grid item xs={1.5} alignContent={"center"}>
                <Typography variant="h2" component="div" display={"inline"}>
                  Devolución
                </Typography>
              </Grid>
              <Grid item xs={2.5} pt="14px" mr="15px">
                <Typography mb={"10px"}>RUT</Typography>
                <RutInput
                  formattedRun={formattedRun}
                  setFormattedRun={setFormattedRun}
                  setValidRut={setValidRut}
                  setSearchRut={setSearchRut}
                  setRutFilter={setRutFilter}
                  disabled={props.disabledActions || loadingDevolution}
                />
              </Grid>
              <Grid item xs={2.5} pt="14px" mr="15px">
                <Typography mb={"10px"}>Estado</Typography>
                <CustomSelect
                  onChange={(e: any) => {
                    setFilters({ ...filters, statusId: e.target.value });
                  }}
                  value={filters.statusId}
                  titulo={"Seleccione"}
                  hideTitle
                  data={[
                    { id: 1, nombre: IN_PROCESS },
                    { id: 2, nombre: UNINITIATED },
                    { id: 3, nombre: PAID },
                    { id: 4, nombre: REJECTED },
                    { id: 5, nombre: "Sin selección" },
                  ]}
                  disabled={props.disabledActions || loadingDevolution}
                />
              </Grid>
              <Grid item xs={1} pt="40px" mr="15px">
                <Button
                  sx={{ minWidth: "112px" }}
                  color="secondary"
                  onClick={() => handleSearch(formattedRun)}
                  disabled={
                    (!validRut && formattedRun !== "") ||
                    props.disabledActions ||
                    loadingDevolution
                  }
                >
                  {loadingDevolution ? (
                    <div className="spinnerButtonGreen"></div>
                  ) : (
                    SEARCH
                  )}
                </Button>
              </Grid>
              {/* <Grid item xs={1} pt="40px" mr="15px">
                <Button
                  data-testid="ver-filtros"
                  color="secondary"
                  onClick={() => setSearchModal(true)}
                  disabled={loadingDevolution}
                >
                  {FILTERS}
                </Button>
              </Grid> */}
              <Divider
                orientation="vertical"
                flexItem
                variant="middle"
                sx={{ ml: "15px", mr: "15px" }}
              />
              <Grid item xs={3} pt="40px">
                <Stack direction="row" justifyContent="end">
                  <Button
                    color="secondary"
                    sx={{
                      minWidth: "112px",
                      mr: "15px",
                    }}
                    onClick={handleClickProcess}
                    disabled={
                      props.disabledActions ||
                      loadingDevolution ||
                      errorDevolution ||
                      props.readOnly
                    }
                  >
                    Procesar
                  </Button>
                  <Menu
                    className="littleMenu"
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                  >
                    <MenuItem
                      disabled={props.readOnly}
                      onClick={() => handleWarningOption(1)}
                    >
                      Procesar dev. a demanda
                    </MenuItem>
                    <MenuItem
                      disabled={props.readOnly}
                      onClick={() => handleWarningOption(2)}
                    >
                      Procesar dev. mensual
                    </MenuItem>
                  </Menu>
                  <MenuExportDevolution
                    loading={
                      loadingDevolutionExport || loadingSAP || inProgress
                    }
                    disabled={
                      props.disabledActions ||
                      loadingDevolution ||
                      errorDevolution ||
                      inProgress
                    }
                    noData={countDevolution === 0}
                    exportDevolution={() => handleClickDevolutionExport()}
                    exportMonthly={() => {
                      setSapType(MONTHLY_ID);
                      handleClickSapExport(MONTHLY_ID);
                    }}
                    exportOnDemand={() => {
                      setSapType(ON_DEMAND_ID);
                      handleClickSapExport(ON_DEMAND_ID);
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box sx={tableBox}>
            <Paper className="paper-tables-2" sx={{ boxShadow: "none " }}>
              {errorDevolution ? (
                <NoResult titulo={ERROR} subtitulo={ERROR_SUBTITLE} error />
              ) : (
                <>
                  {countDevolution === 0 ? (
                    rutFilter ? (
                      <NoResult
                        titulo={NO_RESULT_RUT}
                        subtitulo={NO_RESULT_RUT_SUBTITLE}
                        newFound
                      />
                    ) : (
                      <NoResult
                        titulo={NO_INFO}
                        subtitulo={NO_INFO_DEVOLUTION}
                        newDontSearch
                      />
                    )
                  ) : (
                    <>
                      <TableContainer
                        className="tContainer-padding-0"
                        component={Paper}
                        sx={{
                          borderRadius: 0,
                          boxShadow: "none ",
                          borderTop: "solid 2px #E9EBEC",
                        }}
                      >
                        <Table
                          sx={{ minWidth: 650 }}
                          aria-label="customized table"
                        >
                          <TableHead className="color-head-table">
                            <TableRow>
                              <StyledTableCell>
                                RUT y Nombre cliente
                              </StyledTableCell>
                              <StyledTableCell>
                                Tipo de devolución
                              </StyledTableCell>
                              <StyledTableCell>Saldo</StyledTableCell>
                              <StyledTableCell>Estado</StyledTableCell>
                              <StyledTableCell
                                width={"50px"}
                                className="headerTableAcciones"
                                align="center"
                              >
                                Devolución
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          {rowsDevolution.map((row: any, index: number) => {
                            return (
                              <TableBody>
                                <TableRow key={index}>
                                  <TableCell>
                                    <Typography variant="h5">
                                      {" "}
                                      {row.contributorRut}
                                    </Typography>
                                    <Typography sx={subTable}>
                                      {row.contributorName}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="h5">
                                      {row.typeName}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="h5">
                                      {" "}
                                      $
                                      {row.availableBalance.toLocaleString(
                                        "es-cl"
                                      )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <TagsDevolution status={row.statusName} />
                                  </TableCell>
                                  <TableCell
                                    className="tableBodyAsignar"
                                    align="center"
                                  >
                                    <MenuDevolution
                                      info={row}
                                      handleDevolution={() =>
                                        handleDetails(row, 1)
                                      }
                                      handleHistory={() =>
                                        handleDetails(row, 2)
                                      }
                                      canUpdate={props.canUpdate}
                                      disabledActions={
                                        props.readOnly ||
                                        row.statusId !== UNINITIATED_ID ||
                                        row.typeId === NOT_APPLICABLE_ID
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            );
                          })}
                        </Table>
                      </TableContainer>
                      <ThemeProvider theme={paginatorTheme}>
                        <TablePagination
                          rowsPerPageOptions={[10, 20, 50]}
                          component="div"
                          count={countDevolution}
                          rowsPerPage={rowsPerPage}
                          page={page - 1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelRowsPerPage={"Mostrar por página"}
                          sx={{ mt: "24px", mb: "24px" }}
                          labelDisplayedRows={(e) => {
                            return (
                              "" +
                              (page || 0) +
                              " de " +
                              Math.ceil(countDevolution / rowsPerPage)
                            );
                          }}
                          SelectProps={{
                            disabled: props.disabledActions,
                          }}
                          backIconButtonProps={
                            props.disabledActions
                              ? {
                                  disabled: props.disabledActions,
                                }
                              : undefined
                          }
                          nextIconButtonProps={
                            props.disabledActions
                              ? {
                                  disabled: props.disabledActions,
                                }
                              : undefined
                          }
                        />
                      </ThemeProvider>
                    </>
                  )}
                </>
              )}
            </Paper>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TableDevolution;
