import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import icon_warningOrange from "src/assets/icons/icon_warningOrange.svg";
import {
  CustomModal,
  CustomModalTitle,
} from "src/components/modals/customModal";
import { modalTitle } from "src/components/typography/styles";
import constants from "src/constants/constants";

const {
  COMPONENTS: {
    BUTTONS: { CANCEL },
  },
} = constants;

export const ConfirmModalNew = ({
  onOpen,
  onClose,
  toDo,
  loading,
  title,
  content,
  label,
}: any) => {
  const confirmTitle = () => {
    return <Typography sx={modalTitle}>{title}</Typography>;
  };

  return (
    <>
      <CustomModal
        fullWidth
        maxWidth="sm"
        open={onOpen}
        onClose={onClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <CustomModalTitle id="warning" onClose={onClose}>
          {confirmTitle()}
        </CustomModalTitle>
        {
          <DialogContent
            dividers
            sx={{
              textAlign: "center",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <img
              alt="img"
              src={icon_warningOrange}
              style={{ marginTop: "3px" }}
            />
            {content}
          </DialogContent>
        }

        <DialogActions sx={{ textAlign: "center" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {loading ? (
              <Button
                color="primary"
                sx={{ height: "50px", width: "112.75px" }}
              >
                <div className="spinnerButton"></div>
              </Button>
            ) : (
              <Button
                color="primary"
                sx={{ width: "112.75px" }}
                onClick={() => toDo()}
              >
                {label}
              </Button>
            )}
            <Button
              color="secondary"
              onClick={onClose}
              sx={{ marginLeft: "24px" }}
            >
              {CANCEL}
            </Button>
          </Grid>
        </DialogActions>
      </CustomModal>
    </>
  );
};
