import { useState, useEffect } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import {
  detailText,
  littleTitle,
  modalSubtitle,
} from "src/components/typography/styles";
import {
  CustomInput,
  CustomModal,
  CustomModalTitle,
  CustomSelect,
} from "src/components";
import { getCurrentPeriod } from "src/utils/dates";
import { validateRun } from "src/utils/validationUtils";
import { formatRut } from "src/utils/stringUtils";
import { findOption } from "src/utils/arrayUtils";

const SearchC = ({
  onOpen,
  handleClose,
  filtros,
  setFiltros,
  options,
}: any) => {
  const [disableButton, setDisableButton] = useState(true);
  const [runError, setRunError] = useState("");
  const [runValue, setRunValue] = useState(filtros?.contributorRut || "");
  const [formattedRun, setFormattedRun] = useState(
    formatRut(filtros?.contributorRut) || ""
  );
  const [period, setPeriod] = useState<string>(filtros?.paymentPeriod || null);
  const [option, setOption] = useState(findOption(options, period));

  const handleRunChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let formatted = value.replace(/[^0-9kK-]/g, "").toUpperCase();
    const formatString = formatted?.match(/-/g) || null;

    if (formatted.charAt(0) === "0") {
      formatted = formatted.substring(1);
    }

    if (formatted.length <= 11) {
      setRunValue(formatted.replace("-", ""));
      if (formatted.trim() === "") {
        setRunError("");
        setDisableButton(true);
        setFormattedRun("");
      } else {
        const isValid = validateRun(formatted);
        if (isValid) {
          setRunError("");
          if (formatted.length >= 8) {
            setDisableButton(false);
          }
          const formattedWithSeparators = formatRut(formatted);
          setFormattedRun(formattedWithSeparators);
        } else {
          if (formatted.length >= 8) {
            setRunError("RUN inválido.");
            setDisableButton(true);
          }
          setFormattedRun(formatted);
        }
      }
      if (formatString !== null && formatString.length > 1) {
        setRunError("RUN inválido.");
        setDisableButton(true);
      }
    }
  };

  const handleFilter = () => {
    setFiltros({
      ...filtros,
      contributorRut: runValue !== "" ? formattedRun.replace(/\./g, "") : null,
      paymentPeriod: period,
    });
    handleClose();
  };

  const getOptionsPeriod = (dataOptions: any) => {
    let optionsArray: any[] = [];
    if (dataOptions) {
      dataOptions.forEach((element: any) => {
        optionsArray.push(getCurrentPeriod(element)[0]);
      });
    }
    optionsArray.push("Sin selección");
    return optionsArray;
  };

  const handleValidation = (selectedOption: number) => {
    if (
      selectedOption &&
      runError === "" &&
      (formattedRun.length >= 8 || formattedRun.length === 0)
    ) {
      setDisableButton(false);
    }
  };

  useEffect(() => {
    if (option && runValue === "") {
      setDisableButton(false);
    }
    if (runValue.length < 8 && runValue.length !== 0) {
      setDisableButton(true);
    }
  }, [runValue, option]);

  return (
    <CustomModal
      maxWidth="md"
      open={onOpen}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <CustomModalTitle id="addCredits" onClose={handleClose}>
        <Typography sx={littleTitle} textAlign="center" pt="32px">
          Buscar acreencias
        </Typography>
        <Typography sx={modalSubtitle} textAlign="center">
          Seleccione los criterios de búsqueda para encontrar registros de
          acreencias específicos.
        </Typography>
      </CustomModalTitle>
      <DialogContent dividers>
        <Grid
          container
          direction="column"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid sx={{ margin: "0", width: "100%" }} container spacing={2}>
            <Grid item xs={6} p="0 !important" pr="24px !important">
              <Typography sx={detailText} pb="6px">
                RUN
              </Typography>
              <CustomInput
                placeholder="Ingresar RUN"
                fullWidth
                value={formattedRun || runValue}
                onChange={handleRunChange}
                inputProps={{
                  "data-testid": "input-run",
                }}
              />
              {runError && (
                <Typography
                  sx={{ color: "red", fontSize: 8 }}
                  variant="caption"
                >
                  {runError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} p="0 !important">
              <Typography sx={detailText} pb="6px">
                Periodo de remuneración
              </Typography>
              <CustomSelect
                onChange={(e: any) => {
                  setOption(e.target.value);
                  setPeriod(options[e.target.value - 1]);
                  handleValidation(e.target.value);
                }}
                value={option}
                hideTitle={true}
                titulo={"Seleccionar"}
                data={getOptionsPeriod(options)}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            onClick={handleClose}
            color="secondary"
            sx={{ width: "150px" }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleFilter}
            sx={{ width: "150px" }}
            disabled={disableButton}
          >
            Filtrar
          </Button>
        </Grid>
      </DialogActions>
    </CustomModal>
  );
};

export default SearchC;
