import moment from "moment";
import "moment/locale/es";

moment.locale("es");

export function getCurrentMonthYear(date: string) {
  //Mes capitalizado y año completo (Enero 2023)
  return (
    moment(date).format("MMMM YYYY").charAt(0).toUpperCase() +
    moment(date).format("MMMM YYYY").slice(1)
  );
}

export function getCurrentMonth(date: string) {
  //Mes en formato numero (01)
  return moment(date).format("MM");
}

export function getCurrentYear(date: string) {
  //Año completo (2023)
  return moment(date).format("yyyy");
}

function getCurrentYearMonth(date: string) {
  //Año y mes en formato numero (202301)
  return moment(date).format("YYYYMM");
}

/**
 * @description Creates an array with year and month in different formats
 * @param period string in format "yyyymm"
 * @returns Array with various formats [0,1,2,3]=["MMMM YYYY","MM","yyyy","YYYYMM"]
 */
export function getCurrentPeriod(period: string) {
  return [
    getCurrentMonthYear(period),
    getCurrentMonth(period),
    getCurrentYear(period),
    getCurrentYearMonth(period),
  ];
}

export function getNextMonthYear(date: Date) {
  return (
    moment(date).add(1, "months").format("MMMM YYYY").charAt(0).toUpperCase() +
    moment(date).add(1, "months").format("MMMM YYYY").slice(1)
  );
}

//  Deprecated, remove later
function getPreviousMonthYear(date: Date) {
  return (
    moment(date)
      .subtract(1, "months")
      .format("MMMM YYYY")
      .charAt(0)
      .toUpperCase() +
    moment(date).subtract(1, "months").format("MMMM YYYY").slice(1)
  );
}

function getPreviousMonth(date: Date) {
  return moment(date).subtract(1, "months").format("MM");
}

function getPreviousYear(date: Date) {
  return moment(date).subtract(1, "months").format("YYYY");
}

function getPreviousYearMonth(date: Date) {
  return moment(date).subtract(1, "months").format("YYYYMM");
}

export function getPreviousPeriod(date: Date) {
  return [
    getPreviousMonthYear(date),
    getPreviousMonth(date),
    getPreviousYear(date),
    getPreviousYearMonth(date),
  ];
}

//Array con listado de meses para select de filtro MUI
export function monthList() {
  let monthListArr = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  return monthListArr;
}

export const currentYear = new Date().getFullYear();

export const generateYears = () => {
  let years = [];
  let startYear = 2022;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years.reverse();
};

export const getDateFromExcel = (excelDate: any) => {
  if (typeof excelDate === "string") {
    let auxDate = excelDate.split("-");
    return auxDate[2] + "-" + auxDate[1] + "-" + auxDate[0];
  }
  let m = Date.parse(excelDate);
  let mTZ = m + new Date().getTimezoneOffset() * 60000;
  return moment(mTZ).format("YYYY-MM-DD");
};

/**
 * @description Creates a list of months since 202201 from newest to oldest
 * @param includeNextYear include next year from current date 
 * @returns Array of months in format "yyyymm"
 */
export const createPeriodList = (includeNextYear: boolean) => {
  let periodList = [];
  let startYear = 2022;
  let startMonth = 1;  
  const ongoingYear = includeNextYear ? new Date().getFullYear() + 1 : new Date().getFullYear();
  const ongoingMonth = includeNextYear ? 12 : new Date().getMonth() + 1;
  while (startYear <= ongoingYear) {
    while (startMonth <= 12) {
      if (startYear === ongoingYear && startMonth > ongoingMonth) {
        break;
      }
      periodList.push(
        startYear + "" + (startMonth < 10 ? "0" : "") + startMonth
      );
      startMonth++;
    }
    startMonth = 1;
    startYear++;
  }
  return periodList.reverse();
};

export const convertUTCDateToLocalDate = (date: any) => {
  let m = Date.parse(date);
  let mTZ = m + new Date().getTimezoneOffset() * 60000;
  return mTZ;
};

export const lastDayOfMonth = (date: any) => {
  let formatDate = date.slice(4, 6) + "-01-" + date.slice(0, 4);
  const newDate = new Date(formatDate);
  newDate.setMonth(newDate.getMonth() + 1);
  newDate.setDate(0);
  return newDate;
};

export const dateAsPeriod = () => {
  const period = new Date().toISOString().split("T")[0];
  const formatAux = period.split("-");
  const periodFormat = formatAux[0] + formatAux[1];
  return periodFormat;
};
