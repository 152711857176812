import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { NoResult } from "src/components";
import ExportButton from "src/components/buttons/ExportButton";
import { StyledTableCell } from "src/components/tables/tableStyle";
import EyeIcon from "src/assets/icons/greenEye.svg";
import { paginatorTheme } from "src/components/paginator/paginatorStyle";
import { getCurrentPeriod, getPreviousPeriod } from "src/utils/dates";
import { SkeletonExcess } from "../../distribution/excess/components/skeletonExcess";
import { CustomSelect } from "src/components/Select/CustomSelect";
import download from "src/assets/icons/download.svg";
import plusGreen from "src/assets/icons/plusGreen.svg";
import plusGray from "src/assets/icons/plusGray.svg";
import DetailExcess from "../../distribution/excess/components/detailExcess";
import { DeleteModal } from "src/pages/collection/credits/components/deleteModal";
import ChangeLogModal from "src/components/modals/changeLogModal";
import EditModal from "../../distribution/excess/components/editModal";
import constants from "src/constants/constants";
import EditMovement from "src/pages/rutSearch/components/editMovement";
import AddMovement from "src/pages/rutSearch/components/addMovement";
import { MenuExcessRut } from "../../distribution/excess/components/menuExcessRut";
import { hasPermissions } from "src/utils/hasPermissions";
import { useSurplus } from "../../distribution/hook/useSurplus";
import { renderAmount } from "src/utils/numberUtils";

const {
  COMPONENTS: {
    BUTTONS: { EXPORT, MOVEMENT },
    SELECTS: {
      DATE_RANGE: { TWELVE_MONTHS, TWENTY_FOUR_MONTHS },
    },
  },
} = constants;

type ByRutSurplusProps = {
  rutSearch: string;
  rutInfo: any;
  setRutInfo: React.Dispatch<React.SetStateAction<any>>;
  newSearch: boolean;
  setNewSearch: React.Dispatch<React.SetStateAction<boolean>>;
  refreshOnError: () => void;
  setSnackInfo: React.Dispatch<any>;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  isUpdateEnabled: boolean;
  setLoadingRutSearch: React.Dispatch<React.SetStateAction<boolean>>;
};

const ByRutSurplus = ({
  rutSearch,
  rutInfo,
  setRutInfo,
  newSearch,
  setNewSearch,
  refreshOnError,
  setSnackInfo,
  refresh,
  setRefresh,
  isUpdateEnabled,
  setLoadingRutSearch,
}: ByRutSurplusProps) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showDetail, setShowDetail] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showAddMovement, setShowAddMovement] = useState(false);
  const [showEditMovement, setShowEditMovement] = useState(false);
  const [dateLimit, setDateLimit] = useState<number>(1);
  const [rowInfo, setRowInfo] = useState({
    id: "",
    collectionPeriod: "",
    contributorRut: "",
    contributorName: "",
    paymentPeriod: "",
    surplusGenerated: 0,
    readjustment: 0,
    interest: 0,
    commission: 0,
    excessDetail: [],
  });
  const canUpdate = hasPermissions().update;
  const {
    getSuplusConsolidatedData,
    consolidatedCount,
    consolidatedRows,
    consolidatedLoading,
  } = useSurplus({ collectionPeriod: "" });

  const handleDetails = (elem: any, type: number) => {
    setRowInfo({
      id: elem.id,
      collectionPeriod: elem.collectionPeriod,
      contributorRut: elem.contributorRut,
      contributorName: elem.contributorName,
      paymentPeriod: elem.paymentPeriod,
      surplusGenerated: elem.surplusGenerated,
      readjustment: elem.readjustment,
      interest: elem.interest,
      commission: elem.commission,
      excessDetail: elem.surplusDetail,
    });
    if (type === 1) setShowDetail(true);
    if (type === 2) setShowHistory(true);
    if (type === 3) setShowEditMovement(true);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (consolidatedCount > 0 && page === 1) {
      setRutInfo({
        contributorRut: consolidatedRows[0]?.contributorRut,
        contributorName: consolidatedRows[0]?.contributorName,
        accountableBalance: consolidatedRows[0]?.accountableBalance,
        availableBalance: consolidatedRows[0]?.availableBalance,
        excess: consolidatedRows[0]?.surplusGenerated,
        collectionPeriod: consolidatedRows[0]?.collectionPeriod,
        paymentPeriod: getPreviousPeriod(
          consolidatedRows[0]?.collectionPeriod as any
        )[3],
        excessDetail: consolidatedRows[0]?.surplusDetail,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consolidatedCount, consolidatedRows, page]);

  useEffect(() => {
    if (rutSearch !== "") {
      setPage(1);
      getSuplusConsolidatedData({
        contributorRut: rutSearch.replace(/\./g, ""),
        dateLimit: 12,
        limit: rowsPerPage,
        offset: 0,
      });
      setDateLimit(1);
      setNewSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rutSearch]);

  useEffect(() => {
    if (rutSearch !== "" && newSearch === false) {
      setPage(1);
      getSuplusConsolidatedData({
        contributorRut: rutSearch.replace(/\./g, ""),
        dateLimit: dateLimit === 1 ? 12 : 24,
        limit: rowsPerPage,
        offset: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateLimit]);

  useEffect(() => {
    if (rutSearch) {
      getSuplusConsolidatedData({
        contributorRut: rutSearch.replace(/\./g, ""),
        dateLimit: dateLimit === 1 ? 12 : 24,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (refresh === true) {
      setPage(1);
      getSuplusConsolidatedData({
        contributorRut: rutSearch.replace(/\./g, ""),
        dateLimit: dateLimit === 1 ? 12 : 24,
        limit: rowsPerPage,
        offset: 0,
      });
      setRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    if (page === 1) {
      setLoadingRutSearch(consolidatedLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consolidatedLoading]);

  if (rutSearch === "") {
    return (
      <Box
        sx={{
          border: "1px solid #E9EBEC",
          borderRadius: "8px",
          mt: "16px",
        }}
      >
        <Grid container p="24px">
          <Grid item xs={4}>
            <Typography variant="h3" pb="6px">
              Periodo
            </Typography>
            <CustomSelect
              titulo={TWELVE_MONTHS}
              value={""}
              name="fileType"
              disabled
            />
          </Grid>
          <Grid item xs={8} pt="29px" sx={{ textAlignLast: "end" }}>
            <Button
              sx={{ p: "14px 22px !important", mr: "24px" }}
              color={"secondary"}
              startIcon={
                <img
                  src={plusGray}
                  style={{ paddingRight: "12px" }}
                  alt="add"
                />
              }
              disabled
            >
              {" "}
              {MOVEMENT}
            </Button>
            <Button
              sx={{ p: "14px 22px !important" }}
              color={"secondary"}
              startIcon={
                <img
                  src={download}
                  style={{ paddingRight: "12px" }}
                  alt="download"
                />
              }
              disabled
            >
              {" "}
              {EXPORT}
            </Button>
          </Grid>
        </Grid>
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none",
            borderRadius: "none",
            borderBottom: "none",
          }}
        >
          <NoResult
            titulo={"Realiza una consulta"}
            subtitulo={
              "Selecciona la sección e ingresa el RUT a consultar para visualizar."
            }
            newDontSearch
          />
        </TableContainer>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        border: "1px solid #E9EBEC",
        borderRadius: "8px",
        mt: "16px",
        backgroundColor: "#E6F6F53D",
      }}
      mt="16px"
      mb="24px"
    >
      <DetailExcess
        onOpen={showDetail}
        handleCloseModal={() => setShowDetail(false)}
        info={rowInfo}
        type={1}
      />
      <EditModal
        onOpen={showEdit}
        handleClose={() => setShowEdit(false)}
        info={rowInfo}
        setRefresh={setRefresh}
        setSnackInfo={setSnackInfo}
        refreshOnError={refreshOnError}
      />
      <DeleteModal
        onOpen={showDelete}
        onClose={() => setShowDelete(false)}
        id={rowInfo.id}
        rut={rowInfo.contributorRut}
        payerRut={null}
        period={rowInfo.collectionPeriod}
        setRefresh={setRefresh}
        refreshOnError={refreshOnError}
        type={3}
      />
      <ChangeLogModal
        onOpen={showHistory}
        onClose={() => setShowHistory(false)}
        id={rowInfo.id}
        logTypeId={27}
      />
      {consolidatedLoading ? (
        <SkeletonExcess columns={7} rows={3} module={module} />
      ) : (
        <>
          <EditMovement
            onOpen={showEditMovement}
            handleCloseModal={() => setShowEditMovement(false)}
            info={rutInfo}
            setRefresh={setRefresh}
            setSnackInfo={setSnackInfo}
            refreshOnError={refreshOnError}
          />
          <AddMovement
            onOpen={showAddMovement}
            handleCloseModal={() => setShowAddMovement(false)}
            info={rutInfo}
            distributionType={1}
            setRefresh={setRefresh}
            setSnackInfo={setSnackInfo}
            refreshOnError={refreshOnError}
          />
          <Grid container p="24px">
            <Grid item xs={4}>
              <Typography variant="h3" pb="6px">
                Periodo
              </Typography>
              <CustomSelect
                titulo={TWELVE_MONTHS}
                hideTitle
                value={dateLimit}
                name="fileType"
                onChange={(e: any) => setDateLimit(e.target.value)}
                data={[TWELVE_MONTHS, TWENTY_FOUR_MONTHS]}
                disabled={consolidatedCount === 0}
              />
            </Grid>
            <Grid item xs={8} pt="29px" sx={{ textAlignLast: "end" }}>
              <Button
                onClick={() => setShowAddMovement(true)}
                sx={{ p: "14px 22px !important", mr: "24px" }}
                color={"secondary"}
                startIcon={
                  <img
                    src={
                      consolidatedCount === 0 || !canUpdate || !isUpdateEnabled
                        ? plusGray
                        : plusGreen
                    }
                    style={{ paddingRight: "12px" }}
                    alt="add"
                  />
                }
                disabled={
                  consolidatedCount === 0
                    ? true
                    : !canUpdate || !isUpdateEnabled
                }
              >
                {" "}
                {MOVEMENT}
              </Button>
              <ExportButton
                type={3}
                isActive={true}
                collectionPeriod={null}
                contributorRut={rutSearch}
                disabled={consolidatedCount === 0}
              />
            </Grid>
          </Grid>
          {consolidatedCount === 0 ? (
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "none",
                borderRadius: "none",
                paddingTop: "none",
                borderBottom: "none",
              }}
            >
              <NoResult
                titulo={"No se encontró información asociada al RUT"}
                subtitulo={"Inténtelo nuevamente usando otro criterio"}
                newFound
              />
            </TableContainer>
          ) : (
            <>
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "none",
                  borderRadius: "none",
                  paddingTop: "none",
                  borderBottom: "none",
                }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="customized table">
                  <TableHead className="color-head-table">
                    <TableRow>
                      <StyledTableCell sx={{ minWidth: "134.75px" }}>
                        Periodo de <br></br> recaudación
                      </StyledTableCell>
                      <StyledTableCell sx={{ minWidth: "134.75px" }}>
                        Excedentes
                      </StyledTableCell>
                      <StyledTableCell sx={{ minWidth: "134.75px" }}>
                        Reajuste
                      </StyledTableCell>
                      <StyledTableCell sx={{ minWidth: "134.75px" }}>
                        Intereses
                      </StyledTableCell>
                      <StyledTableCell sx={{ minWidth: "134.75px" }}>
                        Comisión
                      </StyledTableCell>
                      <StyledTableCell sx={{ minWidth: "134.75px" }}>
                        Uso <br></br>
                        Excedentes
                      </StyledTableCell>
                      <StyledTableCell sx={{ minWidth: "134.75px" }}>
                        Saldo
                      </StyledTableCell>
                      <StyledTableCell
                        className="headerTableAcciones"
                        align="center"
                        sx={{ minWidth: "134.75px" }}
                      >
                        Ver detalle
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {consolidatedRows.map((row: any, index: number) => {
                    return (
                      <TableBody>
                        <TableRow data-testid="withData" key={index}>
                          <TableCell>
                            <Typography variant="h5">
                              {getCurrentPeriod(row.collectionPeriod)[0]}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h5">
                              ${renderAmount(row.surplusGenerated)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h5">
                              ${renderAmount(row.readjustment)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h5">
                              ${renderAmount(row.interest)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h5">
                              ${renderAmount(row.commission)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h5">
                              ${renderAmount(row.chargeAmount)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h5">
                              ${renderAmount(row.availableBalance)}
                            </Typography>
                          </TableCell>
                          {page === 1 && index === 0 && isUpdateEnabled ? (
                            <TableCell
                              className="tableBodyAsignar"
                              align="center"
                              sx={{ zIndex: 999 }}
                            >
                              <MenuExcessRut
                                info={row}
                                handleEdit={() => handleDetails(row, 3)}
                                handleDetails={() => handleDetails(row, 1)}
                                canUpdate={canUpdate}
                              />
                            </TableCell>
                          ) : (
                            <TableCell
                              className="tableBodyAsignar"
                              align="center"
                              sx={{ zIndex: 999 }}
                            >
                              <Button
                                color="secondary"
                                data-testid="edit-row-button"
                                onClick={() => handleDetails(row, 1)}
                              >
                                <img src={EyeIcon} alt="" />
                              </Button>
                            </TableCell>
                          )}
                        </TableRow>
                      </TableBody>
                    );
                  })}
                </Table>
              </TableContainer>
              {consolidatedCount !== 0 && (
                <>
                  <Grid
                    alignItems="center"
                    sx={{
                      pt: "24px",
                      pb: "24px",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <ThemeProvider theme={paginatorTheme}>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, 50]}
                        component="div"
                        count={consolidatedCount}
                        rowsPerPage={rowsPerPage}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={"Mostrar por página"}
                        labelDisplayedRows={(e) => {
                          return (
                            "" +
                            page +
                            " de " +
                            Math.ceil(consolidatedCount / rowsPerPage)
                          );
                        }}
                      />
                    </ThemeProvider>
                  </Grid>
                </>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default ByRutSurplus;
