const SNACKINFO_INITIAL_STATE = {
  open: false,
  message: "",
  status: "success",
};

const SNACK_ACTION_TYPE = {
  SET_SNACK_INFO: "SET_SNACK_INFO",
  RESET_SNACK_INFO: "RESET_SNACK_INFO",
  CLOSE_SNACK_INFO: "CLOSE_SNACK_INFO",
  OPEN_SNACK_INFO: "OPEN_SNACK_INFO",
  SET_SNACK_ERROR: "SET_SNACK_ERROR",
  SET_SNACK_SUCCESS: "SET_SNACK_SUCCESS",
  SET_SNACK_WARNING: "SET_SNACK_WARNING",
};

const snackInfoReducer = (state = SNACKINFO_INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SNACK_ACTION_TYPE.SET_SNACK_INFO:
      return {
        open: action.payload.open,
        message: action.payload.message,
        status: action.payload.status,
      };
    case SNACK_ACTION_TYPE.RESET_SNACK_INFO:
      return {
        open: false,
        message: "",
        status: "success",
      };
    case SNACK_ACTION_TYPE.CLOSE_SNACK_INFO:
      return {
        ...state,
        open: false,
      };
    case SNACK_ACTION_TYPE.OPEN_SNACK_INFO:
      return {
        ...state,
        open: true,
      };
    case SNACK_ACTION_TYPE.SET_SNACK_ERROR:
      return {
        open: true,
        message: action.payload,
        status: "error",
      };
    case SNACK_ACTION_TYPE.SET_SNACK_SUCCESS:
      return {
        open: true,
        message: action.payload,
        status: "success",
      };
    case SNACK_ACTION_TYPE.SET_SNACK_WARNING:
      return {
        open: true,
        message: action.payload,
        status: "warning",
      };
    default:
      return state;
  }
};

export { snackInfoReducer, SNACKINFO_INITIAL_STATE, SNACK_ACTION_TYPE };
