import { useReducer, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  GET_CONTRACTS,
  GET_PAYMENTS,
  EXPORT_SURPLUS,
  GENERATE_EXCESS_REPORT,
} from "src/services/graphql/queries";
import {
  collectionCreditXLSX,
  collectionPaymentXLSX,
  creditXLSX,
  excessXLSX,
  excessReportXLSX,
  paymentXLSX,
} from "src/utils/buildExcel";
import { Button } from "@mui/material";
import download from "src/assets/icons/download.svg";
import GreenDownload from "src/assets/icons/greenDownload.svg";
import constants from "src/constants/constants";
import {
  SNACK_ACTION_TYPE,
  SNACKINFO_INITIAL_STATE,
  snackInfoReducer,
} from "src/reducers/snackInfoReducer";
import AlertSnackbar from "../snackbar/AlertSnackbar";

const {
  COMPONENTS: {    
    BUTTONS: { EXPORT, GENERATE_REPORT },
  },
  SNACKBAR_MESSAGE: { DOWNLOAD_ERROR},
} = constants;

const { CLOSE_SNACK_INFO, SET_SNACK_ERROR, SET_SNACK_SUCCESS } =
  SNACK_ACTION_TYPE;

const ExportButton = (props: any) => {
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);
  const [snackInfoState, snackInfoDispatch] = useReducer(
    snackInfoReducer,
    SNACKINFO_INITIAL_STATE
  );

  const [getCreditData, { loading: loadingCreditData, fetchMore }] =
    useLazyQuery(GET_CONTRACTS, {
      fetchPolicy: "no-cache",
      onCompleted: (res) => fetchCreditData(res),
      onError: () => {
        snackInfoDispatch({
          type: SET_SNACK_ERROR,
          payload: DOWNLOAD_ERROR,
        });
        setDownloadInProgress(false);
      },
    });

  const fetchCreditData = async (result: any) => {
    let temp = result.period.collection.contributorContract.allBy;
    if (temp) {
      while (temp.rows.length < temp.count) {
        const { data } = await fetchMore({
          variables: {
            input: {
              contributorRut: props.contributorRut || null,
              paymentPeriod: props.paymentPeriod || null,
              collectionPeriod: props.period,
              workerType: props.workerType || null,
              statusId: props.statusId || null,
              typeId: props.typeId || null,
              addOverdue: props.addOverdue || false,
              isConsolidated: props.consolidated || false,
              isActive: props.isActive || false,
              limit: 5000,
              offset: temp.rows.length,
            },
          },
        });
        const mergeCreditRows = [
          ...temp.rows,
          ...data.period.collection.contributorContract.allBy.rows,
        ];
        temp = {
          ...temp,
          rows: mergeCreditRows,
        };
      }
      const creditData = temp.rows;
      exportCredits(creditData);
    }
  };

  const handleCreditDownload = () => {
    getCreditData({
      variables: {
        input: {
          contributorRut: props.contributorRut || null,
          paymentPeriod: props.paymentPeriod || null,
          collectionPeriod: props.period || null,
          workerType: props.workerType || null,
          statusId: props.statusId || null,
          typeId: props.typeId || null,
          addOverdue: props.addOverdue || false,
          isConsolidated: props.consolidated || false,
          isActive: props.isActive || false,
          limit: 5000,
          offset: 0,
        },
      },
    });
  };

  const [
    getPaymentData,
    { loading: loadingPaymentData, fetchMore: fetchMorePayment },
  ] = useLazyQuery(GET_PAYMENTS, {
    fetchPolicy: "no-cache",
    onCompleted: (res) => fetchPaymentData(res),
    onError: () => {
      snackInfoDispatch({
        type: SET_SNACK_ERROR,
        payload: DOWNLOAD_ERROR,
      });
      setDownloadInProgress(false);
    },
  });

  const fetchPaymentData = async (result: any) => {
    let temp = result.period.collection.payment.allBy;
    if (temp) {
      while (temp.rows.length < temp.count) {
        const { data } = await fetchMorePayment({
          variables: {
            input: {
              contributorRut: props.contributorRut || null,
              paymentPeriod: props.paymentPeriod || null,
              payerRut: props.payerRut || null,
              paymentType: props.paymentType || null,
              collectionPeriod: props.period,
              isActive: props.isActive || false,
              includePrevired: props.includePrevired || false,
              statusId: props.statusId || null,
              workerType: props.workerType || null,
              limit: 5000,
              offset: temp.rows.length,
            },
          },
        });
        const mergePaymentRows = [
          ...temp.rows,
          ...data.period.collection.payment.allBy.rows,
        ];
        temp = {
          ...temp,
          rows: mergePaymentRows,
        };
      }
      const paymentData = temp.rows;
      exportPayment(paymentData);
    }
  };

  const handlePaymentDownload = () => {
    getPaymentData({
      variables: {
        input: {
          contributorRut: props.contributorRut || null,
          paymentPeriod: props.paymentPeriod || null,
          payerRut: props.payerRut || null,
          paymentType: props.paymentType || null,
          collectionPeriod: props.period,
          isActive: props.isActive || false,
          includePrevired: props.includePrevired || false,          
          limit: 5000,
          offset: 0,
          statusId: props.statusId || null,
          workerType: props.workerType || null,
        },
      },
    });
  };

  const [
    getSurplusData,
    { loading: loadingSurplusData, fetchMore: fetchMoreSurplus },
  ] = useLazyQuery(EXPORT_SURPLUS, {
    fetchPolicy: "no-cache",
    onCompleted: (res) => fetchSurplusData(res),
    onError: () => {
      snackInfoDispatch({
        type: SET_SNACK_ERROR,
        payload: DOWNLOAD_ERROR,
      });
      setDownloadInProgress(false);
    },
  });

  const fetchSurplusData = async (result: any) => {
    let temp = result.period.distribution.surplus.surplusMovement.allBy;
    if (temp) {
      while (temp.rows.length < temp.count) {
        const { data } = await fetchMoreSurplus({
          variables: {
            input: {
              contributorRut: props.contributorRut || null,
              collectionPeriod: props.collectionPeriod || null,
              isActive: props.isActive || false,
              order: props.collectionPeriod ? 1 : 2,
              limit: 5000,
              offset: temp.rows.length,
            },
          },
        });
        const mergeSurplusRows = [
          ...temp.rows,
          ...data.period.distribution.surplus.surplusMovement.allBy.rows,
        ];
        temp = { ...temp, rows: mergeSurplusRows };
      }
      const surplusData = temp.rows;
      exportSurplus(surplusData);
    }
  };

  const handleSurplusDownload = () => {
    getSurplusData({
      variables: {
        input: {
          contributorRut: props.contributorRut || null,
          collectionPeriod: props.collectionPeriod || null,
          isActive: props.isActive || false,
          order: props.collectionPeriod ? 1 : 2,
          limit: 5000,
          offset: 0,
        },
      },
    });
  };

  const [
    getExcessReportData,
    { loading: loadingExcessReportData, fetchMore: fetchMoreExcessReport },
  ] = useLazyQuery(GENERATE_EXCESS_REPORT, {
    fetchPolicy: "no-cache",
    onCompleted: (res) => fetchExcessReportData(res),
    onError: () => {
      snackInfoDispatch({
        type: SET_SNACK_ERROR,
        payload: DOWNLOAD_ERROR,
      });
      setDownloadInProgress(false);
    },
  });

  const fetchExcessReportData = async (result: any) => {
    let temp = result.period.distribution.surplus.generateReport;
    if (temp) {
      while (temp.rows.length < temp.count) {
        const { data } = await fetchMoreExcessReport({
          variables: {
            input: {
              collectionPeriod: props.collectionPeriod,
              limit: 2500,
              offset: temp.rows.length,
            },
          },
        });
        const mergeExcessReportRows = [
          ...temp.rows,
          ...data.period.distribution.surplus.generateReport.rows,
        ];
        temp = { ...temp, rows: mergeExcessReportRows };
      }
      const excessReportData = temp.rows;
      exportExcessReport(excessReportData);
    }
  };

  const handleExcessReportDownload = () => {
    getExcessReportData({
      variables: {
        input: {
          collectionPeriod: props.collectionPeriod,
          limit: 2500,
          offset: 0,
        },
      },
    });
  };

  const exportCredits = async (data: any) => {
    let response;
    if (props.collection === true) {
      response = await collectionCreditXLSX(data);
    } else {
      response = await creditXLSX(props.period, props.isActive, data);
    }
    if (response !== undefined) {
      snackInfoDispatch({
        type: response.error ? SET_SNACK_ERROR : SET_SNACK_SUCCESS,
        payload: response.message,
      });
    }
    setDownloadInProgress(false);
  };

  const exportPayment = async (data: any) => {
    let response;
    if (props.collection === true) {
      response = await collectionPaymentXLSX(props.period, data);
    } else {
      response = await paymentXLSX(props.period, props.isActive, data);
    }
    if (response !== undefined) {
      snackInfoDispatch({
        type: response.error ? SET_SNACK_ERROR : SET_SNACK_SUCCESS,
        payload: response.message,
      });
    }
    setDownloadInProgress(false);
  };

  const exportSurplus = async (data: any) => {
    let response;
    response = await excessXLSX(
      props.collectionPeriod,
      props.contributorRut,
      props.isActive,
      data
    );
    if (response !== undefined) {
      snackInfoDispatch({
        type: response.error ? SET_SNACK_ERROR : SET_SNACK_SUCCESS,
        payload: response.message,
      });
    }
    setDownloadInProgress(false);
  };

  const exportExcessReport = async (data: any) => {
    const response = await excessReportXLSX(props.collectionPeriod, data);
    if (response !== undefined) {
      snackInfoDispatch({
        type: response.error ? SET_SNACK_ERROR : SET_SNACK_SUCCESS,
        payload: response.message,
      });
    }

    setDownloadInProgress(false);
  };

  const handleExport = (type: number) => {
    setDownloadInProgress(true);
    switch (type) {
      case 1:
        handleCreditDownload();
        break;
      case 2:
        handlePaymentDownload();
        break;
      case 3:
        handleSurplusDownload();
        break;
      case 4:
        handleExcessReportDownload();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <AlertSnackbar
        onClick={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        onClose={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        open={snackInfoState.open}
        status={snackInfoState.status}
        message={snackInfoState.message}
      />
      {loadingCreditData ||
      loadingPaymentData ||
      loadingSurplusData ||
      loadingExcessReportData ||
      downloadInProgress ? (
        <Button
          color={"secondary"}
          sx={{
            minWidth: props.type === 4 ? "203.15px" : "143.04px",
          }}
        >
          <div className="spinnerButtonGreen"></div>
        </Button>
      ) : (
        <Button
          color={"secondary"}
          startIcon={
            <img
              src={props.disabled ? download : GreenDownload}
              style={{ paddingRight: "12px" }}
              alt="download"
            />
          }
          disabled={props.disabled}
          onClick={() => handleExport(props.type)}
        >
          {" "}
          {props.type === 4 ? GENERATE_REPORT : EXPORT}
        </Button>
      )}
    </>
  );
};

export default ExportButton;
