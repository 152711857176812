import { gql } from "@apollo/client";

export const GET_REVIRED_FILE = gql`
  query Query {
    previredFile {
      id
      name
      size
      type
      mimeType
      targetMonth
      targetYear
      bucketId
    }
  }
`;

export const GET_FILES = gql`
  query Query($input: previredTotalsInputType) {
    period {
      collection {
        previred {
          totals(input: $input) {
            count
            rows {
              id
              fileName
              amount
              registerCount
              uploadDate
              documentId
            }
          }
        }
      }
    }
  }
`;

export const GET_PREVIOUS_FILES = gql`
  query Previred($input: previredTotalsInputType) {
    previred {
      groupByPeriod(input: $input) {
        count
        rows {
          closedDate
          period
          registerCountPL
          registerCountAA
        }
      }
    }
  }
`;

export const GET_END_CLOSE_PERIOD = gql`
  query GetEndClosePeriod($input: closePeriodInputType) {
    getEndClosePeriod(input: $input) {
      status
      dateClosePeriod
    }
  }
`;

export const GET_PREVIOUS_PERIOD_DATA = gql`
  query Query($input: previredInputType) {
    previred(input: $input) {
      cards {
        pl {
          amount
          filesCount
          registerCount
          uploadDate
        }
        aa {
          amount
          filesCount
          registerCount
          uploadDate
        }
      }
      checkPeriodStatus {
        status
        dateClosePeriod
      }
    }
  }
`;

export const GET_PERIOD_DATA = gql`
  query Cards($input: previredInputType) {
    period {
      collection {
        previred {
          cards(input: $input) {
            pl {
              amount
              filesCount
              registerCount
              uploadDate
            }
            aa {
              amount
              filesCount
              registerCount
              uploadDate
            }
          }
        }
      }
    }
  }
`;

export const GET_PERIOD_STATUS = gql`
  query CheckPeriodStatus($input: previredInputType) {
    previred(input: $input) {
      checkPeriodStatus {
        status
        dateClosePeriod
      }
    }
  }
`;

export const GET_FILE_GD = gql`
  query Query($input: gesDocInput) {
    period {
      collection {
        previred {
          documentGD(input: $input) {
            name
            file
          }
        }
      }
    }
  }
`;

export const GET_OPEN_PERIOD = gql`
  query FindLastOpenPeriod {
    previred {
      findLastOpenPeriod {
        status
        period
      }
    }
  }
`;

export const GET_INVENTORY_DETAIL = gql`
  query List(
    $input: esencialPeriodsListInputType
    $inventoryInput2: inventoryInputType
  ) {
    inventory(input: $inventoryInput2) {
      esencial {
        periods {
          list(input: $input) {
            count
            rows {
              id
              statusId
              statusName
              periodId
              period
              updatedAt
              records
            }
          }
        }
      }
    }
  }
`;

export const GET_INVENTORY_STATUS = gql`
  query Generated($input: esencialPeriodsGeneratedInputType) {
    inventory {
      esencial {
        periods {
          generated(input: $input) {
            id
            statusId
            statusName
            periodId
            updatedAt
            statusEtlId
          }
        }
      }
    }
  }
`;

export const KINETYC = gql`
  query Credits($input: inventoryInputType) {
    inventory(input: $input) {
      credits {
        data {
          responseKinetyc
          responseS3
        }
        message
        result
      }
    }
  }
`;

export const GET_INSTITUTIONS = gql`
  query Institutions($input: esencialPeriodsInstitutionsInputType) {
    inventory {
      esencial {
        periods {
          institutions(input: $input) {
            count
            rows {
              id
              inventoryId
              institutionId
              institutionName
              codeInstitutionId
              statusId
              statusName
              cmeSendDate
              cmeReplyUploadDate
              cmeThroughPutDate
              cmeRecognizedCount
              cmeThroughPutGeneratedDate
              finalProcessDate
              documentId
              saveFlag
              cmePaymentDate
              payments
            }
          }
        }
      }
    }
  }
`;

export const GET_ONE_INSTITUTION = gql`
  query Query($input: inventoryOneIntitutionQueryInputType) {
    inventory {
      esencial {
        periods {
          oneInstitution(input: $input) {
            id
            inventoryId
            institutionId
            institutionName
            codeInstitutionId
            statusId
            statusName
            cmeSendDate
            cmeReplyUploadDate
            cmeThroughPutDate
            cmeRecognizedCount
            cmeThroughPutGeneratedDate
            finalProcessDate
            documentId
            saveFlag
            cmePaymentDate
            payments
          }
        }
      }
    }
  }
`;

export const DOWNLOAD_INVENTORY = gql`
  query Cme($input: esencialPeriodsCMEInputType) {
    inventory {
      esencial {
        periods {
          cme(input: $input) {
            count
            rows {
              id
              rut
              inventoryId
              period
              folioFUN
              kinetycId
              institutionRut
              institutionId
              numberForm
              rutCompany
              businessName
              taxableIncome
              mandatoryLegalContribution
              voluntaryContribution
              contributionToPay
              codeEsencial
              rutEsencial
            }
          }
        }
      }
    }
  }
`;

export const LOGS_BY = gql`
  query allLogsBy($input: allLogsByInputType!) {
    log {
      allBy(input: $input) {
        count
        rows {
          id
          logTypeId
          userName
          logDate
          description
          descriptionAnnex
          request
          response
          fkId
        }
      }
    }
  }
`;

export const GET_PRESIGNED_URL = gql`
  query getPresignedURL($input: getPresignedURLInputType) {
    utils {
      getPresignedURL(input: $input) {
        type
        url
      }
    }
  }
`;

export const CURRENCY_INFO = gql`
  query oneCurrencyBy($input: oneCurrencyByInputType!) {
    period {
      currency {
        oneBy(input: $input) {
          id
          currencyType
          currencyValue
          periodName
        }
      }
    }
  }
`;

export const LIST_CURRENCY = gql`
  query allCurrencyBy($input: allCurrencyByInputType!) {
    period {
      currency {
        allBy(input: $input) {
          count
          rows {
            id
            currencyType
            currencyValue
            periodName
          }
        }
      }
    }
  }
`;

export const CURRENT_CURRENCY = gql`
  query currentCurrency {
    period {
      currency {
        current {
          periodName
          currencyId
          currencyValue
        }
      }
    }
  }
`;

export const PERIODS_BY_PROCESS = gql`
  query allPeriodsByProcess($input: allPeriodsByProcessInputType!) {
    period {
      allByProcess(input: $input) {
        name
        statusId
        collectionStatusId
        distributionStatusId
        devolutionStatusId
        isActive
      }
    }
  }
`;

export const PERIOD_DISTRIBUTION_STATUS = gql`
  query onePeriodDistributionStatusBy(
    $input: onePeriodDistributionStatusByInputType!
  ) {
    period {
      oneDistributionStatusBy(input: $input) {
        name
        statusId
        collectionStatusId
        distributionStatusId
        previousDevolutionStatusId
        hasSurplusAndExcess
      }
    }
  }
`;

export const PERIODS_BY = gql`
  query allPeriodsBy($input: allPeriodsByInputType!) {
    period {
      allBy(input: $input) {
        name
        statusId
        collectionStatusId
        devolutionStatusId
        distributionStatusId
        isActive
      }
    }
  }
`;

export const GET_PERIOD_BY = gql`
  query onePeriodBy($input: onePeriodByInputType!) {
    period {
      oneBy(input: $input) {
        name
        statusId
        collectionStatusId
        distributionStatusId
        devolutionStatusId
        isConfirmable
        isPayrollUploadable
        isActive
      }
    }
  }
`;

export const GET_CONTRACTS = gql`
  query allContractsBy($input: allContractsByInputType!) {
    period {
      collection {
        contributorContract {
          allBy(input: $input) {
            count
            rows {
              id
              contributorRut
              contributorName
              sellPeriod
              paymentPeriod
              collectionPeriod
              paymentUf
              contractedAmount
              workerType
              planType
              statusId
              statusName
              typeId
              typeName
              totalPaidAmount
            }
          }
        }
      }
    }
  }
`;

export const GET_PAYMENTS = gql`
  query allPaymentsBy($input: allPaymentsByInputType!) {
    period {
      collection {
        payment {
          allBy(input: $input) {
            count
            rows {
              id
              contributorRut
              contributorName
              payerRut
              payerName
              paymentPeriod
              collectionPeriod
              amount
              paymentDate
              paymentType
              payerPhoneNumber
              payerEmail
              payerStreetAddress
              payerStreetNumber
              payerCityName
              payerCommuneName
              payerRegionId
              law18566
              previredDeclarationNumber
              previredTotalsId
              previredFileName
              previredAgreedPrice
              previredUploadDate
              observation
              paymentClassification
              taxableSevenPercent
              taxableSalary
              additionalAmount
              workerType
              isActive
            }
          }
        }
      }
    }
  }
`;

export const GET_ORIGIN = gql`
  query allPaymentTypesBy($input: allPaymentTypesByInputType!) {
    period {
      collection {
        payment {
          paymentType {
            allBy(input: $input) {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_WORKER_TYPE = gql`
  query allWorkerTypes {
    period {
      collection {
        workerType {
          all {
            id
            name
            smallName
            isActive
          }
        }
      }
    }
  }
`;

export const PREVIRED = gql`
  query Previred(
    $input: allByInputPreviredAAType!
    $allByInput2: allPaymentsByInputType!
  ) {
    period {
      collection {
        previred {
          previredAA {
            allBy(input: $input) {
              count
              rows {
                id
                collectionPeriod
                fileName
                institutionAccountNumber
                institutionCode
                institutionIdentifier
                institutionName
                isActive
                paymentAmount
                paymentDate
                paymentPeriod
                previredDeclarationNumber
                previredTotalsId
                totalPaidFolios
                uploadDate
              }
            }
          }
        }
        payment {
          allBy(input: $allByInput2) {
            count
            rows {
              id
              amount
              additionalAmount
              collectionPeriod
              contributorRut
              contributorName
              isActive
              law18566
              payerCityName
              payerCommuneName
              payerEmail
              payerName
              payerPhoneNumber
              payerRegionId
              payerRut
              payerStreetAddress
              payerStreetNumber
              paymentDate
              paymentPeriod
              paymentType
              previredAgreedPrice
              previredDeclarationNumber
              previredFileName
              previredTotalsId
              previredUploadDate
              taxableSevenPercent
              taxableSalary
              workerType
            }
          }
        }
      }
    }
  }
`;

export const GET_EXCESS = gql`
  query allExcessBy($input: allExcessByInputType!) {
    period {
      distribution {
        excess {
          allBy(input: $input) {
            count
            rows {
              id
              contributorRut
              contributorName
              paymentPeriod
              collectionPeriod
              chargeAmount
              creditAmount
              availableBalance
              statusId
              typeId
              statusName
              typeName
              excessDetail {
                id
                paymentPeriod
                collectionPeriod
                availableDate
                movementDate
                movementType
                amount
                amountType
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_EXCESS_CONSOLIDATED = gql`
  query allExcessConsolidatedBy(
    $input: allExcessConsolidatedByInputType_temp!
  ) {
    period {
      distribution {
        excess {
          allConsolidatedBy(input: $input) {
            count
            rows {
              id
              contributorRut
              contributorName
              collectionPeriod
              paymentPeriod
              chargeAmount
              creditAmount
              availableBalance
              statusId
              statusName
              typeId
              excessDetail {
                id
                paymentPeriod
                collectionPeriod
                movementDate
                movementType
                amount
                amountType
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SURPLUS = gql`
  query allSurplusBy($input: allSurplusByInputType!) {
    period {
      distribution {
        surplus {
          allBy(input: $input) {
            count
            rows {
              id
              contributorRut
              contributorName
              paymentPeriod
              collectionPeriod
              surplusGenerated
              readjustment
              interest
              commission
              chargeAmount
              creditAmount
              availableBalance
              accountableBalance
              surplusDetail {
                id
                movementDate
                movementType
                amount
                amountType
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SURPLUS_CONSOLIDATED = gql`
  query allSurplusConsolidatedBy($input: allSurplusConsolidatedByInputType!) {
    period {
      distribution {
        surplus {
          allConsolidatedBy(input: $input) {
            count
            rows {
              id
              contributorRut
              contributorName
              paymentPeriod
              collectionPeriod
              surplusGenerated
              readjustment
              interest
              commission
              chargeAmount
              creditAmount
              availableBalance
              accountableBalance
              surplusDetail {
                id
                reverseId
                movementDate
                movementType
                amount
                amountType
                reversable
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_EXCESS_TOTALS = gql`
  query allTotalsBy($input: allExcessTotalsByInputType!) {
    period {
      distribution {
        excess {
          allTotalsBy(input: $input) {
            collectionPeriod
            totalExcess
            totalReadjustment
            totalInterest
            totalCommission
            totalChargeAmount
            totalAvailableBalance
          }
        }
      }
    }
  }
`;

export const SURPLUS_TOTALS = gql`
  query allSurplusTotalsBy($input: allSurplusTotalsByInputType!) {
    period {
      distribution {
        surplus {
          allTotalsBy(input: $input) {
            totalSurplusGenerated
            totalReadjustment
            totalInterest
            totalCommission
            totalCreditAmount
            totalChargeAmount
            totalAvailableBalance
          }
        }
      }
    }
  }
`;

export const EXCESS_TOTALS = gql`
  query allExcessTotalsBy($input: allExcessTotalsByInputType!) {
    period {
      distribution {
        excess {
          allTotalsBy(input: $input) {
            totalCreditAmount
            totalChargeAmount
            totalAvailableBalance
          }
        }
      }
    }
  }
`;

export const EXPORT_EXCESS = gql`
  query allExcessMovementsBy($input: allExcessMovementsByInputType!) {
    period {
      distribution {
        excess {
          excessMovement {
            allBy(input: $input) {
              count
              rows {
                id
                contributorRut
                contributorName
                paymentPeriod
                collectionPeriod
                movementType
                amount
                amountType
              }
            }
          }
        }
      }
    }
  }
`;

export const EXPORT_SURPLUS = gql`
  query allSurplusMovementsBy($input: allSurplusMovementsByInputType!) {
    period {
      distribution {
        surplus {
          surplusMovement {
            allBy(input: $input) {
              count
              rows {
                id
                contributorRut
                contributorName
                collectionPeriod
                amount
                amountType
                movementType
                movementDate
                availableDate
              }
            }
          }
        }
      }
    }
  }
`;


export const GENERATE_EXCESS_REPORT = gql`
  query generateSurplusReport($input: generateSurplusReportInputType!) {
    period {
      distribution {
        surplus {
          generateReport(input: $input) {
            count
            rows {
              id
              esencialCode
              collectionPeriod
              contributorRut
              contributorName
              previousAvailableBalance
              surplusGenerated
              currentReadjustmentTotal
              currentInterestTotal
              commission
              chargeAmount
              otherInstitutionDepositAmount
              availableBalance
              accountableBalance
              contributorValidity
              noValidityPeriod
              destinyInstitution
              internalAccountCode
              complementaryReportSequenceCode
              contractDate
              internalAccountName
              massiveReturnAmount
              otherAdjustmentAmount
              annualMasiveReturnAmount
            }
          }
        }
      }
    }
  }
`;

export const GET_ONE_EXCESS_TOTALS = gql`
  query oneExcessContextBy($input: oneExcessContextByInputType!) {
    period {
      distribution {
        excess {
          oneContextBy(input: $input) {
            contributorName
            availableBalance
            accountableBalance
          }
        }
      }
    }
  }
`;

export const GET_SAP = gql`
  query generateExcessSAPLoader($input: generateExcessSAPLoaderInputType!) {
    period {
      distribution {
        excess {
          generateExcessSAPLoader(input: $input) {
            count
            rows {
              id
              controlKey
              documentDate
              accountableDate
              referenceNumber
              headerText
              documentClass
              period
              FISociety
              currency
              changeType
              ledger
              toRecordAccountNumber
              IVAIndicator
              GLSociety
              clientCode
              supplierCode
              CMEIndicator
              documentImport
              creditDebitIndicator
              baseDate
              paymentCondition
              valueDate
              expirationDate
              costCenter
              benefitCenter
              assignment
              positionText
              referenceKey1
              referenceKey2
              referenceKey3
              status
              bankKey
              bankCountry
              bankAccount
              fullName
              mail
              maximumPaymentDate
              minimumPaymentDate
            }
          }
        }
      }
    }
  }
`;


export const GET_RUT_CONTEXT = gql`
  query oneContextBy($input: oneContextByInputType!) {
    period {
      oneContextBy(input: $input) {
        contributorName
        surplusAvailableBalance
        surplusAccountableBalance
        excessAvailableBalance
      }
    }
  }
`;


export const GET_EXCESS_MOVEMENT_TYPES = gql`
  query allMovementsTypesBy($input: allMovementTypesByInputType!) {
    period {
      distribution {
        movementType {
          allBy(input: $input) {
            id
            name
            amountType
          }
        }
      }
    }
  }
`;

export const GET_PENDING_CONTRACTS = gql`
  query allPendingContractsBy($input: allPendingContractsByInputType!) {
    period {
      collection {
        contributorContract {
          allPendingBy(input: $input) {
            id
            collectionPeriod
            dueAmount
          }
        }
      }
    }
  }
`;

export const GET_EXCESS_DEVOLUTION = gql`
  query allExcessBy($input: allExcessByInputType!) {
    period {
      distribution {
        excess {
          allBy(input: $input) {
            count
            rows {
              id
              contributorRut
              contributorName
              collectionPeriod
              availableBalance
              statusId
              statusName
              typeId
              typeName
              excessDetail {
                id
                movementType
                movementDate
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_STATUS = gql`
  query allStatusBy($input: allStatusByInputType!) {
    period {
      status {
        allBy(input: $input) {
          id
          name
          isActive
        }
      }
    }
  }
`;
