import { useEffect, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  // InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableCell } from "src/components/tables/tableStyle";
import {
  blackBold,
  // errorText,
  littleDetail,
  littleTitle,
  modalSubtitle,
  purpleLittle,
  titleTextBox,
} from "src/components/typography/styles";
import {
  ConfirmModalNew,
  // ConfirmModal,
  // CustomInputSlim,
  CustomModal,
  CustomModalTitle,
  NoResult,
} from "src/components";
import { capitalCase } from "src/utils/stringUtils";
import GreenTrash from "src/assets/icons/trashGreen.svg";
import GrayTrash from "src/assets/icons/trashGray.svg";
import { getCurrentPeriod } from "src/utils/dates";
import constants from "src/constants/constants";
import useOverage from "src/pages/distribution/overage/hook/useOverage";

const {
  COMPONENTS: {
    BUTTONS: { CLOSE, DELETE, SAVE_CHANGES },
  },
  EXCESS: {
    MOVEMENT_TYPE: {
      AMOUNT_ADJUSTMENT,
      BALANCE_TRANSFER,
      EXCESS,
      REJECTED_RETURN,
      RETURN_MONTHLY,
      RETURN_ON_DEMAND,
    },
  },
} = constants;

type MovementInfo = {
  id: number;
  movementDate: string;
  movementType: string;
  amountType: string;
  amount: number;
  paymentPeriod: string;
};

type EditMovementProps = {
  onOpen: boolean;
  handleCloseModal: () => void;
  info: {
    contributorRut: string;
    contributorName: string;
    collectionPeriod: string;
    accountableBalance: number;
    availableBalance: number;
    excessDetail: [MovementInfo];
  };
  setRefresh: (value: boolean) => void;
  setSnackInfo: React.Dispatch<React.SetStateAction<any>>;
};

// EditMovement component only deletes movements until field is made editable, edit functionality is commented out

const EditMovementNew = ({
  onOpen,
  handleCloseModal,
  info,
  setRefresh,
  setSnackInfo,
}: EditMovementProps) => {
  const {
    contributorRut,
    contributorName,
    collectionPeriod,
    availableBalance,
    excessDetail,
  } = info || {};

  const [movementData, setMovementData] = useState<any>(excessDetail);
  const [showDelete, setShowDelete] = useState(false);
  const [rowInfo, setRowInfo] = useState<MovementInfo>({
    id: 0,
    movementDate: "",
    movementType: "",
    amountType: "",
    amount: 0,
    paymentPeriod: "",
  });

  const { deleteMovement, loadingDeleteMovement } = useOverage({
    id: rowInfo.id,
    setRefresh,
    setSnackInfo,
    setShowDelete,
  });

  // const updatedMovementInfo = () => {
  //   let updatedMovement = movementInfo.filter(
  //     (item) => item !== undefined && item.id !== 0
  //   );
  //   return updatedMovement;
  // };

  // const checkValidAmount = () => {
  //   let updatedAmount = updatedMovementInfo().map((item: any) => {
  //     const { amount } = item;
  //     return amount;
  //   });
  //   let validAmount = updatedAmount.every((item: any) => item > 0);
  //   return validAmount;
  // };

  const handleDeleteRow = (row: any) => {
    setRowInfo(row);
    setShowDelete(true);
  };

  const handleClose = () => {
    // setMovementData(excessDetail);
    // setMovementInfo([{ id: 0, amount: 0, amountType: "" }]);
    handleCloseModal();
  };

  const editableExcessDetail = excessDetail.filter(
    (item: any) =>
      item.movementType !== EXCESS &&
      item.movementType !== BALANCE_TRANSFER &&
      item.movementType !== RETURN_ON_DEMAND &&
      item.movementType !== RETURN_MONTHLY &&
      item.movementType !== REJECTED_RETURN &&
      item.movementType !== AMOUNT_ADJUSTMENT 
  );

  useEffect(() => {
    onOpen && setMovementData(editableExcessDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen]);

  useEffect(() => {
    setMovementData(editableExcessDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excessDetail]);

  const deleteContent = () => {
    return (
      <div style={{ textAlign: "center", paddingTop: "19px" }}>
        <label className="firstTextModal">
          Estás eliminando el movimiento:
        </label>{" "}
        <br></br>
        <label className="blueModalText">
          {capitalCase(rowInfo.amountType)}, {capitalCase(rowInfo.movementType)}
          , monto de $ {rowInfo.amount.toLocaleString("es-cl")}
        </label>
        <br></br>
        <label className="firstTextModal">
          {" "}
          ¿Estás seguro de continuar con esta acción?
        </label>
      </div>
    );
  };

  return (
    <>
      <ConfirmModalNew
        content={deleteContent()}
        label={DELETE}
        loading={loadingDeleteMovement}
        onClose={() => setShowDelete(false)}
        onOpen={showDelete}
        title={"Eliminar movimiento"}
        toDo={deleteMovement}
      />
      <CustomModal
        maxWidth="md"
        fullWidth
        open={onOpen}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <CustomModalTitle id="" onClose={handleCloseModal}>
          <Typography sx={littleTitle} textAlign="center" pt="32px">
            Eliminar detalle de movimientos
          </Typography>
          <Typography sx={modalSubtitle} textAlign="center">
            Aquí puedes eliminar los movimientos por periodo de un RUT.
          </Typography>
        </CustomModalTitle>
        <DialogContent dividers sx={{ p: "0 !important" }}>
          {/* <SkeletonEditMovement /> */}
          <Grid container p={"18px 24px"}>
            <Grid item xs={4}>
              <Typography sx={purpleLittle} pb="4px">
                Nombre y RUT cliente
              </Typography>
              <Typography sx={blackBold} pt="4px">
                {contributorName} {contributorRut}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={purpleLittle} pb="4px">
                Período recaudación
              </Typography>
              <Typography sx={blackBold} pt="4px">
                {getCurrentPeriod(collectionPeriod as any)[0]}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={purpleLittle} pb="4px">
                Saldo disponible
              </Typography>
              <Typography sx={blackBold} pt="4px">
                ${availableBalance.toLocaleString("es-CL")}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container p={"18px 24px"}>
            <Grid item xs={9}>
              <Typography sx={titleTextBox} pb="4px">
                Movimientos
              </Typography>
              <Typography sx={littleDetail} pt="4px">
                {/* Aquí puedes editar el monto del detalle de movimientos. */}
                Aquí puedes eliminar movimientos.
              </Typography>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={12} pt="24px">
              <TableContainer
                className="grayScroll"
                component={Paper}
                sx={{
                  boxShadow: "none",
                  borderRadius: "8px",
                  border: "0.5px solid #D2D6D9",
                  maxHeight: "290px",
                }}
              >
                {movementData?.length > 0 ? (
                  <Table
                    sx={{ minWidth: "auto" }}
                    aria-label="simple table"
                    stickyHeader
                  >
                    <TableHead className="color-head-table">
                      <TableRow>
                        <StyledTableCell>Fecha movimiento</StyledTableCell>
                        <StyledTableCell>
                          Tipo de <br></br> movimiento
                        </StyledTableCell>
                        <StyledTableCell>Detalle</StyledTableCell>
                        <StyledTableCell>Periodo remuneración</StyledTableCell>
                        <StyledTableCell sx={{ width: "132px !important" }}>
                          Monto
                        </StyledTableCell>
                        <StyledTableCell>Eliminar</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {movementData?.map((row: any, index: number) => {
                      return (
                        <TableBody>
                          <TableRow key={index}>
                            <TableCell>
                              <Typography variant="h5">
                                {row.movementDate}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h5">
                                {capitalCase(row.amountType)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h5">
                                {capitalCase(row.movementType)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h5">
                                {getCurrentPeriod(row.paymentPeriod)[0]}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h5">
                                ${row.amount.toLocaleString("es-cl")}
                              </Typography>
                              {/* Disabled until field is made editable
                              <CustomInputSlim
                                onChange={(e) =>
                                  handleRowAmountChange(e, row.id, index)
                                }
                                error={movementData[index]?.amount === 0}
                                value={movementData[index]?.amount}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                                inputProps={{
                                  maxLength: 10,
                                  min: 0,
                                  "data-testid": "input-amount",
                                }}
                                disabled={false}
                              /> */}
                            </TableCell>
                            <TableCell>
                              <Button
                                color="inherit"
                                onClick={() => handleDeleteRow(row)}
                                disabled={false}
                              >
                                <img
                                  src={false ? GrayTrash : GreenTrash}
                                  alt=""
                                />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })}
                  </Table>
                ) : (
                  <NoResult
                    titulo={"No se encontraron resultados"}
                    subtitulo={
                      "No se encontraron movimientos eliminables asociados a este registro de excesos."
                    }
                  />
                )}
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
          >
            <Button
              onClick={handleClose}
              color="secondary"
              sx={{ width: "150px", mr: "16px" }}
            >
              {CLOSE}
            </Button>
            {false ? (
              <Button
                color="primary"
                sx={{ height: "50px", minWidth: "230px" }}
              >
                <div className="spinnerButton"></div>
              </Button>
            ) : (
              <Button
                // onClick={handleSaveChanges}
                color="primary"
                sx={{ width: "230px" }}
                // disabled={editableExcessDetail === movementData || !checkValidAmount()}
                // disabled until field is made editable
                disabled
              >
                {SAVE_CHANGES}
              </Button>
            )}
          </Grid>
        </DialogActions>
      </CustomModal>
    </>
  );
};
export default EditMovementNew;
