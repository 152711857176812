import { useState } from "react";
import CustomInput from "./customInput";
import littleClose from "src/assets/icons/littleClose.svg";
import { IconButton, Typography } from "@mui/material";
import { errorText } from "src/components/typography/styles";
import { handleRunChangeUtils } from "src/utils/validationUtils";

type rutInputProps = {
  setFormattedRun: React.Dispatch<React.SetStateAction<string>>
  setValidRut: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchRut: React.Dispatch<any>;
  setRutFilter: React.Dispatch<React.SetStateAction<boolean>>;
  formattedRun: string;
  disabled: boolean;
};

const RutInput = ({
  setFormattedRun,
  setValidRut,
  setSearchRut,
  setRutFilter,
  formattedRun,
  disabled,
}: rutInputProps) => {
  const [runValue, setRunValue] = useState("");
  const [rut, setRut] = useState<any>({
    run: "",
    formattedRun: "",
    error: "",
    isValid: false,
  });

  const clearRunFilter = () => {
    setRunValue("");
    setFormattedRun("");
    setRut({
      run: "",
      formattedRun: "",
      error: "",
      isValid: false,
    });
    setSearchRut("");
    setRutFilter(false)
  };

  const checkValidRut = (data: any) => {
    if (data.error === "" && data.run !== "" && data.isValid) return true;
    return false;
  };

  const handleRunChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let checkRun = handleRunChangeUtils(value);
    if (checkRun.runValue !== "" || value.length === 0) {
      setRunValue(checkRun.runValue);
      setFormattedRun(checkRun.formattedRun);
      setRut({
        run: checkRun.formattedRun ? checkRun.runValue : null,
        formattedRun: checkRun.formattedRun,
        error: checkRun.error,
        isValid: checkRun.isValid,
      });
    }
    setValidRut(checkValidRut(checkRun));
  };

  return (
    <>
      <CustomInput
        fullWidth
        placeholder="Ingresar"
        type="text"
        disabled={disabled}
        value={formattedRun || runValue}
        onChange={handleRunChange}
        inputProps={{
          "data-testid": "input-run",
        }}
        InputProps={{
          endAdornment: (
            <IconButton onClick={clearRunFilter} disabled={disabled}>
              <img src={littleClose} alt="limpiar rut" />
            </IconButton>
          ),
        }}
      />
      {rut.error && <Typography sx={errorText}>RUT inválido.</Typography>}
    </>
  );
};

export default RutInput;
