import constants from "src/constants/constants";

export const validateRun = (run: string) => {
  const cleanRun = run.replace(/[^0-9kK]/g, "").toUpperCase();
  const rut = cleanRun.slice(0, -1);
  const dv = cleanRun.slice(-1);

  let suma = 0;
  let multiplo = 2;

  for (let i = rut.length - 1; i >= 0; i--) {
    suma += Number(rut.charAt(i)) * multiplo;
    multiplo = multiplo >= 7 ? 2 : multiplo + 1;
  }

  const resultado = 11 - (suma % 11);
  const dvCalculado =
    resultado === 11 ? "0" : resultado === 10 ? "K" : resultado.toString();

  if (cleanRun === "0") {
    return false;
  }
  if (dv !== dvCalculado) {
    return false;
  }

  return true;
};

/**
 * @description Function to validate run input
 * @param value string with input run value
 * @returns object with runValue, formattedRun, disableButton and error
 */
export const handleRunChangeUtils = (value: string) => {
  let runValue: string = "";
  let formattedRun: string = "";
  let error: string = "";
  let isValid: boolean = false;

  let formatted = value.replace(/[^0-9kK-]/g, "").toUpperCase();
  const formatString = formatted?.match(/-/g) || null;

  if (formatted.charAt(0) === "0") {
    formatted = formatted.substring(1);
  }

  if (formatted.length <= 11) {
    runValue = formatted;
    if (formatted.trim() === "") {
      error = "";
      formattedRun = "";
    } else {
      isValid = validateRun(formatted);
      if (isValid) {
        error = "";
        const formattedWithSeparators = formatted.replace(
          /^(\d{1,2})(\d{3})(\d{3})([\dkK])$/,
          "$1.$2.$3-$4"
        );
        formattedRun = formattedWithSeparators;
      } else {
        if (formatted.length >= 8) {
          error = constants.RUN_VALIDATION_ERROR;
        }
        formattedRun = formatted;
      }
    }
    if (formatString !== null && formatString.length > 1) {
      error = constants.RUN_VALIDATION_ERROR;
    }
  } else {
    error = constants.RUN_VALIDATION_ERROR;
  }

  return {
    runValue,
    formattedRun,
    error,
    isValid,
  };
};
