import { capitalCaseAll } from "./stringUtils";
import { getPreviousPeriod, lastDayOfMonth } from "./dates";
import constants from "src/constants/constants";

const {
  PAGES: {
    DEVOLUTION,
    MODULES: { EXCESS, OVERAGE, CREDITS },
  },
} = constants;

export const buildJsonFromFile = (
  file: any,
  module: string,
  period: string,
  mapData: any
) => {
  switch (module) {
    case CREDITS:
      return buildJsonCredits(file, period);
    case EXCESS:
      return buildJsonExcess(file, period);
    case OVERAGE:
      return buildJsonOverage(file, period);
    case DEVOLUTION:
      return buildJsonDevolution(file, period, mapData);
    default:
      return {
        data: {
          primaryJson: [],
          secondaryJson: [],
        },
        error: "Módulo no válido.",
      };
  }
};

const buildJsonCredits = (data: any, period: string) => {
  const jsonContributors = data.map((item: any) => {
    return {
      rut: item.rut.trim(),
      name: capitalCaseAll(item.name.trim()),
    };
  });
  const uniqueContributors = jsonContributors.filter(
    (item: any, index: number, self: any) =>
      index === self.findIndex((t: any) => t.rut === item.rut && t.rut !== null)
  );

  const jsonContract = data.map((item: any) => {
    return {
      contributor_rut: item.rut.trim(),
      sell_period: item.sellPeriod.toString(),
      payment_period: item.paymentPeriod.toString(),
      collection_period: period,
      payment_uf: item.paymentUF,
      worker_type: item.workerType,
      plan_type: item.planType,
    };
  });

  return {
    data: {
      primaryJson: uniqueContributors,
      secondaryJson: jsonContract,
    },
    error: [],
  };
};

const buildJsonExcess = (data: any, period: string) => {
  let jsonExcess: any = [];
  const jsonError: any = [];

  const contributors = data.map((item: any) => {
    return {
      contributor_rut: item.contributor_rut.trim(),
      payment_period: getPreviousPeriod(period as any)[3],
      collection_period: period,
      distribution_type: "SURPLUS",
    };
  });

  const uniqueContributors = contributors.filter(
    (item: any, index: number, self: any) =>
      index ===
      self.findIndex((t: any) => t.contributor_rut === item.contributor_rut)
  );

  uniqueContributors.forEach((item: any) => {
    const contributorMovements = data.filter(
      (t: any) => t.contributor_rut === item.contributor_rut
    );

    let contributorDetail = item;
    contributorMovements.forEach((t: any) => {
      if (item.contributor_rut === t.contributor_rut) {
        switch (t.movement_type) {
          case "EXCEDENTE":
            if (!contributorDetail.hasOwnProperty("surplus_generated")) {
              contributorDetail = {
                ...contributorDetail,
                surplus_generated: t.amount,
              };
            } else {
              jsonError.push({
                contributor_rut: item.contributor_rut,
                error: "El registro contiene más de un excedente generado.",
              });
            }
            break;
          case "REAJUSTE":
            if (!contributorDetail.hasOwnProperty("readjustment")) {
              contributorDetail = {
                ...contributorDetail,
                readjustment: t.amount,
              };
            } else {
              jsonError.push({
                contributor_rut: item.contributor_rut,
                error: "El registro contiene más de un reajuste.",
              });
            }
            break;
          case "INTERES":
            if (!contributorDetail.hasOwnProperty("interest")) {
              contributorDetail = {
                ...contributorDetail,
                interest: t.amount,
              };
            } else {
              jsonError.push({
                contributor_rut: item.contributor_rut,
                error: "El registro contiene más de un interés.",
              });
            }
            break;
          case "COMISION":
            if (!contributorDetail.hasOwnProperty("commission")) {
              contributorDetail = {
                ...contributorDetail,
                commission: t.amount,
              };
            } else {
              jsonError.push({
                contributor_rut: item.contributor_rut,
                error: "El registro contiene más de una comisión.",
              });
            }
            break;
          default:
            jsonError.push({
              contributor_rut: item.contributor_rut,
              error: "El registro contiene movimientos no válidos.",
            });
        }
      }
    });
    jsonExcess.push(contributorDetail);
  });

  const jsonExcessMovements = data.map((item: any) => {
    return {
      contributor_rut: item.contributor_rut.trim(),
      payment_period: getPreviousPeriod(period as any)[3],
      collection_period: period,
      amount_type: item.amount_type,
      movement_type: item.movement_type,
      amount: item.amount,
      movement_date: lastDayOfMonth(period).toISOString().split("T")[0],
      distribution_type: "SURPLUS",
    };
  });

  return {
    data: {
      primaryJson: jsonExcess,
      secondaryJson: jsonExcessMovements,
    },
    error: jsonError,
  };
};

const buildJsonOverage = (data: any, period: string) => {
  const DISTRIBUTION_TYPE = "EXCESS";
  const AMOUNT_TYPE = "ABONO";
  const MOVEMENT_TYPE = "EXCESO";

  const jsonOverage = data.map((item: any) => {
    return {
      contributor_rut: item.contributor_rut.trim(),
      payment_period: getPreviousPeriod(period as any)[3],
      collection_period: period,
      distribution_type: DISTRIBUTION_TYPE,
    };
  });

  const uniqueJsonOverage = jsonOverage.filter(
    (item: any, index: number, self: any) =>
      index ===
      self.findIndex(
        (t: any) =>
          t.contributor_rut === item.contributor_rut &&
          t.contributor_rut !== null
      )
  );

  const jsonOverageMovements = data.map((item: any) => {
    return {
      contributor_rut: item.contributor_rut.trim(),
      payment_period: item.payment_period,
      collection_period: period,
      amount_type: AMOUNT_TYPE,
      movement_type: MOVEMENT_TYPE,
      amount: item.amount,
      movement_date: lastDayOfMonth(period).toISOString().split("T")[0],
      distribution_type: DISTRIBUTION_TYPE,
    };
  });

  return {
    data: {
      primaryJson: uniqueJsonOverage,
      secondaryJson: jsonOverageMovements,
    },
    error: [],
  };
};

const findIdByName = (searchText: string, items: any): number | undefined => {
  const searchChar = searchText.slice(0, searchText.length - 1).toUpperCase();
  const foundItem = items.find(
    (item: any) =>
      item.name.slice(0, item.name.length - 1).toUpperCase() === searchChar
  );
  return foundItem?.id;
};

const formatDate = (dateString: string) => {
  const aux = dateString.split("/");
  return aux[2] + "-" + aux[1] + "-" + aux[0];
};

const buildJsonDevolution = (data: any, period: string, mapData: any) => {
  const jsonDevolution = data.map((item: any) => {
    return {
      contributor_rut: item.contributor_rut.replace(/\./g, ""),
      collection_period: period,
      amount: item.amount,
      movement_date: formatDate(item.movement_date),
      status_id: findIdByName(item.status_id, mapData),
    };
  });

  return {
    data: {
      primaryJson: jsonDevolution,
      secondaryJson: [],
    },
    error: [],
  };
};
