/* istanbul ignore file */
//
import { Fragment, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export const MenuPrevired = () => {
  //const [modalEliminarV, setModalEliminarV] = useState(false);
  //const [modalHistorialV, setModalHistorialV] = useState(false);
  const [AnclaMenu, setAnclaMenu] = useState(null);
  const open = Boolean(AnclaMenu);

  const AbrirMenuC = (event: any) => {
    setAnclaMenu(event.currentTarget);
  };

  const CerrarMenuC = () => {
    setAnclaMenu(null);
  };

  return (
    <Fragment>
      <IconButton aria-label="more" onClick={AbrirMenuC}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        className="littleMenu"
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={AnclaMenu}
        open={open}
        onClose={CerrarMenuC}
      >
        <MenuItem disabled>Editar</MenuItem>
        <MenuItem disabled>Eliminar</MenuItem>
        <MenuItem disabled>
          Historial de <br></br> cambios
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
