import {
  IconCheckSnack,
  IconErrorSnack,
  IconInfoSnack,
  IconWarningSnack,
} from "../icons/icons";

export function style(value) {
  switch (value) {
    case "info":
      return {
        width: "100%",
        border: "1px solid #978EE6",
        backgroundColor: "#EEECFB",
      };
    case "error":
      return {
        width: "100%",
        border: "1px solid #E57373",
        backgroundColor: "#FFEBEE",
      };
    case "success":
      return {
        width: "100%",
        border: "1px solid #7CB342",
        backgroundColor: "#F1F8E9",
        fontSize: "10px"
      };
    case "warning":
      return {
        width: "100%",
        border: "1px solid #FFB300",
        backgroundColor: "#FFF8E1",
      };
    // case "littleWarning":
    //   return {
    //     width: "100%",
    //     borderRadius: "8px",
    //     border: "1px solid #FFB300",
    //     backgroundColor: "#FFF8E1",
    //     color: "#203442",
    //     fontSize: "12px",
    //   };
    // case "littleInfo":
    //   return {
    //     background: "#EEECFB",
    //     border: "1px solid #978EE6",
    //     borderRadius: "8px",
    //     width: "100%",
    //     color: "#203442",
    //     fontSize: "12px",
    //   };
    // case "littleSuccess":
    //   return {
    //     background: "#F1F8E9",
    //     border: "1px solid #978EE6",
    //     borderRadius: "8px",
    //     width: "100%",
    //     color: "#203442",
    //     fontSize: "12px",
    //   };
    default:
      return {};
  }
}
export function iconStyle(value) {
  switch (value) {
    case "info":
      return {
        info: <IconInfoSnack fontSize="inherit" />,
      };
    case "error":
      return {
        error: <IconErrorSnack fontSize="inherit" />,
      };
    case "success":
      return {
        success: <IconCheckSnack sx={{ width: "20px", height: "20px", padding: "0px !important"}} />,
      };
    case "warning":
      return {
        warning: <IconWarningSnack fontSize="inherit" />,
      };
    default:
      return;
  }
}
