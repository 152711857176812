import { useEffect, useReducer, useState } from "react";
import { Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import {
  Alerts,
  AlertSnackbar,
  Breadcrum,
  TabPanel,
  UploadButton,
} from "src/components";
import { filterBox } from "src/components/box/styles";
import {
  detailText,
  littleDetail,
  littleTitle,
  subText20,
} from "src/components/typography/styles";
import { useLocation } from "react-router-dom";
import TableOverage from "./components/tableOverage";
import { getCurrentPeriod } from "src/utils/dates";
import { hasPermissions } from "src/utils/hasPermissions";
import { useMutation } from "@apollo/client";
import {
  INSERT_FILE_EXCESS,
  INSERT_JSON_EXCESS,
} from "src/services/graphql/mutations";
import constants from "src/constants/constants";
import { systemModules } from "src/utils/selectors";
import { RecordTableCash } from "src/pages/collection/Cash";
import { useLogs } from "src/hooks/useLogs";
import {
  SNACK_ACTION_TYPE,
  SNACKINFO_INITIAL_STATE,
  snackInfoReducer,
} from "src/reducers/snackInfoReducer";
import MissingPeriod from "src/pages/MissingPeriod";

const {
  CLOSE_SNACK_INFO,
  SET_SNACK_ERROR,
  SET_SNACK_SUCCESS,
  SET_SNACK_WARNING,
} = SNACK_ACTION_TYPE;

const {
  PAGES: {
    MODULES: { OVERAGE },
    DISPLAY_NAME: { OVERAGE: OVERAGE_DISPLAY },
  },
  QUERIES: {
    CODE: { VALIDATION_FAILED },
  },
  SNACKBAR_MESSAGE: {
    DISTRIBUTION: {
      OVERAGE: { UPLOAD_ERROR, UPLOAD_SUCCESS, UPLOAD_WARNING },
      PERIOD_STATUS: { IN_PROGRESS },
    },
  },
} = constants;

const Overage = () => {
  const [valueTab, setValueTab] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const location = useLocation();
  const period = location.state?.periodInfo.name || "";
  const isConfirmed =
    location.state?.periodInfo.distributionStatus === 3 ? true : false;
  const isClosingInProgress =
    location.state?.periodInfo.distributionStatus === 2;
  const [snackInfoState, snackInfoDispatch] = useReducer(
    snackInfoReducer,
    SNACKINFO_INITIAL_STATE
  );
  const [openLoad, setOpenLoad] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [disabledActions, setDisabledActions] = useState(false);

  const { getLog, rows: rowsLogs } = useLogs();
  const getLatestUploadedFile = () => {
    getLog({
      logTypeId: systemModules(OVERAGE),
      fkId: parseInt(period),
    });
  };

  const [insertExcessJson] = useMutation(INSERT_JSON_EXCESS, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        collectionPeriod: period,
      },
    },
    onError: (error: any) => {
      const { message, code } = error?.graphQLErrors[0] || {};
      if (VALIDATION_FAILED === code) {
        snackInfoDispatch({
          type: SET_SNACK_ERROR,
          payload: message.split(".")[0],
        });
      } else {
        const { errorType } = message?.split(":")[0] || {};
        snackInfoDispatch({
          type: SET_SNACK_ERROR,
          payload:
            errorType === "SequelizeUniqueConstraintError"
              ? UPLOAD_ERROR.UNIQUE_CONSTRAINT
              : errorType === "SequelizeForeignKeyConstraintError"
              ? UPLOAD_ERROR.FOREIGN_KEY_CONSTRAINT
              : UPLOAD_ERROR.DEFAULT,
        });
      }
      handleCloseLoading();
    },
  });

  const refreshOnError = (status: number) => {
    location.state.periodInfo.distributionStatus = status;
    setRefresh(true);
  };

  const [insertExcessFile] = useMutation(INSERT_FILE_EXCESS, {
    fetchPolicy: "network-only",
    onError: () => {
      snackInfoDispatch({
        type: SET_SNACK_WARNING,
        payload: UPLOAD_WARNING,
      });
      handleCloseLoading();
    },
  });

  const handleInsertDB = async () => {
    if (period) {
      await insertExcessJson().then(async (res) => {
        if (res?.data?.period !== null && res?.data?.period !== undefined) {
          await insertExcessFile({
            variables: {
              input: {
                logId:
                  res.data.period.distribution.excess.bulkCreate.data.logId,
              },
            },
          }).finally(() => {
            setRefresh(true);
            snackInfoDispatch({
              type: SET_SNACK_SUCCESS,
              payload: UPLOAD_SUCCESS,
            });
          });
        }
      });
      handleCloseLoading();
      getLatestUploadedFile();
    }
  };

  const handleCloseLoading = () => {
    setOpenLoad(false);
    setUploadInProgress(false);
  };

  useEffect(() => {
    if (valueTab === 0) {
      getLatestUploadedFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!period) {
    return <MissingPeriod displayName={OVERAGE_DISPLAY} />;
  }

  return (
    <>
      {/* <DetailOverage /> */}
      <AlertSnackbar
        onClick={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        onClose={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        open={snackInfoState.open}
        status={snackInfoState.status}
        message={snackInfoState.message}
      />
      <Box pl="32px" pr="32px">
        <Typography variant="h1" pt="24px" mb="16px">
          {OVERAGE_DISPLAY} - {getCurrentPeriod(period)[0]}
        </Typography>
        <Breadcrum
          back1="Inicio"
          link1="/"
          now={OVERAGE_DISPLAY + " - " + getCurrentPeriod(period)[0]}
        />
        {isConfirmed && (
          <Alerts
            mensaje={[
              "Periodo confirmado " + getCurrentPeriod(period)[0] + ".",
              "",
            ]}
            open={true}
            data={null}
            setOpen={null}
            severidad={"info"}
            noClose
            typeAlert={2}
          />
        )}
        {isClosingInProgress && (
          <Alerts
            mensaje={IN_PROGRESS}
            open={true}
            data={null}
            setOpen={null}
            severidad={"warning"}
            noClose
            typeAlert={2}
          />
        )}
        {/* <Box sx={filterBox} mt="16px" mb="24px">
          <Grid container alignItems={"center"}>
            <Grid xs={7}>
              <Typography sx={littleTitle} component="div" display={"inline"}>
                Generar reporte
              </Typography>

              <Typography sx={detailText} component="div" pt="8px" pb="8px">
                Aquí puedes generar y descargar el reporte maestro acorde al periodo confirmado.
              </Typography>

            </Grid>
            <Grid xs={5} textAlign={"end"}>
              <Button color="secondary">
                Reporte maestro
              </Button>
            </Grid>
          </Grid>
        </Box> */}
        <Box sx={filterBox} mt="16px" mb="24px">
          <Grid container>
            <Grid xs={7}>
              <Typography sx={littleTitle} component="div" display={"inline"}>
                Cargar información de excesos:
              </Typography>
              <Typography sx={detailText} component="div" pt="16px" pb="8px">
                Aquí puedes cargar los archivos del periodo actual.
              </Typography>
              <Typography sx={subText20} component="div">
                {rowsLogs.length > 0
                  ? "Última carga realizada el " +
                    rowsLogs[0]?.logDate.slice(0, 10).replace(/-/g, "/") +
                    " a las " +
                    rowsLogs[0]?.logDate.slice(11, 16) +
                    " hrs: con un total de " +
                    rowsLogs[0]?.description +
                    " registros."
                  : "No se han realizado cargas"}
              </Typography>
            </Grid>
            <Grid xs={5} pt="36px">
              <Stack
                direction="row"
                textAlign="right"
                justifyContent="flex-end"
              >
                <Typography sx={littleDetail} component="div" pr="16px">
                  Formato aceptados: .xlsx <br></br>
                  Peso máximo de 4MB
                </Typography>
                <UploadButton
                  disabled={
                    isConfirmed ||
                    isClosingInProgress ||
                    !hasPermissions().uploadFile ||
                    disabledActions
                  }
                  accept=".xlsx"
                  maxSize={4}
                  onUpload={() => handleInsertDB()}
                  openLoad={openLoad}
                  setOpenLoad={setOpenLoad}
                  uploadInProgress={uploadInProgress}
                  setUploadInProgress={setUploadInProgress}
                  period={period}
                  module={OVERAGE}
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Tabs
          sx={{ width: "1000px" }}
          textColor="primary"
          value={valueTab}
          onChange={(event, value) => setValueTab(value)}
        >
          <Tab label={OVERAGE_DISPLAY} disabled={disabledActions} />
          <Tab
            label="Historial de Carga"
            sx={{ whiteSpace: "pre" }}
            disabled={disabledActions}
          />
          <Tab
            label="Registros eliminados"
            sx={{ whiteSpace: "pre" }}
            disabled={disabledActions}
          />
        </Tabs>
        <TabPanel value={valueTab} index={0}>
          <TableOverage
            period={period}
            isActive={true}
            confirmed={isConfirmed || isClosingInProgress}
            refresh={refresh}
            setRefresh={setRefresh}
            setSnackInfo={snackInfoDispatch}
            canUpdate={hasPermissions().update}
            disabledActions={disabledActions}
            setDisabledActions={setDisabledActions}
            refreshOnError={refreshOnError}
          />
          {/* <TotalOverage /> */}
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          <RecordTableCash
            refresh={refresh}
            setRefresh={setRefresh}
            period={period}
            module={OVERAGE}
            tabValue={valueTab}
          />
        </TabPanel>
        <TabPanel value={valueTab} index={2}>
          <TableOverage
            period={period}
            isActive={false}
            confirmed={isConfirmed || isClosingInProgress}
            refresh={refresh}
            setRefresh={setRefresh}
            setSnackInfo={snackInfoDispatch}
            canUpdate={hasPermissions().update}
            disabledActions={disabledActions}
            setDisabledActions={setDisabledActions}            
          />
          {/* <TotalOverage /> */}
        </TabPanel>
      </Box>
    </>
  );
};

export default Overage;
