import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import SearchC from "./searchC";
import { ChipsC } from "./chipsC";
import { ChangeLogModal, ExportButton, NoResult } from "src/components";
import { StyledTableCell } from "src/components/tables/tableStyle";
import { paginatorTheme } from "src/components/paginator/paginatorStyle";
import { boxNoPadding } from "src/components/box/styles";
import {
  contTitle,
  subTable,
  textTooltip,
} from "src/components/typography/styles";
import { BootstrapTooltip } from "src/components/header/drawerLeft";
import Info from "src/assets/icons/info.svg";
import { createPeriodList, getCurrentPeriod } from "src/utils/dates";
import { SkeletonTableC } from "./skeletonsC";
import { useContract } from "../../hooks/useContract";
import { renderAmount } from "src/utils/numberUtils";

export default function TableDeleted(props: any) {
  const [rowId, setRowId] = useState("");
  const [modalBuscar, setModalBuscar] = useState(false);
  const [modalHistoryChanges, setModalHistoryChanges] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    contributorRut: null,
    paymentPeriod: null,
  });

  const {
    getContractData,
    contractCount,
    contractRows,
    contractLoading,
    contractError,
  } = useContract();

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const TipoTrabajador = () => {
    return (
      <>
        <Typography sx={textTooltip}>D: Dependiente</Typography>
        <Typography sx={textTooltip}>P: Pensionado</Typography>
        <Typography sx={textTooltip}>V: Voluntario</Typography>
        <Typography sx={textTooltip}>I: Independiente</Typography>
      </>
    );
  };

  useEffect(() => {
    getContractData({
      collectionPeriod: props.period,
      contributorRut: null,
      paymentPeriod: null,
      addOverdue: false,
      isActive: false,
      limit: rowsPerPage,
      offset: page * rowsPerPage - rowsPerPage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getContractData({
      ...filters,
      collectionPeriod: props.period,
      addOverdue: false,
      isActive: false,
      limit: rowsPerPage,
      offset: page * rowsPerPage - rowsPerPage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(1);
    getContractData({
      ...filters,
      collectionPeriod: props.period,
      addOverdue: false,
      isActive: false,
      limit: rowsPerPage,
      offset: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <Box sx={boxNoPadding} mt="16px" mb="24px">
      <ChangeLogModal
        onOpen={modalHistoryChanges}
        onClose={() => setModalHistoryChanges(false)}
        id={rowId}
        logTypeId={19}
        data-testid="history-modal"
      />
      {contractLoading ? (
        <SkeletonTableC
          grid={true}
          table={"full"}
          data-testid="skeleton-table"
        />
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                pt="15px"
                pr="24px"
              >
                <Typography sx={contTitle} pt="11px" pl="24px">
                  Listado de registros eliminados
                </Typography>{" "}
                {(contractCount !== 0 ||
                  ((filters.contributorRut !== "" ||
                    filters.paymentPeriod !== "") &&
                    contractCount === 0)) && (
                  <Stack direction={"row"}>
                    <div>
                      <Button
                        data-testid="ver-filtros"
                        color="inherit"
                        onClick={() => setModalBuscar(true)}
                      >
                        Ver filtros
                      </Button>
                    </div>
                    <ExportButton
                      period={props.period}
                      contributorRut={filters.contributorRut || null}
                      paymentPeriod={filters.paymentPeriod || null}
                      isActive={false}
                      addOverdue={false}
                      type={1}
                      disabled={contractCount === 0}
                    />
                  </Stack>
                )}
              </Stack>
            </Grid>
            <ChipsC filtros={filters} setFiltros={setFilters} />
          </Grid>
          {contractError ? (
            <NoResult
              titulo={"No se pudo cargar la tabla"}
              subtitulo={
                "No fue posible mostrar la información debido a un problema de conexión. Intenta recargar la página para solucionar el problema."
              }
              error
            />
          ) : (
            <>
              <SearchC
                onOpen={modalBuscar}
                handleClose={() => setModalBuscar(false)}
                filtros={filters}
                setFiltros={setFilters}
                options={createPeriodList(false)}
                data-testid="search-modal"
              />

              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "none",
                  borderRadius: "none",
                  paddingTop: "17px",
                }}
              >
                {contractCount === 0 ? (
                  filters.contributorRut === ("" || null) &&
                  filters.paymentPeriod === ("" || null) ? (
                    <NoResult
                      titulo={"No hay información para mostrar."}
                      subtitulo={
                        "No se han realizado acciones de eliminación para visualizar registros."
                      }
                      dontSearch
                    />
                  ) : (
                    <NoResult
                      titulo={"Sin Resultados"}
                      subtitulo={"No se encontraron resultados para esta tabla"}
                    />
                  )
                ) : (
                  <>
                    <Table sx={{ minWidth: 650 }} aria-label="customized table">
                      <TableHead className="color-head-table">
                        <TableRow>
                          <StyledTableCell>RUT y Nombre</StyledTableCell>
                          <StyledTableCell>Periodo venta</StyledTableCell>
                          <StyledTableCell>
                            Periodo <br></br> remuneración
                          </StyledTableCell>
                          <StyledTableCell>
                            Periodo <br></br> Recaudación
                          </StyledTableCell>
                          <StyledTableCell>Prima UF</StyledTableCell>
                          <StyledTableCell>Pactado Pesos</StyledTableCell>
                          <StyledTableCell>
                            <Stack
                              direction="row"
                              justifyContent="space-evenly"
                            >
                              Tipo <br></br> trabajador{" "}
                              <BootstrapTooltip
                                title={<TipoTrabajador />}
                                arrow
                                placement="right"
                              >
                                <img src={Info} alt="info" />
                              </BootstrapTooltip>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell>Acciones</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {contractRows.map((file: any) => {
                        return (
                          <TableBody>
                            <TableRow data-testid="withData" key={file.id}>
                              <TableCell>
                                <Typography data-testid="rut" variant="h5">
                                  {file.contributorRut}
                                </Typography>
                                <Typography sx={subTable}>
                                  {file.contributorName}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {getCurrentPeriod(file.sellPeriod)[0]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {getCurrentPeriod(file.paymentPeriod)[0]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {getCurrentPeriod(file.collectionPeriod)[0]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {(
                                    Math.trunc(file.paymentUf * 1000) / 1000
                                  ).toLocaleString("ES-CL")}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  ${renderAmount(file.contractedAmount)}
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <Typography variant="h5">
                                  {file.workerType}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Button
                                  color="secondary"
                                  size="small"
                                  sx={{ padding: "10px 6px !important" }}
                                  onClick={() => {
                                    setModalHistoryChanges(true);
                                    setRowId(file.id);
                                  }}
                                >
                                  Ver historial
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })}
                    </Table>
                    <Grid alignItems="center" pb="15px" pt="24px">
                      <ThemeProvider theme={paginatorTheme}>
                        <TablePagination
                          rowsPerPageOptions={[10, 20, 50]}
                          component="div"
                          count={contractCount}
                          rowsPerPage={rowsPerPage}
                          page={page - 1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelRowsPerPage={"Mostrar por página"}
                          sx={{ mt: "24px", mb: "24px" }}
                          labelDisplayedRows={(e) => {
                            return (
                              "" +
                              (page) +
                              " de " +
                              Math.ceil(contractCount / rowsPerPage)
                            );
                          }}
                        />
                      </ThemeProvider>
                    </Grid>
                  </>
                )}
              </TableContainer>
            </>
          )}
        </>
      )}
    </Box>
  );
}
