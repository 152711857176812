import {
  Box,  
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { filterBox } from "src/components/box/styles";
import SkeletonBar from "src/components/skeleton/skeletonBar";
import { StyledTableCell } from "src/components/tables/tableStyle";

export const SkeletonTableDevolution = (props: any) => (
  <>
    <Box sx={filterBox} mt="16px" alignItems={"center"}>
      <Stack
        direction="row"
        pr={"8px"}
        justifyContent={"space-between"}
        display={"flex"}
        alignItems={"center"}
        pt="9px"
      >
        <SkeletonBar width={220} height={16} />
        <Stack
          direction={"row"}
          spacing={"16px"}
          justifyContent={"flex-end"}
          display={"flex"}
        >
          <Box>
            <Box pb="8px">
              <SkeletonBar width={52} height={13} />
            </Box>
            <SkeletonBar width={266} height={50} />
          </Box>
          <Box>
            <Box pb="8px">
              <SkeletonBar width={52} height={13} />
            </Box>
            <SkeletonBar width={266} height={50} />
          </Box>

          <Box pt="21px">
            <SkeletonBar width={100} height={50} />
          </Box>
          <Box pt="21px">
            <SkeletonBar width={132} height={50} />
          </Box>
          <Box pt="21px">
            <SkeletonBar width={132} height={50} />
          </Box>
        </Stack>
      </Stack>
    </Box>
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        border: "1px solid #E9EBEC",
        marginBottom: "24px  ",
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="customized table">
        <TableHead className="color-head-table" sx={{ height: 56 }}>
          <TableRow>
            {[...Array(props.columns)].map((x, i) => (
              <StyledTableCell key={i} sx={{ minWidth: "134.75px" }}>
                <SkeletonBar width={120} height={14} />
              </StyledTableCell>
            ))}
            <StyledTableCell
              sx={{ minWidth: "134.75px" }}
              className="headerTableAcciones"
              align="center"
            >
              <SkeletonBar width={100} height={14} />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(props.rows)].map((x, i) => (
            <TableRow>
              <TableCell>
                <Box pb={"8px"}>
                  <SkeletonBar width={120} height={14} />
                </Box>
                <SkeletonBar width={120} height={14} />
              </TableCell>
              {[...Array(props.columns - 1)].map((y, o) => (
                <TableCell>
                  <SkeletonBar width={120} height={14} />
                </TableCell>
              ))}
              <TableCell className="tableBodyAsignar">
                <SkeletonBar width={56} height={42} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Stack
        direction={"row"}
        spacing={"24px"}
        justifyContent={"center"}
        p="24px"
        alignItems={"center"}
      >
        <SkeletonBar width={108} height={14} />
        <SkeletonBar width={68} height={32} />
        <SkeletonBar width={88} height={32} />
      </Stack>
    </TableContainer>
  </>
);
