import { Fragment, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import constants from "src/constants/constants";
import { BootstrapTooltip } from "src/components/header/drawerLeft";

const {
  COMPONENTS: {
    MENU_ITEMS: { EDIT, DELETE },
  },
  PAYMENT_TYPE: { EXCESS },
} = constants;

export const MenuCash = (props: any) => {
  const [AnclaMenu, setAnclaMenu] = useState(null);
  const open = Boolean(AnclaMenu);
  const nonEditable = props.paymentType === EXCESS;

  const AbrirMenuC = (event: any) => {
    setAnclaMenu(event.currentTarget);
    props.setRowData({
      id: props.info.id,
      contributorRut: props.info.contributorRut,
      contributorName: props.info.contributorName,
      payerRut: props.info.payerRut,
      payerName: props.info.payerName,
      paymentDate: props.info.paymentDate,
      paymentPeriod: props.info.paymentPeriod,
      amount: props.info.amount,
      paymentType: props.info.paymentType,
    });
  };

  const CerrarMenuC = () => {
    setAnclaMenu(null);
  };

  return (
    <Fragment>
      {nonEditable ? (
        <BootstrapTooltip
          title={
            "Las acciones para pagos de origen exceso están disponibles desde consultas por RUT."
          }
          arrow
          placement="left"
        >
          <IconButton aria-label="more">
            <MoreVertIcon aria-hidden={false}/>
          </IconButton>
        </BootstrapTooltip>
      ) : (
        <IconButton aria-label="more" onClick={AbrirMenuC}>
          <MoreVertIcon aria-hidden={false}/>
        </IconButton>
      )}
      <Menu
        className="littleMenu"
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={AnclaMenu}
        open={open}
        onClose={CerrarMenuC}
      >
        <MenuItem onClick={() => props.setModalHistory(true)}>
          Historial de <br></br> cambios
        </MenuItem>
        <MenuItem
          onClick={() => props.setModalEdit(true)}
          disabled={!props.canUpdate}
        >
          {EDIT}
        </MenuItem>
        <MenuItem
          onClick={() => props.setModalDelete(true)}
          disabled={!props.canUpdate}
        >
          {DELETE}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
