import { Fragment, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import constants from "src/constants/constants";

const {
  COMPONENTS: {
    MENU_ITEMS: { DETAIL, EDIT_RECORD, DELETE_RECORD, CHANGE_HISTORY },
  },
} = constants;

export const MenuExcess = (props: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);    
    props.setRowInfo({
      id: props.info.id,
      collectionPeriod: props.info.collectionPeriod,
      contributorRut: props.info.contributorRut,
      contributorName: props.info.contributorName,
      paymentPeriod: props.info.paymentPeriod,
      surplusGenerated: props.info.surplusGenerated,
      readjustment: props.info.readjustment,
      interest: props.info.interest,
      commission: props.info.commission,
      excessDetail: props.info.surplusDetail,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton aria-label="more" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        className="littleMenu"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => props.handleDetails()} className="littleMenu">
          {DETAIL}
        </MenuItem>
        <MenuItem
          onClick={() => props.handleEdit()}
          className="littleMenu"
          disabled={props.disabledActions || !props.canUpdate}
        >
          {EDIT_RECORD}
        </MenuItem>
        <MenuItem
          onClick={() => props.handleDelete()}
          className="littleMenu"
          disabled={props.disabledActions || !props.canUpdate}
        >
          {DELETE_RECORD}
        </MenuItem>
        <MenuItem onClick={() => props.handleHistory()} className="littleMenu">
          {CHANGE_HISTORY}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
