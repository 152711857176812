const interstate = {
    fontFamily: "Interstate",
};

const boldIntersate = {
    fontFamily: "interstate, sans-serif",
};

const rubik = {
    fontFamily: "Rubik",
};

const breadcrumStyle = {
    color: "#364855",
    fontSize: "14px",
    fontWeight: "400",
    paddingBottom: "14px",
    fontFamily: "interstate",
    marginLeft: "0px",
    paddingLeft: "0px",
};
const breadcrumVisited = {
    color: "#364855",
    fontSize: "14px",
    fontWeight: "700",
    marginBottom: "-1px",
    lineHeight: "24px",
};

const breadcrumMain = {
    paddingBottom: "14px",
    fontFamily: "interstate",
    marginLeft: "0px",
    paddingLeft: "0px",
};

const grayTitle = {
    ...rubik,
    color: '#364855',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: "0.0120588em"
}

const graySub = {
    ...rubik,
    color: '#364855',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: "0.0120588em"
}

const fileTitle = {
    ...rubik,
    fontStyle: "normal",
    color: ' #203442',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '19px',
    letterSpacing: "0.0120588em"
}
const fileSub = {
    ...rubik,
    fontStyle: "normal",
    color: '#63717B',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '17px',
    letterSpacing: "0.0120588em"
}
const fileLoad = {
    ...rubik,
    color: '#364855',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: "0.0120588em"
}

const fileProcess = {
    ...rubik,
    color: '#63717B',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: "0.0120588em"
}

const fileComplete = {
    ...rubik,
    color: '#EEECFB',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: "0.0120588em"
}

const period = {
    ...interstate,
    color: '#5143D5',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '32px',
    letterSpacing: "0.0120588em"
}

const notFound = {
    ...rubik,
    color: '#5143D5',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: "0.0120588em"
}

const dragUpload = {
    ...rubik,
    color: '#203442',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: "0.0120588em"
}

const modalTitle = {
    ...interstate,
    color: '#203442',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: "0.0120588em",
    textAlign: "center"
}
const modalSubtitle = {
    ...interstate,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "0.0120588em",
    textAlign: "center"
}
const modalSubtitleLeft = {
    ...interstate,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "0.0120588em",
    textAlign: "left"
}
const modalText = {
    ...interstate,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "0.0120588em",
    display: "inline"
}

const modalTextBold = {
    ...interstate,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: "0.0120588em",
    display: "inline"
}

const tableTitle = {
    ...interstate,
    color: '#364855',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '28px',
    letterSpacing: "0.0120588em"
}

const loader = {
    ...rubik,
    color: '#364855',
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '32px',
    letterSpacing: "0.0120588em"
}

const cardTitle = {
    ...rubik,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '19px',
    letterSpacing: "0.0120588em"
}

const subCard = {
    ...rubik,
    color: '#4D5D68',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: "0.0120588em"
}

const cardDetail = {
    ...rubik,
    color: '#63717B',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '17px',
    letterSpacing: "0.0120588em"
}

const cardTitleDisabled = {
    ...rubik,
    color: '#79858E',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '19px',
    letterSpacing: "0.0120588em"
}

const cardDetailDisabled = {
    ...rubik,
    color: '#79858E',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '17px',
    letterSpacing: "0.0120588em"
}

const fileNumber = {
    ...interstate,
    color: '#63717B',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: "0.0120588em",
    display: "inline"
}

const line24 = {
    ...interstate,
    color: '#63717B',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "0.0120588em"
}

const line22 = {
    ...interstate,
    color: '#63717B',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '22px',
    letterSpacing: "0.0120588em"
}

const fileUp = {
    ...rubik,
    color: '#364855',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '17px',
    letterSpacing: "0.0120588em"
}
const linkStyle = {
    color: "#364855 !important",
    fontSize: "14px",
    fontWeight: "400",
};

const nowStyle = {
    color: "#364855",
    fontSize: "14px",
    fontWeight: "700",
    marginBottom: "-1px",
    lineHeight: "24px",
};

export {
    nowStyle,
    linkStyle,
}

const littleTitleCard = {
    ...interstate,
    color: '#79858E',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: "0.0120588em"
}

const littleTextCard = {
    ...interstate,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '18px',
    letterSpacing: "0.0120588em"
}

const purpleLittle = {
    ...interstate,
    color: '#392F95',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '14px',
    letterSpacing: "0.0120588em"
}


const blackBold = {
    ...interstate,
    color: '#203442',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '18px',
    letterSpacing: "0.0120588em"
}
const subLoader = {
    ...interstate,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "0.0120588em",
    pt: "8px"
}

const typeFile = {
    ...boldIntersate,
    color: '#364855',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "1.21%"
}
const titleFileText = {
    ...interstate,
    color: '#364855',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '19.85px',
    letterSpacing: " 0.0120588em",
    pt: "16px"
}
const subtitleFileText = {
    ...interstate,
    color: '#63717B',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: " 0.0120588em",
    pt: "16px"
}
const modalTypeFile = {
    ...interstate,
    color: '#79858E',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '22px',
    letterSpacing: " 0.0120588em"
}
const modalLoader = {
    ...interstate,
    color: '#364855',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '17.84px',
    letterSpacing: "0.0120588em"
}

const subText = {
    ...interstate,
    color: '#63717B',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '22px',
    letterSpacing: "0.0120588em"
}

const detailText = {
    ...interstate,
    color: '#364855',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: "0.0021em"
}

const TitleBox = {
    ...rubik,
    color: '#364855',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '19px',
    letterSpacing: "0.0120588em"
}

const subTitleContainer = {
    ...interstate,
    color: '#364855',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '28px',
    letterSpacing: "0.0120588em"
}

const littleText = {
    ...interstate,
    color: '#4D5D68',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: "0.0120588em",
    pt: "8px"
}
const subText20 = {
    ...interstate,
    color: '#63717B',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: "0.0120588em"
}

const littleTitle = {
    ...interstate,
    color: '#203442',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: "0.0120588em"
}

const titleLight = {
    ...interstate,
    color: '#203442',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: "0.0120588em"
}

const littleDetail = {
    ...interstate,
    color: '#364855',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: "0.0120588em"
}

const contTitle = {
    ...interstate,
    color: '#203442',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '28px',
    letterSpacing: "0.0120588em"
}

const subTable = {
    ...interstate,
    color: '#79858E',
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: " 0.0120588em"
}

const titleTooltip = {
    ...interstate,
    color: '#79858E',
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: " 0.0120588em"
}
const textTooltip = {
    ...rubik,
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: " 0.0120588em"
}

const titleTextBox = {
    ...interstate,
    color: '#364855',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: "0.0120588em"
}


const textDetailModal = {
    ...interstate,
    color: '#364855',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: "0.0120588em"
}
const periodStyle = {
    ...interstate,
    color: '#5143D5',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '32px',
    letterSpacing: "0.0120588em"
}
const errorText = {
    ...interstate,
    color: '#E53935',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '16px',
    letterSpacing: "0.0120588em",
    paddingLeft: "6px",
    marginTop: "6px"
}
const errorTextSmall = {
    ...interstate,
    color: '#E53935',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '16px',
    letterSpacing: "0.0120588em",
    paddingLeft: "6px",
    marginBottom: "-20px",
}
const titleMenuBox = {
    ...interstate,
    color: '#203442',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '17.65px',
    letterSpacing: "0.0120588em"
}
const subMenuBox = {
    ...interstate,
    color: '#4D5D68',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: "0.0120588em"
}

const greenFont = {
    ...interstate,
    color: '#01A49A',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '16px',
    letterSpacing: "1.21%"
}

const bigGreenFont = {
    ...interstate,
    color: '#01A49A',
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '28px',
    letterSpacing: "1.21%"
}

const grayFontBox = {
    ...interstate,
    color: '#364855',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: "1.21%"
}

const rutGreen = {
    ...interstate,
    color: '#4DBFB8',
    fontSize: '36px',
    fontWeight: '700',
    lineHeight: '39.71px',
    letterSpacing: "1.21%"
}

const slimText = {
    ...interstate,
    color: '#203442',
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '32px',
    letterSpacing: "1.21%"
}

const subSlimText = {
    ...interstate,
    color: '#203442',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "1.21%"
}

const bigGreen = {
    ...interstate,
    color: '#01A49A',
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '28px',
    letterSpacing: "1.21%"
}

const littleGreen = {
    ...interstate,
    color: '#01A49A',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: "1.21%"
}

const grayDetails = {
    ...interstate,
    color: '#79858E',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: "1.21%"
}

const sublimLH = {
    ...interstate,
    color: '#203442',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "1.21%"
}

const sublimLHBold = {
    ...interstate,
    color: '#203442',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: "1.21%"
}

const warningPurple = {
    ...interstate,
    color: '#5143D5',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "1.21%",
    display: "inline"
}

const warningGray = {
    ...interstate,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "1.21%",
    display: "inline"
}
const ufText = {
    ...interstate,
    color: '#364855',
    fontSize: '24px',
    fontWeight: '800',
    lineHeight: '20px',
    letterSpacing: "1.21%",
}
const warningGrayBold = {
    ...interstate,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: "1.21%",
    display: "inline"
}
export {
    warningGrayBold,
    ufText,
    warningGray,
    warningPurple,
    sublimLHBold,
    sublimLH,
    grayDetails,
    bigGreen,
    subSlimText,
    slimText,
    rutGreen,
    grayFontBox,
    subMenuBox,
    titleMenuBox,
    periodStyle,
    titleTextBox,
    errorText,
    textDetailModal,
    titleTooltip,
    textTooltip,
    subTable,
    contTitle,
    littleDetail,
    littleTitle,
    subText20,
    littleText,
    subTitleContainer,
    TitleBox,
    detailText,
    subText,
    modalLoader,
    modalTypeFile,
    titleFileText,
    subtitleFileText,
    typeFile,
    subLoader,
    fileUp,
    blackBold,
    purpleLittle,
    littleTextCard,
    littleTitleCard,
    line24,
    line22,
    subCard,
    fileNumber,
    cardTitleDisabled,
    cardDetailDisabled,
    cardDetail,
    tableTitle,
    breadcrumStyle,
    breadcrumVisited,
    breadcrumMain,
    grayTitle,
    graySub,
    fileTitle,
    fileSub,
    fileLoad,
    fileProcess,
    fileComplete,
    notFound,
    period,
    modalTitle,
    modalSubtitle,
    dragUpload,
    modalText,
    modalTextBold,
    loader,
    cardTitle,
    titleLight,
    greenFont,
    bigGreenFont,
    errorTextSmall,
    littleGreen,
    modalSubtitleLeft
}
