import { useEffect, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import {
  detailText,
  errorText,
  littleTitle,
  modalSubtitle,
} from "src/components/typography/styles";
import {
  CustomInput,
  CustomModal,
  CustomModalTitle,
  CustomSelect,
} from "src/components";
import { formatRut } from "src/utils/stringUtils";
import { handleRunChangeUtils, validateRun } from "src/utils/validationUtils";

export const workerOptions = [
  {
    id: "1",
    name: "Dependiente",
    smallName: "D",
  },
  {
    id: "2",
    name: "Independiente",
    smallName: "I",
  },
  {
    id: "3",
    name: "Pensionado",
    smallName: "P",
  },
  {
    id: "4",
    name: "Voluntario",
    smallName: "V",
  },
];

const SearchCollection = ({
  onOpen,
  handleClose,
  filters,
  setFilters,
  origen,
  classOptions,
}: //   workerOptions,
any) => {
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [contributorRut, setContributorRut] = useState({
    run: filters?.contributorRut || null,
    formattedRun: formatRut(filters?.contributorRut) || "",
    error: "",
    isValid:
      filters?.contributorRut !== null
        ? validateRun(filters?.contributorRut)
        : false,
  });
  const [contributorRunValue, setContributorRunValue] = useState(
    filters?.contributorRut || ""
  );
  const [formattedContributorRun, setFormattedContributorRun] = useState(
    formatRut(filters?.contributorRut) || ""
  );
  const [payerRut, setPayerRut] = useState({
    run: filters?.payerRut || null,
    formattedRun: formatRut(filters?.payerRut) || "",
    error: "",
    isValid:
      filters?.payerRut !== null ? validateRun(filters?.payerRut) : false,
  });
  const [payerRunValue, setPayerRunValue] = useState(filters?.payerRut || "");
  const [formattedPayerRun, setFormattedPayerRun] = useState(
    formatRut(filters?.payerRut) || ""
  );
  const [origenAux, setOrigenAux] = useState<string>(
    filters?.paymentType || null
  );

  const findClassId = (statusId: number) => {
    let option = null;
    if (classOptions) {
      classOptions.forEach((element: any) => {
        // eslint-disable-next-line eqeqeq
        if (statusId == element.statusId) {
          option = element.id;
        }
      });
    }
    return option;
  };
  const [paymentStatus, setPaymentStatus] = useState<any>(
    findClassId(filters?.statusId) || null
  );

  const findWorkerSmallNameId = (str: string) => {
    let id = "";
    if (workerOptions) {
      workerOptions.forEach((item: any) => {
        if (item.smallName === str) {
          id = item.id;
        }
      });
    }
    return id;
  };
  const [workerTypeId, setWorkerTypeId] = useState(
    findWorkerSmallNameId(filters?.workerType) || null
  );

  const findWorkerTypeId = (id: string) => {
    let option = null;
    if (workerOptions) {
      workerOptions.forEach((element: any, index: number) => {
        // eslint-disable-next-line eqeqeq
        if (id == element.id) {
          option = index + 1;
        }
      });
    }
    return option;
  };

  const findOrigen = (origin: string) => {
    let option = null;
    if (origen) {
      origen.forEach((element: any, index: number) => {
        if (origin === element) {
          option = index + 1;
        }
      });
    }
    return option;
  };
  const [optionOrigen, setOptionOrigen] = useState(findOrigen(origenAux));
  const handleCloseAux = () => {
    handleClose();
  };

  const getOrigen = (dataOptions: any) => {
    let optionsArray: any[] = [];
    if (dataOptions) {
      dataOptions.forEach((element: any) => optionsArray.push(element));
    }
    optionsArray.push("Sin selección");
    return optionsArray;
  };

  const getWorkerOptions = () => {
    let optionsArray: any[] = [];
    if (workerOptions) {
      workerOptions.forEach((element: any) => optionsArray.push(element));
    }
    optionsArray.push({ smallName: "", name: "Sin selección" });
    return optionsArray;
  };

  const getClassOptions = () => {
    let optionsArray: any[] = [];
    if (classOptions) {
      classOptions.forEach((element: any) => optionsArray.push(element));
    }
    optionsArray.push({ name: "Sin selección" });
    return optionsArray;
  };

  const findClass = (id: string) => {
    let option = null;
    if (classOptions) {
      classOptions.forEach((element: any) => {
        // eslint-disable-next-line eqeqeq
        if (id == element.id) {
          option = element.statusId;
        }
      });
    }
    return option ? parseInt(option) : null;
  };

  const findWorkerTypeSmallName = (id: any) => {
    let smallName = "";
    if (workerOptions) {
      workerOptions.forEach((item: any) => {
        if (item.id.toString() === id.toString()) {
          smallName = item.smallName;
        }
      });
    }
    return smallName;
  };

  const handleWorkerTypeId = (id: string) => {
    let workerTypeIdAux = findWorkerTypeId(id);
    // eslint-disable-next-line eqeqeq
    if (workerTypeIdAux == null) {
      setWorkerTypeId(null);
    }
    // eslint-disable-next-line eqeqeq
    else if (id != workerTypeId) {
      setWorkerTypeId(id);
    }
  };

  const handleClassChange = (id: string) => {
    let paymentStatusAux = findClass(id);
    // eslint-disable-next-line eqeqeq
    if (paymentStatusAux == null) {
      setPaymentStatus(null);
    }
    // eslint-disable-next-line eqeqeq
    else if (id != paymentStatus) {
      setPaymentStatus(id);
    }
  };

  const handleRunChange = (value: string, type: number) => {
    let checkRun = handleRunChangeUtils(value);
    if (checkRun.runValue !== "" || value.length === 0) {
      if (type === 1) {
        setContributorRunValue(checkRun.runValue);
        setFormattedContributorRun(checkRun.formattedRun);
        setContributorRut({
          run: checkRun.formattedRun ? checkRun.runValue : null,
          formattedRun: checkRun.formattedRun,
          error: checkRun.error,
          isValid: checkRun.isValid,
        });
      }
      if (type === 2) {
        setPayerRunValue(checkRun.runValue);
        setFormattedPayerRun(checkRun.formattedRun);
        setPayerRut({
          run: checkRun.formattedRun ? checkRun.runValue : null,
          formattedRun: checkRun.formattedRun,
          error: checkRun.error,
          isValid: checkRun.isValid,
        });
      }
    }
  };

  const checkValidRut = (data: any, type: number) => {
    if (data.error === "") {
      if (data.run !== "" && data.isValid) {
        return true;
      }
      if (data.run === "" || data.run === null) {
        return true;
      }
      if (type === 1 && data.run === filters.contributorRut) {
        return true;
      }
      if (type === 2 && data.run === filters.payerRut) {
        return true;
      }
    }
    return false;
  };

  const checkAnyInputUpdated = () => {
    let updatedCRut = filters?.contributorRut !== contributorRut.run;
    let updatedPRut = filters?.payerRut !== payerRut.run;
    let updatedPStatus = filters?.statusId !== findClass(paymentStatus);
    let updatedWType =
      findWorkerSmallNameId(filters?.workerType) !== (workerTypeId || "");
    let updatedPType = filters?.paymentType !== origenAux;
    let inputUpdated = Boolean(
      updatedCRut ||
        updatedPRut ||
        updatedPType ||
        updatedPStatus ||
        updatedWType
    );
    return inputUpdated;
  };

  const checkAllInputsEmpty = () => {
    let emptyCRut = contributorRut.run === "" || contributorRut.run === null;
    let emptyPRut = payerRut.run === "" || payerRut.run === null;
    let emptyPType = !origenAux;
    let emptyPStatus = !paymentStatus;
    let emptyWType = !workerTypeId;
    let allInputsEmpty = Boolean(
      emptyCRut && emptyPRut && emptyPType && emptyPStatus && emptyWType
    );
    return allInputsEmpty;
  };

  const handleValidFilters = () => {
    setDisableButton(
      !(
        checkValidRut(contributorRut, 1) &&
        checkValidRut(payerRut, 2) &&
        checkAnyInputUpdated() &&
        !checkAllInputsEmpty()
      )
    );
  };

  const handleFilter = () => {
    setFilters({
      ...filters,
      contributorRut:
        contributorRut.run !== null
          ? contributorRut.formattedRun.replace(/\./g, "")
          : null,
      payerRut:
        payerRut.run !== null ? payerRut.formattedRun.replace(/\./g, "") : null,
      paymentType: origenAux,
      statusId: paymentStatus ? findClass(paymentStatus) : null,
      workerType: workerTypeId ? findWorkerTypeSmallName(workerTypeId) : null,
    });
    handleCloseAux();
  };

  useEffect(() => {
    if (filters !== undefined) handleValidFilters();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contributorRut,
    payerRut,
    origenAux,
    paymentStatus,
    workerTypeId,
    onOpen,
  ]);

  return (
    <CustomModal
      maxWidth="md"
      fullWidth
      open={onOpen}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <CustomModalTitle id="addCredits" onClose={handleCloseAux}>
        <Typography sx={littleTitle} textAlign="center" pt="32px">
          Buscar recaudación
        </Typography>
        <Typography sx={modalSubtitle} textAlign="center">
          Seleccione los criterios de búsqueda para encontrar registros de pagos
          específicos.
        </Typography>
      </CustomModalTitle>
      <DialogContent dividers>
        <Grid
          container
          direction="column"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid sx={{ margin: "0", width: "100%" }} container spacing={2}>
            <Grid
              item
              xs={6}
              p="0 !important"
              pr="24px !important"
              pb="24px !important"
            >
              <Typography sx={detailText} pb="6px">
                RUT Cliente
              </Typography>
              <CustomInput
                placeholder="Ingresar RUN"
                fullWidth
                value={formattedContributorRun || contributorRunValue}
                onChange={(e: any) => handleRunChange(e.target.value, 1)}
                inputProps={{
                  "data-testid": "input-cRun",
                }}
              />
              {contributorRut.error && (
                <Typography sx={errorText}>{contributorRut.error}</Typography>
              )}
            </Grid>
            <Grid item xs={6} p="0 !important" pb="24px !important">
              <Typography sx={detailText} pb="6px">
                RUT Pagador
              </Typography>
              <CustomInput
                placeholder="Ingresar RUN"
                fullWidth
                value={formattedPayerRun || payerRunValue}
                onChange={(e: any) => handleRunChange(e.target.value, 2)}
                inputProps={{
                  "data-testid": "input-pRun",
                }}
              />
              {payerRut.error && (
                <Typography sx={errorText}>{payerRut.error}</Typography>
              )}
            </Grid>
            <Grid
              item
              xs={6}
              p="0 !important"
              pr="24px !important"
              pb="24px !important"
            >
              <Typography sx={detailText} pb="6px">
                Clasificación
              </Typography>
              <CustomSelect
                titulo={"Seleccione"}
                hideTitle={true}
                value={paymentStatus}
                onChange={(e: any) => {
                  handleClassChange(e.target.value);
                }}
                data={getClassOptions().map((elem: any) => elem.name)}
              />
            </Grid>
            <Grid item xs={6} p="0 !important" pb="24px !important">
              <Typography sx={detailText} pb="6px">
                Tipo de trabajador
              </Typography>
              <CustomSelect
                titulo={"Seleccione"}
                hideTitle={true}
                value={workerTypeId}
                onChange={(e: any) => handleWorkerTypeId(e.target.value)}
                data={getWorkerOptions().map(
                  (elem: any) => elem.smallName + " - " + elem.name
                )}
              />
            </Grid>
            <Grid item xs={12} p="0 !important">
              <Typography sx={detailText} pb="6px">
                Origen
              </Typography>
              <CustomSelect
                onChange={(e: any) => {
                  setOptionOrigen(e.target.value);
                  setOrigenAux(origen[e.target.value - 1]);
                }}
                value={optionOrigen}
                titulo={"Seleccione"}
                hideTitle={true}
                data={getOrigen(origen)}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            onClick={handleCloseAux}
            color="secondary"
            sx={{ width: "150px" }}
          >
            Cancelar
          </Button>
          <Button
            sx={{ width: "150px" }}
            onClick={handleFilter}
            disabled={disableButton}
          >
            Filtrar
          </Button>
        </Grid>
      </DialogActions>
    </CustomModal>
  );
};

export default SearchCollection;
