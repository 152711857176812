import { useEffect, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import {
  blackBold,
  detailText,
  littleTitle,
  modalSubtitle,
  purpleLittle,
} from "src/components/typography/styles";
import {
  CustomModal,
  CustomModalTitle,
  CustomSelect,
} from "src/components";
import { getCurrentPeriod } from "src/utils/dates";
import constants from "src/constants/constants";

const {
  COMPONENTS: {
    BUTTONS: { CLOSE, SAVE },
  },
} = constants;

const ModalUpdateDevolution = ({
  onOpen,
  info,
  loading,
  handleClose,
  toDo,
  selectedType,
  setSelectedType,
}: any) => {
  const {
    contributorRut,
    contributorName,
    collectionPeriod,
    availableBalance,
  } = info || {};
  const [initialType, setInitialType] = useState<any>(null);

  useEffect(() => {
    onOpen && setInitialType(selectedType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen]);

  return (
    <>
      <CustomModal
        maxWidth="md"
        fullWidth
        open={onOpen}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "720px",
            },
          },
        }}
      >
        <CustomModalTitle id="" onClose={handleClose}>
          <Typography sx={littleTitle} textAlign="center" pt="32px">
            Devolución
          </Typography>
          <Typography sx={modalSubtitle} textAlign="center">
            Aquí puedes realizar y marcar tipos de devolución
          </Typography>
        </CustomModalTitle>
        <DialogContent dividers sx={{ p: "0 !important" }}>
          <Grid container p={"18px 24px"}>
            <Grid item xs={5}>
              <Typography sx={purpleLittle} pb="4px">
                Nombre y RUT cliente
              </Typography>
              <Typography sx={blackBold} pt="4px">
                {contributorName} {contributorRut}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={purpleLittle} pb="4px">
                Período recaudación
              </Typography>
              <Typography sx={blackBold} pt="4px">
                {getCurrentPeriod(collectionPeriod as any)[0]}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={purpleLittle} pb="4px">
                Saldo disponible
              </Typography>
              <Typography sx={blackBold} pt="4px">
                ${availableBalance.toLocaleString("es-CL")}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container p={"18px 24px"}>
            <Grid item xs={12} p="0 !important">
              <Typography sx={detailText} pb="6px">
                Tipo de devolución
              </Typography>
              <CustomSelect
                onChange={(e: any) => setSelectedType(e.target.value)}
                value={selectedType}
                titulo={"Seleccione"}
                hideTitle
                data={[
                  { id: 1, nombre: "Mensual" },
                  { id: 2, nombre: "A demanda" },
                ]}
                disabled={loading}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
          >
            <Button
              onClick={handleClose}
              color="secondary"
              sx={{ width: "150px", mr: "16px" }}
            >
              {CLOSE}
            </Button>
            {loading ? (
              <Button
                color="primary"
                sx={{ height: "50px", minWidth: "150px" }}
              >
                <div className="spinnerButton"></div>
              </Button>
            ) : (
              <Button
                onClick={() => toDo()}
                color="primary"
                sx={{ width: "150px" }}
                disabled={initialType === selectedType}
              >
                {SAVE}
              </Button>
            )}
          </Grid>
        </DialogActions>
      </CustomModal>
    </>
  );
};
export default ModalUpdateDevolution;
