import { GET_PAYMENTS } from "src/services/graphql/queries";
import { useLazyQuery } from "@apollo/client";

type getPaymentInputType = {
  collectionPeriod?: string;
  contributorRut: string | null;
  payerRut?: string | null;
  paymentDate?: string | null;
  paymentPeriod?: string | null;
  paymentType?: string | null;
  statusId?: string | null;
  workerType?: string | null;
  includePrevired?: boolean;
  isActive: boolean;
  limit: number;
  offset: number;
};

export const usePayment = () => {
  const [
    getPayment,
    {
      data: {
        period: {
          collection: {
            payment: {
              allBy: { count: paymentCount, rows: paymentRows },
            },
          },
        },
      } = {
        period: { collection: { payment: { allBy: { count: 0, rows: [] } } } },
      },
      loading: paymentLoading,
      error: paymentError,
    },
  ] = useLazyQuery(GET_PAYMENTS, {
    fetchPolicy: "network-only",
  });

  const getPaymentData = (options: getPaymentInputType) => {
    getPayment({
      variables: {
        input: {
          collectionPeriod: options.collectionPeriod,
          contributorRut: options.contributorRut || null,
          paymentPeriod: options.paymentPeriod || null,
          payerRut: options.payerRut || null,
          paymentType: options.paymentType || null,
          paymentDate: options.paymentDate || null,
          statusId: options.statusId || null,
          workerType: options.workerType || null,
          isActive: options.isActive,
          includePrevired: options.includePrevired || false,
          limit: options.limit,
          offset: options.offset,
        },
      },
    });
  };

  return {
    getPayment,
    getPaymentData,
    paymentCount,
    paymentRows,
    paymentLoading,
    paymentError,
  };
};
