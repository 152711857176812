import { useLazyQuery, useMutation } from "@apollo/client";
import {
  BULK_UPDATE_EXCESS_MOVEMENT,
  DELETE_EXCESS,
  DELETE_EXCESS_MOVEMENT,
} from "src/services/graphql/mutations";
import { SNACK_ACTION_TYPE } from "src/reducers/snackInfoReducer";
import constants from "src/constants/constants";
import { GET_EXCESS_CONSOLIDATED } from "src/services/graphql/queries";
import { excessConsolidatedInputType } from "../../../rutSearch/rutSearchTypes";

const { SET_SNACK_ERROR, SET_SNACK_SUCCESS } = SNACK_ACTION_TYPE;
const {
  QUERIES: {
    CODE: { VALIDATION_FAILED },
    FETCH_POLICY: { NETWORK_ONLY },
  },
  SNACKBAR_MESSAGE: {
    DISTRIBUTION: {
      OVERAGE: { DELETE_ERROR, DELETE_SUCCESS },
    },
    EXCESS_MOVEMENT: {
      DELETE_ERROR: DELETE_MOVEMENT_ERROR,
      DELETE_SUCCESS: DELETE_MOVEMENT_SUCCESS,
      UPDATE_ERROR,
      UPDATE_SUCCESS,
    },
  },
} = constants;

const useOverage = ({
  id: excessId,
  excessDetail,
  setSnackInfo,
  setRefresh,
  handleClose,
  setShowDelete,
  refreshOnError,  
}: any) => {
  const [
    getOverage,
    {
      data: {
        period: {
          distribution: {
            excess: {
              allConsolidatedBy: { count: countOverage, rows: rowsOverage },
            },
          },
        },
      } = {
        period: { distribution: { excess: { allConsolidatedBy: { count: 0, rows: [] } } } },
      },
      loading: loadingOverage,
      error: errorOverage,
    },
  ] = useLazyQuery(GET_EXCESS_CONSOLIDATED, {
    fetchPolicy: NETWORK_ONLY as any,
  });

  const getOverageData = (options: excessConsolidatedInputType) => {
    getOverage({
      variables: {
        input: {
          contributorRut: options.contributorRut || null,
          dateLimit: options.dateLimit || null,
          statusId: options.statusId || null,
          isActive: options.isActive,
          limit: options.limit,
          offset: options.offset,
        },
      },
    });
  };

  const [updateMovement, { loading }] = useMutation(
    BULK_UPDATE_EXCESS_MOVEMENT,
    {
      fetchPolicy: NETWORK_ONLY as any,
      onCompleted: () => {
        setSnackInfo({
          type: SET_SNACK_SUCCESS,
          payload: UPDATE_SUCCESS,
        });
        setRefresh(true);
        handleClose();
      },
      onError: (error: any) => {
        handleErrorValidation(error, UPDATE_ERROR);
        handleClose();
      },
    }
  );

  const [deleteMovement, { loading: loadingDeleteMovement }] = useMutation(
    DELETE_EXCESS_MOVEMENT,
    {
      fetchPolicy: NETWORK_ONLY as any,
      variables: {
        input: {
          id: parseInt(excessId),
        },
      },
      onCompleted: () => {
        setRefresh(true);
        setSnackInfo({
          type: SET_SNACK_SUCCESS,
          payload: DELETE_MOVEMENT_SUCCESS,
        });
        setShowDelete(false);
      },
      onError: (error: any) => {
        handleErrorValidation(error, DELETE_MOVEMENT_ERROR);
        setShowDelete(false);
      },
    }
  );

  const handleDeleteMovement = () => {
    if (excessDetail.length === 1) {
      deleteMovement({
        onCompleted: () => {
          setSnackInfo({
            type: SET_SNACK_SUCCESS,
            payload: DELETE_MOVEMENT_SUCCESS,
          });
          setRefresh(true);
          handleClose();
        },
      });
    } else {
      deleteMovement();
    }
  };

  const [deleteOverage, { loading: loadingDelete }] = useMutation(
    DELETE_EXCESS,
    {
      fetchPolicy: NETWORK_ONLY as any,
      variables: {
        input: {
          id: parseInt(excessId),
        },
      },
      onCompleted: () => {
        setRefresh(true);
        setSnackInfo({
          type: SET_SNACK_SUCCESS,
          payload: DELETE_SUCCESS,
        });
        handleClose();
      },
      onError: (error: any) => {
        handleErrorValidation(error, DELETE_ERROR);
        handleClose();
      },
    }
  );

  const handleErrorValidation = (error: any, payload: string) => {
    const { message, code } = error?.graphQLErrors[0] || {};
    if (VALIDATION_FAILED === code) {
      setSnackInfo({
        type: SET_SNACK_ERROR,
        payload: message.split(".")[0],
      });
      refreshOnError(parseInt(message.split(":")[1]));
    } else {
      setSnackInfo({
        type: SET_SNACK_ERROR,
        payload: payload,
      });
    }
  };

  return {
    getOverageData,
    countOverage,
    rowsOverage,
    loadingOverage,
    errorOverage,
    deleteMovement,
    handleDeleteMovement,
    deleteOverage,
    loading,
    loadingDeleteMovement,
    loadingDelete,
    updateMovement,
  };
};

export default useOverage;
