import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { UPLOAD_PREVIRED } from "src/services/graphql/mutations";
import { fileToBase64 } from "src/utils/files";
import { DragAndDrop } from "src/components/dragAndDrop/dragAndDrop";
import {
  CustomModal,
  CustomModalTitle,
} from "src/components/modals/customModal";
import { CustomSelect } from "src/components/Select/CustomSelect";
import { modalSubtitle, modalTitle } from "src/components/typography/styles";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import "./styleModal.css";

export const ModalFiles = (props: any) => {
  const [typeFile, setTypeFile] = useState<number>(0);
  const [uploadInfo, setUploadInfo] = useState<
    { name: string; status: number; error: string }[]
  >([]);
  const [uploadState, setUploadState] = useState(false);
  const [count, setCount] = useState<number>(0);
  const [failedCount, setFailedCount] = useState<number>(0);
  const [selectDisabled, setSelectDisabled] = useState<boolean>(false);
  const [uploadDisabled, setUploadDisabled] = useState<boolean>(false);

  const [uploadFile, { loading }] = useMutation(UPLOAD_PREVIRED, {
    onCompleted: (data) => {
      setCount((prevCount) => prevCount + 1);
      for (let i = 0; i < props.files.length; i++) {
        if (
          props.files[i].file.name ===
          data.period.collection.previred.uploadFile.data.name
        ) {
          setUploadInfo((prevUploadInfo) => {
            return [
              ...prevUploadInfo,
              {
                name: data.period.collection.previred.uploadFile.data.name,
                status: 2,
                error: "",
              },
            ];
          });
        }
      }
    },
    onError: (error) => {
      setFailedCount((prevCount) => prevCount + 1);
      for (let i = 0; i < props.files.length; i++) {
        if (error.graphQLErrors[0].name === props.files[i].file.name) {
          setUploadInfo((prevUploadInfo) => {
            return [
              ...prevUploadInfo,
              {
                name: props.files[i].file.name,
                status: 3,
                error: error.graphQLErrors[0].message,
              },
            ];
          });
        }
      }
    },
  });

  const handleCloseModal = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason === "backdropClick") {
      return;
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    props.setModal(false);
    props.setFiles(undefined);
    setTypeFile(0);
    setSelectDisabled(false);
    setCount(0);
    setFailedCount(0);
    props.setDone(false);
    setUploadState(false);
    setUploadInfo([]);
  };

  const handleDropDisabled = () => {
    if (
      typeFile === 0 ||
      (props.files && props.files.length === props.maxFiles)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleUploadDisabled = () => {
    if (uploadDisabled === false && props.files && props.files.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleOnChangeTypeFile = (newTypeFile: any) => {
    setTypeFile(newTypeFile.target.value);
  };

  const handleUpload = async () => {
    setUploadState(true);
    try {
      for (let i = 0; i < props.files.length; i++) {
        /* istanbul ignore next */
        let fileBase64 = await fileToBase64(props.files[i].file as File);
        uploadFile({
          variables: {
            input: {
              name: props.files[i].file.name,
              size: props.files[i].file.size,
              type: typeFile, //1 = PL, 2 = AA
              mimeType: props.files[i].file.type,
              targetMonth: parseInt(props.period[1]),
              targetYear: parseInt(props.period[2]),
              base64: fileBase64,
            },
          },
        });
        setUploadInfo((prevUploadInfo: any) => [
          ...prevUploadInfo,
          {
            name: props.files[i].file.name,
            status: 1,
          },
        ]);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (count + failedCount === props.files?.length) {
      props.setDone(true);
      props.setRefresh(true);
    }
    // eslint-disable-next-line
  }, [count, failedCount]);

  return (
    <CustomModal
      maxWidth="md"
      open={props.modal}
      onClose={handleCloseModal}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <CustomModalTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography sx={modalTitle}>Cargar archivos Previred</Typography>
        <Typography sx={modalSubtitle}>
          Periodo{" "}
          {props.period && props.period.length > 0 ? props.period[0] : "..."}
        </Typography>
      </CustomModalTitle>
      <DialogContent dividers sx={{ padding: "0 20px" }}>
        <Grid
          container
          spacing={2}
          direction="row"
          sx={{ padding: "4px 24px" }}
          data-testid="modal-content"
        >
          <Grid pt="16px" xs={12} width={820}>
            <Typography variant="h3" pb="6px">
              Tipo de archivo
            </Typography>
            <CustomSelect
              onChange={(newTypeFile: Event) => {
                handleOnChangeTypeFile(newTypeFile);
              }}
              value={typeFile || 0}
              titulo={"Seleccionar"}
              name="fileType"
              data={["PL", "AA"]}
              disabled={selectDisabled}
            />
          </Grid>
          {typeFile !== 0 && (
            <Grid pt="16px" xs={12}>
              <Typography
                variant="h3"
                pb="6px"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: "16px",
                  gap: "8px",
                }}
              >
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 1.5C4.13428 1.5 1 4.63541 1 8.5C1 12.3668 4.13428 15.5 8 15.5C11.8657 15.5 15 12.3668 15 8.5C15 4.63541 11.8657 1.5 8 1.5ZM8 14.1452C4.88015 14.1452 2.35484 11.6209 2.35484 8.5C2.35484 5.38119 4.88026 2.85484 8 2.85484C11.1187 2.85484 13.6452 5.38023 13.6452 8.5C13.6452 11.6198 11.1209 14.1452 8 14.1452ZM8 4.60484C8.65473 4.60484 9.18548 5.1356 9.18548 5.79032C9.18548 6.44505 8.65473 6.97581 8 6.97581C7.34527 6.97581 6.81452 6.44505 6.81452 5.79032C6.81452 5.1356 7.34527 4.60484 8 4.60484ZM9.58064 11.7742C9.58064 11.9612 9.42899 12.1129 9.24193 12.1129H6.75806C6.57101 12.1129 6.41935 11.9612 6.41935 11.7742V11.0968C6.41935 10.9097 6.57101 10.7581 6.75806 10.7581H7.09677V8.95161H6.75806C6.57101 8.95161 6.41935 8.79996 6.41935 8.6129V7.93548C6.41935 7.74843 6.57101 7.59677 6.75806 7.59677H8.56452C8.75157 7.59677 8.90323 7.74843 8.90323 7.93548V10.7581H9.24193C9.42899 10.7581 9.58064 10.9097 9.58064 11.0968V11.7742Z"
                    fill="#203442"
                  />
                </svg>
                Puedes cargar un máximo de 10 archivos.
              </Typography>
              <DragAndDrop
                files={props.files}
                setFiles={props.setFiles}
                maxFiles={props.maxFiles}
                period={props.period}
                fileType={typeFile}
                count={count}
                failed={failedCount}
                setSelectDisabled={setSelectDisabled}
                setUploadDisabled={setUploadDisabled}
                dropDisabled={handleDropDisabled()}
                uploadInfo={uploadInfo}
                uploadState={uploadState}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button color="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          {loading ? (
            <Button color="primary" sx={{ width: "145px", height: "50px" }}>
              <div className="spinnerButton"></div>
            </Button>
          ) : (
            <Button
              color="primary"
              disabled={handleUploadDisabled()}
              onClick={props.done ? handleClose : handleUpload}
            >
              {props.done ? "Continuar" : "Subir archivo"}
            </Button>
          )}
        </Grid>
      </DialogActions>
    </CustomModal>
  );
};
