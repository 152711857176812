import {
  Box,
  // Button,
  //Divider,
  Grid,
  Stack,
  //Typography,
  Paper,
} from "@mui/material";
import {
  //filterBox,
  littleCard,
  //whiteBox
} from "../box/styles";
// import {
//     fileLoad,
//     fileProcess,
//     fileSub,
//     fileTitle,
//     fileUp,
//     notFound,
//     period
// } from "../typography/styles";
//import Consolid from "src/assets/icons/consolid.svg";
import SkeletonBar from "../skeleton/skeletonBar";

export const title = {
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.0120588em",
  color: "#364855",
};

export const regis = {
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  letterSpacing: "0.0120588em",
  color: "#4D5D68",
  display: "inline",
};

// const ter = {
//     fontFamily: "Rubik",
//     fontStyle: "normal",
//     fontWeight: 400,
//     fontSize: "14px",
//     lineHeight: "16px",
//     letterSpacing: "0.0120588em",
//     color: "#63717B",
//     display: "inline",
// };

export const total = {
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  letterSpacing: "0.0120588em",
  color: "#5D5FEF",
  pt: "8px",
};

const LoaderMoreView = ({ columns, rows }: any) => {
  const generateColumns = () => {
    let colArr = [];
    for (let i = 0; i < columns; i++) {
      colArr.push(
        <Grid xs={12} sm={6} mt="18px">
          <Box sx={littleCard}>
            <Grid container>
              <Grid xs={6}>
                <div style={{ paddingBottom: "6px" }}>
                  <SkeletonBar width="auto" height="14px" />
                </div>
                <Stack direction="row" spacing={"6px"} pb="8px">
                  <SkeletonBar width="101px" height="14px" />
                  <SkeletonBar width="137px" height="14px" />
                </Stack>
                <SkeletonBar width="66px" height="14px" />
              </Grid>
              <Grid xs={6} justifyContent="flex-end" display="flex">
                <SkeletonBar width="168px" height="40px" />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      );
    }
    return colArr;
  };

  const generateRows = () => {
    let rowArr = [];
    for (let i = 0; i < rows; i++) {
      rowArr.push(
        <Grid container>
          <>{generateColumns()}</>
        </Grid>
      );
    }
    return rowArr;
  };

  return (
    <Paper className="paper-tables-2" sx={{ boxShadow: "none " }}>
      <Grid container>{generateRows()}</Grid>
    </Paper>
  );
};

export default LoaderMoreView;
