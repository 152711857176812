export const putS3fetch = async (url: string, data: any, type: number) => {
  try {
    if (type === 1) {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      return response.status;
    } else {
      const response = await fetch(url, {
        method: "PUT",
        body: data,
      });
      return response.status;
    }
  } catch (error: any) {
    console.log("Error guardado en S3", error.message);
    return null;
  }
}

/* istanbul ignore next */
export const getS3fetch = async (url: string) => {
  try {
    const response = await fetch(url);

    return await response.text();
  } catch (error: any) {
    console.log("Error al obtener desde S3", error.message);
    return null;
  }
}