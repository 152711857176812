import {
  Button,
  //   Dialog,
  DialogActions,
  DialogContent,
  //   DialogContent,
  DialogContentText,
  //   DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import icon_warningOrange from "../../assets/icons/icon_warningOrange.svg";
import {
  //   modalSubtitle,
  modalText,
  //   modalTextBold,
  modalTitle,
} from "../typography/styles";
import { CustomModal, CustomModalTitle } from "./customModal";

type WarningModalProps = {
  onOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  title: string;
  subTitle?: any;
  primaryButton: string;
  secondaryButton: string;
  loading?: boolean;
  headWarning?: boolean;
  contentText?: React.JSX.Element;
  loadingWidth?: string;
};

export const WarningModalCustom = ({
  onOpen,
  onClose,
  onConfirm,
  title,
  subTitle,
  primaryButton,
  secondaryButton,
  loading,
  headWarning,
  contentText,
  loadingWidth,
}: WarningModalProps) => {
  return (
    <CustomModal
      fullWidth
      maxWidth="sm"
      open={onOpen}
      onClose={onClose}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      {headWarning ? (
        <>
          <CustomModalTitle id="warning" onClose={onClose}>
            <Typography sx={modalTitle} paddingTop="16px">
              {title}
            </Typography>
          </CustomModalTitle>

          <DialogContent
            dividers
            sx={{ textAlign: "center", pl: "24px", pr: "24px" }}
          >
            <img
              alt="img"
              src={icon_warningOrange}
              style={{ marginTop: "20px", marginBottom: "16px" }}
            />
            <Typography sx={modalText}>{subTitle}</Typography>
            {contentText}
          </DialogContent>

          <DialogActions sx={{ textAlign: "center", margin: "5px 0 5px 0" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button color="secondary" onClick={onClose}>
                {secondaryButton}
              </Button>
              {loading ? (
                <Button
                  color="primary"
                  sx={{
                    width: loadingWidth ? loadingWidth : "175px",
                    height: "50px",
                  }}
                >
                  <div className="spinnerButton"></div>
                </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={onConfirm}
                  sx={{ marginLeft: "24px" }}
                >
                  {primaryButton}
                </Button>
              )}
            </Grid>
          </DialogActions>
        </>
      ) : (
        <>
          <CustomModalTitle id="warning" onClose={onClose}>
            <img
              alt="img"
              src={icon_warningOrange}
              style={{ marginTop: "20px" }}
            />
            <Typography sx={modalTitle} paddingTop="16px">
              {title}
            </Typography>
          </CustomModalTitle>

          <DialogContentText textAlign="center" pl="24px" pr="24px">
            <Typography sx={modalText}>{subTitle}</Typography>
            {/* <Typography sx={modalTextBold}>
                  Enero 2023.
              </Typography>
              <br></br>
              <Typography sx={modalText}>
                  Ten en cuenta que no podrás volver a cargar este periodo
              </Typography> */}
          </DialogContentText>

          <DialogActions sx={{ textAlign: "center", margin: "5px 0 15px 0" }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {loading ? (
                <Button color="primary" sx={{ width: "175px", height: "50px" }}>
                  <div className="spinnerButton"></div>
                </Button>
              ) : (
                <>
                  <Button
                    color="primary"
                    onClick={onConfirm}
                    sx={{ minWidth: "175px" }}
                  >
                    {primaryButton}
                  </Button>
                </>
              )}
              <Button
                color="secondary"
                onClick={onClose}
                sx={{ marginLeft: "24px" }}
              >
                {secondaryButton}
              </Button>
            </Grid>
          </DialogActions>
        </>
      )}
    </CustomModal>
  );
};
