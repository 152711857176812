import {
  Box,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { boxNoPadding } from "src/components/box/styles";
import SkeletonBar from "src/components/skeleton/skeletonBar";
import { StyledTableCell } from "src/components/tables/tableStyle";

const SkeletonTableCredits = (props: any) => (
  <Box sx={boxNoPadding} mt="16px" mb="24px" pb="80px">
    <Grid container>
      <Grid item xs={12}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          pt="15px"
          pr="24px"
        >
          <div style={{ paddingLeft: "24px", paddingTop: "16px" }}>
            <Stack direction={"row"}>
              <div style={{ paddingRight: "40px" }}>
                <SkeletonBar width={120} height={16} />
              </div>
              <SkeletonBar width={120} height={16} />
            </Stack>
          </div>

          <Stack direction={"row"}>
            <div style={{ paddingRight: "16px", paddingTop: "12px" }}>
              <SkeletonBar width={120} height={16} />
            </div>
            <SkeletonBar width={140} height={40} />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none", borderRadius: "none", paddingTop: "17px" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="customized table">
        <TableHead className="color-head-table">
          <TableRow>
            {[...Array(props.columns)].map((x, i) => (
              <StyledTableCell key={i}>
                <SkeletonBar width={80} height={14} />
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(props.rows)].map((x, i) => (
            <TableRow data-testid="withData">
              {[...Array(props.columns)].map((y, o) => (
                <TableCell>
                  <SkeletonBar width={100} height={14} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
);

const SkeletonModalTable = () => (
  <>
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        borderRadius: "8px",
        border: "1px solid #E9EBEC",
      }}
    >
      {/* <NoResult noDivider dontSearch titulo={"No hay información para mostrar."} subtitulo="No se han encontrados pagos asociados a esta acreencia." /> */}
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="color-head-table">
          <TableRow>
            <StyledTableCell>
              <SkeletonBar width={80} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={80} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={80} height={14} />
            </StyledTableCell>
            <StyledTableCell>
              <SkeletonBar width={80} height={14} />
            </StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>
              <div style={{ paddingBottom: "8px" }}>
                <SkeletonBar width={141} height={14} />
              </div>

              <SkeletonBar width={141} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={141} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={141} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={141} height={14} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Grid alignItems="center">
        <SkeletonBar width={371} height={16} />
      </Grid>
    </TableContainer>
  </>
);

export { SkeletonTableCredits, SkeletonModalTable };
