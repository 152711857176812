import { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import SearchPrevired from "./searchPrevired";
import { ChipsPrevired } from "./chipsPrevired";
// import { MenuPrevired } from "./menuPrevired";
// import { NoResult } from "src/components/tables/noResult";
import { StyledTableCell } from "src/components/tables/tableStyle";
import { paginatorTheme } from "src/components/paginator/paginatorStyle";
import { boxNoPadding } from "src/components/box/styles";
import {
  contTitle,
  subTable,
  // textTooltip,
} from "src/components/typography/styles";
import download from "src/assets/icons/download.svg";
// import GreenDownload from "src/assets/icons/greenDownload.svg";
// import { SkeletonTableP } from "./skeletonsPrevired";
// import Info from "src/assets/icons/info.svg";
// import { BootstrapTooltip } from "src/components/header/drawerLeft";

const TableDeletePrevired = (props: any) => {
  const [modalBuscar, setModalBuscar] = useState(false);
  const [filters, setFilters] = useState({
    rut: null,
    paymentPeriod: null,
  });
  // const [periods, setPeriods] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <Box sx={boxNoPadding} mt="16px" mb="24px">
      {/* <SkeletonTableP data-testid="skeleton-table" /> */}

      <>
        <Grid container>
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              pt="15px"
              pr="24px"
              pb="17px"
            >
              <Typography sx={contTitle} pt="11px" pl="24px">
                Listado de acreencias
              </Typography>{" "}
              <Stack direction={"row"}>
                <div>
                  <Button
                    data-testid="ver-filtros"
                    color="inherit"
                    onClick={() => setModalBuscar(true)}
                  >
                    Ver filtros
                  </Button>
                </div>
                <Button
                  color={"secondary"}
                  startIcon={
                    <img
                      // src={GreenDownload}
                      src={download}
                      style={{ paddingRight: "12px" }}
                      alt="download"
                    />
                  }
                  disabled
                >
                  {" "}
                  Exportar
                </Button>
              </Stack>
            </Stack>
          </Grid>

          {/* Filtros */}
          <ChipsPrevired filtros={filters} setFiltros={setFilters} />
        </Grid>

        {/* <NoResult
                    titulo={"No se pudo cargar la tabla"}
                    subtitulo={
                        "No fue posible mostrar la información debido a un problema de conexión. Intenta recargar la página para solucionar el problema."
                    }
                    error
                /> */}

        <>
          <SearchPrevired
            onOpen={modalBuscar}
            handleClose={() => setModalBuscar(false)}
            filtros={filters}
            setFiltros={setFilters}
            //options={mockOptions}
            data-testid="search-modal"
          />

          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "none",
              borderRadius: "none",
            }}
          >
            {/*  
                        <NoResult
                            titulo={"Sin Resultados"}
                            subtitulo={"No se encontraron resultados para esta tabla"}
                        /> */}
            <>
              <Table sx={{ minWidth: 650 }} aria-label="customized table">
                <TableHead className="color-head-table">
                  <TableRow>
                    <StyledTableCell>
                      RUT y Nombre <br></br> Cliente
                    </StyledTableCell>
                    <StyledTableCell>
                      RUT y Nombre <br></br> Pagador
                    </StyledTableCell>
                    <StyledTableCell>
                      Cotización <br></br> a pagar
                    </StyledTableCell>
                    <StyledTableCell>Cotización 7%</StyledTableCell>
                    <StyledTableCell>
                      Cotización <br></br> adicional
                    </StyledTableCell>
                    <StyledTableCell>
                      R. Imponible <br></br> trabajador
                    </StyledTableCell>
                    <StyledTableCell>
                      Fecha de <br></br>
                      Pago
                    </StyledTableCell>
                    <StyledTableCell>Acciones</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow data-testid="withData">
                    <TableCell>
                      <Typography data-testid="rut" variant="h5">
                        17283944-3
                      </Typography>
                      <Typography sx={subTable}>Juan González</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography data-testid="rut" variant="h5">
                        17283944-3
                      </Typography>
                      <Typography sx={subTable}>Juan González</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5">$720309</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5">$720309</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5">$720309</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5">$720309</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5">06/05/2023</Typography>
                    </TableCell>
                    <TableCell>
                      <Button
                        color="secondary"
                        sx={{ padding: "10px 11px !important" }}
                      >
                        Ver Historial
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Grid alignItems="center" pb="15px" pt="24px">
                <ThemeProvider theme={paginatorTheme}>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={4}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={"Mostrar por página"}
                    sx={{ mt: "24px", mb: "24px" }}
                    labelDisplayedRows={(e) => {
                      return "" + (page || 0) + " de " + 4;
                    }}
                  />
                </ThemeProvider>
              </Grid>
            </>
          </TableContainer>
        </>
      </>
    </Box>
  );
};

export default TableDeletePrevired;
