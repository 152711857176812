import "regenerator-runtime"; // This import is necessary to run ExcelJS because avoid the CSP (content security policy) error
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import constants from "src/constants/constants";
import moment from "moment";

//TODO: Add the following constants to the constants file
// add constants for table headers & keys

const numFmtStr = '_("$"* #,##0_);_("$"* (#,##0);_("$"* "-"??_);_(@_)';
const minusNumFmtStr = '_("$"* #,##0_);_("$"* -#,##0;_("$"* "-"??_);_(@_)';
const columnWidth = {
  A: 12,
  B: 14,
};
const {
  FILE: {
    MIMETYPE: { EXCEL },
  },
  SNACKBAR_MESSAGE: {
    COLLECTION,
    CREDITS_EXPORT_ERROR,
    CREDITS_EXPORT_SUCCESS,
    DEVOLUTION: {
      EXPORT_ERROR: DEVOLUTION_EXPORT_ERROR,
      EXPORT_SUCCESS: DEVOLUTION_EXPORT_SUCCESS,
    },
    DISTRIBUTION: {
      EXCESS_EXPORT_ERROR,
      EXCESS_EXPORT_SUCCESS,
      EXCESS_REPORT_EXPORT_ERROR,
      EXCESS_REPORT_EXPORT_SUCCESS,
      OVERAGE: {
        EXPORT_ERROR,
        EXPORT_SUCCESS,
        SAP_EXPORT_ERROR,
        SAP_MONTHLY_EXPORT_SUCCESS,
        SAP_ON_DEMAND_EXPORT_SUCCESS,
      },
    },
    PAYMENTS_EXPORT_ERROR,
    PAYMENTS_EXPORT_SUCCESS,
    PREVIRED_CONSOLIDATED_SUCCESS,
    PREVIRED_CONSOLIDATED_ERROR,
  },
} = constants;

const maxColumnLength = (column: any) => {
  let maxLength = 0;
  column.eachCell({ includeEmpty: true }, function (cell: any) {
    const columnLength = cell.value ? cell.value.toString().length : 10;
    if (columnLength > maxLength) {
      maxLength = columnLength;
    }
  });
  return maxLength;
};

export const buildPreviredPL = (data: any) => {
  let arrayPreviredPL: any = [];
  if (Array.isArray(data)) {
    data.forEach((pl: any) => {
      let dataPreviredPL = {
        contributorRut: pl.contributorRut,
        payerRut: pl.payerRut,
        paymentPeriod: pl.paymentPeriod,
        amount: pl.amount,
        taxableSevenPercent: pl.taxableSevenPercent,
        law18566: pl.law18566,
        additionalAmount: pl.additionalAmount,
        taxableSalary: pl.taxableSalary,
        previredAgreedPrice: pl.previredAgreedPrice,
        payerName: pl.payerName,
        contributorName: pl.contributorName,
        paymentDate: pl.paymentDate
          ? pl.paymentDate.substring(0, 10).replace(/-/g, "/")
          : "",
        previredDeclarationNumber: pl.previredDeclarationNumber,
        payerCommuneName: pl.payerCommuneName,
        payerCityName: pl.payerCityName,
        payerEmail: pl.payerEmail,
        payerStreetAddress: pl.payerStreetAddress,
        payerStreetNumber: pl.payerStreetNumber,
        payerPhoneNumber: pl.payerPhoneNumber,
        fileName: pl.previredFileName,
        uploadDate: pl.previredUploadDate,
      };
      arrayPreviredPL.push(dataPreviredPL);
    });
  }
  return arrayPreviredPL;
};

export const buildPreviredAA = (data: any) => {
  let arrayPreviredAA: any = [];
  if (Array.isArray(data)) {
    data.forEach((aa: any) => {
      let dataPreviredAA = {
        institutionIdentifier: aa.institutionIdentifier,
        paymentAmount: aa.paymentAmount,
        institutionAccountNumber: aa.institutionAccountNumber,
        institutionName: aa.institutionName,
        paymentDate: aa.paymentDate
          ? aa.paymentDate.split("-").reverse().join("/")
          : "",
        institutionCode: aa.institutionCode,
        totalPaidFolios: aa.totalPaidFolios,
        fileName: aa.fileName,
        uploadDate: aa.uploadDate
          ? aa.uploadDate.split("-").reverse().join("/")
          : "",
      };
      arrayPreviredAA.push(dataPreviredAA);
    });
  }
  return arrayPreviredAA;
};

export const buildInventory = (data: any) => {
  const arrayInventory: any = [];
  if (Array.isArray(data)) {
    data.forEach((inventory: any) => {
      let dataInventory = {
        run: inventory.rut.split("-")[0],
        dv: inventory.rut.split("-")[1],
        id: "",
        rutEntity: "",
        dvEntity: "",
        nameEntity: "",
        taxableIncome: "",
        mandatoryContribution: "",
        voluntaryContribution: "",
        totalContribution: "",
        month: inventory.period.slice(4, 6) + inventory.period.slice(0, 4),
        rutInstitution: "",
        dvInstitution: "",
        rutInsurance: "",
        dvInsurance: "",
        codeInsuranceDestination: "",
        folioFun: "",
        codeInsuranceOrigin: "",
        reasonNotTransfer: "",
      };
      arrayInventory.push(dataInventory);
    });
  }
  return arrayInventory;
};

export const buildConfirmedInventory = (data: any, code: number) => {
  const arrayInventory: any = [];
  if (Array.isArray(data)) {
    data.forEach((inventory: any) => {
      if (inventory.kinetycId === code) {
        let dataInventory = {
          run: inventory.rut ? inventory.rut.split("-")[0] : "",
          dv: inventory.rut ? inventory.rut.split("-")[1] : "",
          id: "",
          rutEntity: "",
          dvEntity: "",
          nameEntity: "",
          taxableIncome: "",
          mandatoryContribution: "",
          voluntaryContribution: "",
          totalContribution: "",
          month: inventory.period
            ? inventory.period.slice(4, 6) + inventory.period.slice(0, 4)
            : "",
          rutInstitution: "",
          dvInstitution: "",
          rutInsurance: inventory.rutEsencial
            ? inventory.rutEsencial.split("-")[0]
            : "",
          dvInsurance: inventory.rutEsencial
            ? inventory.rutEsencial.split("-")[1]
            : "",
          codeInsuranceDestination: inventory.codeEsencial
            ? inventory.codeEsencial
            : "",
          folioFun: inventory.folioFUN ? inventory.folioFUN : "",
          codeInsuranceOrigin: "",
          reasonNotTransfer: "",
        };
        arrayInventory.push(dataInventory);
      } else {
        let dataInventory = {
          run: inventory.rut ? inventory.rut.split("-")[0] : "",
          dv: inventory.rut ? inventory.rut.split("-")[1] : "",
          id: "",
          rutEntity: "",
          dvEntity: "",
          nameEntity: "",
          taxableIncome: "",
          mandatoryContribution: "",
          voluntaryContribution: "",
          totalContribution: "",
          month: inventory.period
            ? inventory.period.slice(4, 6) + inventory.period.slice(0, 4)
            : "",
          rutInstitution: "",
          dvInstitution: "",
          rutInsurance: "",
          dvInsurance: "",
          codeInsuranceDestination: "",
          folioFun: "",
          codeInsuranceOrigin: "",
          reasonNotTransfer: "",
        };
        arrayInventory.push(dataInventory);
      }
    });
  }
  return arrayInventory;
};

export const buildHandoverInventory = (data: any, code: number) => {
  const arrayInventory: any = [];
  if (Array.isArray(data)) {
    data.forEach((inventory: any) => {
      if (inventory.kinetycId === code) {
        let dataInventory = {
          run: inventory.rut ? inventory.rut.split("-")[0] : "",
          dv: inventory.rut ? inventory.rut.split("-")[1] : "",
          id: inventory.numberForm ? inventory.numberForm : "",
          rutEntity: inventory.rutCompany
            ? inventory.rutCompany.split("-")[0]
            : "",
          dvEntity: inventory.rutCompany
            ? inventory.rutCompany.split("-")[1]
            : "",
          nameEntity: inventory.businessName ? inventory.businessName : "",
          taxableIncome: inventory.taxableIncome ? inventory.taxableIncome : "",
          mandatoryContribution: inventory.mandatoryLegalContribution
            ? inventory.mandatoryLegalContribution
            : "",
          voluntaryContribution: inventory.voluntaryContribution
            ? inventory.voluntaryContribution
            : 0,
          totalContribution: inventory.contributionToPay
            ? inventory.contributionToPay
            : "",
          month: inventory.period
            ? inventory.period.slice(4, 6) + inventory.period.slice(0, 4)
            : "",
          rutInstitution: inventory.institutionRut
            ? inventory.institutionRut.split("-")[0]
            : "",
          dvInstitution: inventory.institutionRut
            ? inventory.institutionRut.split("-")[1]
            : "",
          rutInsurance: inventory.rutEsencial
            ? inventory.rutEsencial.split("-")[0]
            : "",
          dvInsurance: inventory.rutEsencial
            ? inventory.rutEsencial.split("-")[1]
            : "",
          codeInsuranceDestination: inventory.codeEsencial
            ? inventory.codeEsencial
            : "",
          folioFun: inventory.folioFUN ? inventory.folioFUN : "",
          codeInsuranceOrigin: inventory.kinetycId ? inventory.kinetycId : "",
          reasonNotTransfer: 0,
        };
        arrayInventory.push(dataInventory);
      } else {
        let dataInventory = {
          run: inventory.rut ? inventory.rut.split("-")[0] : "",
          dv: inventory.rut ? inventory.rut.split("-")[1] : "",
          id: "",
          rutEntity: "",
          dvEntity: "",
          nameEntity: "",
          taxableIncome: "",
          mandatoryContribution: "",
          voluntaryContribution: "",
          totalContribution: "",
          month: inventory.period
            ? inventory.period.slice(4, 6) + inventory.period.slice(0, 4)
            : "",
          rutInstitution: "",
          dvInstitution: "",
          rutInsurance: "",
          dvInsurance: "",
          codeInsuranceDestination: "",
          folioFun: "",
          codeInsuranceOrigin: "",
          reasonNotTransfer: "",
        };
        arrayInventory.push(dataInventory);
      }
    });
  }
  return arrayInventory;
};

export const columnsPreviredPL = [
  { header: "RUT Pagador", key: "payerRut", width: 14 },
  { header: "RUT trabajador", key: "contributorRut", width: 14 },
  { header: "Periodo Pago", key: "paymentPeriod", width: 14 },
  { header: "Cotización Pagar", key: "amount", width: 14 },
  { header: "Cotización 7%", key: "taxableSevenPercent", width: 14 },
  { header: "Ley 18566", key: "law18566", width: 14 },
  {
    header: "Cotización Adicional Voluntaria",
    key: "additionalAmount",
    width: 14,
  },
  { header: "Renta Imponible del trabajador", key: "taxableSalary", width: 14 },
  { header: "Cotización Pactadas", key: "previredAgreedPrice", width: 14 },
  { header: "Nombre Empleador", key: "payerName", width: 40 },
  { header: "Nombre", key: "contributorName", width: 40 },
  { header: "Fecha de Pago", key: "paymentDate", width: 14 },
  { header: "Planilla", key: "previredDeclarationNumber", width: 20 },
  { header: "Comuna", key: "payerCommuneName", width: 20 },
  { header: "Ciudad", key: "payerCityName", width: 20 },
  { header: "Correo", key: "payerEmail", width: 40 },
  { header: "Dirección", key: "payerStreetAddress", width: 20 },
  { header: "Numero", key: "payerStreetNumber", width: 14 },
  { header: "Telefono", key: "payerPhoneNumber", width: 14 },
  { header: "Archivo Asociado", key: "fileName", width: 14 },
  { header: "Fecha Subida", key: "uploadDate", width: 14 },
];

export const columnsPreviredAA = [
  {
    header: "Identificador Institucion",
    key: "institutionIdentifier",
    width: 14,
  },
  { header: "Monto abonado", key: "paymentAmount", width: 14 },
  {
    header: "Cta. Cte. Fondo Institucion",
    key: "institutionAccountNumber",
    width: 20,
  },
  { header: "Nombre Banco", key: "institutionName", width: 20 },
  { header: "Fecha Abono Cuentas Indicadas", key: "paymentDate", width: 14 },
  { header: "Codigo Banco Recaudador", key: "institutionCode", width: 14 },
  { header: "Numero de Folios Abonados", key: "totalPaidFolios", width: 14 },
  { header: "Archivo Asociado", key: "fileName", width: 14 },
  { header: "Fecha Subida", key: "uploadDate", width: 14 },
];

const columnsInventory = [
  { header: "RUN de la Persona Cotizante", key: "run" },
  { header: "DV de la Persona Cotizante", key: "dv" },
  { header: "Número Planilla de Cotización", key: "id" },
  {
    header: "RUT Entidad encargada del pago de la cotización",
    key: "rutEntity",
  },
  { header: "DV Entidad encargada del pago de la cotización", key: "dvEntity" },
  {
    header:
      "Nombre o Razón Social de la Entidad encargada del pago de la cotización",
    key: "nameEntity",
  },
  { header: "Remuneración Imponible", key: "taxableIncome" },
  { header: "Cotización Legal Obligatoria", key: "mandatoryContribution" },
  { header: "Cotización Adicional Voluntaria", key: "voluntaryContribution" },
  { header: "Cotización total Pagada", key: "totalContribution" },
  { header: "Mes de Remuneración", key: "month" },
  { header: "RUT Institución recaudadora", key: "rutInstitution" },
  { header: "DV Institución recaudadora", key: "dvInstitution" },
  { header: "RUT Aseguradora de Destino", key: "rutInsurance" },
  { header: "DV Aseguradora Destino", key: "dvInsurance" },
  { header: "Código Aseguradora Destino", key: "codeInsuranceDestination" },
  { header: "Folio FUN", key: "folioFun" },
  { header: "Código Aseguradora Origen", key: "codeInsuranceOrigin" },
  { header: "Motivo del No Traspaso", key: "reasonNotTransfer" },
];

export const previredConsolidated = async (
  period: string,
  dataPL: any,
  dataAA: any
) => {
  try {
    const workbook = new Workbook();
    workbook.creator = "CME";
    workbook.addWorksheet("PL");
    workbook.addWorksheet("AA");

    const worksheetPL = workbook.getWorksheet(1);
    worksheetPL.columns = columnsPreviredPL;
    worksheetPL.addRows(buildPreviredPL(dataPL));
    const worksheetAA = workbook.getWorksheet(2);
    worksheetAA.columns = columnsPreviredAA;
    worksheetAA.addRows(buildPreviredAA(dataAA));

    worksheetPL.getColumn("amount").numFmt = numFmtStr;
    worksheetPL.getColumn("taxableSevenPercent").numFmt = numFmtStr;    
    worksheetPL.getColumn("additionalAmount").numFmt = numFmtStr;
    worksheetPL.getColumn("taxableSalary").numFmt = numFmtStr;
    worksheetPL.getColumn("previredAgreedPrice").numFmt = numFmtStr;
    worksheetAA.getColumn("paymentAmount").numFmt = numFmtStr;

    worksheetPL.getRow(1).alignment = { vertical: "distributed" };
    worksheetAA.getRow(1).alignment = { vertical: "distributed" };

    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: EXCEL,
      });
      const name = `Data_Previred_${period}.xlsx`;
      saveAs(blob, name);
    });
    return {
      status: "success",
      error: false,
      message: PREVIRED_CONSOLIDATED_SUCCESS,
    };
  } catch (error: any) {
    return {
      status: "error",
      error: true,
      message: PREVIRED_CONSOLIDATED_ERROR,
    };
  }
};

export const inventoryXLSX = async (period: string, data: any) => {
  try {
    const workbook = new Workbook();
    workbook.creator = "CME";
    workbook.addWorksheet(period);

    const worksheet = workbook.getWorksheet(1);

    worksheet.columns = columnsInventory;
    worksheet.addRows(buildInventory(data));

    for (let i = 1; i <= worksheet.columnCount; i++) {
      const cellInv = worksheet.getColumn(i);
      cellInv.eachCell((cell, rowNumber) => {
        if (i === 2 || i === 5 || i === 6 || i === 13 || i === 15 || i === 19) {
          cell.numFmt = "@";
        } else {
          cell.numFmt = "#,##0";
        }
      });
    }

    workbook.xlsx.writeBuffer().then((dataWb: any) => {
      const blob = new Blob([dataWb], {
        type: EXCEL,
      });
      const name = `Archivo de Consulta de CME Esencial_${period}.xlsx`;
      saveAs(blob, name);
    });
  } catch (error: any) {
    console.log("ERROR: ", error.message);
    return null;
  }
};

export const inventoryConfirmedXLSX = async (
  period: string,
  data: any,
  code: number
) => {
  try {
    const workbook = new Workbook();
    workbook.creator = "CME";
    workbook.addWorksheet(period);

    const worksheet = workbook.getWorksheet(1);

    worksheet.columns = columnsInventory;
    worksheet.addRows(buildConfirmedInventory(data, code));

    for (let i = 1; i <= worksheet.columnCount; i++) {
      const cellInv = worksheet.getColumn(i);
      cellInv.eachCell((cell, rowNumber) => {
        if (i === 2 || i === 5 || i === 6 || i === 13 || i === 15 || i === 19) {
          cell.numFmt = "@";
        } else {
          cell.numFmt = "#,##0";
        }
      });
    }

    workbook.xlsx.writeBuffer().then((dataWb: any) => {
      const blob = new Blob([dataWb], {
        type: EXCEL,
      });
      const name = `Archivo_de_Confirmacion_de_CME_${code}_${period}_Esencial.xlsx`;
      saveAs(blob, name);
    });
  } catch (error: any) {
    console.log("ERROR: ", error.message);
    return null;
  }
};

export const inventoryHandoverXLSX = async (
  period: string,
  data: any,
  code: number
) => {
  try {
    const workbook = new Workbook();
    workbook.creator = "CME";
    workbook.addWorksheet(period);

    const worksheet = workbook.getWorksheet(1);

    worksheet.columns = columnsInventory;
    worksheet.addRows(buildHandoverInventory(data, code));

    for (let i = 1; i <= worksheet.columnCount; i++) {
      const cellInv = worksheet.getColumn(i);
      cellInv.eachCell((cell, rowNumber) => {
        if (i === 2 || i === 5 || i === 6 || i === 13 || i === 15 || i === 19) {
          cell.numFmt = "@";
        } else {
          cell.numFmt = "#,##0";
        }
      });
    }

    workbook.xlsx.writeBuffer().then((dataWb: any) => {
      const blob = new Blob([dataWb], {
        type: EXCEL,
      });
      const name = `Archivo_de_Traspaso_CME_${code}_108_${period}.xlsx`;
      saveAs(blob, name);
    });
  } catch (error: any) {
    console.log("ERROR: ", error.message);
    return null;
  }
};

const columnsCredit = [
  { header: "RUT Cliente", key: "contributorRut", width: 14 },
  { header: "Nombre Cliente", key: "contributorName" },
  { header: "Periodo de Venta", key: "sellPeriod", width: 14 },
  { header: "Periodo de Remuneración", key: "paymentPeriod", width: 14 },
  { header: "Periodo de Recaudación", key: "collectionPeriod", width: 14 },
  { header: "Prima UF", key: "paymentUf", width: 12 },
  { header: "Pactado en Pesos", key: "contractedAmount", width: 14 },
  { header: "Tipo de Trabajador", key: "workerType", width: 12 },
  { header: "Tipo de plan", key: "planType", width: 12 },
];

const columnsPayment = [
  { header: "RUT Cliente", key: "contributorRut", width: 14 },
  { header: "RUT Pagador", key: "payerRut", width: 14 },
  { header: "Nombre Pagador", key: "payerName", width: 14 },
  { header: "Tipo de Trabajador", key: "workerType", width: 14 },
  { header: "Periodo Remuneración", key: "paymentPeriod", width: 12 },
  { header: "Monto Pagado", key: "amount", width: 12 },
  { header: "7% Pesos", key: "taxableSevenPercent", width: 12 },
  { header: "Adicional", key: "additionalAmount", width: 12 },
  { header: "Imponible", key: "taxableSalary", width: 12 },
  { header: "Origen Pago", key: "paymentType", width: 12 },
  { header: "Fecha Pago", key: "paymentDate", width: 12 },
];

const columnsCollectionCredit = [
  { header: "RUT Cliente", key: "contributorRut", width: 14 },
  { header: "Nombre Cliente", key: "contributorName" },
  { header: "Periodo de Venta", key: "sellPeriod", width: 14 },
  { header: "Periodo de Remuneración", key: "paymentPeriod", width: 14 },
  { header: "Periodo de Recaudación", key: "collectionPeriod", width: 14 },
  { header: "Prima UF", key: "paymentUf", width: 12 },
  { header: "Pactado Pesos", key: "contractedAmount", width: 14 },
  { header: "Total Pagado", key: "totalPaidAmount", width: 14 },
  { header: "Tipo Trabajador", key: "workerType", width: 12 },
  { header: "Tipo Acreencia", key: "typeName", width: 12 },
  { header: "Estado", key: "statusName", width: 12 },
];

const columnsCollectionPayment = [
  { header: "RUT Cliente", key: "contributorRut", width: 14 },
  { header: "Nombre Cliente", key: "contributorName", width: 14 },
  { header: "RUT Pagador", key: "payerRut", width: 14 },
  { header: "Nombre Pagador", key: "payerName", width: 14 },
  { header: "Periodo Remuneración", key: "paymentPeriod", width: 12 },
  { header: "Tipo de Trabajador", key: "workerType", width: 14 },
  { header: "Estado Pago", key: "paymentClassification", width: 12 },
  { header: "Fecha Pago", key: "paymentDate", width: 12 },
  { header: "Monto Pagado", key: "amount", width: 12 },
  { header: "Origen Pago", key: "paymentType", width: 12 },
  { header: "Observación", key: "observation", width: 14 },
];

export const buildCreditSheet = (data: any) => {
  let arrayCredit: any = [];
  if (Array.isArray(data)) {
    data.forEach((credit: any) => {
      let dataCredit = {
        contributorRut: credit.contributorRut,
        contributorName: credit.contributorName,
        sellPeriod: credit.sellPeriod,
        paymentPeriod: credit.paymentPeriod,
        collectionPeriod: credit.collectionPeriod,
        paymentUf: credit.paymentUf,
        contractedAmount: credit.contractedAmount,
        workerType: credit.workerType,
        planType: credit.planType,
      };
      arrayCredit.push(dataCredit);
    });
  }
  return arrayCredit;
};

export const buildCollectionCreditSheet = (data: any) => {
  let arrayCollectionCredit: any = [];
  if (Array.isArray(data)) {
    data.forEach((credit: any) => {
      let dataCollectionCredit = {
        contributorRut: credit.contributorRut,
        contributorName: credit.contributorName,
        sellPeriod: credit.sellPeriod,
        paymentPeriod: credit.paymentPeriod,
        collectionPeriod: credit.collectionPeriod,
        paymentUf: credit.paymentUf,
        contractedAmount: credit.contractedAmount,
        totalPaidAmount: credit.totalPaidAmount,
        workerType: credit.workerType,
        typeName: credit.typeName,
        statusName: credit.statusName,
      };
      arrayCollectionCredit.push(dataCollectionCredit);
    });
  }
  return arrayCollectionCredit;
};

export const buildPaymentSheet = (data: any) => {
  let arrayPayment: any = [];
  if (Array.isArray(data)) {
    data.forEach((payment: any) => {
      let dataPayment = {
        contributorRut: payment.contributorRut,
        payerRut: payment.payerRut,
        payerName: payment.payerName,
        workerType: payment.workerType,
        paymentPeriod: payment.paymentPeriod,
        amount: payment.amount,
        taxableSevenPercent: payment.taxableSevenPercent,
        additionalAmount: payment.additionalAmount,
        taxableSalary: payment.taxableSalary,
        paymentType: payment.paymentType,
        paymentDate: payment.paymentDate.slice(0, 10).replace(/-/g, "/"),
      };
      arrayPayment.push(dataPayment);
    });
  }
  return arrayPayment;
};

export const buildCollectionPaymentSheet = (data: any) => {
  let arrayCollectionPayment: any = [];
  if (Array.isArray(data)) {
    data.forEach((payment: any) => {
      let dataCollectionPayment = {
        contributorRut: payment.contributorRut,
        contributorName: payment.contributorName,
        payerRut: payment.payerRut,
        payerName: payment.payerName,
        paymentPeriod: payment.paymentPeriod,
        workerType: payment.workerType,
        paymentClassification: payment.paymentClassification,
        paymentDate: payment.paymentDate.slice(0, 10).replace(/-/g, "/"),
        amount: payment.amount,
        paymentType: payment.paymentType,
        observation: payment.observation,
      };
      arrayCollectionPayment.push(dataCollectionPayment);
    });
  }
  return arrayCollectionPayment;
};

export const creditXLSX = async (
  period: string,
  active: boolean,
  data: any
) => {
  try {
    const workbook = new Workbook();
    workbook.creator = "CME";
    workbook.addWorksheet(period);
    const worksheet = workbook.getWorksheet(1);
    worksheet.columns = columnsCredit;
    worksheet.addRows(buildCreditSheet(data));

    let maxLength = 0;
    worksheet
      .getColumn("contributorName")
      .eachCell({ includeEmpty: true }, function (cell) {
        const columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
    worksheet.getColumn("contributorName").width = maxLength < 10 ? 10 : maxLength;
    worksheet.getColumn("paymentUf").numFmt = "#,##0.000";
    worksheet.getColumn("contractedAmount").numFmt = "$#,###0,000";
    worksheet.getRow(1).alignment = { vertical: "distributed" };

    workbook.xlsx.writeBuffer().then((dataBuffer: any) => {
      const blob = new Blob([dataBuffer], {
        type: EXCEL,
      });
      const name = active
        ? `Acreencias_${period}.xlsx`
        : `Acreencias_eliminados_${period}.xlsx`;
      saveAs(blob, name);
    });
    return {
      status: "success",
      error: false,
      message: CREDITS_EXPORT_SUCCESS,
    };
  } catch (error: any) {
    return {
      status: "error",
      error: true,
      message: CREDITS_EXPORT_ERROR,
    };
  }
};

export const collectionCreditXLSX = async (data: any) => {
  try {
    const workbook = new Workbook();
    workbook.creator = "CME";
    workbook.addWorksheet("Acreencias");
    const worksheet = workbook.getWorksheet(1);
    worksheet.columns = columnsCollectionCredit;
    worksheet.addRows(buildCollectionCreditSheet(data));

    worksheet.getColumn("contributorName").width =
      maxColumnLength(worksheet.getColumn("contributorName")) < 10
        ? 10
        : maxColumnLength(worksheet.getColumn("contributorName"));
    worksheet.getColumn("contractedAmount").numFmt = numFmtStr;
    worksheet.getColumn("totalPaidAmount").numFmt = numFmtStr;
    worksheet.getRow(1).alignment = { vertical: "distributed" };

    workbook.xlsx.writeBuffer().then((dataBuffer: any) => {
      const blob = new Blob([dataBuffer], {
        type: EXCEL,
      });
      const name = `Consolidado_acreencias.xlsx`;
      saveAs(blob, name);
    });
    return {
      status: "success",
      error: false,
      message: COLLECTION.CREDITS_EXPORT_SUCCESS,
    };
  } catch (error: any) {
    return {
      status: "error",
      error: true,
      message: COLLECTION.CREDITS_EXPORT_ERROR,
    };
  }
};

export const paymentXLSX = async (
  period: string,
  active: boolean,
  data: any
) => {
  try {
    const workbook = new Workbook();
    workbook.creator = "CME";
    workbook.addWorksheet(period);
    const worksheet = workbook.getWorksheet(1);
    worksheet.columns = columnsPayment;
    worksheet.addRows(buildPaymentSheet(data));

    let maxLength = 0;
    worksheet
      .getColumn("payerName")
      .eachCell({ includeEmpty: true }, function (cell) {
        const columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
    worksheet.getColumn("payerName").width = maxLength < 10 ? 10 : maxLength;
    worksheet.getColumn("taxableSevenPercent").numFmt = numFmtStr;
    worksheet.getColumn("additionalAmount").numFmt = numFmtStr;
    worksheet.getColumn("amount").numFmt = numFmtStr;
    worksheet.getColumn("taxableSalary").numFmt = numFmtStr;
    worksheet.getRow(1).alignment = { vertical: "distributed" };
    workbook.xlsx.writeBuffer().then((dataBuffer: any) => {
      const blob = new Blob([dataBuffer], {
        type: EXCEL,
      });
      const name = active
        ? `Pagos_${period}.xlsx`
        : `Pagos_eliminados_${period}.xlsx`;
      saveAs(blob, name);
    });
    return {
      status: "success",
      error: false,
      message: PAYMENTS_EXPORT_SUCCESS,
    };
  } catch (error: any) {
    return {
      status: "error",
      error: true,
      message: PAYMENTS_EXPORT_ERROR,
    };
  }
};

export const collectionPaymentXLSX = async (period: string, data: any) => {
  try {
    const workbook = new Workbook();
    workbook.creator = "CME";
    workbook.addWorksheet(period);
    const worksheet = workbook.getWorksheet(1);
    worksheet.columns = columnsCollectionPayment;
    worksheet.addRows(buildCollectionPaymentSheet(data));

    worksheet.getColumn("contributorName").width =
      maxColumnLength(worksheet.getColumn("contributorName")) < 10
        ? 10
        : maxColumnLength(worksheet.getColumn("contributorName"));
    worksheet.getColumn("payerName").width =
      maxColumnLength(worksheet.getColumn("payerName")) < 10
        ? 10
        : maxColumnLength(worksheet.getColumn("payerName"));
    worksheet.getColumn("observation").width =
      maxColumnLength(worksheet.getColumn("observation")) < 10
        ? 10
        : maxColumnLength(worksheet.getColumn("observation"));
    worksheet.getColumn("amount").numFmt = numFmtStr;
    worksheet.getRow(1).alignment = { vertical: "distributed" };
    workbook.xlsx.writeBuffer().then((dataBuffer: any) => {
      const blob = new Blob([dataBuffer], {
        type: EXCEL,
      });
      const name = `Consolidado_pagos_${period}.xlsx`;
      saveAs(blob, name);
    });
    return {
      status: "success",
      error: false,
      message: COLLECTION.PAYMENTS_EXPORT_SUCCESS,
    };
  } catch (error: any) {
    return {
      status: "error",
      error: true,
      message: COLLECTION.PAYMENTS_EXPORT_ERROR,
    };
  }
};

const columnsExcess = [
  { header: "Periodo excedente", key: "collectionPeriod", width: 12 },
  { header: "RUT afiliado", key: "contributorRut", width: 14 },
  { header: "Nombre afiliado", key: "contributorName", width: 14 },
  { header: "Tipo movimiento", key: "amountType", width: 12 },
  { header: "Detalle movimiento", key: "movementType", width: 14 },
  { header: "Monto", key: "amount", width: 12 },
  { header: "Fecha movimiento", key: "movementDate", width: 14 },
  { header: "Fecha disponible", key: "availableDate", width: 14 },
];

export const buildExcessSheet = (data: any) => {
  let arrayExcess: any = [];
  if (Array.isArray(data)) {
    data.forEach((excess: any) => {
      let dataExcess = {
        collectionPeriod: excess.collectionPeriod,
        contributorRut: excess.contributorRut,
        contributorName: excess.contributorName,
        amountType: excess.amountType,
        movementType: excess.movementType,
        amount: excess.amount,
        movementDate: excess.movementDate,
        availableDate: excess.availableDate,
      };
      arrayExcess.push(dataExcess);
    });
  }
  return arrayExcess;
};

export const excessXLSX = async (
  period: string,
  rut: string,
  active: boolean,
  data: any
) => {
  let name = period ? period : "RUT_" + rut;
  try {
    const workbook = new Workbook();
    workbook.creator = "CME";
    workbook.addWorksheet("Excedentes " + name);
    const worksheet = workbook.getWorksheet(1);
    worksheet.columns = columnsExcess;
    worksheet.addRows(buildExcessSheet(data));

    worksheet.getColumn("contributorName").width =
      maxColumnLength(worksheet.getColumn("contributorName")) < 10
        ? 10
        : maxColumnLength(worksheet.getColumn("contributorName"));
    worksheet.getColumn("movementType").width =
      maxColumnLength(worksheet.getColumn("movementType")) < 10
        ? 10
        : maxColumnLength(worksheet.getColumn("movementType"));
    worksheet.getColumn("amount").numFmt = numFmtStr;
    worksheet.getRow(1).alignment = { vertical: "distributed" };

    workbook.xlsx.writeBuffer().then((dataBuffer: any) => {
      const blob = new Blob([dataBuffer], {
        type: EXCEL,
      });
      const nameFile = active
        ? `Excedentes_${name}.xlsx`
        : `Excedentes_eliminados_${name}.xlsx`;
      saveAs(blob, nameFile);
    });
    return {
      status: "success",
      error: false,
      message: EXCESS_EXPORT_SUCCESS,
    };
  } catch (error: any) {
    return {
      status: "error",
      error: true,
      message: EXCESS_EXPORT_ERROR,
    };
  }
};

const columnsExcessReport = [
  { header: "Código isapre", key: "esencialCode", width: 12 },
  { header: "Periodo de información", key: "collectionPeriod", width: 14 },
  { header: "RUN cotizante", key: "contributorRut", width: 14 },
  { header: "Identifiación Afiliado", key: "contributorName", width: 12 },
  {
    header: "Saldo acumulado mes anterior",
    key: "previousAvailableBalance",
    width: 14,
  },
  { header: "Excedentes generados", key: "surplusGenerated", width: 12 },
  { header: "Reajuste", key: "currentReadjustmentTotal", width: 14 },
  { header: "Interés", key: "currentInterestTotal", width: 14 },
  { header: "Comisión", key: "commission", width: 14 },
  { header: "Usos", key: "chargeAmount", width: 14 },
  {
    header: "Traspaso de excedentes desde otras isapres",
    key: "otherInstitutionDepositAmount",
    width: 14,
  },
  { header: "Saldo contable mes actual", key: "accountableBalance", width: 14 },
  { header: "Saldo disponible mes actual", key: "availableBalance", width: 14 },
  { header: "Vigencia cotizante", key: "contributorValidity", width: 14 },
  { header: "Periodo no vigencia", key: "noValidityPeriod", width: 14 },
  { header: "Institución de destino", key: "destinyInstitution", width: 14 },
  { header: "Codigo de cuenta interno", key: "internalAccountCode", width: 14 },
  {
    header: "Codigo secuencia informe complementario",
    key: "complementaryReportSequenceCode",
    width: 14,
  },
  { header: "Anualidad de contrato", key: "contractDate", width: 14 },
  {
    header: "Nombre de la cuenta Interna",
    key: "internalAccountName",
    width: 14,
  },
  {
    header: "Monto reintegro devolucion masiva de excedentes",
    key: "massiveReturnAmount",
    width: 14,
  },
  { header: "Otros ajustes", key: "otherAdjustmentAmount", width: 14 },
  {
    header: "Monto devolucion masiva Anual",
    key: "annualMasiveReturnAmount",
    width: 14,
  },
];

export const buildExcessReportSheet = (data: any) => {
  const excessReportData =
    Array.isArray(data) && data.length > 0
      ? data.map((item: any) => {
          return {
            esencialCode: item.esencialCode,
            collectionPeriod: item.collectionPeriod,
            contributorRut: item.contributorRut,
            contributorName: item.contributorName,
            previousAvailableBalance: item.previousAvailableBalance,
            surplusGenerated: item.surplusGenerated,
            currentReadjustmentTotal: item.currentReadjustmentTotal,
            currentInterestTotal: item.currentInterestTotal,
            commission: item.commission,
            chargeAmount: item.chargeAmount,
            otherInstitutionDepositAmount: item.otherInstitutionDepositAmount,
            availableBalance: item.availableBalance,
            accountableBalance: item.accountableBalance,
            contributorValidity: item.contributorValidity,
            noValidityPeriod: item.noValidityPeriod,
            destinyInstitution: item.destinyInstitution,
            internalAccountCode: item.internalAccountCode,
            complementaryReportSequenceCode:
              item.complementaryReportSequenceCode,
            contractDate: item.contractDate,
            internalAccountName: item.internalAccountName,
            massiveReturnAmount: item.massiveReturnAmount,
            otherAdjustmentAmount: item.otherAdjustmentAmount,
            annualMasiveReturnAmount: item.annualMasiveReturnAmount,
          };
        })
      : [];

  return excessReportData;
};

export const excessReportXLSX = async (collectionPeriod: string, data: any) => {
  try {
    const workbook = new Workbook();
    workbook.creator = "CME";
    workbook.addWorksheet("Maestro Excedentes " + collectionPeriod);

    const worksheet = workbook.getWorksheet(1);
    worksheet.columns = columnsExcessReport;
    worksheet.addRows(buildExcessReportSheet(data));

    worksheet.getColumn("contributorName").width =
      maxColumnLength(worksheet.getColumn("contributorName")) < 10
        ? 10
        : maxColumnLength(worksheet.getColumn("contributorName"));

    worksheet.getColumn("internalAccountName").width =
      maxColumnLength(worksheet.getColumn("internalAccountName")) < 10
        ? 10
        : maxColumnLength(worksheet.getColumn("internalAccountName"));

    worksheet.getRow(1).alignment = { vertical: "distributed" };

    workbook.xlsx.writeBuffer().then((dataBuffer: any) => {
      const blob = new Blob([dataBuffer], {
        type: EXCEL,
      });

      const nameFile = `Maestro_Excedentes_${collectionPeriod}.xlsx`;
      saveAs(blob, nameFile);
    });

    return {
      status: "success",
      error: false,
      message: EXCESS_REPORT_EXPORT_SUCCESS,
    };
  } catch (error: any) {
    return {
      status: "error",
      error: true,
      message: EXCESS_REPORT_EXPORT_ERROR,
    };
  }
};

const columnsReportSAP = [
  { header: "Clave de control", key: "controlKey", width: columnWidth.A },
  { header: "Fecha documento", key: "documentDate", width: columnWidth.B },
  { header: "Fecha Contable", key: "accountableDate", width: columnWidth.B },
  { header: "Numero referencia", key: "referenceNumber", width: columnWidth.B },
  { header: "Texto cabecera", key: "headerText", width: columnWidth.B },
  { header: "Clase de documento", key: "documentClass", width: columnWidth.A },
  { header: "Periodo", key: "period", width: columnWidth.A },
  { header: "Sociedad FI", key: "FISociety", width: columnWidth.A },
  { header: "Moneda", key: "currency", width: columnWidth.A },
  { header: "Tipo de cambio", key: "changeType", width: columnWidth.A },
  { header: "Ledger", key: "ledger", width: columnWidth.A },
  {
    header: "N° cuenta a contabilizar",
    key: "toRecordAccountNumber",
    width: columnWidth.B,
  },
  { header: "indicador iva", key: "IVAIndicator", width: columnWidth.A },
  { header: "Sociedad GL", key: "GLSociety", width: columnWidth.A },
  { header: "Código de cliente", key: "clientCode", width: columnWidth.A },
  { header: "Código de proveedor", key: "supplierCode", width: columnWidth.A },
  { header: "Indicador CME", key: "CMEIndicator", width: columnWidth.A },
  {
    header: "Importe Docto Documento",
    key: "documentImport",
    width: columnWidth.A,
  },
  {
    header: "Indicador debe Haber",
    key: "creditDebitIndicator",
    width: columnWidth.A,
  },
  { header: "Fecha Base", key: "baseDate", width: columnWidth.A },
  {
    header: "Condición de pago",
    key: "paymentCondition",
    width: columnWidth.A,
  },
  { header: "Fecha Valor", key: "valueDate", width: columnWidth.A },
  {
    header: "Fecha de vencimiento",
    key: "expirationDate",
    width: columnWidth.A,
  },
  { header: "Centro de costo", key: "costCenter", width: columnWidth.A },
  { header: "Centro de beneficio", key: "benefitCenter", width: columnWidth.A },
  { header: "Asignación", key: "assignment", width: columnWidth.A },
  { header: "Texto posición", key: "positionText", width: columnWidth.A },
  { header: "Clave referencia 1", key: "referenceKey1", width: columnWidth.A },
  { header: "Clave referencia 2", key: "referenceKey2", width: columnWidth.A },
  { header: "Clave referencia 3", key: "referenceKey3", width: columnWidth.A },
  { header: "Estatus", key: "status", width: columnWidth.A },
  { header: "Clave Banco", key: "bankKey", width: columnWidth.A },
  { header: "País Banco", key: "bankCountry", width: columnWidth.A },
  { header: "Cuenta Bancaria", key: "bankAccount", width: columnWidth.A },
  { header: "Nombre Completo", key: "fullName", width: columnWidth.A },
  { header: "Mail", key: "mail", width: columnWidth.A },
  {
    header: "Fecha de Pago Máxima",
    key: "maximumPaymentDate",
    width: columnWidth.A,
  },
  {
    header: "Fecha de Pago Efectiva",
    key: "minimumPaymentDate",
    width: columnWidth.A,
  },
];

export const buildReportSAPSheet = (data: any) => {
  const reportSAPData =
    Array.isArray(data) && data.length > 0
      ? data.map((item: any) => {
          return {
            controlKey: item.controlKey,
            documentDate: item.documentDate,
            accountableDate: item.accountableDate,
            referenceNumber: item.referenceNumber,
            headerText: item.headerText,
            documentClass: item.documentClass,
            period: item.period,
            FISociety: item.FISociety,
            currency: item.currency,
            changeType: item.changeType,
            ledger: item.ledger,
            toRecordAccountNumber: item.toRecordAccountNumber,
            IVAIndicator: item.IVAIndicator,
            GLSociety: item.GLSociety,
            clientCode: item.clientCode,
            supplierCode: item.supplierCode,
            CMEIndicator: item.CMEIndicator,
            documentImport: item.documentImport,
            creditDebitIndicator: item.creditDebitIndicator,
            baseDate: item.baseDate,
            paymentCondition: item.paymentCondition,
            valueDate: item.valueDate,
            expirationDate: item.expirationDate,
            costCenter: item.costCenter,
            benefitCenter: item.benefitCenter,
            assignment: item.assignment,
            positionText: item.positionText,
            referenceKey1: item.referenceKey1,
            referenceKey2: item.referenceKey2,
            referenceKey3: item.referenceKey3,
            status: item.status,
            bankKey: item.bankKey,
            bankCountry: item.bankCountry,
            bankAccount: item.bankAccount,
            fullName: item.fullName,
            mail: item.mail,
            maximumPaymentDate: item.maximumPaymentDate,
            minimumPaymentDate: item.minimumPaymentDate,
          };
        })
      : [];
  return reportSAPData;
};

export const SAPLoaderFile = async (
  period: string,
  returnType: number,
  data: any
) => {
  try {
    const workbook = new Workbook();
    workbook.creator = "CME";
    workbook.addWorksheet("Reporte SAP " + period);
    const worksheet = workbook.getWorksheet(1);
    worksheet.columns = columnsReportSAP;
    worksheet.addRows(buildReportSAPSheet(data));

    workbook.xlsx.writeBuffer().then((dataBuffer: any) => {
      const blob = new Blob([dataBuffer], {
        type: EXCEL,
      });
      const type = returnType === 20 ? "mensual" : "demanda";
      const loaderDate = moment(new Date()).format("YYYYMMDD");
      const nameFile = `Cargador SAP Excesos_${type}_${loaderDate}.xlsx`;
      saveAs(blob, nameFile);
    });

    return {
      status: "success",
      error: false,
      message:
        returnType === 20
          ? SAP_MONTHLY_EXPORT_SUCCESS
          : SAP_ON_DEMAND_EXPORT_SUCCESS,
    };
  } catch (error: any) {
    return {
      status: "error",
      error: true,
      message: SAP_EXPORT_ERROR,
    };
  }
};

const columnsExcessOverage = [
  { header: "RUT afiliado", key: "contributorRut", width: columnWidth.B },
  { header: "Nombre afiliado", key: "contributorName", width: columnWidth.B },
  {
    header: "Periodo remuneración",
    key: "paymentPeriod",
    width: columnWidth.B,
  },
  {
    header: "Periodo recaudación",
    key: "collectionPeriod",
    width: columnWidth.B,
  },
  { header: "Tipo movimiento", key: "amountType", width: columnWidth.A },
  { header: "Detalle movimiento", key: "movementType", width: columnWidth.B },
  { header: "Monto", key: "amount", width: columnWidth.A },
];

const buildExcessOverageSheet = (data: any) => {
  const excessOverageData =
    Array.isArray(data) && data.length > 0
      ? data.map((item: any) => {
          return {
            contributorRut: item.contributorRut,
            contributorName: item.contributorName,
            paymentPeriod: item.paymentPeriod,
            collectionPeriod: item.collectionPeriod,
            amountType: item.amountType,
            movementType: item.movementType,
            amount: item.amount,
          };
        })
      : [];
  return excessOverageData;
};

export const excessOverageFile = async (
  filename: string,
  active: boolean,
  data: any
) => {
  try {
    const workbook = new Workbook();
    workbook.creator = "CME";
    workbook.addWorksheet("Excesos " + filename);
    const worksheet = workbook.getWorksheet(1);
    worksheet.columns = columnsExcessOverage;
    worksheet.addRows(buildExcessOverageSheet(data));

    worksheet.getColumn("contributorName").width =
      maxColumnLength(worksheet.getColumn("contributorName")) < columnWidth.B
        ? columnWidth.B
        : maxColumnLength(worksheet.getColumn("contributorName"));
    worksheet.getColumn("movementType").width =
      maxColumnLength(worksheet.getColumn("movementType")) < columnWidth.B
        ? columnWidth.B
        : maxColumnLength(worksheet.getColumn("movementType"));
    worksheet.getColumn("amount").numFmt = numFmtStr;
    worksheet.getRow(1).alignment = { vertical: "distributed" };

    workbook.xlsx.writeBuffer().then((dataBuffer: any) => {
      const blob = new Blob([dataBuffer], {
        type: EXCEL,
      });
      const nameFile = active
        ? `Excesos_${filename}.xlsx`
        : `Excesos_eliminados_${filename}.xlsx`;
      saveAs(blob, nameFile);
    });
    return {
      status: "success",
      error: false,
      message: EXPORT_SUCCESS,
    };
  } catch (error: any) {
    return {
      status: "error",
      error: true,
      message: EXPORT_ERROR,
    };
  }
};

const columnsDevolution = [
  { header: "RUT afiliado", key: "contributorRut", width: columnWidth.B },
  { header: "Nombre afiliado", key: "contributorName", width: columnWidth.B },
  {
    header: "Tipo de devolución",
    key: "typeName",
    width: columnWidth.B,
  },
  {
    header: "Saldo",
    key: "availableBalance",
    width: columnWidth.B,
  },
  { header: "Estado", key: "statusName", width: columnWidth.A },
  { header: "Fecha de movimiento", key: "movementDate", width: columnWidth.B },
];

const buildDevolutionSheet = (data: any) => {
  const devolutionData =
    Array.isArray(data) && data.length > 0
      ? data.map((item: any) => {
          return {
            contributorRut: item.contributorRut,
            contributorName: item.contributorName,
            typeName: item.typeName,
            availableBalance: item.availableBalance,
            statusName: item.statusName,
            movementDate: getMovementDate(item.excessDetail),
          };
        })
      : [];
  return devolutionData;
};

const getMovementDate = (data: any) => {
  const movementItem = data.find((item: any) =>
    ["DEVOLUCION A DEMANDA", "DEVOLUCION MENSUAL"].includes(item.movementType)
  );
  return movementItem ? movementItem.movementDate : "";
};

export const excessDevolutionFile = async (period: string, data: any) => {
  try {
    const workbook = new Workbook();
    workbook.creator = "CME";
    workbook.addWorksheet("Devoluciones " + period);
    const worksheet = workbook.getWorksheet(1);
    worksheet.columns = columnsDevolution;
    worksheet.addRows(buildDevolutionSheet(data));

    worksheet.getColumn("contributorName").width =
      maxColumnLength(worksheet.getColumn("contributorName")) < columnWidth.B
        ? columnWidth.B
        : maxColumnLength(worksheet.getColumn("contributorName"));
    worksheet.getColumn("typeName").width =
      maxColumnLength(worksheet.getColumn("typeName")) < columnWidth.B
        ? columnWidth.B
        : maxColumnLength(worksheet.getColumn("typeName"));
    worksheet.getColumn("availableBalance").numFmt = minusNumFmtStr;
    worksheet.getRow(1).alignment = { vertical: "distributed" };

    workbook.xlsx.writeBuffer().then((dataBuffer: any) => {
      const blob = new Blob([dataBuffer], {
        type: EXCEL,
      });
      saveAs(blob, `Devoluciones_${period}.xlsx`);
    });
    return {
      status: "success",
      error: false,
      message: DEVOLUTION_EXPORT_SUCCESS,
    };
  } catch (error: any) {
    return {
      status: "error",
      error: true,
      message: DEVOLUTION_EXPORT_ERROR,
    };
  }
};
