import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { NoResult } from "src/components/tables/noResult";
import { StyledTableCell } from "src/components/tables/tableStyle";
import { paginatorTheme } from "src/components/paginator/paginatorStyle";
import { boxNoPadding } from "src/components/box/styles";
import { contTitle, subTable } from "src/components/typography/styles";
import { SkeletonTableP } from "./skeletonsPrevired";
import DownloadButton from "src/components/buttons/DownloadButton";
import { useLogs } from "src/hooks/useLogs";
import { systemModules } from "src/utils/selectors";
import constants from "src/constants/constants";

const {
  PAGES: {
    MODULES: { CREDITS },
  },
} = constants;

export default function TableRecordPrevired(props: any) {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const { getLog, count, rows, loading, error } = useLogs();

  useEffect(() => {
    getLog({
      limit: rowsPerPage,
      offset: 0,
      logTypeId: systemModules(CREDITS),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.refresh === true) {
      getLog({
        limit: rowsPerPage,
        offset: 0,
        logTypeId: systemModules(CREDITS),
      });
      props.setRefresh(false);
    }
    // eslint-disable-next-line
  }, [props.refresh]);

  useEffect(() => {
    getLog({
      limit: rowsPerPage,
      offset: page * rowsPerPage - rowsPerPage,
      logTypeId: systemModules(CREDITS),
    });
    // eslint-disable-next-line
  }, [page, rowsPerPage]);

  return (
    <Box sx={boxNoPadding} mt="16px" mb="24px">
      {loading ? (
        <SkeletonTableP />
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Stack
                direction={"column"}
                justifyContent="space-between"
                pt="15px"
                pr="24px"
              >
                <Typography sx={contTitle} pt="11px" pl="24px">
                  Historial de Carga
                </Typography>{" "}
                <Typography variant="h3" component="div" pt="11px" pl="24px">
                  {" "}
                  Aquí podrás ver los cambios que se han realizado en este
                  módulo.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          {error ? (
            <NoResult
              titulo={"Error"}
              subtitulo={"Error en la carga de datos"}
              error
            />
          ) : (
            <>
              {count === 0 ? (
                <NoResult
                  titulo={"No hay información para mostrar."}
                  subtitulo={"Cargue información para visualizar historial"}
                  dontSearch
                />
              ) : (
                <>
                  <TableContainer
                    component={Paper}
                    sx={{
                      boxShadow: "none",
                      borderRadius: "none",
                      paddingTop: "17px",
                    }}
                  >
                    <Table sx={{ minWidth: 650 }} aria-label="customized table">
                      <TableHead className="color-head-table">
                        <TableRow>
                          <StyledTableCell>Usuario</StyledTableCell>
                          <StyledTableCell>
                            Fecha y hora de carga
                          </StyledTableCell>
                          <StyledTableCell>
                            Registros de la carga
                          </StyledTableCell>
                          <StyledTableCell sx={{ width: "200px" }}>
                            Acciones
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {rows.map((file: any) => {
                        return (
                          <TableBody>
                            <TableRow data-testid="withData">
                              <TableCell>
                                <Typography variant="h5">
                                  {file.userName}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography data-testid="rut" variant="h5">
                                  {file.logDate
                                    .slice(0, 10)
                                    .split("-")
                                    .join("/") + " "}
                                </Typography>
                                <Typography sx={subTable}>
                                  {file.logDate.slice(11, 16) + "hrs"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h5">
                                  {file.description}{" "}
                                  {Number(file.description) === 1
                                    ? "registro"
                                    : "registros"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <DownloadButton
                                  disabled={
                                    file?.descriptionAnnex === null
                                      ? true
                                      : false
                                  }
                                  display="list"
                                  documentId={file?.descriptionAnnex}
                                  fileType={"xlsx"}
                                  data-testid="downloadButton"
                                  txt={false}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })}
                    </Table>
                    <Grid alignItems="center" pb="15px" pt="24px">
                      <ThemeProvider theme={paginatorTheme}>
                        <TablePagination
                          rowsPerPageOptions={[10, 20, 50]}
                          component="div"
                          count={count || 0}
                          rowsPerPage={rowsPerPage}
                          page={page - 1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelRowsPerPage={"Mostrar por página"}
                          sx={{ mt: "24px", mb: "24px" }}
                          labelDisplayedRows={(e) => {
                            return (
                              "" +
                              (page || 0) +
                              " de " +
                              Math.ceil((count || 0) / (rowsPerPage || 0))
                            );
                          }}
                        />
                      </ThemeProvider>
                    </Grid>
                  </TableContainer>
                </>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
}
