import {
  GET_PERIOD_BY,
  PERIODS_BY,
  PERIODS_BY_PROCESS,
  PERIOD_DISTRIBUTION_STATUS,
} from "src/services/graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { getCurrentPeriod } from "src/utils/dates";

export const usePeriod = ({ processType }: { processType: number }) => {
  const [
    allPeriodsBy,
    {
      data: { period: { allBy: periodList } } = {
        period: {
          allBy: [],
        },
      },
      loading: loadingPeriodList,
    },
  ] = useLazyQuery(PERIODS_BY, {
    fetchPolicy: "network-only",
  });

  const getAllPeriodsBy = (options: {
    statusId?: number;
    collectionStatusId?: number;
    distributionStatusId?: number;
    devolutionStatusId?: number;
  }) => {
    allPeriodsBy({
      variables: {
        input: {
          statusId: options.statusId || null,
          collectionStatusId: options.collectionStatusId || null,
          distributionStatusId: options.distributionStatusId || null,
          devolutionStatusId: options.devolutionStatusId || null,
        },
      },
    });
  };


  const [
    allPeriodsByProcess,
    {
      data: { period: { allByProcess: periodsByProcess } } = {
        period: { allByProcess: [] },
      },
      loading: loadingByProcess,
    },
  ] = useLazyQuery(PERIODS_BY_PROCESS, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        processType,
      },
    },
  });

  const [
    periodDistributionStatus,
    {
      data: {
        period: {
          oneDistributionStatusBy: {
            hasSurplusAndExcess,
            distributionStatusId,
            previousDevolutionStatusId,
          },
        },
      } = {
        period: {
          oneDistributionStatusBy: {
            hasSurplusAndExcess: false,
            distributionStatusId: 0,
            previousDevolutionStatusId: 0,
          },
        },
      },
      loading: loadingPeriodStatus,
    },
  ] = useLazyQuery(PERIOD_DISTRIBUTION_STATUS, {
    fetchPolicy: "network-only",
  });

  const getPeriodDistributionStatus = (periodName: string) => {
    periodDistributionStatus({
      variables: {
        input: {
          name: periodName,
        },
      },
    });
  };


  const [
    getLastConfirmedPeriod,
    {
      data: { period: { oneBy: confirmedPeriod } } = {
        period: {
          oneBy: null,
        },
      },
      loading: loadingConfirmedPeriod,
    },
  ] = useLazyQuery(GET_PERIOD_BY, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        distributionStatusId: 3,
        isCurrent: true,
      },
    },
  });



  const listPeriodNames = (list: any) => {
    const array: any[] = [];
    if (list) {
      list.forEach((opt: any) => {
        array.push(getCurrentPeriod(opt.name)[0]);
      });
    }
    return array;
  };

  return {
    getAllPeriodsBy,
    periodList,
    loadingPeriodList,    
    getLastConfirmedPeriod,
    confirmedPeriod,
    loadingConfirmedPeriod,
    getPeriodDistributionStatus,
    hasSurplusAndExcess,
    distributionStatusId,
    previousDevolutionStatusId,
    loadingPeriodStatus,
    allPeriodsByProcess,
    periodsByProcess,
    loadingByProcess,
    listPeriodNames,
  };
};
