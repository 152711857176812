import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { boxNoPadding2, greenBack } from "src/components/box/styles";
import {
  errorText,
  grayFontBox,
  greenFont,
  littleTitle,
  subTable,
  titleLight,
} from "src/components/typography/styles";
import littleClose from "src/assets/icons/littleClose.svg";
import { NoResult } from "src/components/tables/noResult";
import ExportButton from "src/components/buttons/ExportButton";
import { StyledTableCell } from "src/components/tables/tableStyle";
import { paginatorTheme } from "src/components/paginator/paginatorStyle";
import CustomInput from "src/components/input/customInput";
import { handleRunChangeUtils } from "src/utils/validationUtils";
import { getCurrentPeriod } from "src/utils/dates";
import { SkeletonExcess, SkeletonExcessTotals } from "./skeletonExcess";
import DetailExcess from "./detailExcess";
import { DeleteModal } from "src/pages/collection/credits/components/deleteModal";
import { MenuExcess } from "./menuExcess";
import ChangeLogModal from "src/components/modals/changeLogModal";
import EditModal from "./editModal";
import constants from "src/constants/constants";
import { hasPermissions } from "src/utils/hasPermissions";
import { useSurplus } from "../../hook/useSurplus";
import { renderAmount } from "src/utils/numberUtils";

const {
  COMPONENTS: {
    BUTTONS: { LOG_HISTORY, SEARCH },
  },
} = constants;

const TableExcess = (props: any) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showDetail, setShowDetail] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [rutFilter, setRutFilter] = useState(false);
  const [runValue, setRunValue] = useState("");
  const [formattedRun, setFormattedRun] = useState("");
  const [rut, setRut] = useState<any>({
    run: "",
    formattedRun: "",
    error: "",
    isValid: false,
  });
  const [rowInfo, setRowInfo] = useState({
    id: "",
    collectionPeriod: "",
    contributorRut: "",
    contributorName: "",
    paymentPeriod: "",
    surplusGenerated: 0,
    readjustment: 0,
    interest: 0,
    commission: 0,
    excessDetail: [],
  });
  const canUpdate = hasPermissions().update;

  const {
    getSurplusData,
    surplusCount,
    surplusRows,
    surplusLoading,
    surplusError,
    getSurplusTotals,
    allTotalsBy,
    loadingTotal,
  } = useSurplus({ collectionPeriod: props.period });

  const handleRunChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let checkRun = handleRunChangeUtils(value);
    if (checkRun.runValue !== "" || value.length === 0) {
      setRunValue(checkRun.runValue);
      setFormattedRun(checkRun.formattedRun);
      setRut({
        run: checkRun.formattedRun ? checkRun.runValue : null,
        formattedRun: checkRun.formattedRun,
        error: checkRun.error,
        isValid: checkRun.isValid,
      });
    }
  };

  const clearRunFilter = () => {
    setRunValue("");
    setFormattedRun("");
    setRut({
      run: "",
      formattedRun: "",
      error: "",
      isValid: false,
    });
    setRutFilter(false);
  };

  const checkValidRut = (data: any) => {
    if (data.error === "" && data.run !== "" && data.isValid) return true;
    return false;
  };

  const handleDetails = (elem: any, type: number) => {
    setRowInfo({
      id: elem.id,
      collectionPeriod: elem.collectionPeriod,
      contributorRut: elem.contributorRut,
      contributorName: elem.contributorName,
      paymentPeriod: elem.paymentPeriod,
      surplusGenerated: elem.surplusGenerated,
      readjustment: elem.readjustment,
      interest: elem.interest,
      commission: elem.commission,
      excessDetail: elem.surplusDetail,
    });
    if (type === 1) setShowDetail(true);
    if (type === 2) setShowHistory(true);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleFilter = () => {
    setRutFilter(true);
    setPage(1);
    getSurplusData({
      contributorRut: rut.formattedRun.replace(/\./g, ""),
      isActive: props.isActive,
      limit: rowsPerPage,
      offset: 0,
    });
  };

  useEffect(() => {
    if (props.period !== "") {
      getSurplusData({
        contributorRut: null,
        isActive: props.isActive,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
      });
      getSurplusTotals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (runValue === "" && props.period !== "") {
      setPage(1);
      getSurplusData({
        contributorRut: null,
        isActive: props.isActive,
        limit: rowsPerPage,
        offset: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runValue]);

  useEffect(() => {
    if (props.period !== "") {
      getSurplusData({
        contributorRut: rut.formattedRun.replace(/\./g, "") || null,
        isActive: props.isActive,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (props.refresh === true) {
      setPage(1);
      getSurplusData({
        contributorRut: rut.formattedRun.replace(/\./g, "") || null,
        isActive: props.isActive,
        limit: rowsPerPage,
        offset: 0,
      });
      getSurplusTotals();
      props.setRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  return (
    <Box sx={boxNoPadding2} mt="16px" mb="24px">
      <DetailExcess
        onOpen={showDetail}
        handleCloseModal={() => setShowDetail(false)}
        info={rowInfo}
        type={1}
      />
      <EditModal
        onOpen={showEdit}
        handleClose={() => setShowEdit(false)}
        info={rowInfo}
        setRefresh={props.setRefresh}
        setSnackInfo={props.setSnackInfo}
        refreshOnError={props.refreshOnError}
      />
      <DeleteModal
        onOpen={showDelete}
        onClose={() => setShowDelete(false)}
        id={rowInfo.id}
        rut={rowInfo.contributorRut}
        payerRut={null}
        period={rowInfo.collectionPeriod}
        setRefresh={props.setRefresh}
        refreshOnError={props.refreshOnError}
        type={3}
      />
      <ChangeLogModal
        onOpen={showHistory}
        onClose={() => setShowHistory(false)}
        id={rowInfo.id}
        logTypeId={27}
      />
      {surplusLoading ? (
        <>
          <SkeletonExcess columns={8} rows={3} module={props.module} />
        </>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
                pt="21px"
                pr="24px"
                pl="24px"
              >
                <div>
                  <Typography
                    sx={littleTitle}
                    component="div"
                    display={"inline"}
                  >
                    Excedentes -
                  </Typography>
                  <Typography
                    sx={titleLight}
                    component="div"
                    display={"inline"}
                  >
                    {"  "} Saldo carga
                  </Typography>
                </div>

                <Stack direction={"row"}>
                  <div>
                    <Typography pb="8px" variant="h6">
                      RUT
                    </Typography>
                    <CustomInput
                      sx={{ width: "95%" }}
                      placeholder="Ingresar"
                      type="text"
                      disabled={surplusError ? true : false}
                      value={formattedRun || runValue}
                      onChange={handleRunChange}
                      inputProps={{
                        "data-testid": "input-run",
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={clearRunFilter}
                            data-testid="cleanButton"
                          >
                            <img src={littleClose} alt="limpiar rut" />
                          </IconButton>
                        ),
                      }}
                    />
                    {rut.error && (
                      <Typography sx={errorText}>RUT inválido.</Typography>
                    )}
                  </div>
                  <div style={{ paddingTop: "27px", paddingRight: "24px" }}>
                    <Button
                      color={"secondary"}
                      sx={{ padding: "15px 21px !important" }}
                      disabled={surplusError ? true : !checkValidRut(rut)}
                      onClick={handleFilter}
                    >
                      {SEARCH}
                    </Button>
                  </div>
                  <div style={{ paddingTop: "27px" }}>
                    <ExportButton
                      type={3}
                      collectionPeriod={props.period}
                      contributorRut={
                        rutFilter ? rut.formattedRun.replace(/\./g, "") : null
                      }
                      isActive={props.isActive}
                      disabled={
                        surplusCount === 0 || surplusError ? true : false
                      }
                    />
                  </div>
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          {surplusCount === 0 ? (
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "none",
                borderRadius: "none",
                paddingTop: "17px",
                borderBottom: "none",
              }}
            >
              {!rutFilter ? (
                <NoResult
                  titulo={"No hay información para mostrar."}
                  subtitulo={
                    props.isActive
                      ? "Cargue información para visualizar excesos"
                      : "No se han realizado acciones de eliminación para visualizar registros."
                  }
                  newDontSearch
                />
              ) : (
                <NoResult
                  titulo={
                    "No se encontró información de excedentes asociada a ese RUT"
                  }
                  subtitulo={"Inténtelo nuevamente usando otro registro"}
                  newFound
                />
              )}
            </TableContainer>
          ) : (
            <>
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "none",
                  borderRadius: "none",
                  paddingTop: "17px",
                  borderBottom: "none",
                }}
              >
                {surplusError ? (
                  <NoResult
                    titulo={"No se pudo cargar la tabla"}
                    subtitulo={
                      "No fue posible mostrar la información debido a un problema de conexión. Intenta recargar la página para solucionar el problema."
                    }
                    error
                  />
                ) : (
                  <Table sx={{ minWidth: 650 }} aria-label="customized table">
                    <TableHead className="color-head-table">
                      <TableRow>
                        <StyledTableCell sx={{ minWidth: "114px" }}>
                          RUT y Nombre <br></br> Cliente
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "114px" }}>
                          Periodo de <br></br>
                          recaudación
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "134px" }}>
                          Excedentes
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "113px" }}>
                          Reajuste
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "142px" }}>
                          Intereses
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "104px" }}>
                          Comisión
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "116px" }}>
                          Uso <br></br>
                          Excedentes
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "114px" }}>
                          Saldo
                        </StyledTableCell>
                        <StyledTableCell
                          className="headerTableAcciones"
                          align="center"
                          sx={{ minWidth: "120px" }}
                        >
                          Acciones
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {surplusRows.map((row: any, index: number) => {
                      return (
                        <TableBody>
                          <TableRow data-testid="withData" key={index}>
                            <TableCell>
                              <Typography data-testid="rut" variant="h5">
                                {row.contributorRut}
                              </Typography>
                              <Typography sx={subTable}>
                                {row.contributorName ?? "N/D"}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h5">
                                {getCurrentPeriod(row.collectionPeriod)[0]}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h5">
                                ${renderAmount(row.surplusGenerated)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h5">
                                ${renderAmount(row.readjustment)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h5">
                                ${renderAmount(row.interest)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h5">
                                ${renderAmount(row.commission)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h5">
                                ${renderAmount(row.chargeAmount)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h5">
                                ${renderAmount(row.availableBalance)}
                              </Typography>
                            </TableCell>
                            {props.isActive ? (
                              <TableCell
                                className="tableBodyAsignar"
                                align="center"
                                sx={{ zIndex: 999 }}
                              >
                                <MenuExcess
                                  info={row}
                                  handleDetails={() => setShowDetail(true)}
                                  handleEdit={() => setShowEdit(true)}
                                  handleDelete={() => setShowDelete(true)}
                                  handleHistory={() => setShowHistory(true)}
                                  setRowInfo={setRowInfo}
                                  disabledActions={props.isConfirmed}
                                  canUpdate={canUpdate}
                                />
                              </TableCell>
                            ) : (
                              <TableCell
                                className="tableBodyAsignar"
                                align="center"
                              >
                                <Button
                                  color="secondary"
                                  sx={{ p: "10px 8.5px !important" }}
                                  onClick={() => handleDetails(row, 2)}
                                >
                                  {LOG_HISTORY}
                                </Button>
                              </TableCell>
                            )}
                          </TableRow>
                        </TableBody>
                      );
                    })}
                  </Table>
                )}
              </TableContainer>
              {!surplusError && surplusCount !== 0 && (
                <>
                  <Grid alignItems="center" sx={{}}>
                    <ThemeProvider theme={paginatorTheme}>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, 50]}
                        component="div"
                        count={surplusCount}
                        rowsPerPage={rowsPerPage}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={"Mostrar por página"}
                        sx={{ mt: "24px", mb: "24px" }}
                        labelDisplayedRows={(e) => {
                          return (
                            "" +
                            (page || 0) +
                            " de " +
                            Math.ceil(surplusCount / (rowsPerPage || 0))
                          );
                        }}
                      />
                    </ThemeProvider>
                    <Divider sx={{ border: "1px solid #E9EBEC" }} />
                  </Grid>
                </>
              )}
            </>
          )}
        </>
      )}
      {/* TODO
        move totals into new component
      */}
      {loadingTotal ? (
        <SkeletonExcessTotals />
      ) : (
        <>
          {!surplusError && allTotalsBy !== null && (
            <Box padding={"24px 19px"}>
              <Stack
                direction={"row"}
                sx={greenBack}
                padding={"16px"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography sx={greenFont} pr="20px">
                  TOTALES
                </Typography>
                <Divider
                  sx={{
                    border: "2px solid #4DC0B8",
                    height: "56px",
                    mr: "10px",
                  }}
                  orientation="vertical"
                />
                <Stack direction="column">
                  <Box>
                    <Typography sx={grayFontBox} display={"inline"}>
                      Intereses:
                    </Typography>
                    <Typography sx={greenFont} display={"inline"} pr="20px">
                      {" "}
                      ${renderAmount(allTotalsBy?.totalInterest)}
                    </Typography>
                    <Divider
                      sx={{
                        borderLeft: " 1px solid #D2D6D9",
                        height: "24px",
                        display: "inline",
                        mr: "8px",
                      }}
                      orientation="vertical"
                    />
                    <Typography sx={grayFontBox} display={"inline"}>
                      Comisiones:
                    </Typography>
                    <Typography sx={greenFont} display={"inline"} pr="20px">
                      {" "}
                      ${renderAmount(allTotalsBy?.totalCommission)}
                    </Typography>
                    <Divider
                      sx={{
                        borderLeft: " 1px solid #D2D6D9",
                        height: "24px",
                        display: "inline",
                        mr: "8px",
                      }}
                      orientation="vertical"
                    />
                    <Typography sx={grayFontBox} display={"inline"}>
                      Reajustes:
                    </Typography>
                    <Typography sx={greenFont} display={"inline"} pr="20px">
                      {" "}
                      ${renderAmount(allTotalsBy?.totalReadjustment)}
                    </Typography>
                    <Divider
                      sx={{
                        borderLeft: " 1px solid #D2D6D9",
                        height: "24px",
                        display: "inline",
                        mr: "8px",
                      }}
                      orientation="vertical"
                    />
                    <Typography sx={grayFontBox} display={"inline"}>
                      Excedentes:
                    </Typography>
                    <Typography sx={greenFont} display={"inline"}>
                      {" "}
                      ${renderAmount(allTotalsBy?.totalSurplusGenerated)}
                    </Typography>
                  </Box>
                  <Box pt="10px">
                    <Typography sx={grayFontBox} display={"inline"}>
                      Uso de Excedentes:
                    </Typography>
                    <Typography sx={greenFont} display={"inline"} pr="20px">
                      {" "}
                      ${renderAmount(allTotalsBy?.totalChargeAmount)}
                    </Typography>
                    <Divider
                      sx={{
                        borderLeft: " 1px solid #D2D6D9",
                        height: "24px",
                        display: "inline",
                        mr: "8px",
                      }}
                      orientation="vertical"
                    />
                    <Typography sx={grayFontBox} display={"inline"}>
                      Saldo:
                    </Typography>
                    <Typography sx={greenFont} display={"inline"} pr="20px">
                      {" "}
                      ${renderAmount(allTotalsBy?.totalAvailableBalance)}
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default TableExcess;
