import { Box, Typography } from "@mui/material";
import { Alerts, Breadcrum } from "src/components";

type MissingPeriodProps = {
  displayName: string;
};

const MissingPeriod = ({ displayName }: MissingPeriodProps) => {
  return (
    <Box pl="32px" pr="32px">
      <Typography variant="h1" pt="24px" mb="16px">
        {displayName} - Sin información
      </Typography>
      <Breadcrum back1="Inicio" link1="/" now={displayName} />
      <Alerts
        mensaje={[
          `No has seleccionado un periodo de ${displayName}. Vuelve al menú e ingresa tras seleccionar un periodo.`,
        ]}
        open={true}
        data={null}
        setOpen={null}
        severidad={"warning"}
        noClose
        typeAlert={2}
      />
    </Box>
  );
};

export default MissingPeriod;
