import { useLazyQuery, useMutation } from "@apollo/client";
import { SNACK_ACTION_TYPE } from "src/reducers/snackInfoReducer";
import constants from "src/constants/constants";
import {
  GET_PENDING_CONTRACTS,
  GET_RUT_CONTEXT,
} from "src/services/graphql/queries";
import { ExcessMovementInfo } from "./../rutSearchTypes";
import { CREATE_EXCESS_MOVEMENT } from "src/services/graphql/mutations";

const { SET_SNACK_ERROR, SET_SNACK_SUCCESS } = SNACK_ACTION_TYPE;
const {
  QUERIES: {
    CODE: { VALIDATION_FAILED },
    FETCH_POLICY: { NETWORK_ONLY },
  },
  SNACKBAR_MESSAGE: {
    EXCESS_MOVEMENT: { CREATE_ERROR, CREATE_SUCCESS },
  },
} = constants;

type byRutType = {
  handleClose: () => void | undefined;
  setSnackInfo: React.Dispatch<React.SetStateAction<any>>;
  setRefresh: (value: boolean) => void;
};

const useRutSearch = ({ handleClose, setSnackInfo, setRefresh }: byRutType) => {
  const [
    getRutContext,
    {
      data: {
        period: {
          oneContextBy: {
            contributorName,
            surplusAvailableBalance,
            surplusAccountableBalance,
            excessAvailableBalance,
          },
        },
      } = {
        period: {
          oneContextBy: {
            contributorName: "",
            surplusAvailableBalance: 0,
            surplusAccountableBalance: 0,
            excessAvailableBalance: 0,
          },
        },
      },
      loading: loadingContext,
      error: errorContext,
    },
  ] = useLazyQuery(GET_RUT_CONTEXT, {
    fetchPolicy: NETWORK_ONLY as any,
  });

  const getContextData = (rut: string) => {
    getRutContext({
      variables: {
        input: {
          contributorRut: rut.replace(/\./g, ""),
        },
      },
    });
  };

  const [
    getPendingContracts,
    {
      data: {
        period: {
          collection: {
            contributorContract: { allPendingBy },
          },
        },
      } = {
        period: {
          collection: {
            contributorContract: { allPendingBy: [] },
          },
        },
      },
      loading: loadingPendingContracts,
      error: errorPendingContracts,
    },
  ] = useLazyQuery(GET_PENDING_CONTRACTS, {
    fetchPolicy: NETWORK_ONLY as any,
  });

  const getPendingContractsData = (rut: string) => {
    getPendingContracts({
      variables: {
        input: {
          contributorRut: rut,
        },
      },
    });
  };

  const [
    createExcessMovement,
    { loading: loadingCreateExcessMovement, error: errorExcessMovement },
  ] = useMutation(CREATE_EXCESS_MOVEMENT, {
    onCompleted: () => {
      setSnackInfo({
        type: SET_SNACK_SUCCESS,
        payload: CREATE_SUCCESS,
      });
      setRefresh(true);
      handleClose();
    },
    onError: (error: any) => {
      const { message, code } = error?.graphQLErrors[0] || {};
      if (VALIDATION_FAILED === code) {
        setSnackInfo({
          type: SET_SNACK_ERROR,
          payload: message.split(".")[0],
        });
      } else {
        setSnackInfo({
          type: SET_SNACK_ERROR,
          payload: CREATE_ERROR,
        });
      }
      setRefresh(true);
      handleClose();
    },
  });

  const AddExcessMovement = (options: ExcessMovementInfo) => {
    createExcessMovement({
      variables: {
        input: {
          amount: options.amount,
          contractId: options.contractId,
          contributorRut: options.contributorRut,
          movementDate: options.movementDate,
          movementType: options.movementType,
        },
      },
    });
  };

  return {
    getContextData,
    contributorName,
    surplusAvailableBalance,
    surplusAccountableBalance,
    excessAvailableBalance,
    loadingContext,
    errorContext,
    getPendingContractsData,
    allPendingBy,
    loadingPendingContracts,
    errorPendingContracts,
    AddExcessMovement,
    loadingCreateExcessMovement,
    errorExcessMovement,
  };
};

export default useRutSearch;
