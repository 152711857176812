import { useState, useEffect } from "react";
import { Box, Typography, Button, Tabs, Tab, Stack, Grid } from "@mui/material";
import { Breadcrum, TabPanel } from "src/components";
import {
  subText20,
  detailText,
  littleTitle,
  littleDetail,
} from "src/components/typography/styles";
import { filterBox } from "src/components/box/styles";
import {
  ModalFileCash,
  RecordTableCash,
  SkeletonHeaderCash,
  TableDeletedCash,
  TableRegistersCash,
} from "./index";
import { useLocation } from "react-router-dom";
import Upload from "../../../assets/icons/uploadWhite.svg";
import { getCurrentPeriod } from "src/utils/dates";
import { hasPermissions } from "src/utils/hasPermissions";
import constants from "src/constants/constants";
import { useLogs } from "src/hooks/useLogs";
import { systemModules } from "src/utils/selectors";
import MissingPeriod from "src/pages/MissingPeriod";

const {
  COMPONENTS: {
    BUTTONS: { UPLOAD_FILE },
  },
  PAGES: {
    MODULES: { PAYMENTS },
    DISPLAY_NAME: { CASH },
  },
} = constants;

const Cash = () => {
  const [valorTab, setValorTab] = useState(0);
  const [openModalFile, setOpenModalFile] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const location = useLocation();
  const period = location.state?.period || "";

  const { getLog, rows: rowsLogs, loading: loadingLogs } = useLogs();
  const getLatestUploadedFile = () => {
    getLog({
      logTypeId: systemModules(PAYMENTS),
      fkId: parseInt(period),
    });
  };

  useEffect(() => {
    getLatestUploadedFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refresh === true) {
      getLatestUploadedFile();
      setRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  if (!period) {
    return <MissingPeriod displayName={CASH} />;
  }

  return (
    <>
      <ModalFileCash
        onOpen={openModalFile}
        onClose={() => setOpenModalFile(false)}
        period={period}
        setRefresh={setRefresh}
      />
      <Box pl="32px" pr="32px">
        <Typography variant="h1" pt="24px" mb="16px">
          {CASH} - {getCurrentPeriod(period)[0]}
        </Typography>
        <Breadcrum
          back1="Inicio"
          link1="/"
          now={CASH + " - " + getCurrentPeriod(period)[0]}
        />
        <Box sx={filterBox} mt="16px" mb="24px">
          {loadingLogs ? (
            <SkeletonHeaderCash />
          ) : (
            <Grid container>
              <Grid xs={7}>
                <Typography sx={littleTitle} component="div">
                  Cargar recaudación {CASH}
                </Typography>
                <Typography
                  sx={detailText}
                  component="div"
                  pt="16px"
                  pb="8px   "
                >
                  Carga información específica sobre la recaudación {CASH}.
                </Typography>
                <Typography sx={subText20} component="div">
                  {rowsLogs.length > 0
                    ? "Última carga realizada el " +
                      rowsLogs[0]?.logDate.slice(0, 10).split("-").join("/") +
                      " a las " +
                      rowsLogs[0]?.logDate.slice(11, 16) +
                      " hrs: con un total de " +
                      rowsLogs[0]?.description.split("|")[0] +
                      " registros."
                    : "Comienza cargando información."}
                </Typography>
              </Grid>
              <Grid xs={5} pt="36px">
                <Stack
                  direction="row"
                  textAlign="right"
                  justifyContent="flex-end"
                >
                  <Typography sx={littleDetail} component="div" pr="16px">
                    Formato aceptados: .xlsx <br></br>
                    Peso máximo de 4MB
                  </Typography>
                  <Button
                    color="primary"
                    startIcon={<img src={Upload} alt="" />}
                    onClick={() => setOpenModalFile(true)}
                    disabled={!hasPermissions().uploadFile}
                  >
                    {UPLOAD_FILE}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          )}
        </Box>
        <Tabs
          sx={{ width: "80%" }}
          textColor="primary"
          value={valorTab}
          onChange={(event, value) => setValorTab(value)}
        >
          <Tab label={CASH} />
          <Tab label="Historial de carga" />
          <Tab label="Registros eliminados" />
        </Tabs>
        <TabPanel value={valorTab} index={0}>
          <TableRegistersCash
            refresh={refresh}
            setRefresh={setRefresh}
            period={period}
          />
        </TabPanel>
        <TabPanel value={valorTab} index={1}>
          <RecordTableCash
            refresh={refresh}
            setRefresh={setRefresh}
            period={period}
            module={PAYMENTS}
            tabValue={valorTab}
          />
        </TabPanel>
        <TabPanel value={valorTab} index={2}>
          <TableDeletedCash period={period} />
        </TabPanel>
      </Box>
    </>
  );
};

export default Cash;
