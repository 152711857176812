import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { MsalProvider } from "@azure/msal-react";
import { getAccessToken, getAccount } from "./utils/msal";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/themeConfig";
// trigger !!!
const msalInstance = getAccount();

const abortController = new AbortController();

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}`,
  fetchOptions: {
    signal: abortController.signal,
  },
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getAccessToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // 'Apollo-Require-Preflight': '*',
      Authorization: token ? `Bearer ${token}` : "",
      // 'Cache-Control': 'no-cache',
      "Content-Type": "application/json",
      // 'Accept': '*/*'
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      PreviredType: {
        merge: true,
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <MsalProvider instance={msalInstance}>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </MsalProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
