import { GET_STATUS } from "src/services/graphql/queries";
import { useLazyQuery } from "@apollo/client";

type StatusType = {
  specificType: number;
};

export const useStatus = ({ specificType }: StatusType) => {
  const [
    statusByType,
    {
      data: {
        period: {
          status: { allBy: allStatusByType },
        },
      } = {
        period: {
          status: {
            allBy: [],
          },
        },
      },
      loading: loadingByType,
      error: errorByType,
    },
  ] = useLazyQuery(GET_STATUS, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        specificType,
      },
    },
  });

  return {
    statusByType,
    allStatusByType,
    loadingByType,
    errorByType,
  };
};
