import { useState } from "react";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import download from "src/assets/icons/download.svg";
import GreenDownload from "src/assets/icons/greenDownload.svg";
import constants from "src/constants/constants";

const {
  COMPONENTS: {
    BUTTONS: { EXPORT },
  },
} = constants;

const MenuExportDevolution = (props: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {props.loading ? (
        <Button
          color={"secondary"}
          sx={{
            minWidth: "120px",            
          }}
          disabled={props.disabled}
        >
          <div className="spinnerButtonGreen"></div>
        </Button>
      ) : (
        <Button
          aria-label="more"
          disabled={props.disabled}
          onClick={handleClick}
          color={"secondary"}
          sx={{
            minWidth: "120px",
          }}
          startIcon={
            <img
              src={props.disabled ? download : GreenDownload}
              style={{ paddingRight: "6px" }}
              alt="download"
            />
          }
        >
          {EXPORT}
        </Button>
      )}
      <Menu
        className="littleMenu"
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
      >
        <MenuItem
          disabled={props.disabled || props.noData}
          onClick={() => {
            handleClose();
            props.exportDevolution();
          }}
        >
          {EXPORT}
        </MenuItem>
        <MenuItem
          disabled={props.disabled}
          onClick={() => {
            handleClose();
            props.exportOnDemand();
          }}
          data-testid="export-on-demand"
        >
          Generar cargador dev. a <br></br>
          demanda
        </MenuItem>
        <MenuItem
          disabled={props.disabled}
          onClick={() => {
            handleClose();
            props.exportMonthly();
          }}
          data-testid="export-monthly"
        >
          Generar cargador dev. <br></br>
          mensual
        </MenuItem>
      </Menu>
    </>
  );
};

export default MenuExportDevolution;
