export const capitalCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const capitalCaseAll = (str: string) => {
  return str
    .split(" ")
    .map((word) => capitalCase(word))
    .join(" ");
};

export const formatRut = (rut: string) => {
  return rut?.replace(/^(\d{1,3})(\d{3})(\d{3})([-]?)([\dkK])$/, "$1.$2.$3-$5");
};

export const runDvToUpperCase = (run: string) => {
  if (run && run.charAt(run.length - 1) === "k") {
    return run.substring(0, run.length - 1) + "K";
  }
  return run;
};
