import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import constants from "src/constants/constants";

const {
  COMPONENTS: {
    MENU_ITEMS: { DELETE_RECORD, DETAIL, EDIT_RECORD, CHANGE_HISTORY },
  },
} = constants;

const MenuOverage = (props: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-label="overageActions" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        className="littleMenu"
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
      >
        <MenuItem onClick={() => props.handleDetails()}>{DETAIL}</MenuItem>
        <MenuItem onClick={() => props.handleEdit()} disabled={props.disabledActions}>{EDIT_RECORD}</MenuItem>
        <MenuItem onClick={() => props.handleDelete()} disabled={props.disabledActions}>{DELETE_RECORD}</MenuItem>
        <MenuItem
          onClick={() => props.handleHistory()}          
        >
          {CHANGE_HISTORY}
        </MenuItem>
      </Menu>
    </>
  );
};

export default MenuOverage;
