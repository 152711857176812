import { Alert, Box, Collapse, Grid } from "@mui/material";
import { iconStyle, style } from "./styles";

export default function Alerts({
  //   titulo,
  //   subtitulo,
  mensaje,
  data,
  open,
  setOpen,
  severidad,
  noClose,
  typeAlert,
}) {
  return (
    <Grid xs={12}>
      <Box sx={{ width: "97%" }}>
        <Collapse in={open}>
          <Grid sx={{ paddingTop: open ? "16px" : "0px" }}>
            <Alert
              severity={severidad}
              sx={style(severidad)}
              iconMapping={iconStyle(severidad)}
              onClose={noClose ? null : () => setOpen(false)}
            >
              {typeAlert === 1
                ? mensaje[0] + data[0] + mensaje[1] + data[1]
                : mensaje}
            </Alert>
          </Grid>
        </Collapse>
      </Box>
    </Grid>
  );
}
