import { Fragment, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import constants from "src/constants/constants";

const {
  COMPONENTS: {
    MENU_ITEMS: { EDIT, DELETE },
  },
} = constants;

export const MenuC = (props: any) => {
  const [AnclaMenu, setAnclaMenu] = useState(null);
  const open = Boolean(AnclaMenu);

  const AbrirMenuC = (event: any) => {
    setAnclaMenu(event.currentTarget);
    props.setRowData({
      id: props.info.id,
      contributorRut: props.info.contributorRut,
      contributorName: props.info.contributorName,
      paymentUf: props.info.paymentUf,
      paymentPeriod: props.info.paymentPeriod,
      contractedAmount: props.info.contractedAmount,
      workerType: props.info.workerType,
    });
  };

  const CerrarMenuC = () => {
    setAnclaMenu(null);
  };

  return (
    <Fragment>
      <IconButton aria-label="more" onClick={AbrirMenuC}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        className="littleMenu"
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={AnclaMenu}
        open={open}
        onClose={CerrarMenuC}
      >
        <MenuItem onClick={() => props.setModalHistory(true)}>
          Historial de <br></br> cambios
        </MenuItem>
        <MenuItem
          onClick={() => props.setModalEdit(true)}
          disabled={!props.canUpdate}
        >
          {EDIT}
        </MenuItem>
        <MenuItem
          onClick={() => props.setModalDelete(true)}
          disabled={!props.canUpdate}
        >
          {DELETE}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
