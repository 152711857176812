import {
  Box,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { boxNoPadding, filterBox } from "src/components/box/styles";
import SkeletonBar from "src/components/skeleton/skeletonBar";
import { StyledTableCell } from "src/components/tables/tableStyle";

const SkeletonTableCollection = (props: any) => (
  <Box sx={boxNoPadding} mt="16px" mb="24px" pb="80px">
    <Grid container>
      <Grid item xs={12}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          pt="15px"
          pr="24px"
        >
          <div style={{ paddingLeft: "24px", paddingTop: "16px" }}>
            <Stack direction={"row"}>
              <div style={{ paddingRight: "40px" }}>
                <SkeletonBar width={120} height={16} />
              </div>
              <SkeletonBar width={120} height={16} />
            </Stack>
          </div>

          <Stack direction={"row"}>
            <div style={{ paddingRight: "16px", paddingTop: "12px" }}>
              <SkeletonBar width={120} height={16} />
            </div>
            <SkeletonBar width={140} height={40} />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none", borderRadius: "none", paddingTop: "17px" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="customized table">
        <TableHead className="color-head-table">
          <TableRow>
            {[...Array(props.columns)].map((x, i) => (
              <StyledTableCell key={i}>
                <SkeletonBar width={80} height={14} />
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(props.rows)].map((x, i) => (
            <TableRow data-testid="withData">
              {[...Array(props.columns)].map((y, o) => (
                <TableCell key={o}>
                  <SkeletonBar width={100} height={14} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
);

const SkeletonClosePeriod = () => (
  <Box sx={filterBox} mt="16px" mb="24px">
    <Grid container>
      <Grid xs={9}>
        <div style={{ paddingBottom: "8px" }}>
          <SkeletonBar width={371} height={16} />
        </div>

        <SkeletonBar width={371} height={16} />
      </Grid>
      <Grid
        xs={3}
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <SkeletonBar width={140} height={40} />
      </Grid>
    </Grid>
  </Box>
);

export { SkeletonTableCollection, SkeletonClosePeriod };
