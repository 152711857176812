import { useState, useEffect } from "react";
import { getCurrentPeriod } from "src/utils/dates";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
  Checkbox,
} from "@mui/material";
import SearchCollection from "./searchCollection";
import { ChipsCollection } from "./chipsCollection";
import { ExportButton, NoResult } from "src/components";
import { StyledTableCell } from "src/components/tables/tableStyle";
import { paginatorTheme } from "src/components/paginator/paginatorStyle";
import {
  boxNoPadding2,
} from "src/components/box/styles";
import {
  contTitle,
  subTable,
  textTooltip,
} from "src/components/typography/styles";
import download from "src/assets/icons/download.svg";
import { SkeletonTableCollection } from "./skeletonsCollection";
import Info from "src/assets/icons/info.svg";
import { BootstrapTooltip } from "src/components/header/drawerLeft";
import PencilIcon from "../../../assets/icons/pencil.svg";
import ButtonEdit from "./buttonEdit";
import EditClassification from "./editClassification";
import EditCollection from "./editCollection";
import { hasPermissions } from "src/utils/hasPermissions";
import constants from "src/constants/constants";
import { usePayment } from "src/pages/collection/hooks/usePayment";
import { renderAmount } from "src/utils/numberUtils";

const {
  PAYMENT_TYPE: { EXCESS },
} = constants;

export const classOptions = [
  {
    id: "1",
    statusId: "11",
    name: "Ingresado",
  },
  {
    id: "2",
    statusId: "12",
    name: "Anticipado",
  },
  {
    id: "3",
    statusId: "13",
    name: "CME",
  },
];

export type Filters = {
  contributorRut: string | null;
  payerRut: string | null;
  statusId: string | null;
  workerType: string | null;
  paymentType: string | null;
};

export type CollectionFilterProps = {
  filtros: Filters;
  setFiltros: React.Dispatch<React.SetStateAction<Filters>>;
};

const TableCollection = (props: any) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalBuscar, setModalBuscar] = useState<boolean>(false);
  const [showEditObs, setShowEditObs] = useState<boolean>(false);
  const [editClassification, setEditClassification] = useState<boolean>(false);
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [isCheck, setIsCheck] = useState<number[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [rowData, setRowData] = useState({
    id: null,
    contributorRut: null,
    contributorName: null,
    payerRut: null,
    payerName: null,
    paymentType: null,
    paymentClassification: null,
    observation: null,
  });

  const [filters, setFilters] = useState<Filters>({
    contributorRut: null,
    payerRut: null,
    statusId: null,
    workerType: null,
    paymentType: null,
  });
  const canEdit = hasPermissions().update;

  const {
    getPaymentData,
    paymentCount,
    paymentRows,
    paymentLoading,
    paymentError,
  } = usePayment();

  const cleanFilters = () => {
    setFilters({
      contributorRut: null,
      payerRut: null,
      statusId: null,
      workerType: null,
      paymentType: null,
    });
  };

  const handleRowClick = (row: any) => {
    setRowData({
      id: row.id,
      contributorRut: row.contributorRut,
      contributorName: row.contributorName,
      payerRut: row.payerRut,
      payerName: row.payerName,
      paymentType: row.paymentType,
      paymentClassification: row.paymentClassification,
      observation: row.observation,
    });
    setShowEditObs(true);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const TipoTrabajador = () => {
    return (
      <>
        <Typography sx={textTooltip}>D: Dependiente</Typography>
        <Typography sx={textTooltip}>P: Pensionado</Typography>
        <Typography sx={textTooltip}>V: Voluntario</Typography>
        <Typography sx={textTooltip}>I: Independiente</Typography>
      </>
    );
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckAll(event.target.checked);
    if (event.target.checked) {
      setIsCheck(
        paymentRows
          .filter((elem: any) => elem.paymentType !== EXCESS)
          .map((elem: any) => parseInt(elem.id))
      );
    } else {
      setIsCheck([]);
    }
  };

  const handleCheck = (event: any, id: any) => {
    if (event.target.checked) {
      setIsCheck([...isCheck, parseInt(id)]);
    } else {
      setIsCheck(isCheck.filter((item: any) => item !== parseInt(id)));
    }
  };

  const resetCheck = () => {
    setIsCheck([]);
    setIsCheckAll(false);
  };

  const TagsCollection = ({ estado }: any) => {
    switch (true) {
      case estado === "Ingresado":
        return <label className="tagEstadoCasos tagGreen">Ingresado</label>;
      case estado === "CME":
        return <label className="tagEstadoCasos tagYellow">CME</label>;
      case estado === "malRecibir":
        return (
          <label className="tagEstadoCasos tagPurple">Mal ent. recibir</label>
        );
      case estado === "Anticipado":
        return <label className="tagEstadoCasos tagPurple">Anticipado</label>;
      case estado === "malDevolver":
        return (
          <label className="tagEstadoCasos tagPurple">Mal ent. devolver</label>
        );
      default:
        return (
          <label className="tagEstadoCasos tagRed">
            {estado ? estado : "Sin Clasificación"}
          </label>
        );
    }
  };

  useEffect(() => {
    if (props.period !== "") {
      setPage(1);
      getPaymentData({
        limit: rowsPerPage,
        offset: 0,
        collectionPeriod: props.period,
        includePrevired: true,
        isActive: true,
        contributorRut: null,
        payerRut: null,
        statusId: null,
        workerType: null,
        paymentType: null,
      });
      cleanFilters();
      resetCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.period]);

  useEffect(() => {
    if (props.period !== "") {
      setPage(1);
      getPaymentData({
        limit: rowsPerPage,
        offset: 0,
        collectionPeriod: props.period,
        includePrevired: true,
        isActive: true,
        ...filters,
      });
      resetCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (props.period !== "") {
      getPaymentData({
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
        collectionPeriod: props.period,
        includePrevired: true,
        isActive: true,
        ...filters,
      });
      resetCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (refresh) {
      getPaymentData({
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
        collectionPeriod: props.period,
        includePrevired: true,
        isActive: true,
        ...filters,
      });
      setRefresh(false);
      resetCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <Box sx={boxNoPadding2} mt="16px" mb="24px">
      <EditClassification
        onOpen={editClassification}
        handleClose={() => setEditClassification(false)}
        classOptions={classOptions}
        data={isCheck}
        setRefresh={setRefresh}
        resetCheck={resetCheck}
      />
      <EditCollection
        onOpen={showEditObs}
        handleClose={() => setShowEditObs(false)}
        info={rowData}
        period={props.period}
        setRefresh={setRefresh}
        classOptions={classOptions}
      />
      {props.period === "" ? (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                pt="15px"
                pr="24px"
              >
                <Typography sx={contTitle} pt="11px" pl="24px">
                  Recaudación
                </Typography>{" "}
                <Stack direction={"row"}>
                  <div>
                    <Button color="inherit" disabled>
                      Ver filtros
                    </Button>
                  </div>
                  <Button
                    color={"secondary"}
                    startIcon={
                      <img
                        src={download}
                        style={{ paddingRight: "12px" }}
                        alt="download"
                      />
                    }
                    disabled
                  >
                    {" "}
                    Exportar
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "none",
              borderRadius: "none",
              paddingTop: "17px",
              borderBottom: "none",
            }}
          >
            <NoResult
              titulo={"No se ha seleccionado un periodo de recaudación"}
              subtitulo={"Seleccione para ver el listado"}
              dontSearch
            />
          </TableContainer>
        </>
      ) : (
        <>
          {paymentLoading ? (
            <SkeletonTableCollection
              columns={7}
              rows={3}
              data-testid="skeleton-table"
            />
          ) : (
            <>
              <Grid container>
                <Grid item xs={12}>
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    pt="15px"
                    pr="24px"
                  >
                    <Typography sx={contTitle} pt="11px" pl="24px">
                      Recaudación
                    </Typography>{" "}
                    <Stack direction={"row"}>
                      <div>
                        <Button
                          data-testid="ver-filtros"
                          color="inherit"
                          onClick={() => setModalBuscar(true)}
                        >
                          Ver filtros
                        </Button>
                      </div>
                      <ExportButton
                        type={2}
                        collection={true}
                        contributorRut={filters.contributorRut || null}
                        payerRut={filters.payerRut || null}
                        paymentType={filters.paymentType}
                        period={props.period}
                        isActive={true}
                        includePrevired={true}
                        statusId={filters.statusId}
                        workerType={filters.workerType}
                        disabled={paymentCount === 0}
                      />
                    </Stack>
                  </Stack>
                </Grid>
                <ChipsCollection filtros={filters} setFiltros={setFilters} />
              </Grid>
              <>
                <SearchCollection
                  onOpen={modalBuscar}
                  handleClose={() => setModalBuscar(false)}
                  filters={filters}
                  setFilters={setFilters}
                  origen={props.originOptions}
                  // workerOptions={props.workerOptions}
                  data-testid="search-modal"
                  classOptions={classOptions}
                />
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "none",
                    borderRadius: "none",
                    paddingTop: "17px",
                    borderBottom: "none",
                  }}
                >
                  {paymentError ? (
                    <NoResult
                      titulo={"No se pudo cargar la tabla"}
                      subtitulo={
                        "No fue posible mostrar la información debido a un problema de conexión. Intenta recargar la página para solucionar el problema."
                      }
                      error
                    />
                  ) : (
                    <>
                      {paymentCount === 0 ? (
                        filters.contributorRut ||
                        filters.payerRut ||
                        filters.statusId ||
                        filters.workerType ||
                        filters.paymentType ? (
                          <NoResult
                            titulo={"Sin Resultados"}
                            subtitulo={
                              "No se encontraron resultados para esta tabla"
                            }
                          />
                        ) : (
                          <NoResult
                            titulo={"No se han cargado archivos de recaudación"}
                            subtitulo={"Cargue información para ver el listado"}
                          />
                        )
                      ) : (
                        <Table
                          sx={{ minWidth: 650 }}
                          aria-label="customized table"
                        >
                          <TableHead className="color-head-table">
                            <TableRow>
                              <StyledTableCell sx={{ minWidth: "48px" }}>
                                <Checkbox
                                  onChange={handleSelectAll}
                                  checked={isCheckAll}
                                  disabled={!canEdit}
                                />
                              </StyledTableCell>
                              <StyledTableCell sx={{ minWidth: "114px" }}>
                                RUT y <br></br> Nombre <br></br> Cliente
                              </StyledTableCell>
                              <StyledTableCell sx={{ minWidth: "114px" }}>
                                RUT y <br></br>Nombre <br></br> Pagador
                              </StyledTableCell>

                              <StyledTableCell sx={{ minWidth: "134px" }}>
                                Periodo <br></br> remuneración
                              </StyledTableCell>
                              <StyledTableCell sx={{ minWidth: "113px" }}>
                                Total <br></br> Pagado
                              </StyledTableCell>
                              <StyledTableCell sx={{ minWidth: "124px" }}>
                                <Stack
                                  direction="row"
                                  justifyContent="space-evenly"
                                >
                                  Tipo <br></br> trabajador{" "}
                                  <BootstrapTooltip
                                    title={<TipoTrabajador />}
                                    arrow
                                    placement="right"
                                  >
                                    <img src={Info} alt="info" />
                                  </BootstrapTooltip>
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell sx={{ minWidth: "142px" }}>
                                Clasificación
                              </StyledTableCell>
                              <StyledTableCell sx={{ minWidth: "104px" }}>
                                Origen
                              </StyledTableCell>
                              <StyledTableCell sx={{ minWidth: "116px" }}>
                                Fecha de <br></br> Pago
                              </StyledTableCell>
                              <StyledTableCell sx={{ minWidth: "114px" }}>
                                Obs.
                              </StyledTableCell>
                              <StyledTableCell
                                className="headerTableAcciones"
                                align="center"
                                sx={{ minWidth: "120px" }}
                              >
                                Acciones
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          {paymentRows.map((elem: any) => {
                            return (
                              <TableBody>
                                <TableRow data-testid="withData" key={elem.id}>
                                  <TableCell>
                                    <Checkbox
                                      onChange={(e) => handleCheck(e, elem.id)}
                                      checked={isCheck.includes(
                                        parseInt(elem.id)
                                      )}
                                      disabled={
                                        !canEdit || elem.paymentType === EXCESS
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Typography data-testid="rut" variant="h5">
                                      {elem.contributorRut}
                                    </Typography>
                                    <Typography sx={subTable}>
                                      {elem.contributorName}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography data-testid="rut" variant="h5">
                                      {elem.payerRut}
                                    </Typography>
                                    <Typography sx={subTable}>
                                      {elem.payerName}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="h5">
                                      {getCurrentPeriod(elem.paymentPeriod)[0]}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="h5">
                                      ${renderAmount(elem.amount)}
                                    </Typography>
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    <Typography variant="h5">
                                      {elem.workerType}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <TagsCollection
                                      estado={elem.paymentClassification}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="h5">
                                      {elem.paymentType}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="h5">
                                      {elem.paymentDate
                                        ? elem.paymentDate
                                            .slice(0, 10)
                                            .replace(/-/g, "/")
                                        : "Sin info"}
                                    </Typography>
                                  </TableCell>
                                  {elem.observation ? (
                                    <TableCell>
                                      <>
                                        {elem.observation.length > 24 ? (
                                          <BootstrapTooltip
                                            title={elem.observation}
                                            arrow
                                            placement="left"
                                          >
                                            <Typography variant="h5">
                                              {elem.observation.slice(0, 20) +
                                                "..."}
                                            </Typography>
                                          </BootstrapTooltip>
                                        ) : (
                                          <Typography variant="h5">
                                            {elem.observation}
                                          </Typography>
                                        )}
                                      </>
                                    </TableCell>
                                  ) : (
                                    <TableCell>
                                      <Typography variant="h5">
                                        {"Sin info"}
                                      </Typography>
                                    </TableCell>
                                  )}
                                  <TableCell
                                    className="tableBodyAsignar"
                                    align="center"
                                    sx={{ zIndex: 999 }}
                                  >
                                    {elem.paymentType === EXCESS ? (
                                      <BootstrapTooltip
                                        title={
                                          "Para gestionar pagos de origen exceso debe ingresar a consultas por RUT."
                                        }
                                        arrow
                                        placement="left"
                                      >
                                        <Typography variant="h5">
                                          <Button color="secondary" disabled>
                                            <img
                                              src={PencilIcon}
                                              alt="PencilIcon"
                                            />
                                          </Button>
                                        </Typography>
                                      </BootstrapTooltip>
                                    ) : (
                                      <Button
                                        color="secondary"
                                        onClick={() => handleRowClick(elem)}
                                        disabled={!canEdit}
                                        data-testid="edit-row-button"
                                      >
                                        <img
                                          src={PencilIcon}
                                          alt="PencilIcon"
                                        />
                                      </Button>
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            );
                          })}
                        </Table>
                      )}
                    </>
                  )}
                </TableContainer>
                {isCheck.length > 0 && (
                  <ButtonEdit
                    setOpen={setEditClassification}
                    checkCount={isCheck.length}
                  />
                )}
                {paymentCount !== 0 && (
                  <Grid alignItems="center">
                    <ThemeProvider theme={paginatorTheme}>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, 50]}
                        component="div"
                        count={paymentCount}
                        rowsPerPage={rowsPerPage}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={"Mostrar por página"}
                        sx={{ mt: "24px", mb: "24px" }}
                        labelDisplayedRows={(e) => {
                          return (
                            "" +
                            (page) +
                            " de " +
                            Math.ceil(paymentCount / rowsPerPage)
                          );
                        }}
                      />
                    </ThemeProvider>
                  </Grid>
                )}
              </>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default TableCollection;
