import { useEffect, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableCell } from "src/components/tables/tableStyle";
import {
  blackBold,
  littleDetail,
  littleTitle,
  modalSubtitle,
  purpleLittle,
  titleTextBox,
} from "src/components/typography/styles";
import {
  ConfirmModalNew,
  CustomInputSlim,
  CustomModal,
  CustomModalTitle,
  NoResult,
} from "src/components";
import { capitalCase } from "src/utils/stringUtils";
import GreenTrash from "src/assets/icons/trashGreen.svg";
import GrayTrash from "src/assets/icons/trashGray.svg";
import { getCurrentPeriod } from "src/utils/dates";
import constants from "src/constants/constants";
import useOverage from "../hook/useOverage";
import { SkeletonEditMovement } from "../../excess/components/skeletonExcess";

const {
  COMPONENTS: {
    BUTTONS: { CLOSE, DELETE, SAVE_CHANGES },
  },
  EXCESS: {
    MOVEMENT_TYPE: {
      BALANCE_TRANSFER,
      REJECTED_RETURN,
      RETURN_MONTHLY,
      RETURN_ON_DEMAND,
    },
  },
} = constants;

type MovementInfo = {
  id: number;
  movementDate: string;
  movementType: string;
  amountType: string;
  amount: number;
  paymentPeriod: string;
};

type EditMovementProps = {
  onOpen: boolean;
  handleCloseModal: () => void;
  info: {
    contributorRut: string;
    contributorName: string;
    collectionPeriod: string;
    availableBalance: number;
    excessDetail: [MovementInfo];
  };
  setRefresh: (value: boolean) => void;
  setSnackInfo: React.Dispatch<React.SetStateAction<any>>;
  canUpdate: boolean;
  refreshOnError?: (value: number) => void;
};

const EditOverageNew = ({
  onOpen,
  handleCloseModal,
  info,
  setRefresh,
  setSnackInfo,
  canUpdate,
  refreshOnError,
}: EditMovementProps) => {
  const {
    contributorRut,
    contributorName,
    collectionPeriod,
    availableBalance,
    excessDetail,
  } = info || {};
  const [movementData, setMovementData] = useState<any>(excessDetail);
  const [movementInfo, setMovementInfo] = useState([
    { id: 0, amount: 0, amountType: "" },
  ]);
  const [showDelete, setShowDelete] = useState(false);
  const [rowInfo, setRowInfo] = useState<MovementInfo>({
    id: 0,
    movementDate: "",
    movementType: "",
    amountType: "",
    amount: 0,
    paymentPeriod: "",
  });

  const handleClose = () => {
    setMovementData(editableExcessDetail);
    setMovementInfo([{ id: 0, amount: 0, amountType: "" }]);
    setShowDelete(false);
    clearRowInfo();
    handleCloseModal();
  };

  const handleRowAmountChange = (e: any, id: number, index: number) => {
    const newMovementInfo = [...movementInfo];
    const regex = /^\d+$/;
    const value = e.target.value.replace(/^0+/, "");

    if (regex.test(value)) {
      newMovementInfo[index] = {
        id: id,
        amount: parseInt(value),
        amountType: movementData[index].amountType,
      };
      const updatedValues = movementData.map((item: any) => {
        return item.id === id ? { ...item, amount: parseInt(value) } : item;
      });
      setMovementData(updatedValues);
    } else if (value === "") {
      newMovementInfo[index] = {
        id: id,
        amount: 0,
        amountType: movementData[index].amountType,
      };
      const updatedValues = movementData.map((item: any) => {
        return item.id === id ? { ...item, amount: 0 } : item;
      });
      setMovementData(updatedValues);
    }
    setMovementInfo(newMovementInfo);
  };

  const updatedMovementInfo = () => {
    const updatedMovement = movementInfo.filter(
      (item) => item !== undefined && item.id !== 0
    );
    return updatedMovement;
  };

  const checkValidAmount = () => {
    const updatedAmount = updatedMovementInfo().map((item: any) => {
      const { amount } = item;
      return amount;
    });
    const validAmount = updatedAmount.every((item: any) => item > 0);
    return validAmount;
  };

  const handleSaveChanges = () => {
    const updatedMovement = updatedMovementInfo().map((item: any) => {
      const { amountType, ...rest } = item;
      return rest;
    });

    updateMovement({
      variables: {
        input: {
          movementsData: updatedMovement,
        },
      },
    });
  };

  const {
    handleDeleteMovement,
    loading,
    loadingDeleteMovement,
    updateMovement,
  } = useOverage({
    id: rowInfo.id,
    excessDetail,
    setSnackInfo,
    setRefresh,
    handleClose,
    setShowDelete,
    refreshOnError,
  });

  const handleDeleteRow = (row: any) => {
    setShowDelete(true);
    setRowInfo(row);
  };

  const clearRowInfo = () => {
    setRowInfo({
      id: 0,
      movementDate: "",
      movementType: "",
      amountType: "",
      amount: 0,
      paymentPeriod: "",
    });
  };

  const editableExcessDetail = excessDetail.filter(
    (item: any) =>
      item.movementType !== BALANCE_TRANSFER &&
      item.movementType !== RETURN_ON_DEMAND &&
      item.movementType !== REJECTED_RETURN &&
      item.movementType !== RETURN_MONTHLY
  );  

  const checkUpdatedAmount = () => {
    const updatedAmount = updatedMovementInfo().map((item: any) => {
      const { amount } = item;
      return amount;
    });
    const originalAmount = editableExcessDetail.map((item: any) => {
      const { amount } = item;
      return amount;
    });
    const isUpdated = updatedAmount.every(
      (item: any, index: number) => item !== originalAmount[index]
    );
    return isUpdated;
  } 

  useEffect(() => {
    onOpen && setMovementData(editableExcessDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen]);

  useEffect(() => {
    setMovementData(editableExcessDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excessDetail]);

  const confirmContent = () => {
    return (
      <div style={{ textAlign: "center", paddingTop: "19px" }}>
        <label className="firstTextModal">
          Estás eliminando el movimiento:
        </label>{" "}        
        <br></br>
        <label className="blueModalText">
          {getCurrentPeriod(rowInfo?.paymentPeriod as any)[0]} - monto de $
          {rowInfo?.amount}
        </label>
        <br></br>
        <label className="boldModalText">
          {" "}
          {movementData?.length === 1 &&
            "Al eliminar este movimiento se eliminará el registro de excesos de la tabla. "}
          ¿Estás seguro de continuar con esta acción?
        </label>
      </div>
    );
  };

  return (
    <>
      <ConfirmModalNew
        content={confirmContent()}
        label={DELETE}
        loading={loadingDeleteMovement}
        onClose={() => setShowDelete(false)}
        onOpen={showDelete}
        title={"Eliminar registro"}
        toDo={handleDeleteMovement}
      />
      <CustomModal
        maxWidth="lg"
        fullWidth
        open={onOpen}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <CustomModalTitle id="" onClose={handleCloseModal}>
          <Typography sx={littleTitle} textAlign="center" pt="32px">
            Editar registro
          </Typography>
          <Typography sx={modalSubtitle} textAlign="center">
            Aquí puedes editar todos los movimientos por periodo de un RUT.
          </Typography>
        </CustomModalTitle>
        <DialogContent dividers sx={{ p: "0 !important" }}>
          {loadingDeleteMovement ? (
            <SkeletonEditMovement rows={2} columns={5} />
          ) : (
            <>
              <Grid container p={"18px 24px"}>
                <Grid item xs={5}>
                  <Typography sx={purpleLittle} pb="4px">
                    Nombre y RUT cliente
                  </Typography>
                  <Typography sx={blackBold} pt="4px">
                    {contributorName} {contributorRut}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={purpleLittle} pb="4px">
                    Período recaudación
                  </Typography>
                  <Typography sx={blackBold} pt="4px">
                    {getCurrentPeriod(collectionPeriod as any)[0]}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={purpleLittle} pb="4px">
                    Saldo disponible
                  </Typography>
                  <Typography sx={blackBold} pt="4px">
                    ${availableBalance?.toLocaleString("es-CL")}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container p={"18px 24px"}>
                <Grid item xs={12}>
                  <Typography sx={titleTextBox} pb="4px">
                    Movimientos
                  </Typography>
                  <Typography sx={littleDetail} pt="4px">
                    Aquí puedes editar el monto del detalle de movimientos.
                  </Typography>
                </Grid>
                <Grid item xs={12} pt="24px">
                  <TableContainer
                    className="grayScroll"
                    component={Paper}
                    sx={{
                      boxShadow: "none",
                      borderRadius: "8px",
                      border: "0.5px solid #D2D6D9",
                      maxHeight: "290px",
                    }}
                  >
                    {movementData?.length > 0 ? (
                      <Table
                        sx={{ minWidth: "auto" }}
                        aria-label="simple table"
                        stickyHeader
                      >
                        <TableHead className="color-head-table">
                          <TableRow>
                            <StyledTableCell>
                              Periodo <br></br>remuneración
                            </StyledTableCell>
                            <StyledTableCell>
                              Fecha de <br></br> movimiento
                            </StyledTableCell>
                            <StyledTableCell>
                              Tipo de <br></br> movimiento
                            </StyledTableCell>
                            <StyledTableCell>
                              Detalle de <br></br> movimiento
                            </StyledTableCell>
                            <StyledTableCell sx={{ width: "132px !important" }}>
                              Monto
                            </StyledTableCell>
                            <StyledTableCell sx={{ width: "78px" }}>
                              Eliminar
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        {movementData?.map((row: any, index: number) => {
                          return (
                            <TableBody>
                              <TableRow key={index}>
                                <TableCell>
                                  <Typography variant="h5">
                                    {getCurrentPeriod(row.paymentPeriod)[0]}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="h5">
                                    {row.movementDate}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="h5">
                                    {capitalCase(row.amountType)}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="h5">
                                    {capitalCase(row.movementType)}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <CustomInputSlim
                                    onChange={(e) =>
                                      handleRowAmountChange(e, row.id, index)
                                    }
                                    error={movementData[index]?.amount === 0}
                                    value={movementData[index]?.amount}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                    inputProps={{
                                      maxLength: 10,
                                      min: 0,
                                      "data-testid": "input-amount",
                                    }}
                                    disabled={!canUpdate}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Button
                                    color="inherit"
                                    onClick={() => handleDeleteRow(row)}
                                    disabled={!canUpdate}
                                  >
                                    <img
                                      src={!canUpdate ? GrayTrash : GreenTrash}
                                      alt=""
                                    />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          );
                        })}
                      </Table>
                    ) : (
                      <NoResult
                        titulo={"No se encontraron resultados"}
                        subtitulo={
                          "No se encontraron movimientos editables asociados a este registro de excedentes."
                        }
                      />
                    )}
                  </TableContainer>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
          >
            <Button
              onClick={handleClose}
              color="secondary"
              sx={{ width: "150px", mr: "16px" }}
            >
              {CLOSE}
            </Button>
            {loading ? (
              <Button
                color="primary"
                sx={{ height: "50px", minWidth: "230px" }}
              >
                <div className="spinnerButton"></div>
              </Button>
            ) : (
              <Button
                onClick={handleSaveChanges}
                color="primary"
                sx={{ width: "230px" }}
                disabled={
                  !checkUpdatedAmount() || 
                  !checkValidAmount()}
              >
                {SAVE_CHANGES}
              </Button>
            )}
          </Grid>
        </DialogActions>
      </CustomModal>
    </>
  );
};
export default EditOverageNew;
