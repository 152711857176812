import { useEffect, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import {
  detailText,
  errorText,
  littleTitle,
  modalSubtitle,
} from "src/components/typography/styles";
import CustomInput from "src/components/input/customInput";
import {
  CustomModal,
  CustomModalTitle,
} from "src/components/modals/customModal";
import { handleRunChangeUtils } from "src/utils/validationUtils";
import { DatePicker } from "src/components/datePicker/datePicker";
import { formatRut } from "src/utils/stringUtils";
import moment from "moment";
import constants from "src/constants/constants";

const SearchPrevired = ({ onOpen, handleClose, filtros, setFiltros }: any) => {
  const [contributorRut, setContributorRut] = useState({
    run: filtros?.contributorRut || null,
    formattedRun: formatRut(filtros?.contributorRut) || "",
    error: "",
    isValid: false,
  });
  const [payerRut, setPayerRut] = useState({
    run: filtros?.payerRut || null,
    formattedRun: formatRut(filtros?.payerRut) || "",
    error: "",
    isValid: false,
  });
  const [disableButton, setDisableButton] = useState(true);
  const [paymentDate, setPaymentDate] = useState<any>(
    filtros?.paymentDate || null
  );

  const handleRunChange = (value: string, type: number) => {
    let checkRun = handleRunChangeUtils(value);
    if (type === 1) {
      setContributorRut({
        run: checkRun.runValue ? checkRun.runValue : null,
        formattedRun: checkRun.formattedRun,
        error: checkRun.error,
        isValid: checkRun.isValid,
      });
    }
    if (type === 2) {
      setPayerRut({
        run: checkRun.runValue ? checkRun.runValue : null,
        formattedRun: checkRun.formattedRun,
        error: checkRun.error,
        isValid: checkRun.isValid,
      });
    }
  };

  const checkValidRut = (data: any, type: number) => {
    if (data.error === "") {
      if (data.run !== "" && data.isValid) {
        return true;
      }
      if (data.run === "" || data.run === null) {
        return true;
      }
      if (type === 1 && data.run === filtros.contributorRut) {
        return true;
      }
      if (type === 2 && data.run === filtros.payerRut) {
        return true;
      }
    }

    return false;
  };

  const checkAnyInputUpdated = () => {
    let updatedCRut = filtros?.contributorRut !== contributorRut.run;
    let updatedPRut = filtros?.payerRut !== payerRut.run;
    let updatedPDate = filtros?.paymentDate !== paymentDate;
    let inputUpdated = Boolean(updatedCRut || updatedPRut || updatedPDate);
    return inputUpdated;
  };

  const checkAllInputsEmpty = () => {
    let emptyCRut = contributorRut.run === "" || contributorRut.run === null;
    let emptyPRut = payerRut.run === "" || payerRut.run === null;
    let emptyPDate = paymentDate === null;
    let inputsEmpty = Boolean(emptyCRut && emptyPRut && emptyPDate);
    return inputsEmpty;
  };

  const handleButton = () => {
    setDisableButton(
      !(
        checkValidRut(contributorRut, 1) &&
        checkValidRut(payerRut, 2) &&
        checkAnyInputUpdated() &&
        !checkAllInputsEmpty()
      )
    );
  };

  const handleFilter = () => {
    setFiltros({
      ...filtros,
      contributorRut:
        contributorRut.run !== null
          ? contributorRut.formattedRun.replace(/\./g, "")
          : null,
      payerRut:
        payerRut.run !== null ? payerRut.formattedRun.replace(/\./g, "") : null,
      paymentDate: paymentDate
        ? moment(paymentDate).format(constants.DATE.UPDATE_FORMAT)
        : null,
    });
    handleClose();
  };

  useEffect(() => {
    if (filtros !== undefined) {
      handleButton();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payerRut, contributorRut, paymentDate]);

  return (
    <CustomModal
      fullWidth
      maxWidth="md"
      open={onOpen}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <CustomModalTitle id="addCredits" onClose={handleClose}>
        <Typography sx={littleTitle} textAlign="center" pt="32px">
          Buscar en registros Previred
        </Typography>
        <Typography sx={modalSubtitle} textAlign="center">
          Seleccione los criterios de búsqueda para encontrar registros en
          Previred específicos.
        </Typography>
      </CustomModalTitle>
      <DialogContent dividers>
        <Grid container direction="column" alignItems="center">
          <Grid sx={{ margin: "0", width: "100%" }} container>
            <Grid
              item
              xs={6}
              p="0 !important"
              pr="24px !important"
              pb="24px !important"
            >
              <Typography sx={detailText} pb="6px">
                RUT Cliente
              </Typography>
              <CustomInput
                placeholder="Ingresar RUN"
                fullWidth
                value={contributorRut.formattedRun || contributorRut.run}
                onChange={(e: any) => handleRunChange(e.target.value, 1)}
                inputProps={{
                  "data-testid": "input-cRun",
                }}
              />
              {contributorRut.error && (
                <Typography sx={errorText}>{contributorRut.error}</Typography>
              )}
            </Grid>
            <Grid item xs={6} p="0 !important" pb="24px !important">
              <Typography sx={detailText} pb="6px">
                RUT Pagador
              </Typography>
              <CustomInput
                placeholder="Ingresar RUN"
                fullWidth
                value={payerRut.formattedRun || payerRut.run}
                onChange={(e: any) => handleRunChange(e.target.value, 2)}
                inputProps={{
                  "data-testid": "input-pRun",
                }}
              />
              {payerRut.error && (
                <Typography sx={errorText}>{payerRut.error}</Typography>
              )}
            </Grid>

            <Grid item xs={12} p="0 !important">
              <Typography sx={detailText} pb="6px">
                Fecha de pago
              </Typography>
              <DatePicker
                value={
                  paymentDate !== null
                    ? moment(paymentDate, "YYYY-MM-DD").toDate()
                    : null
                }
                onChange={(date: any) => {
                  setPaymentDate(date);
                }}
                label={""}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            onClick={handleClose}
            color="secondary"
            sx={{ width: "150px" }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleFilter}
            sx={{ width: "150px" }}
            disabled={disableButton}
          >
            Filtrar
          </Button>
        </Grid>
      </DialogActions>
    </CustomModal>
  );
};

export default SearchPrevired;
