import { Box, Typography, Grid } from "@mui/material";
import { littleCard } from "../box/styles";
import DownloadButton from "../buttons/DownloadButton";

export const title = {
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.0120588em",
  color: "#364855",
};

export const regis = {
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  letterSpacing: "0.0120588em",
  color: "#4D5D68",
  display: "inline",
};

const ter = {
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  letterSpacing: "0.0120588em",
  color: "#63717B",
  display: "inline",
};

export const total = {
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  letterSpacing: "0.0120588em",
  color: "#5D5FEF",
  pt: "8px",
};

const FileDetail = ({ name, registers, payment, documentId }: any) => {
  return (
    <Box sx={littleCard}>
      <Grid container>
        <Grid xs={6}>
          <Typography sx={title}>{name.replace(".txt", "")}</Typography>
          <Typography sx={regis}>{registers} registros</Typography>
          <Typography sx={ter}>/ 0 no encontrados</Typography>
          <Typography sx={total}>
            {payment.toLocaleString("es-CL", {
              style: "currency",
              currency: "CLP",
            })}
          </Typography>
        </Grid>
        <Grid xs={6} textAlign="right">
          <DownloadButton
            disabled={documentId === null ? true : false}
            display="detail"
            documentId={documentId}
            fileType={"txt"}
            txt={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FileDetail;
