import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CustomSelect, NoResult } from "src/components";
import constants from "src/constants/constants";
import plusGreen from "src/assets/icons/plusGreen.svg";
import plusGray from "src/assets/icons/plusGray.svg";
import EyeIcon from "src/assets/icons/greenEye.svg";
import { StyledTableCell } from "src/components/tables/tableStyle";
import { paginatorTheme } from "src/components/paginator/paginatorStyle";
import { getCurrentPeriod } from "src/utils/dates";
import AddMovementNew from "./AddMovementNew";
import EditMovementNew from "./EditMovementNew";
import useOverage from "src/pages/distribution/overage/hook/useOverage";
import DetailExcess from "src/pages/distribution/excess/components/detailExcess";
import { MenuExcessRut } from "src/pages/distribution/excess/components/menuExcessRut";
import ExportOverage from "src/pages/distribution/overage/components/exportOverage";
import { SkeletonTableOverage } from "src/pages/distribution/overage/components/loaderOverage";
import { excessConsolidatedInputType } from "../rutSearchTypes";
import { renderAmount } from "src/utils/numberUtils";

const {
  COMPONENTS: {
    BUTTONS: { MOVEMENT },
    MENU_ITEMS: { DELETE_MOVEMENTS },
    NO_RESULT: {
      ERROR,
      ERROR_SUBTITLE,
      NEW_SEARCH,
      NEW_SEARCH_SUBTITLE,
      NO_RESULT_RUT,
      NO_RESULT_RUT_SUBTITLE,
    },
    SELECTS: {
      DATE_RANGE: { TWELVE_MONTHS, TWENTY_FOUR_MONTHS },
    },
  },
} = constants;

type OverageProps = {
  contributorName: string;
  excessAvailableBalance: number;
  rutSearch: string;
  setSnackInfo: React.Dispatch<React.SetStateAction<any>>;
  setLoadingRutSearch: React.Dispatch<React.SetStateAction<boolean>>;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

const ByRutExcess = ({
  contributorName,
  excessAvailableBalance,
  rutSearch,
  setSnackInfo,
  setLoadingRutSearch,
  refresh,
  setRefresh,
}: OverageProps) => {
  const [dateLimit, setDateLimit] = useState(1);
  const [showAddMovement, setShowAddMovement] = useState(false);
  const [showEditMovement, setShowEditMovement] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [disabledActions, setDisabledActions] = useState(false);
  const [rowInfo, setRowInfo] = useState<any>({
    id: "",
    contributorRut: "",
    contributorName: "",
    collectionPeriod: "",
    paymentPeriod: "",
    availableBalance: 0,
    statusId: 0,
    statusName: "",
    typeId: 0,
    excessDetail: [],
  });
  const [rutOptions, setRutOptions] = useState<excessConsolidatedInputType>({
    isActive: true,
    contributorRut: rutSearch.replace(/\./g, ""),
    dateLimit: dateLimit === 1 ? 12 : 24,
    statusId: null,
    limit: 10,
    offset: 0,
  });

  const {
    getOverageData,
    countOverage,
    rowsOverage,
    loadingOverage,
    errorOverage,
  } = useOverage({
    options: rutOptions,
  });

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleDetails = (elem: any, type: number) => {
    setRowInfo({
      id: elem.id,
      paymentPeriod: elem.paymentPeriod,
      contributorRut: elem.contributorRut,
      contributorName: elem.contributorName,
      collectionPeriod: elem.collectionPeriod,
      availableBalance: elem.availableBalance,
      statusId: elem.statusId,
      statusName: elem.statusName,
      typeId: elem.typeId,
      excessDetail: elem.excessDetail,
    });
    type === 1 ? setShowDetail(true) : setShowEditMovement(true);
  };

  useEffect(() => {
    rutSearch &&
      setRutOptions({
        ...rutOptions,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  useEffect(() => {
    if (rutSearch) {
      setRutOptions({
        ...rutOptions,
        contributorRut: rutSearch.replace(/\./g, ""),
        offset: 0,
      });
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rutSearch]);

  useEffect(() => {
    setPage(1);
    setRutOptions({
      ...rutOptions,
      dateLimit: dateLimit === 1 ? 12 : 24,
      offset: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateLimit]);

  useEffect(() => {
    rutSearch && getOverageData(rutOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rutOptions]);

  useEffect(() => {
    setLoadingRutSearch(loadingOverage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingOverage]);

  useEffect(() => {
    if (page === 1 && rowsOverage.length > 0) {
      setRowInfo({
        id: rowsOverage[0].id,
        paymentPeriod: rowsOverage[0].paymentPeriod,
        contributorRut: rowsOverage[0].contributorRut,
        contributorName: rowsOverage[0].contributorName,
        collectionPeriod: rowsOverage[0].collectionPeriod,
        availableBalance: rowsOverage[0].availableBalance,
        statusId: rowsOverage[0].statusId,
        statusName: rowsOverage[0].statusName,
        typeId: rowsOverage[0].typeId,
        excessDetail: rowsOverage[0].excessDetail,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsOverage]);

  useEffect(() => {
    if (refresh) {
      setRutOptions({
        ...rutOptions,
        offset: 0,
      });
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const renderEmptyState = () => {
    if (errorOverage) {
      return <NoResult titulo={ERROR} subtitulo={ERROR_SUBTITLE} error />;
    } else if (!rutSearch) {
      return (
        <NoResult
          titulo={NEW_SEARCH}
          subtitulo={NEW_SEARCH_SUBTITLE}
          newDontSearch
        />
      );
    } else {
      return (
        <NoResult
          titulo={NO_RESULT_RUT}
          subtitulo={NO_RESULT_RUT_SUBTITLE}
          newFound
        />
      );
    }
  };

  return (
    <>
      <AddMovementNew
        onOpen={showAddMovement}
        handleCloseModal={() => setShowAddMovement(false)}
        info={{
          contributorRut: rutSearch,
          contributorName: contributorName,
          availableBalance: excessAvailableBalance,
        }}
        setRefresh={setRefresh}
        setSnackInfo={setSnackInfo}
      />
      <EditMovementNew
        onOpen={showEditMovement}
        handleCloseModal={() => setShowEditMovement(false)}
        info={rowInfo}
        setRefresh={setRefresh}
        setSnackInfo={setSnackInfo}
      />
      <DetailExcess
        onOpen={showDetail}
        handleCloseModal={() => setShowDetail(false)}
        info={rowInfo}
        type={2}
      />
      {loadingOverage ? (
        <SkeletonTableOverage rows={3} columns={4} filter={false} />
      ) : (
        <Box
          sx={{
            border: "1px solid #E9EBEC",
            borderRadius: "8px",
            mt: "16px",
            backgroundColor: "#E6F6F53D",
          }}
          mt="16px"
          mb="24px"
        >
          <Grid container p="24px">
            <Grid item xs={4}>
              <Typography variant="h3" pb="6px">
                Periodo
              </Typography>
              <CustomSelect
                titulo={TWELVE_MONTHS}
                hideTitle
                value={dateLimit}
                name="fileType"
                onChange={(e: any) => setDateLimit(e.target.value)}
                data={[TWELVE_MONTHS, TWENTY_FOUR_MONTHS]}
                disabled={
                  !rutSearch || loadingOverage || countOverage === 0
                    ? true
                    : false
                }
              />
            </Grid>
            <Grid item xs={8} pt="29px" sx={{ textAlignLast: "end" }}>
              <Button
                onClick={() => setShowAddMovement(true)}
                sx={{ p: "14px 22px !important", mr: "24px" }}
                color={"secondary"}
                startIcon={
                  <img
                    src={
                      !rutSearch || loadingOverage || countOverage === 0
                        ? plusGray
                        : plusGreen
                    }
                    style={{ paddingRight: "12px" }}
                    alt="add"
                  />
                }
                disabled={
                  !rutSearch || loadingOverage || countOverage === 0
                    ? true
                    : false
                }
              >
                {" "}
                {MOVEMENT}
              </Button>
              <ExportOverage
                period={null}
                setSnackInfo={setSnackInfo}
                disabled={
                  !rutSearch ||
                  loadingOverage ||
                  disabledActions ||
                  countOverage === 0
                    ? true
                    : false
                }
                contributorRut={rutSearch.replace(/\./g, "")}
                isActive={true}
                order={2}
                noData={countOverage === 0}
                setDisabledActions={setDisabledActions}
              />
            </Grid>
          </Grid>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "none",
              borderRadius: "none",
              paddingTop: "none",
              borderBottom: "none",
            }}
          >
            {countOverage === 0 || !rutSearch ? (
              renderEmptyState()
            ) : (
              <Table sx={{ minWidth: 650 }} aria-label="customized table">
                <TableHead className="color-head-table">
                  <TableRow>
                    <StyledTableCell sx={{ minWidth: "114px" }}>
                      Periodo de recaudación
                    </StyledTableCell>
                    <StyledTableCell sx={{ minWidth: "134px" }}>
                      Abono
                    </StyledTableCell>
                    <StyledTableCell sx={{ minWidth: "113px" }}>
                      Cargo
                    </StyledTableCell>
                    <StyledTableCell sx={{ minWidth: "142px" }}>
                      Saldo
                    </StyledTableCell>
                    <StyledTableCell
                      className="headerTableAcciones"
                      align="center"
                      sx={{ minWidth: "120px" }}
                    >
                      Ver detalle
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                {rowsOverage?.map((row: any, index: number) => {
                  return (
                    <TableBody>
                      <TableRow data-testid="withData" key={index}>
                        <TableCell>
                          <Typography variant="h5">
                            {getCurrentPeriod(row.collectionPeriod)[0]}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h5">
                            ${renderAmount(row.creditAmount)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h5">
                            ${renderAmount(row.chargeAmount)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h5">
                            ${renderAmount(row.availableBalance)}
                          </Typography>
                        </TableCell>
                        {
                          <>
                            {page === 1 && index === 0 ? (
                              <TableCell
                                className="tableBodyAsignar"
                                align="center"
                                sx={{ zIndex: 999 }}
                              >
                                <MenuExcessRut
                                  info={row}
                                  handleEdit={() => handleDetails(row, 2)}
                                  handleDetails={() => handleDetails(row, 1)}
                                  canUpdate={true}
                                  label={DELETE_MOVEMENTS}
                                />
                              </TableCell>
                            ) : (
                              <TableCell
                                className="tableBodyAsignar"
                                align="center"
                                sx={{ zIndex: 999 }}
                              >
                                <Button
                                  color="secondary"
                                  data-testid="show-row-button"
                                  onClick={() => handleDetails(row, 1)}
                                >
                                  <img src={EyeIcon} alt="" />
                                </Button>
                              </TableCell>
                            )}
                          </>
                        }
                      </TableRow>
                    </TableBody>
                  );
                })}
              </Table>
            )}
          </TableContainer>
          {countOverage !== 0 && rutSearch && (
            <Grid
              alignItems="center"
              sx={{
                pt: "24px",
                pb: "24px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <ThemeProvider theme={paginatorTheme}>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={countOverage}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={"Mostrar por página"}
                  labelDisplayedRows={(e) => {
                    return (
                      "" + page + " de " + Math.ceil(countOverage / rowsPerPage)
                    );
                  }}
                />
              </ThemeProvider>
            </Grid>
          )}
        </Box>
      )}
    </>
  );
};

export default ByRutExcess;
