import { useReducer, useState } from "react";
import { BULK_UPDATE_PAYMENT_STATUS } from "src/services/graphql/mutations";
import { useMutation } from "@apollo/client";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import {
  detailText,
  littleTitle,
  modalSubtitle,
} from "src/components/typography/styles";
import {
  AlertSnackbar,
  CustomModal,
  CustomModalTitle,
  CustomSelect,
} from "src/components";
import constants from "src/constants/constants";
import {
  SNACK_ACTION_TYPE,
  SNACKINFO_INITIAL_STATE,
  snackInfoReducer,
} from "src/reducers/snackInfoReducer";

const { CLOSE_SNACK_INFO, SET_SNACK_ERROR, SET_SNACK_SUCCESS } =
  SNACK_ACTION_TYPE;
const {
  COMPONENTS: {
    BUTTONS: { EDIT, CANCEL },
  },
  SNACKBAR_MESSAGE: {
    COLLECTION: { PAYMENTS_BULK_UPDATE_ERROR, PAYMENTS_BULK_UPDATE_SUCCESS },
  },
} = constants;

const EditClassification = ({
  onOpen,
  handleClose,
  classOptions,
  data,
  setRefresh,
  resetCheck,
}: any) => {
  const [paymentStatus, setPaymentStatus] = useState<any>(null);
  const [classification, setClassification] = useState<any>(null);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [snackInfoState, snackInfoDispatch] = useReducer(
    snackInfoReducer,
    SNACKINFO_INITIAL_STATE
  );
  const [bulkUpdatePaymentStatus, { loading }] = useMutation(
    BULK_UPDATE_PAYMENT_STATUS,
    {
      fetchPolicy: "network-only",
      variables: {
        input: {
          ids: data,
          statusId: paymentStatus,
        },
      },
      onCompleted: () => {
        setRefresh(true);
        snackInfoDispatch({
          type: SET_SNACK_SUCCESS,
          payload: PAYMENTS_BULK_UPDATE_SUCCESS,
        });
        handleCloseAux();
      },
      onError: () => {
        snackInfoDispatch({
          type: SET_SNACK_ERROR,
          payload: PAYMENTS_BULK_UPDATE_ERROR,
        });
        handleCloseAux();
      },
    }
  );

  const getClassOptions = () => {
    let optionsArray: any[] = [];
    if (classOptions) {
      classOptions.forEach((element: any) => optionsArray.push(element));
    }
    return optionsArray;
  };

  const findClass = (id: string) => {
    let option = null;
    if (classOptions) {
      classOptions.forEach((element: any) => {
        // eslint-disable-next-line eqeqeq
        if (id == element.id) {
          option = element.statusId;
        }
      });
    }
    return option ? parseInt(option) : null;
  };

  const handleClassChange = (id: string) => {
    setClassification(id);
    setPaymentStatus(findClass(id));
    if (data && data.length > 0) {
      setDisabled(false);
    }
  };

  const handleCloseAux = () => {
    handleClose();
    setClassification(null);
    setPaymentStatus(null);
    setDisabled(true);
    resetCheck();
  };

  return (
    <>
      <AlertSnackbar
        onClick={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        onClose={() => snackInfoDispatch({ type: CLOSE_SNACK_INFO })}
        open={snackInfoState.open}
        status={snackInfoState.status}
        message={snackInfoState.message}
      />
      <CustomModal
        fullWidth
        maxWidth="sm"
        open={onOpen}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <CustomModalTitle id="addCredits" onClose={handleClose}>
          <Typography sx={littleTitle} textAlign="center" pt="32px">
            Editar clasificación
          </Typography>
          <Typography sx={modalSubtitle} textAlign="center">
            Selecciona para modificar.
          </Typography>
        </CustomModalTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="column"
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid sx={{ margin: "0", width: "100%" }} container spacing={2}>
              <Grid item xs={12} p="0 !important">
                <Typography sx={detailText} pb="6px">
                  Clasificación
                </Typography>
                <CustomSelect
                  titulo={"Seleccione"}
                  hideTitle={true}
                  value={classification}
                  onChange={(e: any) => {
                    handleClassChange(e.target.value);
                  }}
                  data={getClassOptions().map((elem: any) => elem.name)}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              onClick={handleClose}
              color="secondary"
              sx={{ width: "150px" }}
            >
              {CANCEL}
            </Button>
            {loading ? (
              <Button
                color="primary"
                sx={{ height: "50px", minWidth: "150px" }}
              >
                <div className="spinnerButton"></div>
              </Button>
            ) : (
              <Button
                sx={{ width: "150px" }}
                disabled={disabled}
                onClick={() => bulkUpdatePaymentStatus()}
              >
                {EDIT}
              </Button>
            )}
          </Grid>
        </DialogActions>
      </CustomModal>
    </>
  );
};

export default EditClassification;
